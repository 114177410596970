import React from 'react';
import Tooltip from 'src/components/Tooltip';
import moment from 'moment';
import {
  apiDataToLabelMap,
  labelToUrlKeyMap,
  urlToGraphQLValueMap,
} from '../../maps';
import { Users } from 'react-feather';
import get from 'lodash.get';
import './styles.scss';
import { navigate, useLocation } from '@reach/router';
const getHasRelatedWebform = (allWebforms, currentWebform) => {
  const relatedWebforms = get(currentWebform, 'relatedWebforms') || [];
  // const foundRelatedWebform =
  //   relatedWebforms.length > 0
  //     ? relatedWebforms.find(relatedWebform => {
  //         return currentWebform.node.email === relatedWebform.email;
  //       })
  //     : null;

  const foundRelatedWebform = relatedWebforms.length > 0 ? true : false;

  if (foundRelatedWebform) return true;

  // const foundMultipleWebformsByEmail = allWebforms.filter(
  //   lead => currentWebform.node.email === lead.node.email
  // );
  // if (foundMultipleWebformsByEmail.length > 1) {
  //   return true;
  // }
  // return false;
};

const WebformsTable = ({
  onRowClick,
  selectedWebform = null,
  updatedStatus = null,
  data,
  customClass = '',
  customTableClass = '',
}) => {
  console.log('data.edges', data.edges);
  return (
    <div className={`card rounded table-responsive ${customClass}`}>
      <table className={`leads-table table px-3 ${customTableClass}`}>
        <thead>
          <tr>
            <th>Reference</th>
            <th>Date</th>
            {/* <th>Source</th>
            <th className="status-header" id="Popover1">
              <Tooltip
                label="Click dropdown to update status"
                placement="top"
                id={`leads-status-tooltip`}
              >
                <span id="update-status">Status</span>
              </Tooltip>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data.edges.length === 0 && (
            <tr>
              <td colSpan="5" className="no-leads-heading mt-2">
                No leads to display
              </td>
            </tr>
          )}
          {data.edges.map(({ node: webform }, index) => {
            // const hasRelatedWebform = getHasRelatedWebform(nodes, lead);
            // const updatedWebformByStatus =
            //   selectedWebform && selectedWebform.id === lead.id;

            return (
              <tr
                key={index}
                onClick={() => {
                  navigate(`/webforms/${webform.id}`);
                  //onRowClick(lead, hasRelatedWebform);
                  // setSelectedWebform({
                  //   ...lead.node,
                  //   emailDate: lead.node && lead.node.leadDate,
                  // });
                  // setModalIsOpen(true);
                  // setUpdatedStatus(lead.node.status);
                }}
              >
                <td className="d-flex align-items-center">
                  {webform.reference}|
                </td>

                <td>
                  {moment(webform.createdAt)
                    // .utc()
                    .format('MM/DD/YYYY hh:mm A')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WebformsTable;
