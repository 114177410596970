import React from 'react';

import {
  formatDateWithDashes,
  addDays,
  getDateRange,
  getDaysInMonth,
  getAllMonthsWithinRange,
} from '../../../../../util/dateHelper';

const tooShortWithinMinStayNumber = 3;

// const getCellAttributes = ({ resource, currentDateCellDateOffset, currentDay }) => {
//   const changeOverAlerts = (resource?.calendar?.changeOverAlert || '').split(
//     ''
//   );

//   const getCellTitle = () => {
//     if (changeOverAlerts[currentDateCellDateOffset] === 'Y')
//       return 'Same-day change over. Limited cleaning & maintenance window.';
//   };
//   const getCellClassNames = () => {
//     const classNames = [];
//     if (changeOverAlerts[currentDateCellDateOffset] === 'Y')
//       classNames.push('change-over-alert');
//     if (currentDay === 'DISCRG') {
//       classNames.push('discourage-alert');
//     }
//     if (currentDay === 'CANBL') {
//       classNames.push('cannibalizm-alert');
//     }
//     return classNames.length === 0 ? 'reserved-full-day' : classNames.join(' ');
//   };
//   const attributes = {};

//   return {
//     title: getCellTitle(),
//     className: getCellClassNames(),
//   };
// };

const ResourceAvailabliltyTd = ({
  date,
  index,
  lessThanMinumumStayClass,
  title,
  className,
  currentDay,
}) => {
  return (
    <td
      data-current-availability={currentDay}
      data-date={formatDateWithDashes(date)}
      key={index}
      // data-container="th"
      data-html="true"
      data-toggle="tooltip"
      data-placement="bottom"
      data-template={`<div class="tooltip calendar-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`}
      title={title}
      className={className}
    >
      5
    </td>
  );
};

const ResourceEventCells = ({
  resource,
  events,
  dateRange,
  showMinStay,
  showRates,
  showRateDistribution,
}) => {
  const availability = (resource?.calendar?.availability || '').split('');

  const resourceBlockTypes = (resource?.calendar?.blockType || '').split(',');

  // console.log('resourceBlockTypes', resourceBlockTypes);
  // console.log('str', (resource?.calendar?.availability || '').split(''));
  const changeOverAlerts = (resource?.calendar?.changeOverAlert || '').split(
    ''
  );
  const checkInRestrictions = (
    resource?.calendar?.checkInRestriction || ''
  ).split('');
  const checkOutRestrictions = (
    resource?.calendar?.checkOutRestriction || ''
  ).split('');
  const minStayThresholdAlerts = (
    resource?.calendar?.minStayThresholdAlert || ''
  ).split('');
  const childPropertyRateAlertType = (
    resource?.calendar?.childPropertyRateAlertType || ''
  ).split('');
  const childPropertyRateSum = (
    resource?.calendar?.childPropertyRateSum || ''
  ).split(',');
  const minStayValues = (resource?.calendar?.minStay || '').split(',');
  const ratesValues = (resource?.calendar?.rates || '').split(',');

  // const changeOverAlerts = (resource?.calendar?.changeOverAlert || '').split(
  //   ''
  // );

  const resourceStartDate = resource?.calendar?.start
    ? new Date(resource?.calendar?.start)
    : new Date();

  // let currentLastUnavailableNightInRange;

  // const getCurrentLastNightUnavailableInRange = (
  //   availabilityRange,
  //   currentClosestAvailableCheckInIndex
  // ) => {
  //   const minStayRangeFromCheckIn = availabilityRange.slice(
  //     currentClosestAvailableCheckInIndex
  //   );

  //   minStayRangeFromCheckIn.find((date, index) => {
  //     currentLastUnavailableNightInRange = index + 1;

  //     return date === 'Y' && minStayRangeFromCheckIn[index + 1] === 'N';
  //   });
  //   return currentLastUnavailableNightInRange;
  // };

  return dateRange.map((date, index) => {
    const currentDateCellDateOffset = Math.floor(
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
        Date.UTC(
          resourceStartDate.getFullYear(),
          resourceStartDate.getMonth(),
          resourceStartDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
    const getBlockType = (offset = 0) => {
      return `${resourceBlockTypes[currentDateCellDateOffset + offset] ||
        'a'}`.toLowerCase();
    };
    const getCheckInRestriction = (offset = 0) => {
      return `${
        checkInRestrictions[currentDateCellDateOffset + offset]
      }`.toLowerCase();
    };
    const getChildPropertyRateAlertType = (offset = 0) => {
      return `${
        childPropertyRateAlertType[currentDateCellDateOffset + offset]
      }`.toLowerCase();
    };
    const getCheckOutRestriction = (offset = 0) => {
      return `${
        checkOutRestrictions[currentDateCellDateOffset + offset]
      }`.toLowerCase();
    };
    const getChangeOverAlert = () => {
      return `${changeOverAlerts[currentDateCellDateOffset]}`.toLowerCase();
    };
    const getMinStay = () => {
      return minStayValues[currentDateCellDateOffset]
        ? `${minStayValues[currentDateCellDateOffset]}`.toLowerCase()
        : '';
    };
    const getRates = () => {
      return ratesValues[currentDateCellDateOffset]
        ? `${ratesValues[currentDateCellDateOffset]}`.toLowerCase()
        : '';
    };
    // const getChangeOverRestrictionAlert = () => {
    //   return `${
    //     checkInRestrictions[currentDateCellDateOffset] === 'Y' ||
    //     checkOutRestrictions[currentDateCellDateOffset] === 'Y'
    //       ? 'Y'
    //       : 'N'
    //   }`.toLowerCase();
    // };
    const getTitleTag = () => {
      if (childPropertyRateAlertType[currentDateCellDateOffset] === 'L')
        return `Combo property rate too low. Target Rate: $${childPropertyRateSum[currentDateCellDateOffset]}`;
      if (childPropertyRateAlertType[currentDateCellDateOffset] === 'H')
        return `Combo property rate too high. Target Rate: $${childPropertyRateSum[currentDateCellDateOffset]}`;
      if (
        checkOutRestrictions[currentDateCellDateOffset] === 'Y' ||
        checkInRestrictions[currentDateCellDateOffset] === 'Y'
      ) {
        const restrictions = [];
        if (
          checkInRestrictions[currentDateCellDateOffset] === 'Y' &&
          availability[currentDateCellDateOffset] === 'Y'
        ) {
          restrictions.push('In');
        }
        if (
          checkOutRestrictions[currentDateCellDateOffset] === 'Y' &&
          availability[currentDateCellDateOffset - 1] === 'Y'
        ) {
          restrictions.push('Out');
        }
        if (restrictions.length > 0) {
          return `Check ${restrictions.join('/')} Restriction`;
        }
      }
      if (changeOverAlerts[currentDateCellDateOffset] === 'Y')
        return 'Same-day change over. Limited cleaning & maintenance window.';
      if (resourceBlockTypes[currentDateCellDateOffset] === 'DISCRG')
        return 'Discouragement Block';
      if (resourceBlockTypes[currentDateCellDateOffset] === 'CANBL')
        return 'Cannibalism Block';

      return null;
    };
    // const blockTypePreviousDate = resourceBlockTypes[currentDateCellDateOffset + 1];
    // const blockTypeCurrentDate = resourceBlockTypes[currentDateCellDateOffset];
    // const blockTypeNextDate = resourceBlockTypes[currentDateCellDateOffset - 1];
    return (
      <td
        className={`date-cell current-date-${
          getBlockType() == 'a' ? 'available' : 'blocked'
        } 
        child-property-rate-alert-type-${
          showRateDistribution ? getChildPropertyRateAlertType() : ''
        }
        next-date-${getBlockType(1) == 'a' ? 'available' : 'blocked'}
        previous-date-${getBlockType(-1) == 'a' ? 'available' : 'blocked'}
        check-in-restriction-${getCheckInRestriction()}
        check-out-restriction-${getCheckOutRestriction()}
        change-over-alert-${getChangeOverAlert()} previous-block-type-${getBlockType(
          -1
        )} current-block-type-${getBlockType()} next-block-type-${getBlockType(
          1
        )}`}
        title={getTitleTag()}
      >
        {showMinStay &&
        (getBlockType() == 'a' ||
          (getBlockType(-1) == 'a' && getBlockType(1) == 'a'))
          ? getMinStay()
          : ''}
        {showRates &&
        (getBlockType() == 'a' ||
          (getBlockType(-1) == 'a' && getBlockType(1) == 'a')) ? (
          <div className="rate-label">
            ${Number(getRates()).toLocaleString('en-US')}
          </div>
        ) : (
          ''
        )}
      </td>
    );

    // const currentDay = resourceBlockTypes[currentDateCellDateOffset];
    // const nextDay = resourceBlockTypes[currentDateCellDateOffset + 1];
    // const previousDay = resourceBlockTypes[currentDateCellDateOffset - 1];

    // const currentDayIsBlocked = currentDay !== 'A';
    // const nextDayIsBlocked = nextDay !== 'A';
    // const previousDayIsBlocked = previousDay !== 'A';
    // const currentDayIsAvailable = currentDay === 'A';
    // const nextDayIsAvailable = nextDay === 'A';
    // const previousDayIsAvailable = previousDay === 'A';
    // console.log('date', date);
    // console.log('currentDay', currentDay);
    // const addCheckInReservedClasses = classNames => {
    //   if (nextDay === 'DISCRG') {
    //     return `${classNames} discourage-alert-check-in`;
    //   }
    //   if (nextDay === 'CANBL') {
    //     return `${classNames} cannibalism-alert-check-in`;
    //   }

    //   return classNames;
    // };
    // const addCheckOutReservedClasses = classNames => {
    //   // min-stay-reserved-check-out

    //   if (previousDay === 'DISCRG') {
    //     // return `${classNames} ${
    //     //   classNames.indexOf('min-stay-reserved-check-out') !== -1
    //     //     ? 'discourage-alert-min-stay-check-out'
    //     //     : 'discourage-alert-check-out'
    //     return `${classNames} discourage-alert-check-out`;
    //   }
    //   if (previousDay === 'CANBL') {
    //     return `${classNames} cannibalism-alert-check-out`;
    //   }

    //   return classNames;
    // };

    // const getCellAttributes = ({ currentDay }) => {
    //   // this function deals with our new blockTypes as well as changeover shading for FULL days (no check in or check out shading)
    //   const changeOverAlerts = (
    //     resource?.calendar?.changeOverAlert || ''
    //   ).split('');

    //   const getCellTitle = () => {
    //     if (changeOverAlerts[currentDateCellDateOffset] === 'Y')
    //       return 'Same-day change over. Limited cleaning & maintenance window.';
    //   };
    //   const getCellClassNames = () => {
    //     const classNames = [];
    //     if (changeOverAlerts[currentDateCellDateOffset] === 'Y') {
    //       classNames.push('change-over-alert');
    //       if (previousDay === 'DISCRG') {
    //         classNames.push('discourage-alert-check-out');
    //       }
    //     }

    //     if (currentDay === 'DISCRG') {
    //       if (previousDay !== 'DISCRG') {
    //         classNames.push('discourage-alert-check-in');
    //       } else classNames.push('discourage-alert');
    //       // TODO: account for yin and yang may 28th 2021
    //     }
    //     if (currentDay === 'CANBL') {
    //       if (previousDay !== 'CANBL') {
    //         classNames.push('cannibalism-alert-check-in');
    //       } else classNames.push('cannibalism-alert');
    //     }
    //     if (classNames.length === 0) {
    //       if (previousDay === 'DISCRG') {
    //         classNames.push('reserved-check-in discourage-alert-check-out');
    //       }
    //       if (previousDay === 'CANBL') {
    //         classNames.push('reserved-check-in cannibalism-alert-check-out');
    //       }
    //     }
    //     return classNames.length === 0
    //       ? 'reserved-full-day'
    //       : classNames.join(' ');
    //   };
    //   const attributes = {};

    //   return {
    //     title: getCellTitle(),
    //     className: getCellClassNames(),
    //   };
    // };

    // const getPastClosestAvailableCheckInIndex = currentDateCellDateOffset => {
    //   // if a custom range is set and an available check in date has not been defined, we must loop through the past dates and get the last available check in date
    //   const rangeToCurrentDate = resourceBlockTypes.slice(0, currentDateCellDateOffset);
    //   const currentCheckInIndex = rangeToCurrentDate.reduce(
    //     (acc, item, index) => {
    //       let counter = index + 1;
    //       const nextDay = resourceBlockTypes[counter + 1];
    //       const previousDay = resourceBlockTypes[counter - 1];
    //       const previousDayIsBlocked = previousDay !== 'A';
    //       const nextDayIsAvailable = nextDay === 'A';

    //       if (previousDayIsBlocked && nextDayIsAvailable) {
    //         acc = counter;
    //       }
    //       return acc;
    //     },
    //     0
    //   );

    //   return currentCheckInIndex ? currentCheckInIndex : null;
    // };

    // const getMinStayThreshholdTrueCellShading = (currentDateCellDateOffset, date, index) => {
    //   const currentClosestAvailableCheckInIndex = getPastClosestAvailableCheckInIndex(
    //     currentDateCellDateOffset
    //   );

    //   if (
    //     minStayThresholdAlerts[currentDateCellDateOffset - 1] === 'Y' ||
    //     previousDayIsAvailable
    //   ) {
    //     const currentLastUnavailableNightInRange = getCurrentLastNightUnavailableInRange(
    //       minStayThresholdAlerts,
    //       currentClosestAvailableCheckInIndex
    //     );

    //     const isLessThanMinumumStay =
    //       currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;

    //     return (
    //       <ResourceAvailabliltyTd
    //         currentDay={currentDay}
    //         currentDateCellDateOffset={currentDateCellDateOffset}
    //         index={index}
    //         key={index}
    //         className={`date-cell position-relative ${
    //           isLessThanMinumumStay ? '' : 'reserved-min-stay-full-day'
    //         }`}
    //         title={
    //           isLessThanMinumumStay
    //             ? 'Availability too short to sell'
    //             : 'MinStay exceeds days available'
    //         }
    //       />
    //     );
    //   }

    //   const currentLastUnavailableNightInRange = getCurrentLastNightUnavailableInRange(
    //     minStayThresholdAlerts,
    //     currentClosestAvailableCheckInIndex
    //   );
    //   const isLessThanMinumumStay =
    //     currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;
    //   return (
    //     <ResourceAvailabliltyTd
    //       currentDay={currentDay}
    //       currentDateCellDateOffset={currentDateCellDateOffset}
    //       index={index}
    //       key={index}
    //       className={`${
    //         isLessThanMinumumStay
    //           ? addCheckOutReservedClasses('reserved-check-out')
    //           : 'reserved-min-stay-check-out'
    //       } `}
    //       title={
    //         isLessThanMinumumStay
    //           ? 'Availability too short to sell'
    //           : 'MinStay exceeds days available'
    //       }
    //     />
    //   );
    // };

    // // const currentDateCellDateOffset = Math.floor(
    // //   (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
    // //     Date.UTC(
    // //       resourceStartDate.getFullYear(),
    // //       resourceStartDate.getMonth(),
    // //       resourceStartDate.getDate()
    // //     )) /
    // //     (1000 * 60 * 60 * 24)
    // // );

    // if (minStayThresholdAlerts[currentDateCellDateOffset] === 'Y') {
    //   return getMinStayThreshholdTrueCellShading(currentDateCellDateOffset, date, index);
    // }

    // if (minStayThresholdAlerts[currentDateCellDateOffset + 1] === 'Y') {
    //   // if minStayThresholdAlerts[currentDateCellDateOffset] === 'N'
    //   // red or yellow shading only if the next day is fully shaded red or yellow. We check by making sure the days after next is fully shaded
    //   // currentUnavailableMinStayCheckInIndex = currentDateCellDateOffset;

    //   if (currentDayIsAvailable) {
    //     const currentClosestAvailableCheckInIndex = getPastClosestAvailableCheckInIndex(
    //       currentDateCellDateOffset
    //     );

    //     const currentLastUnavailableNightInRange = getCurrentLastNightUnavailableInRange(
    //       minStayThresholdAlerts,
    //       currentClosestAvailableCheckInIndex
    //     );
    //     const isLessThanMinumumStay =
    //       currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;
    //     return (
    //       <ResourceAvailabliltyTd
    //         currentDay={currentDay}
    //         currentDateCellDateOffset={currentDateCellDateOffset}
    //         index={index}
    //         key={index}
    //         className={`date-cell position-relative ${
    //           isLessThanMinumumStay ? '' : 'reserved-min-stay-full-day'
    //         }`}
    //         title={
    //           isLessThanMinumumStay
    //             ? 'Availability too short to sell'
    //             : 'MinStay exceeds days available'
    //         }
    //       />
    //     );
    //   }
    // }
    // if (minStayThresholdAlerts[currentDateCellDateOffset - 1] === 'Y') {
    //   // min stay is 'N' and the previous day min stay is Y
    //   const currentClosestAvailableCheckInIndex = getPastClosestAvailableCheckInIndex(
    //     currentDateCellDateOffset
    //   );

    //   const currentLastUnavailableNightInRange = getCurrentLastNightUnavailableInRange(
    //     minStayThresholdAlerts,
    //     currentClosestAvailableCheckInIndex
    //   );
    //   const isLessThanMinumumStay =
    //     currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;

    //   return (
    //     <ResourceAvailabliltyTd
    //       currentDay={currentDay}
    //       currentDateCellDateOffset={currentDateCellDateOffset}
    //       index={index}
    //       key={index}
    //       title={
    //         isLessThanMinumumStay
    //           ? 'Availability too short to sell'
    //           : 'MinStay exceeds days available'
    //       }
    //       className={`${
    //         isLessThanMinumumStay
    //           ? addCheckInReservedClasses('reserved-check-in')
    //           : 'reserved-min-stay-check-in'
    //       }`}
    //     />
    //   );
    // }

    // if (
    //   currentDayIsBlocked &&
    //   previousDayIsAvailable &&
    //   minStayThresholdAlerts[currentDateCellDateOffset - 1] !== 'Y'
    // ) {
    //   const currentClosestAvailableCheckInIndex = getPastClosestAvailableCheckInIndex(
    //     currentDateCellDateOffset
    //   );

    //   const currentRangeFromCheckIn = resourceBlockTypes.slice(
    //     currentClosestAvailableCheckInIndex
    //   );
    //   currentRangeFromCheckIn.find((date, index) => {
    //     currentLastUnavailableNightInRange = index + 1;
    //     // return date === 'A' && currentRangeFromCheckIn[index + 1] === 'B';
    //     return date === 'A' && currentRangeFromCheckIn[index + 1] !== 'A';
    //   });
    //   // const isLessThanMinumumStay =
    //   //   currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;
    //   return (
    //     <ResourceAvailabliltyTd
    //       currentDay={currentDay}
    //       currentDateCellDateOffset={currentDateCellDateOffset}
    //       index={index}
    //       key={index}
    //       title={null}
    //       // className={
    //       //   isLessThanMinumumStay ? 'reserved-check-in' : 'reserved-check-in'
    //       // }
    //       className={addCheckInReservedClasses('reserved-check-in')}
    //     />
    //   );
    // } else if (
    //   currentDayIsAvailable &&
    //   (!resourceBlockTypes[currentDateCellDateOffset - 1] || previousDayIsBlocked)
    // ) {
    //   const currentClosestAvailableCheckInIndex = getPastClosestAvailableCheckInIndex(
    //     currentDateCellDateOffset
    //   );

    //   const currentRangeFromCheckIn = resourceBlockTypes.slice(
    //     currentClosestAvailableCheckInIndex
    //   );
    //   currentRangeFromCheckIn.find((date, index) => {
    //     currentLastUnavailableNightInRange = index + 1;
    //     return date === 'A' && currentRangeFromCheckIn[index + 1] === 'A';
    //   });

    //   // const isLessThanMinumumStay =
    //   //   currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;

    //   return (
    //     <ResourceAvailabliltyTd
    //       currentDay={currentDay}
    //       currentDateCellDateOffset={currentDateCellDateOffset}
    //       index={index}
    //       key={index}
    //       title={null}
    //       // className={
    //       //   isLessThanMinumumStay ? 'reserved-check-out' : 'reserved-check-out'
    //       // }
    //       className={addCheckOutReservedClasses('reserved-check-out')}
    //     />
    //   );
    // } else if (currentDayIsBlocked) {
    //   return (
    //     <ResourceAvailabliltyTd
    //       currentDay={currentDay}
    //       currentDateCellDateOffset={currentDateCellDateOffset}
    //       index={index}
    //       key={index}
    //       {...getCellAttributes({ currentDay })}
    //     />
    //   );
    // }

    // const isLessThanMinumumStay =
    //   currentLastUnavailableNightInRange < tooShortWithinMinStayNumber;

    // return (
    //   <ResourceAvailabliltyTd
    //     currentDay={currentDay}
    //     currentDateCellDateOffset={currentDateCellDateOffset}
    //     index={index}
    //     key={index}
    //     title={null}
    //     className={isLessThanMinumumStay ? '' : ''}
    //     // {...getCellAttributes({ resource, currentDateCellDateOffset, currentDay })}
    //   />
    // );
  });
};
export default ResourceEventCells;
