import React from 'react';
import { navigate } from 'gatsby';
import styles from './styles.module.scss';

const ThemedTable = ({ children, classes }) => {
  return (
    <div className={`card ${styles.themedCard}`}>
      <div className="table-responsive">
        <table
          id="themed-table"
          className={`${styles.themedTable} table pl-3 pr-3 ${classes}`}
        >
          {children}
        </table>
      </div>
    </div>
  );
};

export default ThemedTable;
