import React, { useState, useEffect } from 'react';
import Query from 'src/components/GraphQL/components/Query';
import { PlusCircle, Search, ArrowLeft } from 'react-feather';
import ThemedPager from 'src/components/ThemedPager/index.js';
import Layout from 'src/layouts';
// import { Location } from '@reach/router';
// import { URL } from 'url';
import { navigate, Link } from 'gatsby';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
// import styles from './styles.module.scss';
// import Modal from 'src/components/Modal';
// import GuestForm from './components/GuestForm';
import withLocation from 'src/components/WithLocation';
//import GuestNotesTable from './components/GuestNotesTable';
import ThemedTable from 'src/components/ThemedTable/index.js';
import GuestNotesInnerTable from 'src/routes/notes/components/GuestNotesInnerTable';
// import RelatedGuests from './components/RelatedGuests';
import SearchInput from 'src/components/SearchInput';

const ALL_NOTES = gql`
  query guestNoteSearchConnection($first: Int, $skip: Int, $searchTerm: String) {
    guestNoteSearchConnection(
      where: { searchTerm: $searchTerm }
      first: $first
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          id
          note
          createdAt
          creator {
            userName
          }
          guest {
            id
            firstName
            lastName
            blockType
            primaryEmailAddress {
              emailAddress
            }
            primaryPhoneNumber {
              phoneNumber
            }
            guestSource {
              id
              name
            }
          }
        }
      }
    }
    # guestSources {
    #   key
    #   name
    #   id
    # }
  }
`;

let timeout;
function debounce(func, wait) {
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const GuestNotes = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const recordsPerPage = 10;
  const searchTerm = params.get('search') || null;
  // const [searchTerm, setSearchTerm] = useState(params.get('search') || null);
  const [pageNumber, setPageNumber] = useState(params.get('page') || '1');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const updateUrl = (value, pageNumber = 1) => {
    if (pageNumber === 1) {
      if (value?.length > 3 || searchTerm !== null) {
        navigate(`/notes?search=${value}`);
      }
      // setSearchTerm(value);
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber);
      navigate(`/notes?page=${pageNumber}${value ? `&search=${value}` : ''}`);
    }
  };

  return (
    <Layout metaTitle={'Notes'}>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col mt-1">
            <>
            
                <h1 className="d-inline-block">Notes</h1>
             
            </>
          </div>
          <div className="col col-12 col-lg-4 mb-3">
            <span>
              <SearchInput
                placeHolder={`Search Notes`}
                value={searchTerm}
                onChange={value => {
                  const debounced = debounce(function() {
                    updateUrl(value);
                  }, 500);
                  debounced();
                }}
              />
            </span>
          </div>
        </div>
        <Query
          variables={{
            searchTerm: (searchTerm || {}).length > 3 ? searchTerm : null,
            first: recordsPerPage,
            skip: (pageNumber - 1) * recordsPerPage,
          }}
          query={ALL_NOTES}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <div className="container-fluid pt-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <LoadingSpinner />
                  </div>
                </div>
              );
            if (error)
              return (
                <div className="container-fluid pt-3">
                  {error.message}
                  <a
                    href="#"
                    onClick={() => {
                      updateUrl(searchTerm);
                    }}
                  >
                    Try again
                  </a>
                  .
                </div>
              );
            const { guestNoteSearchConnection } = data || {};
            if (!guestNoteSearchConnection)
              return (
                <div className="container-fluid pt-3">
                  No Results
                </div>
              );

            const transformGuestData = guestNoteSearchConnection => {
              return guestNoteSearchConnection.edges.reduce((acc, edge) => {
                acc.push(edge.node);
                return acc;
              }, []);
            };
            const count = guestNoteSearchConnection?.aggregate?.count || 0;
            const notes = transformGuestData(guestNoteSearchConnection);
            return (
              <div>
     
                  <>
                    <div className="row">
                      <div className="col">
                        {/* <GuestTable
                          count={guestNoteSearchConnection?.aggregate?.count || 0}
                          guests={transformGuestData(guestNoteSearchConnection)}
                        /> */}
                        <ThemedTable >
                          <GuestNotesInnerTable notes={notes} count={count} />
                        </ThemedTable>
                        
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {guestNoteSearchConnection.aggregate.count > recordsPerPage && (
                          <ThemedPager
                            totalRecords={guestNoteSearchConnection.aggregate.count}
                            dataConnection={guestNoteSearchConnection}
                            recordsPerPage={recordsPerPage}
                            pageNumber={Number(pageNumber)}
                            changePageNumber={pageNumber =>
                              updateUrl(searchTerm, pageNumber)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </>
           

        
              </div>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withLocation(GuestNotes);
