import React, { useEffect, useState, useRef } from 'react';
import { Sliders } from 'react-feather';
import { Formik } from 'formik';
import Query from '../../../GraphQL/components/Query';
import gql from 'graphql-tag';
import ThemedButton from '../ThemedButton';
import filter from 'lodash/filter';
import styles from './styles.module.scss';
import propertyFilterByAmenity from '../../../../util/propertyFilterByAmenity';
import get from 'lodash/get';
import WithLocation from '../../../WithLocation';
import LoadingSpinner from '../../../LoadingSpinner';
import {
  updateUrlWithCommaDelimitedString,
  updateUrl,
} from 'src/util/urlHelper';
import * as queryString from 'query-string';
import { Link } from 'gatsby';

const FILTERED_PROPERTY_LIST = gql`
  query Properties {
    properties {
      key
      id
      name
      label
      sleeps
      bedrooms
      bathrooms
      type
      group
      sitePage {
        slug
        featuredImage {
          file {
            fullUrl
          }
        }
      }
      amenities {
        name
        key
        value
        # sort
      }
    }
    propertyGroups {
      label: name
      key
    }
  }
`;

const closeFormDropdown = () => {
  $('.dropdown-menu.keep-open').removeClass('show');
};

const DropdownFilterForm = ({
  onFormSubmit,
  onClearFilters,
  filtersApplied,
  onPropertyFilterSelect,
  onGroupFilterSelect,
  hideFilterByProperty,
  location,
  propertiesFromParent,
  dropdownMenuAlignmentClass,
  onDropdownSelect,
}) => {
  let formRef = useRef(null);
  const [amenityFilters, setAmenityFilters] = useState(null);
  const [combinedAmenityTotals, setCombinedAmenityTotals] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const params = new URLSearchParams(location.search);
  const pathArr = location.pathname.split('');
  const path = pathArr.shift();
  const pathName = pathArr.join('');
  const queryParams = queryString.parse(location.search);

  const getAvailabilityParams = () => {
    return Object.keys(queryParams).reduce((acc, param) => {
      if (
        param === 'checkIn' ||
        param === 'checkOut' ||
        param === 'flexibilityInDays'
      ) {
        if (!acc[param]) {
          acc[param] = queryParams[param];
        }
      }
      return acc;
    }, {});
  };

  // const filterProperties = properties => {
  //   if (selectedProperty) {
  //     return (
  //       [
  //         properties.find(property => property.label === selectedProperty.key),
  //       ] || []
  //     );
  //   } else if (selectedGroup) {
  //     const filteredPropertiesByGroup = properties.filter(
  //       property => property.group === selectedGroup.toLowerCase()
  //     );
  //     return filteredPropertiesByGroup;
  //   }
  //   return properties;
  // };

  const PropertyDropdown = ({ properties }) => {
    const propertiesWithoutCombos = properties.filter(
      property => property.type !== 'COMBO'
    );

    const propertyDropdownOptions = propertiesWithoutCombos.reduce(
      (acc, property) => {
        const fullUrl =
          get(property, 'sitePage.featuredImage.file.fullUrl') || null;
        if (property.type !== 'COMBO' && fullUrl) {
          acc.push({
            label: `${property.label} - ${property.name}`,
            key: property.key,
          });
        }
        return acc;
      },
      []
    );
    useEffect(() => {
      $('#property-dropdown-toggle').on('click.property-dropdown', function(e) {
        $('#property-dropdown-menu').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
      return () => {
        $('#property-dropdown-toggle').off('click.property-dropdown');
      };
    }, [propertyDropdownOptions]);

    const getSelectedProperty = () => {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.get('property') && propertyDropdownOptions.length > 0) {
        const found = propertyDropdownOptions.find(property => {
          return property.key === urlParams.get('property');
        });

        return found ? found.label : 'All Properties';
      } else if (!urlParams.get('property')) {
        return 'All Properties';
      }
      return (selectedProperty && selectedProperty.label) || 'All Properties';
    };

    return (
      <div className="dropdown">
        <button
          className={`btn ${styles.filterDropdownToggle} dropdown-toggle text-left`}
          type="button"
          id="property-dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {getSelectedProperty()}
        </button>
        <div
          className={`${styles.filterDropdownMenu} py-0 dropdown-menu`}
          aria-labelledby="property-list"
          id="property-dropdown-menu"
        >
          {propertyDropdownOptions.map((option, index) => {
            return (
              <button
                type="button"
                key={`${option.key}_${index}`}
                className="dropdown-item filter-dropdown-item"
                data-class={option.key}
                onClick={() => {
                  setAmenityFilters(null);
                  clearCheckBoxes();
                  onPropertyFilterSelect(option.key);
                  setSelectedProperty(option);
                  setSelectedGroup(null);
                  closeFormDropdown();
                  setCombinedAmenityTotals(null);
                  updateUrl(
                    pathName,
                    { property: option.key, ...getAvailabilityParams() },
                    true
                  );
                }}
              >
                {option.label}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const GroupDropdown = ({ groupDropdownOptions, properties }) => {
    useEffect(() => {
      $('#group-dropdown-toggle').on('click.group-dropdown', function(e) {
        $('#group-dropdown-menu').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
      return () => {
        $('#group-dropdown-toggle').off('click.group-dropdown');
      };
    }, []);
    return (
      <div className="dropdown">
        <button
          className={`btn ${styles.filterDropdownToggle} dropdown-toggle text-left`}
          type="button"
          id="group-dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedGroup || 'All groups'}
        </button>
        <div
          className={`${styles.filterDropdownMenu} py-0  dropdown-menu`}
          aria-labelledby="property-list"
          id="group-dropdown-menu"
        >
          {groupDropdownOptions.map((option, index) => {
            return (
              <button
                type="button"
                key={`${option.key}_${index}`}
                className="dropdown-item filter-dropdown-item"
                data-class={option.key}
                onClick={() => {
                  setAmenityFilters(null);
                  clearCheckBoxes();
                  onGroupFilterSelect(option.key);
                  closeFormDropdown();
                  setSelectedGroup(option.key.toUpperCase());
                  setSelectedProperty(null);
                  setCombinedAmenityTotals(null);

                  updateUrl(
                    `${pathName}`,
                    { group: option.key, ...getAvailabilityParams() },
                    true
                  );
                }}
              >
                {option.key.toUpperCase()}
              </button>
            );
           
          })}
           {/* {<button
                type="button"
                key={`filter_payout`}
                className="dropdown-item filter-dropdown-item"
                // data-class={option.key}
                onClick={() => {
                  setAmenityFilters(null);
                  clearCheckBoxes();
                  // onGroupFilterSelect(option.key);
                  closeFormDropdown();
                  // setSelectedGroup(option.key.toUpperCase());
                  setSelectedProperty(null);
                  setCombinedAmenityTotals(null);

                  updateUrl(
                    `${pathName}`,
                    { group: 'A', ...getAvailabilityParams() },
                    true
                  );
                }}
              >
                Early Payouts
              </button>} */}
        </div>
      </div>
    );
  };

  const updateAmenityTotals = (checkedValues, properties) => {
    const resetValues = () => {
      setAmenityFilters(null);
      setCombinedAmenityTotals(null);
    };

    const setValues = total => {
      setAmenityFilters(filterObject);
      setCombinedAmenityTotals(total);
    };
    const filterObject = {};
    const allAmenities = properties.map(property => {
      return property.amenities.reduce((acc, amenity) => {
        acc[amenity['key']] = amenity.value;
        return acc;
      }, {});
    });

    checkedValues.forEach(value => (filterObject[value] = 'Y'));

    // const total = _.filter(allAmenities, filterObject).length;
    const total = filter(allAmenities, filterObject).length;

    properties.length !== total ? setValues(total) : resetValues();
  };

  const getCheckBoxValues = params => {
    const $checkBoxes = $(formRef.current).find('[data-checkbox]');
    const checkedValues = [];
    $checkBoxes.each((index, value) => {
      if ($(value).prop('checked')) {
        checkedValues.push($(value).prop('name'));
      }
    });
    return checkedValues;
    // setSelectedGroup(null);
    // setSelectedProperty(null);
    // updateAmenityTotals(checkedValues, properties);
  };
  const submitForm = () => {
    closeFormDropdown();
    onFormSubmit(amenityFilters);
  };
  const clearCheckBoxes = () => {
    const $checkBoxes = $(formRef.current).find('[data-checkbox]');
    $checkBoxes.each((index, value) => {
      $(value).prop('checked', false);
    });
  };
  const clearFilters = () => {
    clearCheckBoxes();
    setAmenityFilters(null);
    setCombinedAmenityTotals(null);
    setSelectedGroup(null);
    setSelectedProperty(null);
    onClearFilters();
  };
  // const setCheckboxValues = amenitiesList => {
  //   const amenityObj = amenitiesList.split(',').reduce((acc, amenity) => {
  //     if (acc) {
  //       acc[amenity] = true;
  //     }

  //     return acc;
  //   }, {});
  // };
  useEffect(() => {
    if (params.get('group')) {
      setSelectedGroup(params.get('group').toUpperCase());
    } else if (!params.get('group')) {
      setSelectedGroup(null);
    }
    const checkedValues = params.get('amenities')
      ? params.get('amenities').split(',')
      : [];
    const checkedValuesFromUrlParams = checkedValues.reduce((acc, value) => {
      if (value === 'plungePool') {
        acc.push('swimSpa');
      } else {
        acc.push(value);
      }
      return acc;
    }, []);

    if (propertiesFromParent) {
      updateAmenityTotals(checkedValuesFromUrlParams, propertiesFromParent);
    }

    $('.dropdown-menu.keep-open').on('click.parent-dropdown', function(e) {
      $('#property-dropdown-menu').hide();
      $('#group-dropdown-menu').hide();
      if ($(e.target).attr('id') === 'property-list') {
        e.stopPropagation();
      }
    });
    return () => {
      $('.dropdown-menu.keep-open').off('click.parent-dropdown');
    };
  }, [params.get('group')]);

  const amenitiesFromUrl = params.get('amenities')
    ? params
        .get('amenities')
        .split(',')
        .reduce((acc, amenity) => {
          if (acc) {
            acc[amenity] = true;
          }

          return acc;
        }, {})
    : {};

  return (
    <div className={`dropdown`}>
      <button
        data-boundary="window"
        data-flip
        id="filter-property-attributes-button"
        className={`btn btn-link border-0 ${styles.filterFormToggle} dropdown-toggle dropdownMenuButton`}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setFormIsOpen(true)}
      >
        <div className="d-flex align-items-center">
          <Sliders />
          <span className="ml-1">Filter</span>
        </div>
      </button>
      {/* {filtersApplied && (
        <button
          className="btn btn-link"
          onClick={() => {
            clearFilters();
          }}
        >
          <span className="">Clear Filters</span>
        </button>
      )} */}

      <div
        className={`dropdown-menu ${dropdownMenuAlignmentClass} keep-open`}
        aria-labelledby="dropdownMenuButton"
      >
        <Query query={FILTERED_PROPERTY_LIST} skip={!formIsOpen}>
          {({ data, error, loading }) => {
            {
              loading && (
                <div
                  className="d-flex justify-content-center align-items-center" /*style={{height: '80vh'}}*/
                >
                  <div
                    className="spinner-border"
                    role="status"
                    style={{
                      color: '#CCC',
                      height: '100px',
                      width: '100px',
                    }}
                  ></div>
                </div>
              );
            }
            if (error)
              return (
                <div className="container-fluid pt-3">{error.message}</div>
              );

            const properties = get(data, 'properties') || [];
            const propertiesWithoutCombos = properties.filter(
              property => property.type !== 'COMBO'
            );
            // const filteredProperties = filterProperties(
            //   get(data, 'properties') || []
            // );
            // console.log('filteredProperties', filteredProperties);
            const propertyGroups = get(data, 'propertyGroups') || [];
            // let temporaryPropertiesList = propertiesWithoutCombos;
            // temporaryPropertiesList[0]?.amenities.push({
            //   name: 'Swim Spa',
            //   key: 'swimSpa',
            //   value: 'Y',
            // });

            const { amenityTotals } = propertyFilterByAmenity(
              propertiesWithoutCombos,
              amenityFilters
            );

            const checkBoxOptions = amenityTotals
              ? Object.values(amenityTotals).map(amenity => ({
                  key: amenity.key,
                  label: `${
                    amenity.key === 'swimSpa' ? 'Plunge Pool' : amenity.label
                  } (${amenity.total})`,
                  total: amenity.total,
                  sort: amenity?.sort,
                }))
              : [{ key: '', label: '', total: 0 }];

            const numColumns = 2;
            const ItemsInEachGroup = checkBoxOptions.length / numColumns;
            const amenityArray = Array.from({ length: numColumns });

            const checkBoxes = checkBoxOptions.map((option, index) => {
              return (
                <div key={index}>
                  <div
                    className="custom-control custom-checkbox mr-sm-2 pb-4"
                    key={option.key}
                  >
                    <input
                      className="custom-control-input"
                      id={option.key}
                      disabled={option.total === 0}
                      onClick={() => {
                        const checkedValues = getCheckBoxValues(params);
                        setSelectedGroup(null);
                        setSelectedProperty(null);
                        updateAmenityTotals(
                          checkedValues,
                          propertiesWithoutCombos
                        );
                      }}
                      data-checkbox
                      type="checkbox"
                      name={option.key}
                      name={`${
                        option.key === 'swimSpa' ? 'plungePool' : option.key
                      }`}
                      // value="true"
                      defaultChecked={
                        amenitiesFromUrl[
                          option.key === 'swimSpa' ? 'plungePool' : option.key
                        ]
                          ? true
                          : false
                      }
                    />{' '}
                    <label
                      key={option.key}
                      className="d-block custom-control-label"
                      htmlFor={option.key}
                    >
                      {option.label}
                    </label>
                  </div>
                </div>
              );
            });

            // const propertyGroupOptions = propertyGroups ? propertyGroups.map(group => ({ value: group.key, label: group.name.toUpperCase() })) : [{ value: '', label: 'No Groups'}]
            return (
              <div className={`${styles.filterFormContainer} container`}>
                <form
                  id="dropdown-filter-form"
                  ref={formRef}
                  onSubmit={() => submitForm()}
                  className={styles.filterForm}
                >
                  <div className="row m-2">
                    {!hideFilterByProperty && (
                      <div className="mt-3 col col-12 col-md-6">
                        <h3 className="dropdown-heading font-weight-bold">
                          Filter by property
                        </h3>
                        <PropertyDropdown
                          properties={propertiesWithoutCombos}
                        />
                      </div>
                    )}
                    <div className="mt-3 col col-12 col-md-6">
                      <div className="group-filter">
                        <h3 className="dropdown-heading font-weight-bold">
                          Filter by group
                        </h3>
                        <GroupDropdown
                          properties={propertiesWithoutCombos}
                          groupDropdownOptions={propertyGroups}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group pl-0 m-4">
                    <h3 className="checkboxes-heading d-inline font-weight-bold">
                      Filter by amenities
                    </h3>
                    {loading && (
                      <div style={{ maxHeight: '200px' }}>
                        <div className="d-flex justify-content-center position-relative mt-2">
                          <LoadingSpinner customHeight={0} />
                        </div>
                      </div>
                    )}

                    <span className="checkboxes-total">
                      {combinedAmenityTotals || combinedAmenityTotals === 0
                        ? ` (${combinedAmenityTotals} results)`
                        : ''}
                    </span>

                    <div className="row mt-3">
                      {numColumns &&
                        amenityArray.map((_, index) => {
                          return (
                            <div
                              className="col col-12 col-md-auto"
                              /*xs={12/numColumns}*/ key={index}
                            >
                              {checkBoxes.slice(
                                index * ItemsInEachGroup,
                                ItemsInEachGroup * (index + 1)
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {combinedAmenityTotals &&
                    combinedAmenityTotals !==
                      propertiesWithoutCombos.length && (
                      <div className="row">
                        <div className="col">
                          <div className="text-center submit-button-wrapper mb-4">
                            <ThemedButton
                              onClick={() => submitForm()}
                              type="button"
                              className="w-100 mb-2 btn btn-outline-primary"
                            >
                              Filter
                            </ThemedButton>
                          </div>
                        </div>
                      </div>
                    )}
                </form>
              </div>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

DropdownFilterForm.defaultProps = {
  hideFilterByProperty: false,
  propertiesFromParent: false,
  dropdownMenuAlignmentClass: '',
  onDropdownSelect: () => {},
};

export default WithLocation(DropdownFilterForm);
