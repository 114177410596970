import React, { useState } from 'react';
import Tooltip from 'src/components/Tooltip';
import { PlusCircle } from 'react-feather';

const RedFlagsButton = ({
  reservationId,
  redFlags,
  openModal,
  reference,
  cell,
  enableAddAndRemoveButton = true,
  ...rest
}) => {
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAddAndRemoveButton, setShowAddAndRemoveButton] = useState(
    showAddAndRemoveButton
  );

  const flagTooltipPlacement = redFlags?.length > 3 ? 'right' : 'top';
  return (
    <div
      className="_d-flex position-relative align-items-center justify-content-center"
      style={{ minHeight: '20px' }}
      onMouseOver={() => {
        setShowAddAndRemoveButton(true);
      }}
      onMouseLeave={() => {
        setShowAddAndRemoveButton(false);
      }}
    >
      {enableAddAndRemoveButton && (
        <button
          onClick={e => {
            openModal(true);
          }}
          className={`btn btn-link p-0 _d-flex align-items-center position-absolute m-auto ${
            showAddAndRemoveButton ? 'd-flex' : 'd-none'
          }`}
          style={{ top: 0, bottom: 0 }}
        >
          <Tooltip
            label="Add / Remove Flag(s)"
            placement="top"
            id={reference}
            // hide={!isMenuCollapsed}
            // className="d-none"
          >
            <PlusCircle className="plus-circle d-flex" size={18} />
          </Tooltip>
        </button>
      )}

      <Tooltip
        label={
          <ul
            style={{ listStylePosition: 'inside' }}
            className="m-0 p-0 text-center"
          >
            {redFlags.map(flag => {
              return (
                <li key={flag?.label}>
                  <span>{flag?.label}</span>
                </li>
              );
            })}
          </ul>
        }
        // placement={`${redFlags?.length > 4 ? 'right' : 'top'}`}
        placement={flagTooltipPlacement}
        id={`${reference}_flag`}
        // hide={!isMenuCollapsed}
        // className="d-none"
      >
        {redFlags?.length > 0 ? '🚩' : ''}
      </Tooltip>
    </div>
  );
};
export default RedFlagsButton;
