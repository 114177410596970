import React, { useRef, useState, useEffect } from 'react';
import Layout from 'src/layouts';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import moment from 'moment';
import Loadable from '@loadable/component';
import { addDays, getNumberOfDaysInRange } from 'src/util/dateHelper';
import { AlertsConsumer } from 'src/contexts/Alerts';

const PropertiesCalendar = Loadable(() =>
  import('./components/PropertiesCalendar')
);

const availabilityCalendarToEvents = property => {
  const { start, availability: availabilityString } = property.calendar;
  let day = start;
  const calendar = availabilityString.split('').reduce((acc, availability) => {
    if (availability === 'N') {
      acc.push({
        start: day, // formatDate(day),
        resourceId: property.id,
      });
    }
    day = addDays(day, 1);
    return acc;
  }, []);
  return calendar;
};

const PROPERTIES_QUERY = gql`
  query Properties {
    propertyAmenities {
      key
      name
    }
    properties {
      id
      key
      label
      name
      title: name
      type
      sort
      bedrooms
      bathrooms
      sleeps
      group

      amenities {
        name
        key
        value
      }
      masterPlatformListing {
        payoutType
      }
      ... on PropertyCombo {
        masterPlatformListing {
          payoutType
        }
        # amenities {
        #   name
        #   key
        #   value
        # }
        childProperties {
          name
          key
        }
      }
      calendar {
        start
        end
        availability(ignoreMinPriorNotify: true)
        blockType(ignoreMinPriorNotify: true)
        checkInAvailability
        checkOutAvailability
        checkInRestriction
        checkOutRestriction
        minStay
        minStayThresholdAlert
        childPropertyRateAlertType
        childPropertyRateSum
        rates
        changeOverAlert
      }
      sitePage {
        slug
        title
        status
        featuredImageSecondary {
          file {
            version(where: { key: "small" }) {
              fullUrl
            }
          }
        }
      }
    }
  }
`;

const CALENDAR_DATA_QUERY = gql`
  query CalendarData2 {
    companyMeta {
      ownerManagementPeriodEndDate
    }
    propertyAmenities {
      key
      name
    }
    properties {
      id
      title: key
      key
      label
      type
      name
      sort
      group
      masterPlatformListing {
        payoutType
      }
      ... on PropertyCombo {
        masterPlatformListing {
          payoutType
        }
        calendar {
          start
          availability(ignoreMinPriorNotify: true)
        }
        childProperties {
          id
          key
          label
        }
      }

      amenities {
        name
        key
        value
      }
    }
    # calendarEventAlerts {
    #   type
    #   summary
    #   level
    #   calendarEvent {
    #     id
    #     uid
    #     summary
    #     start
    #     end
    #     propertyListing {
    #       property {
    #         id
    #         label
    #         key
    #         name
    #       }
    #       platform {
    #         name
    #       }
    #     }
    #   }
    # }
  }
`;


const Calendar = props => {
  const params = new URLSearchParams(props.location.search);
  const [displayMode, setDisplayMode] = useState(params.get('displaymode'));
  const startDate = params.get('start');
  const endDate = params.get('end');

  if (!startDate) {
    window.location.replace(`/calendar?start=${moment().format('YYYYMMDD')}`);
  }
  const popstate = () => (window.location.href = window.location.href);
  useEffect(() => {
    window.addEventListener('popstate', popstate);
    return () => {
      window.removeEventListener('popstate', popstate);
    };
  }, []);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const dayRangeNumber =
    (!endDate &&
      getNumberOfDaysInRange(
        startDate,
        moment(startDate)
          .add(6, 'months')
          .format('YYYYMMDD')
      )) ||
    null;

  return (
    <Layout
      metaTitle={displayMode === 'multicalendar' ? 'Multicalendar' : 'Calendar'}
      onLayoutResize={() => setSidebarCollapsed(prevState => !prevState)}
    >
      <div className="container-fluid _mb-5 h-100">
        <div className="row h-100">
          <div id="calendar-column" className="col h-100">
            {displayMode === 'multicalendar' ? (
              <Query query={PROPERTIES_QUERY} fetchPolicy="no-cache">
                {({ data, loading, error }) => {
                  if (loading)
                    return (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '80vh' }}
                      >
                        <LoadingSpinner />
                      </div>
                    );
                  if (error) return <p>Error: ${error.message}</p>;
                  const properties = data?.properties;
                  const propertyGroups = data?.propertyGroups;
                  const propertyAmenities = data?.propertyAmenities;

                  const filteredPropertiesWithoutCombos = properties.filter(
                    property =>
                      (property.type !== 'COMBO' &&
                        property.sitePage != null &&
                        property.calendar.availability) ||
                      property.key === '1345' ||
                      property.key === '1065'
                  );

                  const comboProperties = properties.filter(property => {
                    return (
                      (property.type === 'COMBO' &&
                      property?.sitePage?.slug &&
                      property.sitePage?.status === 'PUBLISHED')
                      || property?.key == '13441345' 
                    );
                  });

                  const propertiesForDisplay = [
                    ...filteredPropertiesWithoutCombos,
                    ...comboProperties,
                  ];

                  const resources = propertiesForDisplay;
console.log('properties', propertiesForDisplay)
                  const events = propertiesForDisplay.reduce(
                    (acc, property) => {
                      const events = availabilityCalendarToEvents(property);
                      acc.push(...events);
                      return acc;
                    },
                    []
                  );

                  return (
                    <div className="no-gutters-sm-down h-100">
                      <AvailabilityCalendar
                        location={props.location}
                        resources={resources}
                        events={events}
                        defaultDateRangeNumber={dayRangeNumber}
                        days={
                          moment()
                            .add(24, 'months')
                            .endOf('month')
                            .diff(moment(), 'days') + 1
                        }
                        properties={properties}
                        propertyGroups={propertyGroups}
                        propertyAmenities={propertyAmenities}
                      />
                    </div>
                  );
                }}
              </Query>
            ) : (
              // <AlertsConsumer>
              //   {({
              //     state: alertsState,
              //     loading: alertsLoading,

              //   }) => {
              //     return (
              <Query errorPolicy="all" query={CALENDAR_DATA_QUERY}>
                {({ error, loading, data = {} }) => {
                  const {
                    companyMeta,
                    properties,
                    propertyGroups,
                    propertyAmenities,
                  } = data || {};

                  if (loading)
                    return (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '80vh' }}
                      >
                        <LoadingSpinner />
                      </div>
                    );

                    // BUG?: This logic isn't actually applied. It assumes calendar.availability is present in query (which it isn't)
                  const filteredPropertiesWithoutCombos = properties.filter(
                    property =>
                      (property.type !== 'COMBO' &&
                        property.sitePage != null &&
                        property.calendar.availability) ||
                      property.key === '1345' ||
                      property.key === '1065'
                  );

                  const comboProperties = properties.filter(property => {
                    return (
                      property.type === 'COMBO' &&
                      property?.sitePage?.slug &&
                      property.sitePage?.status === 'PUBLISHED'
                    );
                  });

                  const propertiesForDisplay = [
                    ...filteredPropertiesWithoutCombos,
                    ...comboProperties,
                  ];
                 // console.log('properties', properties);
                  const resources = propertiesForDisplay;

                  return (
                    <>
                      {Object.keys(data).length > 0 && (
                        <>
                          <AlertsConsumer>
                            {({
                              state: alertsState,
                              loading: alertsLoading,
                            }) => {
                              if (!alertsState?.calendarEventAlerts)
                                return (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: '80vh' }}
                                  >
                                    <LoadingSpinner />
                                  </div>
                                );
                              return (
                                <>
                                  {alertsState?.calendarEventAlerts && (
                                    <PropertiesCalendar
                                      resources={properties}
                                      companyMeta={companyMeta}
                                      properties={properties}
                                      propertyGroups={propertyGroups}
                                      days={720}
                                      // calendarEventAlerts={alertsClone}
                                      calendarEventAlerts={
                                        alertsState?.calendarEventAlerts || []
                                      }
                                      sidebarCollapsed={sidebarCollapsed}
                                      defaultDateRangeNumber={dayRangeNumber}
                                      propertyAmenities={propertyAmenities}
                                    />
                                  )}
                                </>
                              );
                            }}
                          </AlertsConsumer>
                        </>
                      )}
                    </>
                  );
                }}
              </Query>
              //     );
              //   }}
              // </AlertsConsumer>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Calendar;
