export const apiDataToLabelMap = {
  SENT: 'Response Sent',
  INPROGRESS: 'In Progress',
  SPAM: 'Spam',
  OPEN: 'Action Required',
  CLOSED: 'Closed',
  ALL: 'Show All',
  '': 'Action Required',
};

export const labelToUrlKeyMap = {
  'Response Sent': 'sent',
  'In Progress': 'inprogress',
  Spam: 'spam',
  'Action Required': '',
  Closed: 'closed',
  'Show All': 'all',
};

export const urlToGraphQLValueMap = {
  sent: 'SENT',
  inprogress: 'INPROGRESS',
  spam: 'SPAM',
  'action-required': 'OPEN',
  closed: 'CLOSED',
};

export const urlToLabelKeyMap = {
  sent: 'Response Sent',
  inprogress: 'In Progress',
  spam: 'Spam',
  // 'Action Required': '',
  closed: 'Closed',
  all: 'Show All',
};
