import React, { useState } from 'react';
import {
  ReactTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
} from 'src/components/ReactTable';
import { withApollo } from 'react-apollo';
import { useSelectFilter } from 'src/routes/reports/components/BookingsTable/util/index';
import StarImg from '../../../../../images/star.svg';
import BTable from 'react-bootstrap/Table';
import { enumToLabel } from 'src/util/index';
import { Filter, ChevronUp, ChevronDown } from 'react-feather';
import { Tooltip as TippyTooltip } from 'react-tippy';
import gql from 'graphql-tag';
import './styles.scss';
const StarIcons = ({ numberOfStars }) => {
  return Array.from({ length: Number(numberOfStars) }).map((_, index) => {
    return <img key={index} width="20" src={StarImg} />;
  });
};
const UPDATE_GUEST_MEMORY_STATUS = gql`
  mutation UpdateGuestMemory($token: String, $status: GuestMemoryStatus) {
    updateGuestMemory(where: { token: $token }, data: { status: $status }) {
      id
      status
    }
  }
`;

const GuestMemoriesTable = ({
  tableData,
  statusTypes,
  client,
  refetchMemories,
}) => {
  const updateGuestMemoryStatus = async (token, status) => {
    try {
      const { data, loading } = await client.mutate({
        mutation: UPDATE_GUEST_MEMORY_STATUS,
        variables: {
          token,
          status,
        },
      });
      return data?.updateGuestMemory?.status;
    } catch (err) {
      alert('There was a problem updating the status. Please try again.');
      return null;
    }
  };
  const CustomSelectList = ({ cell, value }) => {
    const defaultStatus = statusTypes.find(type => type === value) || 'OPEN';
    const [selected, setSelected] = useState(defaultStatus);
    const [showStatusSuccessMessage, setShowStatusSuccessMessage] = useState(
      false
    );

    return (
      <div>
        <select
          className="form-control status-select"
          onChange={async e => {
            const confirmation = confirm(
              'Are you sure you want to change the status for this memory?'
            );
            if (confirmation) {
              const status = await updateGuestMemoryStatus(
                cell?.row?.original?.token,
                e.target.value
              );
              if (status) {
                setSelected(status);
                setShowStatusSuccessMessage(true);
                setTimeout(() => {
                  setShowStatusSuccessMessage(false);
                }, 3000);
                refetchMemories();
              }
            } else {
              setSelected(selected);
            }
          }}
          value={selected}
        >
          {statusTypes.map((item, index) => {
            return (
              <option key={index} value={item}>
                {enumToLabel(item)}
              </option>
            );
          })}
        </select>
        <div
          className={`alert alert-success alert-dismissible position-absolute ${
            showStatusSuccessMessage ? 'fade-out' : 'd-none'
          }`}
          //   className={`alert alert-success alert-dismissible position-absolute `}
          role="alert"
          style={{
            zIndex: '1',
            top: '0px',
            right: '17px',
            minWidth: '180px',
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          Status Updated!
        </div>
      </div>
    );
  };
  const DynamicComponent = ({ value, cell, outsideLink }) => {
    const columnDefinition = cell.column.columnDefinition || {};
    const componentKey = cell?.column?.component;
    const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
      if (cell.row.original[key]) {
        acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
      }
      return acc;
    }, {});

    switch (cell?.column?.Header) {
      case 'Status': {
        return <CustomSelectList cell={cell} value={value} />;
      }
      case 'Photos': {
        const parentFolder = cell?.row?.original?.photosParentFolder;
        return (
          <div className="d-flex align-items-center">
            <span className="mr-2">{value}</span>
            {parentFolder ? (
              <>
                (
                <a
                  target="_blank"
                  href={`https://drive.google.com/drive/u/0/folders/${parentFolder}`}
                >
                  View Photos
                </a>
                )
              </>
            ) : (
              ''
            )}
          </div>
        );
      }
      case 'Five Star Rating': {
        return <StarIcons numberOfStars={value} />;
      }

      default:
        return value;
    }
    return value;
  };

  const CustomCell = ({ value, cell }) => {
    return value ? (
      <DynamicComponent value={value} cell={cell} outsideLink={true} />
    ) : (
      ''
    );
  };

  const getTableColumns = (topLevelDataObjects, isUpcomingCheckins) => {
    const getTitleCase = text => {
      var result = text.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };
    const keysNeeded = key => {
      return (
        key !== '__typename' &&
        key !== 'photosNumber' &&
        key !== 'photosParentFolder' &&
        key !== 'token' &&
        key !== 'name' &&
        key !== 'label'
      );
    };

    return topLevelDataObjects.reduce((acc, column) => {
      Object.keys(column).forEach(key => {
        const filterOptions = {};
        if (typeof column[key] === 'number') {
          filterOptions.Filter = NumberRangeColumnFilter;
          filterOptions.filter = 'between';
        } else if (useSelectFilter(key, topLevelDataObjects)) {
          filterOptions.Filter = SelectColumnFilter;
          filterOptions.filter = 'includes';
        }
        if (acc && keysNeeded(key)) {
          const matchingKey = acc.find(accItem => accItem.accessor === key);

          if (!matchingKey) {
            acc.push({
              Header: getTitleCase(key),
              accessor: key,
              Cell: CustomCell,
              ...filterOptions,
            });
          }
        }
      });
      return acc;
    }, []);
  };
  const reduceObjValues = obj => {
    const objectValuesForTable = {};
    const objectValues = Object.keys(obj).reduce((acc, cur) => {
      if (!Array.isArray(obj[cur]) && typeof obj[cur] === 'object') {
        return reduceObjValues({ ...acc, ...obj[cur] }, /*cache*/ {});
      }
      acc[cur] = obj[cur];

      return acc;
    }, {});

    objectValuesForTable.property = `${objectValues.name} (${objectValues.label})`;
    objectValuesForTable.photos =
      objectValues.photos?.length > 0 ? objectValues.photos?.length : '0';
    objectValuesForTable.photosNumber =
      objectValues.photos?.length > 0 ? objectValues.photos?.length : null;
    objectValuesForTable.photosParentFolder =
      objectValues.photos?.length > 0
        ? objectValues.photos[0].parentFolder
        : null;

    objectValuesForTable.fiveStarRating = objectValues?.fiveStarRating
      ? Number(objectValues?.fiveStarRating)
      : 0;

    return { ...objectValues, ...objectValuesForTable };
  };
  const topLevelTableData = tableData
    ? tableData.reduce((acc, edge) => {
        acc.push(reduceObjValues(edge?.node));
        return acc;
      }, [])
    : null;
  const columns = topLevelTableData && getTableColumns(topLevelTableData, true);
  const memoizedColumns = React.useMemo(() => columns, []);
  const memoizedData = React.useMemo(() => topLevelTableData, [
    topLevelTableData,
  ]);
  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <div>
          <ChevronDown />
        </div>
      ) : (
        <div>
          <ChevronUp />
        </div>
      )
    ) : (
      <div className="d-flex flex-column">
        <ChevronUp />
        <ChevronDown style={{ marginTop: '-10px' }} />
      </div>
    );
  };
  return (
    <section className="d-flex flex-column h-100 m-3">
      <section className="d-flex justify-content-between align-items-center">
        <h1 className="">Guest Memories</h1>
        {/* <CSVLink
          filename={`${bookingType}-${moment().format('YYYYMMDD')}.csv`}
          data={sortedRows.map(sortedRow => sortedRow.values)}
        >
          <span style={{ whiteSpace: 'nowrap' }}>Download Report</span>
        </CSVLink> */}
      </section>
      <ReactTable data={memoizedData} columns={memoizedColumns}>
        {({
          getTableProps,
          getTableBodyProps,
          headerGroups,
          prepareRow,
          rows,
          sortedRows,
          setFilter,
          columns,
        }) => {
          return (
            <div id="table-wrapper" className="p-0">
              <BTable
                id="memories-table"
                className="mb-0"
                // id="sticky-header-table"
                data-sticky-header-table
                striped
                bordered
                hover
                size="sm"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th key={column?.Header} className="text-nowrap">
                          <div className="d-flex justify-content-between">
                            <span
                              style={{
                                whiteSpace:
                                  column.Header.length < 15
                                    ? 'nowrap'
                                    : 'inherit',
                              }}
                            >
                              {column.render('Header')}
                            </span>
                            {/*   Add a sort direction indicator
                          isSorted sorts in ascending order
                      */}
                            <TippyTooltip
                              // options
                              html={
                                <div className="d-flex">
                                  <div>{column.render('Filter')}</div>
                                  <div>
                                    <button
                                      className="btn btn-light py-0 ml-2"
                                      {...column.getSortByToggleProps()}
                                    >
                                      {generateSortingIndicator(column)}
                                    </button>
                                  </div>
                                </div>
                              }
                              position="bottom"
                              interactive
                              trigger="click"
                              theme="light"
                              style={{ cursor: 'pointer' }}
                            >
                              <Filter
                                color={
                                  column.filterValue || column.isSorted
                                    ? '#333'
                                    : '#ccc'
                                }
                                size={20}
                              />
                            </TippyTooltip>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              className="text-nowrap"
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </BTable>
            </div>
          );
        }}
      </ReactTable>
    </section>
  );
};

export default withApollo(GuestMemoriesTable);
