import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import get from 'lodash.get';

const UPSERT_PROPERTY_LISTING = gql`
  mutation upsertPropertyListing(
    $propertyUrl: String!
    $icalUrl: String!
    $urlId: String!
    $propertyListingId: Int
    $propertyId: Int!
    $platformId: Int!
  ) {
    upsertPropertyListing(
      where: { id: $propertyListingId }
      update: { propertyUrl: $propertyUrl, urlId: $urlId, icalUrl: $icalUrl }
      create: {
        propertyId: $propertyId
        platformId: $platformId
        propertyUrl: $propertyUrl
        urlId: $urlId
        icalUrl: $icalUrl
      }
    ) {
      id
      propertyUrl
    }
  }
`;

const validationSchema = Yup.object().shape({
  icalUrl: Yup.string().url('Url must start with http:// or https://'),
  propertyUrl: Yup.string().url('Url must start with http:// or https://'),
  urlId: Yup.string().min(3, 'Must be at least 3 characters'),
});

const PropertyListingForm = props => {
  const {
    urlId,
    propertyUrl,
    icalUrl,
    platform,
    property,
    id,
    platformId,
    onSuccess,
    label,
  } = props;
  //   const [errors, setErrors] = useState(null);
  const propertyId = props.property ? props.property.id : null;
  return (
    <div>
      <Mutation mutation={UPSERT_PROPERTY_LISTING}>
        {(upsertPropertyListing, { data }) => (
          <Formik
            enableReinitialize={true}
            initialValues={{
              icalUrl: icalUrl ? icalUrl : '',
              propertyUrl: propertyUrl ? propertyUrl : '',
              urlId: urlId ? urlId : '',
              propertyListingId: id ? id : null,
              platformId: platformId ? platformId : null,
              propertyId,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, setErrors }
            ) => {
              setSubmitting(true);

              try {
                const result = await upsertPropertyListing({
                  variables: {
                    propertyUrl: values.propertyUrl,
                    icalUrl: values.icalUrl,
                    urlId: values.urlId,
                    propertyListingId: values.propertyListingId,
                    propertyId: values.propertyId,
                    platformId: values.platformId,
                  },
                });
                // setErrors({});

                if (result.data.upsertPropertyListing) {
                  onSuccess();
                  setStatus({ success: true });
                }
              } catch (err) {
                setErrors(err);
                setStatus({ error: err });
              }
              // setSubmitting(false);
              // setStatus({ isSubmitted: true });
            }}
          >
            {formikProps => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                status,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="font-weight-bold">iCal Url:</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.icalUrl}
                      className="form-control"
                      name="icalUrl"
                      type="url"
                      placeholder={
                        values.icalUrl ? values.icalUrl : 'Enter a new ical url'
                      }
                      invalid={String(errors.icalUrl && touched.icalUrl)}
                    />
                    <div className="small text-danger mt-1">
                      {touched.icalUrl && errors.icalUrl && errors.icalUrl}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold">Property Url:</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyUrl}
                      className="form-control"
                      name="propertyUrl"
                      type="url"
                      placeholder={
                        values.propertyUrl
                          ? values.propertyUrl
                          : 'Enter a new property url'
                      }
                      invalid={String(
                        errors.propertyUrl && touched.propertyUrl
                      )}
                    />

                    <div className="small text-danger mt-1">
                      {touched.propertyUrl &&
                        errors.propertyUrl &&
                        errors.propertyUrl}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold">Url Id:</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.urlId}
                      className="form-control"
                      name="urlId"
                      type="text"
                      placeholder={
                        values.urlId ? values.urlId : 'Enter a new url id'
                      }
                      invalid={String(errors.urlId && touched.urlId)}
                    />
                    <div className="small text-danger mt-1">
                      {touched.urlId && errors.urlId && errors.urlId}
                    </div>
                  </div>

                  <FormResponse
                    errors={get(status, 'error')}
                    isVisible={get(status, 'error') || get(status, 'success')}
                  >
                    {get(status, 'success')}
                  </FormResponse>
                  <button
                    className="btn btn-outline-primary"
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Submit
                  </button>
                </form>
              );
            }}
          </Formik>
        )}
      </Mutation>
    </div>
  );
};

export default PropertyListingForm;
