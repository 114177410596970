import React, { useState, useEffect, useRef } from 'react';
// import WelcomeMessageSendForm from './components/WelcomeMessageSendForm';
import LoadingSpinner from 'src/components/LoadingSpinner';
// import TabbedDrawer from 'src/components/TabbedDrawer';
// import Handlebars from 'src/util/handlebarsHelpers';
// import debounce from 'src/util/debounce';
// import useUser from 'src/contexts/useUser';
import gql from 'graphql-tag';
import moment from 'moment';
import Query from 'src/components/GraphQL/components/Query';
import { enumToLabel } from 'src/util';
// import { Mutation, withApollo } from 'react-apollo';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Tooltip from 'src/components/Tooltip';
// import ThemedButton from 'src/components/Forms/components/ThemedButton';
// import HandlebarsExampleTabs from './components/HandlebarsExampleTabs';
// import { AlertTriangle } from 'react-feather';
//import Modal from 'src/components/Modal';
import StarImg from 'src/images/star.svg';

const StarIcons = ({ numberOfStars }) => {
  return Array.from({ length: Number(numberOfStars) }).map((_, index) => {
    return <img key={index} width="20" src={StarImg} />;
  });
};
const GUEST_RESERVATION_REVIEW = gql`
  query GuestReservationReview($id: Int) {
    guestReservationReview(where: { id: $id }) {
      id
      headline
      body
      fiveStarRating
      reviewDate
      reviewStatus
      reservation {
        checkIn
        checkOut
      }
      property {
        name
        label
      }
    }
  }
`;
export default ({ id }) => {
  return (
    <Query
      variables={{ id }}
      query={GUEST_RESERVATION_REVIEW}
      fetchPolicy={'no-cache'}
    >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '80vh' }}
            >
              <LoadingSpinner />
            </div>
          );
        console.log('data', data);
        /*
body: "Our group of 10 friends celebrated the 50th birthday of one of the ladies in the group. We were so very pleased with this beautiful property and lovely home. The house was exceptionally clean and well organized. Anything we could possible need was available (including beach towels, bikes, boogie boards, toiletries, bug repellant, coffee, tea...the list goes on and on). The kitchen was fully loaded with all we needed to prepare meals for a big group. Al was so great to work with and VERY responsive! He was a great support throughout the whole time before, during and after our stay. My friend's birthday celebration was a BIG hit and we all agree the house, beach and surrounding area were a huge part of the success. I absolutely recommend this property. Al and his team work very hard and it shows! (side note: the No see 'ums are NO joke! There were helpful hints posted about them along with bug repellant to help, which was greatly appreciated!) Thanks to Al, the owner and the whole supportive team for their thoughtfulness and well managed property!"
fiveStarRating: 5
headline: "A Happy 50th Birthday"
id: 1485
property: {name: "Del Mar al Cielo", label: "1981", __typename: "Property"}
reviewDate: "2021-09-06T04:00:00.000Z"
reviewStatus: "OWNER_GRACE_PERIOD"

*/

        return (
          <>
            <div className="d-flex pb-3 justify-content-between">
              <div>
                <StarIcons
                  numberOfStars={data.guestReservationReview.fiveStarRating}
                />{' '}
              </div>
              <div>
                {data.guestReservationReview.reviewDate
                  ? moment(data.guestReservationReview.reviewDate).format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </div>
            </div>

            <p>
              {data.guestReservationReview.property.name}:{' '}
              {moment(data.guestReservationReview.reservation.checkIn).format(
                'MM/DD/YYYY'
              )}
              -
              {moment(data.guestReservationReview.reservation.checkOut).format(
                'MM/DD/YYYY'
              )}
            </p>

            <p style={{ whiteSpace: 'pre-wrap' }}>
              {data.guestReservationReview.body}
            </p>
            <p>
              <b>Status:</b>
              <br />
              {enumToLabel(data.guestReservationReview.reviewStatus)}
            </p>
          </>
        );
      }}
    </Query>
  );
  // return <Modal
  //             // large={true}
  //             isOpen={isOpen}
  //             title="Welcome Message Send Date"
  //             styleType="medium"
  //             disableForceFocus={true}
  //             closeOnOverlayClick={false}
  //             closeOnEsc={false}
  //             onClose={() => {
  //               setMarkAsSentModalIsOpen(false);
  //             }}
  //           >
  //           CONTENT
  //             {/* <WelcomeMessageSendForm
  //               reference={reference || urlParams.get('reference')}
  //               onSuccess={() => {
  //                 setWelcomeSentLabel('Mark as Unsent');
  //                 onSuccess();
  //               }}
  //             /> */}
  //           </Modal>
};
