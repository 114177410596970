import React, { useState } from 'react';
import BookingsTable from '../components/BookingsTable';
import ReservationsTable from '../components/ReservationsTable';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import gql from 'graphql-tag';
import moment from 'moment';
import _ from 'lodash';
import Layout from 'src/layouts';

//import { Tooltip } from 'react-tippy';
import Modal from 'src/components/Modal';
// import DynamicReportForm from './components/DynamicReportForm';
// import { keyToLabel } from 'src/util';
// import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';
import { Info } from 'react-feather';
// import { reduceObjValues } from '../components/BookingsTable/util';

const GUESTS_RESERVATION_FORM_ENTRIES = gql`
  query GuestReservationFormEntries(
    #$where: GuestReservationWhereInput
    # $reservationCreateDate_gte: Date
    # $reservationCreateDate_lte: Date
    # # $checkIn_gte: Date
    $first: Int!
    $skip: Int!
  ) {
    guestReservationFinalizationStepOptions {
      id
      label
      stage
      sort
    }
    guestReservationRedFlagOptions {
      id
      # type
      label
      sort
    }
    guestReservationFormEntriesConnection(first: $first, skip: $skip) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          webformEntryId: id
          createdAt
          payload
          reservation {
            confirmationStatus
            welcomeMessageDateSent
            reference
            id
            externalConversationUrl
            reservationFormToken {
              token
            }
            guestStaysAtThisProperty
            guestStaysAtOtherProperties
            reservationCreateDate
            checkInDate: checkIn
            checkOutDate: checkOut
            checkInHour
            checkOutHour
            property {
              key
              maxAdults
            }
            guest {
              guestId: id
              firstName
              lastName
              address1
              city
              state
              postalCode
              country
            }
            status
            adults
            children
            pets
            visitors
            agreementSigned
            eventType
            mobilityChallenged
            largeVehicles
            resortAccess
            vehicles
            redFlags {
              id
              label
              type
            }
            finalizationSteps {
              id
              label
              readOnly
            }
          }
        }
      }
    }
  }
`;

const TYFRReport = () => {
  const today = new Date();

  return (
    <Layout hideMainOverflow={true} metaTitle={'TYFR Report'}>
      <Query
        variables={{
          // where: {
          //   status: 'BOOKING',
          //   reservationCreateDate_gte: moment(today)
          //     .subtract(45, 'days')
          //     .format('YYYY-MM-DD'),
          //   checkOut_gte: moment(today).format('YYYY-MM-DD'),
          // },
          // checkIn_gte: today,
          first: 250,
          skip: 0,
          // orderBy: 'reservationCreateDate_DESC',
        }}
        notifyOnNetworkStatusChange={true}
        query={GUESTS_RESERVATION_FORM_ENTRIES}
        // skip={searchTerm}
        fetchPolicy="no-cache"
      >
        {({
          loading,
          error,
          data,
          refetch: refetchAllReservations,
          networkStatus,
        }) => {
          const edges = data?.guestReservationFormEntriesConnection?.edges;

          const isRefetching = networkStatus === 4;

          function flat(r, a) {
            var b = {};
            Object.keys(a).forEach(function(k) {
              if (k !== 'items') {
                b[k] = a[k];
              }
            });
            r.push(b);
            if (Array.isArray(a.items)) {
              b.items = a.items.map(function(a) {
                return a.id;
              });
              return a.items.reduce(flat, r);
            }
            return r;
          }

          const reduceObjValues = obj => {
            if (!obj) return {};
            const objectValues = Object.keys(obj).reduce((acc, cur) => {
              if (!Array.isArray(obj[cur]) && typeof obj[cur] === 'object') {
                return reduceObjValues({ ...acc, ...obj[cur] }, /*cache*/ {});
              }
              acc[cur] = obj[cur];

              return acc;
            }, {});
            return objectValues;
          };

          // const topLevelDataObjects = edges ? edges.reduce(flat, []) : null;
          // // TODO: Flatten recursion
          // console.log('Flatten2ed', );
          const topLevelDataObjects = edges
            ? edges.reduce((acc, edge) => {
                acc.push(reduceObjValues(edge?.node));
                return acc;
              }, [])
            : null;
          //  console.log('topLevelDataObjects1', topLevelDataObjects);
          /*
          ReservationsTable Roadmap
          Goal: rewrite BookingsTable to be more generic

          Phase 1: Pass table data into component. Minimize implementation-specific column definitions. Controll outside of component
          I'm ok with forming the label outside of component, and each key may be a "reserved key" that unlocks functionality (like a modal on click)

          Phase 2: Support for more complex obects. Example : finalizeStatus: {component: 'FinalizationStepModalHyperlink', data: {finalizationSteps} }

          */

          const getFinalizeStatusLabel = (
            finalizationSteps,
            guestReservationFinalizationStepOptions
          ) => {
            // const guestReservationFinalizationStepOptions =
            //   finalizedStatusAndRedFlagData?.guestReservationFinalizationStepOptions;
            const steps = finalizationSteps;
            if (
              steps?.length === guestReservationFinalizationStepOptions?.length
            ) {
              return 'Complete';
            }
            return guestReservationFinalizationStepOptions
              ? `${steps?.length} of ${guestReservationFinalizationStepOptions?.length}`
              : ``;
          };
          //  console.log('topLevelDataObjects', topLevelDataObjects);
          const sum = (...all) => {
            return all.reduce((acc, val) => {
              acc = acc + (Number(val) || 0);
              return acc;
            }, 0);
          };
          const tableData = topLevelDataObjects
            ? topLevelDataObjects.map(object => {
                return {
                  redFlag: object?.redFlags?.length > 0 ? '🚩' : ' ',
                  finalizeStatus: getFinalizeStatusLabel(
                    object?.finalizationSteps,
                    data?.guestReservationFinalizationStepOptions
                  ),
                  confirmed:
                    object?.confirmationStatus === 'CONFIRMED' ? '✔️' : '🚫',
                  tyfrCompleted: moment(object?.createdAt).format('MM/DD/YYYY'),
                  reservationId: object?.reference,
                  'house#': object?.key,
                  guestName: `${object?.firstName} ${object.lastName}`,
                  address: object?.address1
                    ? `${object?.address1} ${object?.city ||
                        ''} ${object?.state || ''} ${object?.postalCode ||
                        ''} ${object?.country || ''}`
                    : '',
                  //                     Guest Adults (total)
                  // Guest Children (total)
                  // Visitor Adults (total)
                  // Visitor Children (total)
                  // Pets (total)
                  // Vehicles (total)
                  guestAdults: sum(
                    object?.adultsStayingConfirmed,
                    object?.adultsStayingPossible
                  ),
                  guestChildren: sum(
                    object?.childrenStayingConfirmed,
                    object?.childrenStayingPossible,
                    object?.toddlersStayingConfirmed,
                    object?.toddlersStayingPossible
                  ),
                  visitorAdults: sum(
                    object?.adultsVisitingConfirmed,
                    object?.adultsVisitingPossible
                  ),
                  visitorChildren: sum(
                    object?.childrenVisitingConfirmed,
                    object?.childrenVisitingPossible,
                    object?.toddlersVisitingConfirmed,
                    object?.toddlersVisitingPossible
                  ),
                  pets: sum(
                    object?.petsStayingConfirmed,
                    object?.petsStayingPossible
                  ),
                  vehicles: sum(
                    object?.vehiclesStayingConfirmed,
                    object?.vehiclesStayingPossible
                  ),
                  event: object?.hasSpecialOccasion,
                  otherReservations: object?.hasOtherReservations,
                  redFlags: object?.redFlags,
                  finalizationSteps: object?.finalizationSteps,
                  guestId: object?.guestId,
                  webformEntryId: object?.webformEntryId,
                  reference: object?.reference,
                  mobilityIssuesStaying: object?.mobilityIssuesStaying,
                  mobilityIssuesVisiting: object?.mobilityIssuesVisiting,
                };
              })
            : null;

          // const columnDefinitions = {
          //   guestAdults: {
          //     component: () => {
          //       return <>HYPERLINK</>;
          //     },
          //   },
          // };
          // console.log('tableData', tableData);
          return (
            <>
              {edges ? (
                // <BookingsTable
                //   bookingType={'tyfr'}
                //   isRefetching={isRefetching}
                //   tableData={edges}
                //   preprocess={false}
                //   refetchAllReservations={refetchAllReservations}
                // />
                <>
                  <ReservationsTable
                    bookingType={'tyfr'}
                    isRefetching={isRefetching}
                    reportDescription="Report returns data from all TYFR forms submitted."
                    // columnDefinitions={columnDefinitions}
                    tableData={tableData}
                    refetchAllReservations={refetchAllReservations}
                  />
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              )}
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default TYFRReport;
