import React, { useState, useEffect } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import moment from 'moment';
import { withApollo } from 'react-apollo';
import get from 'lodash/get';
import {
  addDays,
  differenceBetweenDays,
  formatDateWithDashes,
  getDatesInRange,
  parseDateStringDashesToSlashes,
} from '../../util/dateHelper';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { date } from 'yup';

const PROPERTY_QUERY = gql`
  query Property($propertyWhereUniqueInput: PropertyWhereUniqueInput) {
    property(where: $propertyWhereUniqueInput) {
      calendar {
        start
        rates
        availability
        minStay
        minPriorNotify
        checkInAvailability
        checkOutAvailability
      }
    }
  }
`;
const PROPERTY_QUOTE = gql`
  query PropertyQuote($propertyQuoteWhereInput: PropertyQuoteWhereInput) {
    propertyQuote(where: $propertyQuoteWhereInput) {
      totalNights
      avgPerNight
      damageDeposit
      cleaningFee
      petFee
      lodgingTax
      rate
    }
  }
`;

const DayCell = ({
  onClick,
  dayInMonth,
  classList,
  index,
  onMouseEnter = () => {},
}) => {
  return (
    <div
      data-date={formatDateWithDashes(dayInMonth)}
      className={classList.join(' ')}
      onClick={onClick}
      key={`day_${index}`}
      onMouseEnter={e => {
        onMouseEnter(e);
      }}
    >
      {index + 1}
    </div>
  );
};
const getAvailabilityCalendarGroups = availabilityCalendar => {
  let status = 'notAvailable';
  const availabilityGroups = Object.keys(availabilityCalendar).reduce(
    (acc, dateKey, index) => {
      const {
        availability,
        checkInAvailability,
        checkOutAvailability,
        numberOfConsecutiveDaysAvailable,
        minPriorNotifyFail,
      } = availabilityCalendar[dateKey];
      availabilityCalendar[dateKey] = {
        ...availabilityCalendar[dateKey],
        status,
      };
      const nextDate = Object.keys(availabilityCalendar)[index + 1];
      const nextDayOnCalendar = nextDate
        ? availabilityCalendar[nextDate]
        : null;
      if (
        availability === 'Y' &&
        checkInAvailability === 'Y' &&
        minPriorNotifyFail === false &&
        status !== 'available'
      ) {
        availabilityCalendar[dateKey] = {
          ...availabilityCalendar[dateKey],
          status: 'checkIn',
        };
        status = 'available';
      } else if (
        checkOutAvailability === 'Y' &&
        status == 'available' &&
        numberOfConsecutiveDaysAvailable === 0
      ) {
        availabilityCalendar[dateKey] = {
          ...availabilityCalendar[dateKey],
          status: 'checkOut',
        };
        status = 'notAvailable';
      } else if (
        checkOutAvailability === 'N' &&
        checkInAvailability === 'N' &&
        availability === 'N'
      ) {
        availabilityCalendar[dateKey] = {
          ...availabilityCalendar[dateKey],
          status: 'notAvailable',
        };
        status = 'notAvailable';
      } else if (
        nextDayOnCalendar.checkOutAvailability === 'N' &&
        nextDayOnCalendar.checkInAvailability === 'N' &&
        nextDayOnCalendar.availability === 'N' &&
        status == 'available'
      ) {
        availabilityCalendar[dateKey] = {
          ...availabilityCalendar[dateKey],
          status: 'checkOut',
        };
      }
      acc[dateKey] = availabilityCalendar[dateKey];
      return acc;
    },
    {}
  );

  return availabilityGroups;
};

const getNumberOfConsecutiveDaysAvailable = (dateKey, availabilityCalendar) => {
  const allDates = Object.keys(availabilityCalendar);
  const dateRange = allDates.slice(allDates.indexOf(dateKey));
  const consecutiveDates = [];
  dateRange.some(date => {
    consecutiveDates.push(availabilityCalendar[date]);
    return availabilityCalendar[date].availability === 'N';
  });
  return consecutiveDates.slice(0, consecutiveDates.length - 1).length;
};

const getAvailabilityCalendar = property => {
  const {
    availability: availabilityString,
    checkInAvailability: checkInAvailabilityString,
    checkOutAvailability: checkOutAvailabilityString,
    minStay: minStayString,
    minPriorNotify: minPriorNotifyString,
  } = property.calendar;
  const availability = availabilityString.split('');
  const checkInAvailability = checkInAvailabilityString.split('');
  const checkOutAvailability = checkOutAvailabilityString.split('');
  const minStay = minStayString.split(',');
  const minPriorNotify = minPriorNotifyString.split(',');
  const availabilityCalendarBase = availability.reduce((acc, day, index) => {
    const dateKey = moment(day.start)
      .add(index, 'days')
      .format('YYYY-MM-DD');
    acc[`${dateKey}`] = {
      dateKey,
      availability: availability[index],
      checkInAvailability: checkInAvailability[index],
      checkOutAvailability: checkOutAvailability[index],
      minStay: minStay[index],
      minPriorNotify: minPriorNotify[index],
    };
    return acc;
  }, {});

  const availabilityCalendar = Object.keys(availabilityCalendarBase).reduce(
    (acc, dateKey) => {
      const numberOfConsecutiveDaysAvailable = getNumberOfConsecutiveDaysAvailable(
        dateKey,
        availabilityCalendarBase
      );
      const daysFromNow =
        moment(dateKey)
          .utc()
          .diff(moment().utc(), 'days') + 1;
      acc[dateKey] = {
        ...availabilityCalendarBase[dateKey],
        numberOfConsecutiveDaysAvailable,
        minPriorNotifyFail:
          daysFromNow < availabilityCalendarBase[dateKey].minPriorNotify
            ? true
            : false,
      };
      return acc;
    },
    {}
  );
  return availabilityCalendar;
};

const QuoteGenerator = ({
  propertyId,
  client,
  resetRangeOnClick,
  checkIn,
  checkOut,
  startMonth,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateHoverRange, setDateHoverRange] = useState(null);
  const [propertyQuoteData, setPropertyQuoteData] = useState();
  const propertyWhereUniqueInput = { id: propertyId };
  const [queryIsLoading, setQueryIsLoading] = useState(false);
  const [dayCellData, setDayCellData] = useState({});
  const [formFeedback, setFormFeedback] = useState(null);
  const [resetDates, setResetDates] = useState(false);
  const [quoteQueryIsLoading, setQuoteQueryIsLoading] = useState(false);

  const getMinStayDate = (startDate, endDate, closestDayAvailableString) => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      return closestDayAvailableString;
    }
    return startDate;
  };

  const handleSelectedRangeHighlightingOnClick = dateOnClick => {
    const selectedDate = new Date(parseDateStringDashesToSlashes(dateOnClick));

    const startDateObject = new Date(parseDateStringDashesToSlashes(startDate));
    const endDateObject = new Date(parseDateStringDashesToSlashes(endDate));
    const range = getDatesInRange(startDateObject, selectedDate);
    if (
      (resetRangeOnClick && endDate && startDate) ||
      (resetRangeOnClick && !endDate && !startDate)
    ) {
      setDateHoverRange(null);
      return;
    }
    if (selectedDate < startDateObject) {
      setDateHoverRange(null);
    } else if (endDate && selectedDate > endDateObject) {
      setDateHoverRange(range);
    } else if (endDate && selectedDate < endDateObject) {
      setDateHoverRange(range);
    }
  };
  const isWithinDateRange = (hoveredDate, startDateObject, endDateObject) => {
    if (hoveredDate > startDateObject && hoveredDate < endDateObject) {
      return true;
    }
    return false;
  };
  const handleDateRangeHighlighting = (currentDate, classList) => {
    if (startDate) {
      const startDateObject = new Date(
        parseDateStringDashesToSlashes(startDate)
      );
      const endDateObject = new Date(parseDateStringDashesToSlashes(endDate));
      const hoveredDate = new Date(parseDateStringDashesToSlashes(currentDate));
      const range = getDatesInRange(startDateObject, hoveredDate);
      const alreadySelectedInRange = classList
        ? Array.from(classList).find(name => name === 'inSelectedRange')
        : false;
      const isWithinRange = classList
        ? isWithinDateRange(hoveredDate, startDateObject, endDateObject)
        : true;
      if (startDateObject && endDateObject && isWithinRange) {
        !alreadySelectedInRange && setDateHoverRange(range);
      } else if (startDate && !endDate) {
        hoveredDate > startDateObject && setDateHoverRange(range);
        hoveredDate <= startDateObject && setDateHoverRange(null);
      }
    }
  };
  const getClosestDayAvailable = (currentDate, dayCellData, checkInMode) => {
    const findAvailableKey = date => {
      const isDayAvailable = getDateAvailability(date, dayCellData);
      return isDayAvailable && date;
    };
    const dataCellKeys = Object.keys(dayCellData);
    const dateIndex =
      dataCellKeys.indexOf(currentDate) !== -1
        ? dataCellKeys.indexOf(currentDate)
        : 0;
    const pastDates = dataCellKeys.slice(0, dateIndex).reverse();
    const futureDates = dataCellKeys.slice(dateIndex);
    const pastDayAvailable = pastDates.find(findAvailableKey);
    const futureDayAvailable = futureDates.find(findAvailableKey);

    const dayDifferenceFromPast = pastDayAvailable
      ? differenceBetweenDays(
          parseDateStringDashesToSlashes(pastDayAvailable),
          parseDateStringDashesToSlashes(currentDate)
        )
      : null;
    const dayDifferenceFromFuture = futureDayAvailable
      ? differenceBetweenDays(
          parseDateStringDashesToSlashes(currentDate),
          parseDateStringDashesToSlashes(futureDayAvailable)
        )
      : null;

    if (!dayDifferenceFromPast && !dayDifferenceFromFuture) return null;
    if (dayDifferenceFromPast && !dayDifferenceFromFuture)
      return pastDayAvailable;
    if (!dayDifferenceFromPast && dayDifferenceFromFuture)
      return futureDayAvailable;
    return dayDifferenceFromPast <= dayDifferenceFromFuture
      ? pastDayAvailable
      : futureDayAvailable;
  };
  const shouldDisableSelect = (selectedDate, classes, dayCellData) => {
    const fullDayIsBooked = classes
      ? classes.find(name => name === 'notAvailable')
      : null;
    const selectedDateObject = new Date(
      parseDateStringDashesToSlashes(selectedDate)
    );
    const startDateObject = new Date(parseDateStringDashesToSlashes(startDate));
    let isBeforeStartDateFeedback = [];
    const isInCheckInMode = !startDate || (startDate && endDate) ? true : false;

    if (startDate && !isInCheckInMode) {
      if (selectedDateObject < startDateObject) {
        isBeforeStartDateFeedback.push(
          `The check out date cannot be before the check in date.`
        );
      }
    }
    const selectedDayCellData = dayCellData[selectedDate] || {};

    const noCheckIn =
      selectedDayCellData.checkInAvailability === 'N' &&
      selectedDayCellData.checkOutAvailability === 'Y';
    const noCheckOut =
      selectedDayCellData.checkOutAvailability === 'N' &&
      selectedDayCellData.checkInAvailability === 'Y';

    const status = selectedDayCellData.status;
    if (!classes.find(name => name === 'hyperlink')) {
      const closestDayAvailableString = getClosestDayAvailable(
        selectedDate,
        dayCellData,
        isInCheckInMode
      );

      const selectedDateString = selectedDateObject.toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });

      const closestDayAvailableDate = closestDayAvailableString
        ? new Date(parseDateStringDashesToSlashes(closestDayAvailableString))
        : null;

      const localeString = closestDayAvailableDate
        ? closestDayAvailableDate.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })
        : null;

      if (
        fullDayIsBooked ||
        (isInCheckInMode && noCheckIn && status === 'checkOut')
      ) {
        setFormFeedback([
          ...isBeforeStartDateFeedback,
          `${selectedDateString} is not available.`,
          ` ${
            closestDayAvailableString
              ? `The closest check in date available is ${localeString}`
              : ''
          } `,
        ]);
        setPropertyQuoteData(null);
        return true;
      }
      if (!isInCheckInMode) {
        if (status === 'checkIn' || noCheckOut) {
          setFormFeedback([
            ...isBeforeStartDateFeedback,
            `${selectedDateString} is not available.`,
            `${
              closestDayAvailableString
                ? `The closest check out date available ${localeString}`
                : ''
            } `,
          ]);
          setPropertyQuoteData(null);
          return true;
        }

        const unavailableDayFound = [
          ...Array(differenceBetweenDays(startDate, selectedDate)).keys(),
        ].find((_, index) => {
          const dateString = formatDateWithDashes(
            addDays(new Date(parseDateStringDashesToSlashes(startDate)), index)
          );
          return (
            !dayCellData[dateString] ||
            (dayCellData[dateString].checkInAvailability === 'N' &&
              dayCellData[dateString].checkOutAvailability === 'N' &&
              dayCellData[dateString].availability === 'N')
          );
        });
        const foundUnavailableDay =
          unavailableDayFound || unavailableDayFound === 0;

        if (foundUnavailableDay) {
          setFormFeedback([
            ...isBeforeStartDateFeedback,
            `${selectedDateString} is not available.`,
            ` ${
              closestDayAvailableString
                ? `The closest check out date available ${localeString}`
                : ''
            } `,
          ]);
          setPropertyQuoteData(null);
          return true;
        }
      }

      const minStayDate = getMinStayDate(
        startDate,
        endDate,
        closestDayAvailableString
      );

      setFormFeedback([
        `${selectedDateString} is not available.`,
        `There is a ${dayCellData[minStayDate].minStay} night minimum stay.`,
        `${
          closestDayAvailableString
            ? `The closest ${
                isInCheckInMode ? 'check in' : 'check out'
              } date available is ${localeString}`
            : ''
        }`,
      ]);
      setPropertyQuoteData(null);
      return true;
    }
  };
  const setDateWithStartDateAndNoEndDate = async (
    startDate,
    selectedDate,
    currentDate
  ) => {
    const start = new Date(parseDateStringDashesToSlashes(startDate));
    if (selectedDate < start) {
      setStartDate(currentDate);
    } else {
      setEndDate(currentDate);
      await submitForQuote(startDate, currentDate);
    }
  };
  const setDatesWithStartAndEndDatesSelected = async (
    startDate,
    endDate,
    selectedDate
  ) => {
    const start = new Date(parseDateStringDashesToSlashes(startDate));
    const end = new Date(parseDateStringDashesToSlashes(endDate));
    const isoDateString = selectedDate.toISOString();
    const formattedDateString = isoDateString.substring(
      0,
      isoDateString.indexOf('T')
    );
    if (resetRangeOnClick && startDate && endDate) {
      setStartDate(formattedDateString);
      setEndDate(null);
      return;
    }
    if (selectedDate < start) {
      setStartDate(formattedDateString);
      setEndDate(null);
    } else if (selectedDate > start && selectedDate < end) {
      setEndDate(formattedDateString);
    } else {
      setEndDate(formattedDateString);
    }
  };
  const handleDateSelect = async (currentDate, classes, dayCellData) => {
    const dateSelected = new Date(parseDateStringDashesToSlashes(currentDate));
    // console.log('currentDate', currentDate);
    // console.log('endDate', endDate);
    if (!shouldDisableSelect(currentDate, classes, dayCellData)) {
      if (!startDate) {
        setStartDate(currentDate);
        return true;
      } else if (startDate && !endDate) {
        setDateWithStartDateAndNoEndDate(startDate, dateSelected, currentDate);
      } else if (startDate && endDate) {
        setDatesWithStartAndEndDatesSelected(startDate, endDate, dateSelected);
      }
      // handleSelectedRangeHighlightingOnClick(currentDate);
    }
  };
  useEffect(() => {
    const getCalendarGroups = property => {
      const availabilityCalendar = getAvailabilityCalendar(property);
      const availabilityGroups = getAvailabilityCalendarGroups(
        availabilityCalendar
      );
      return availabilityGroups;
    };
    const populateCalendar = property => {
      const availabilityGroups = property
        ? getCalendarGroups(property)
        : dayCellData;
      setDayCellData(availabilityGroups);

      if (checkIn && availabilityGroups) {
        const targetDateCell =
          document.querySelector(`[data-date="${checkIn}"]`) || {};
        const classes = Array.from(targetDateCell.classList || {});
        if (!startDate) {
          handleDateSelect(checkIn, classes, availabilityGroups);
        } else if (checkIn === startDate) {
          handleDateSelect(checkOut, classes, availabilityGroups);
          handleSelectedRangeHighlightingOnClick(checkOut);
          handleDateRangeHighlighting(checkOut);
        }
      }
    };

    if (!resetDates) {
      const checkAvailability = async () => {
        setQueryIsLoading(true);
        try {
          const { data } = await client.query({
            query: PROPERTY_QUERY,
            variables: {
              propertyWhereUniqueInput,
            },
            fetchPolicy: 'no-cache',
          });
          if (data) setQueryIsLoading(false);
          populateCalendar(data.property);
        } catch (err) {
          setQueryIsLoading(false);
          setFormFeedback([`${err.message}`]);
        }
      };
      Object.keys(dayCellData).length === 0 && checkAvailability();
      Object.keys(dayCellData).length > 0 && populateCalendar();
    }
  }, [startDate]);
  const submitForQuote = async (startDate, endDate) => {
    const propertyQuoteWhereInput = {
      propertyId: propertyId,
      checkIn: startDate,
      checkOut: endDate,
      //   checkIn: '2020-02-04',
      //   checkOut: '2020-02-06',
      hasPet: false,
    };
    setQuoteQueryIsLoading(true);
    try {
      const { data } = await client.query({
        query: PROPERTY_QUOTE,
        variables: {
          propertyQuoteWhereInput,
        },
        fetchPolicy: 'no-cache',
      });
      setQuoteQueryIsLoading(false);
      setPropertyQuoteData(data.propertyQuote);
      setFormFeedback(null);
    } catch (err) {
      // graphQLErrors[0].message: "Cannot read property 'checkInAvailability' of undefined"
      setQuoteQueryIsLoading(false);
      const validationErrors =
        get(err, 'graphQLErrors[0].extensions.exception.validationErrors') ||
        null;
      if (validationErrors) setFormFeedback(validationErrors);
    }
  };
  const getDateAvailability = (currentDate, dayCellData) => {
    const checkInAvailable =
      dayCellData[currentDate].checkInAvailability === 'Y' &&
      dayCellData[currentDate].status !== 'notAvailable';
    const checkOutAvailable =
      dayCellData[currentDate].checkOutAvailability === 'Y' &&
      dayCellData[currentDate].status !== 'notAvailable';
    const startDateObject = new Date(parseDateStringDashesToSlashes(startDate));
    const currentDateObject = new Date(
      parseDateStringDashesToSlashes(currentDate)
    );
    if (startDate && !endDate) {
      const minStayFutureDateObject = addDays(
        startDateObject,
        Number(dayCellData[startDate].minStay)
      );
      const minStayFutureDateString = formatDateWithDashes(
        minStayFutureDateObject
      );

      if (currentDateObject >= minStayFutureDateObject) {
        const unavailableDayFound = [
          ...Array(
            differenceBetweenDays(minStayFutureDateString, currentDate)
          ).keys(),
        ].find((_, index) => {
          const dateString = formatDateWithDashes(
            addDays(minStayFutureDateObject, index)
          );
          return (
            dayCellData[dateString].checkOutAvailability === 'N' &&
            dayCellData[dateString].availability === 'N'
          );
        });
        const foundUnavailableDay =
          unavailableDayFound || unavailableDayFound === 0;
        if (
          !foundUnavailableDay &&
          checkOutAvailable &&
          !dayCellData[currentDate].minPriorNotifyFail
        ) {
          // classList.push('hyperlink');
          return true;
        }
      }
      return false;
    }
    if (checkInAvailable && !dayCellData[currentDate].minPriorNotifyFail) {
      return true;
      // classList.push('hyperlink');
    }
  };
  return (
    <Query
      query={PROPERTY_QUERY}
      variables={{ propertyWhereUniqueInput }}
      fetchPolicy={'no-cache'}
      skip={true}
    >
      {({ data, loading, error }) => {
        // if (queryIsLoading)
        //   return (
        //     <div className="container pt-3">
        //       <div
        //         className="d-flex justify-content-center align-items-center"
        //         style={{ height: '80vh' }}
        //       >
        //         <LoadingSpinner />
        //       </div>
        //     </div>
        //   );
        // if (error) return <p className="mt-2">{error.message}</p>;
        // if (!data) return null;
        // if (!data.property) {
        //   return (
        //     <div className="container-fluid">
        //       <div className="row">
        //         <div className="col mt-4">
        //           <span>There are no properties with the provided ID.</span>
        //         </div>
        //       </div>
        //     </div>
        //   );
        // }
        // const availabilityCalendar = getAvailabilityCalendar(data.property);
        // const dayCellData = getAvailabilityCalendarGroups(availabilityCalendar);
        const setDayCellDisplay = (currentDate, classList, dayCellData) => {
          const today = new Date();
          const currentDateObject = new Date(
            parseDateStringDashesToSlashes(currentDate)
          );
          if (currentDateObject < today) classList.push('notAvailable');
          if (!dayCellData || !dayCellData[currentDate]) {
            return;
          }
          classList.push(dayCellData[currentDate].status);
          const isDayAvailable = getDateAvailability(currentDate, dayCellData);
          isDayAvailable && classList.push('hyperlink');
        };

        const disableDayCell = (startDate, currentDate, classList) => {
          const currentDateObject = new Date(
            parseDateStringDashesToSlashes(currentDate)
          );
          const startDateObject = new Date(
            parseDateStringDashesToSlashes(startDate)
          );
          if (currentDateObject < startDateObject) {
            classList.push('disabled');
          }
        };

        const getAverageTotalCostPerNight = (numberOfNights, pricePerNight) => {
          const product = numberOfNights * pricePerNight;
          return product.toFixed(2);
        };

        return (
          <div>
            <div className="alert alert-warning" role="alert">
              EXPERIMENTAL
            </div>
            {checkIn && (
              <p className="mb-1">
                <span className="label font-weight-bold">
                  Check In Preference:
                </span>{' '}
                <span>
                  {moment(checkIn)
                    .utc()
                    .format('MM/DD/YYYY')}
                </span>
              </p>
            )}
            {checkOut && (
              <p className="mb-1">
                <span className="label font-weight-bold">
                  Check Out Preference:
                </span>{' '}
                <span>
                  {moment(checkOut)
                    .utc()
                    .format('MM/DD/YYYY')}
                </span>
              </p>
            )}
            {queryIsLoading ? (
              <div className="container pt-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <DateRangePicker
                startMonth={startMonth}
                startDate={checkIn}
                endDate={checkOut}
                showClearDates={startDate || endDate}
                resetRangeOnClick={true}
                dateHoverRange={dateHoverRange}
                onResetDates={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setPropertyQuoteData(null);
                  setFormFeedback(null);
                  setResetDates(true);
                  setDateHoverRange(null);
                }}
                onDayRender={({
                  dayInMonth,
                  classList,
                  dateHoverRange,
                  resetRangeOnClick,
                  ...props
                }) => {
                  const currentDate = formatDateWithDashes(dayInMonth);
                  setDayCellDisplay(currentDate, classList, dayCellData);
                  if (startDate && !endDate) {
                    disableDayCell(startDate, currentDate, classList);
                  }
                  if (
                    startDate &&
                    dateHoverRange &&
                    dateHoverRange.find(date => date === currentDate)
                  ) {
                    classList.push('inSelectedRange');
                  }

                  if (currentDate === startDate || currentDate === endDate) {
                    classList.push('selected');
                  }
                  // if (!dayCellData[currentDate]) {
                  //   return true;
                  // }

                  return (
                    <DayCell
                      {...props}
                      dayInMonth={dayInMonth}
                      key={dayInMonth}
                      startDate={startDate}
                      endDate={endDate}
                      onClick={async e => {
                        const selectedDate = e.target.dataset.date;
                        const classes = Array.from(e.target.classList);
                        handleDateSelect(selectedDate, classes, dayCellData);
                      }}
                      onMouseEnter={e => {
                        handleDateRangeHighlighting(
                          e.target.dataset.date,
                          e.target.classList
                        );
                      }}
                      classList={classList}
                    />
                  );
                }}
              />
            )}

            <div>
              <>
                {!quoteQueryIsLoading && formFeedback && (
                  <ul className="list-unstyled">
                    {formFeedback.map((node, index) => {
                      return <li key={index}>{node.message || node}</li>;
                    })}
                  </ul>
                )}
                {quoteQueryIsLoading && <LoadingSpinner />}
                {!quoteQueryIsLoading && propertyQuoteData && (
                  <ul className="list-unstyled">
                    <li>
                      <b>
                        ${propertyQuoteData.avgPerNight.toFixed(2)} x{' '}
                        {propertyQuoteData.totalNights} nights:
                      </b>{' '}
                      $
                      {getAverageTotalCostPerNight(
                        Number(propertyQuoteData.totalNights),
                        Number(propertyQuoteData.avgPerNight)
                      )}
                    </li>
                    {/* <li>
                      <b>Total Nights:</b> {propertyQuoteData.totalNights}
                    </li>
                    <li>
                      <b>Average Per Night:</b> $
                      {propertyQuoteData.avgPerNight.toFixed(2)}
                    </li> */}
                    <li>
                      <b>Damage Deposit:</b> $
                      {propertyQuoteData.damageDeposit.toFixed(2)}
                    </li>
                    <li>
                      <b>Cleaning Fee:</b> $
                      {propertyQuoteData.cleaningFee.toFixed(2)}
                    </li>
                    <li>
                      <b>Lodging Tax:</b> $
                      {propertyQuoteData.lodgingTax.toFixed(2)}
                    </li>
                    <li>
                      <b>Total:</b> ${propertyQuoteData.rate.toFixed(2)}{' '}
                      <span className="text-danger font-weight-bold ml-1">
                        EXPERIMENTAL - Do not assume this amount is accurate.{' '}
                      </span>
                    </li>
                  </ul>
                )}
              </>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

QuoteGenerator.defaultProps = {
  resetRangeOnClick: true,
  checkIn: null,
  checkOut: null,
  startMonth: 0,
};

export default withApollo(QuoteGenerator);
