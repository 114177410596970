import React, { useEffect, useState } from 'react';
import Layout from 'src/layouts';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import PropertyEditForm from './components/PropertyEditForm';
import moment from 'moment';
import { withApollo, Mutation } from 'react-apollo';
import './styles.scss';
import LoadingSpinner from 'src/components/LoadingSpinner';

const PROPERTY_QUERY = gql`
  query Property($propertyWhereUniqueInput: PropertyWhereUniqueInput) {
    property(where: $propertyWhereUniqueInput) {
      id
      name
      key
      label
      # description
      sleeps
      sleepsInBed
      # maxTravelers
      maxAdults
      maxAdultComfort
      bedrooms
      bedroomsPrivate
      bedroomsSemiPrivate
      bathrooms
      bathroomsHalf
      sqft
      type
      latitude
      longitude
      ... on Property {
        mistingSystemOptionId
      }
      # virtualTours {
      #   url
      # }
      # sitePage {
      #   slug
      #   featuredFileCollection {
      #     files {
      #       mimetype
      #       fullUrl
      #     }
      #   }
      # }
      amenities {
        name
        key
      }
      calendar {
        start
        rates
        availability
        minStay
        minPriorNotify
        checkInAvailability
        checkOutAvailability
      }
    }
    propertyAmenities {
      name
      key
    }
  }
`;

const EditProperty = ({ propertyKey, client, isModal }) => {
  const propertyWhereUniqueInput = { key: propertyKey };
  const EditPropertyFormWrapper = () => {
    return (
      <Query query={PROPERTY_QUERY} variables={{ propertyWhereUniqueInput }}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <div className="container pt-4">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            );
          if (error) return <p className="mt-2">{error.message}</p>;
          if (!data) return null;
          if (!data.property) {
            return (
              <div className="container-fluid">
                <div className="row">
                  <div className="col mt-4">
                    <span>There are no properties with the provided ID.</span>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div className="container-fluid">
              <div className="row">
                <PropertyEditForm {...data} showBackButton={!isModal} />
              </div>
            </div>
          );
        }}
      </Query>
    );
  };

  return (
    <>
      {isModal ? (
        <EditPropertyFormWrapper />
      ) : (
        <Layout metaTitle={propertyKey}>
          <EditPropertyFormWrapper />
        </Layout>
      )}
    </>
  );
};

EditProperty.defaultProps = {
  isModal: false,
};

export default withApollo(EditProperty);
