import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import Query from 'src/components/GraphQL/components/Query';
import { Mutation, withApollo } from 'react-apollo';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import gql from 'graphql-tag';

const SYNC_RED_FLAGS = gql`
  mutation syncGuestReservationRedFlags(
    $reference: String!
    $guestReservationRedFlagOptionIds: [Int]
  ) {
    syncGuestReservationRedFlags(
      where: { reference: $reference }
      data: {
        guestReservationRedFlagOptionIds: $guestReservationRedFlagOptionIds
      }
    ) {
      id
      redFlags {
        id
        label
        type
      }
    }
  }
`;

const RedFlagsForm = ({ reference, selectedRedFlags, redFlags, onSuccess }) => {
  const getDefaultValues = options => {
    return options.reduce((acc, option) => {
      const found = redFlags.find(flag => flag?.id === option?.id);
      if (found) {
        acc.push(`${option?.id}`);
      }
      return acc;
    }, []);
  };
  const automatedFlags = selectedRedFlags.filter(
    flag => flag?.type === 'AUTOMATED'
  );

  return (
    <Mutation mutation={SYNC_RED_FLAGS}>
      {(syncRedFlags, { data }) => {
        return (
          <div>
            <section className="mb-5">
              <p className="font-weight-bold">Automated Flags:</p>
              {automatedFlags?.length > 0 ? (
                <ul className="pl-3">
                  {automatedFlags.map(flag => (
                    <li key={flag?.label}>
                      <span>{flag?.label}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>N/A</p>
              )}
            </section>
            <Formik
              // enableReinitialize={true}
              initialValues={{
                checked: getDefaultValues(selectedRedFlags),
              }}
              onSubmit={async (
                values,
                { setSubmitting, setStatus, setErrors }
              ) => {
                const guestReservationRedFlagOptionIds = values?.checked?.map(
                  value => Number(value)
                );

                setSubmitting(true);
                try {
                  const result = await syncRedFlags({
                    variables: {
                      reference,
                      guestReservationRedFlagOptionIds,
                    },
                  });

                  if (result) {
                    setStatus({ success: true });
                    onSuccess(
                      result?.data?.syncGuestReservationRedFlags?.redFlags
                    );
                  }
                  setSubmitting(false);
                  //   setStatus({ isSubmitted: true });
                } catch (err) {
                  console.log('err', err);
                  // setErrors({ errors: err });
                  setStatus({ error: err });
                }
              }}
            >
              {formikProps => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  submitCount,
                  status,
                } = formikProps;

                const CheckBoxInput = ({ option, customClasses }) => {
                  return (
                    <div className="form-group mb-1" key={option?.id}>
                      <div
                        className={`custom-control custom-checkbox ${customClasses}`}
                      >
                        <Field
                          id={option?.id}
                          className="custom-control-input form-control"
                          type="checkbox"
                          name="checked"
                          value={`${option?.id}`}
                        />
                        <label
                          className="custom-control-label font-weight-bold"
                          htmlFor={option?.id}
                        >
                          {option?.label}
                        </label>
                      </div>
                    </div>
                  );
                };

                return (
                  <form className="" onSubmit={handleSubmit}>
                    <section>
                      <p className="font-weight-bold">Manual Flags:</p>
                    </section>
                    <section className="d-block m-4">
                      {redFlags.map(flag => {
                        return (
                          <CheckBoxInput
                            key={flag?.label}
                            option={flag}
                            customClasses={'pl-auto pl-lg-0'}
                          />
                        );
                      })}
                    </section>

                    <FormResponse
                      errors={status?.error}
                      isVisible={status?.error || status?.success}
                    >
                      {status?.success}
                    </FormResponse>
                    <ThemedButton
                      type="submit"
                      loading={isSubmitting}
                      className="w-100 btn btn-outline-primary"
                    >
                      Save
                    </ThemedButton>
                  </form>
                );
              }}
            </Formik>
          </div>
        );
      }}
    </Mutation>
  );
};

export default withApollo(RedFlagsForm);
