import React, { useState } from 'react';
const DatePicker = Loadable(() => import('src/components/DatePicker')); // code-splitted, on demand loaded component
import moment from 'moment';
import { Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import {
  formatDateWithSlashes,
  getNumberOfDaysInRange,
} from '../../../../../util/dateHelper';
import Loadable from '@loadable/component';
import WithLocation from '../../../../../components/WithLocation';
const DateRangeSelectorForm = ({
  startDate,
  setNumberOfDaysInRange,
  setDatePickerModalIsOpen,
  setCustomDateRangeApplied,
  totalDateRange,
  setStartDate,
  location,
}) => {
  //   const [datePickerModalIsOpen, setDatePickerModalIsOpen] = useState(false);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const params = new URLSearchParams(location.search);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        startDate,
        // endDate: endDate ? endDate : '',
        endDate: '',
        flexibilityInDays: 0,
      }}
      onSubmit={(
        values,
        { setSubmitting, setStatus, setErrors, resetForm }
      ) => {
        const endDate = values.endDate != '' ? values.endDate : defaultEndDate;
        setStartDate(values.startDate);
        setNumberOfDaysInRange(
          getNumberOfDaysInRange(values.startDate, endDate, params)
        );
        setSubmitting(true);
        setDatePickerModalIsOpen(false);
        setCustomDateRangeApplied(true);
        params.set(
          'start',
          moment(values.startDate)
            .utc()
            .format('YYYYMMDD')
        );
        params.set(
          'end',
          moment(endDate)
            .utc()
            .format('YYYYMMDD')
        );
        navigate(window.location.pathname + `?${params.toString()}`);
      }}
    >
      {formikProps => {
        const { values, handleSubmit, setFieldValue } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <p className="font-weight-bold mb-1">Start</p>
            <button
              style={{ minHeight: '38px' }}
              type="button"
              className="btn w-100 text-left border mb-2"
              onClick={() => {
                setStartDatePickerOpen(
                  prevState => !prevState.startDatePickerOpen
                );
              }}
            >
              {values.startDate !== ''
                ? formatDateWithSlashes(values.startDate)
                : ''}
            </button>
            {startDatePickerOpen && (
              <DatePicker
                allowSameDay={true}
                onClickOutside={() => setStartDatePickerOpen(false)}
                selectsStart
                startDate={values.startDate}
                endDate={values.EndDate}
                selected={values.startDate}
                name="startDate"
                type="text"
                className="form-control rounded"
                id="startDate"
                withPortal
                inline
                minDate={new Date()}
                onChange={args => {
                  setFieldValue('startDate', args);
                  setStartDatePickerOpen(false);
                  if (values.startDate) {
                    if (new Date(args) > new Date(values.endDate)) {
                      setFieldValue('endDate', '');
                    }
                  }
                }}
              />
            )}
            <p className="font-weight-bold mb-1">End</p>{' '}
            <button
              style={{ minHeight: '38px' }}
              type="button"
              className="btn w-100 text-left border mb-2"
              onClick={() => {
                setEndDatePickerOpen(true);
              }}
            >
              {values.endDate !== ''
                ? formatDateWithSlashes(values.endDate)
                : ''}
            </button>
            {endDatePickerOpen && (
              <DatePicker
                allowSameDay={true}
                onClickOutside={() => setEndDatePickerOpen(false)}
                selectsEnd
                startDate={values.startDate}
                endDate={values.endDate}
                selected={values.startDate}
                minDate={values.startDate ? values.startDate : new Date()}
                maxDate={new Date(totalDateRange[totalDateRange.length - 1])}
                name="endDate"
                type="text"
                className="form-control rounded"
                placeholderText="test"
                withPortal
                inline
                onChange={args => {
                  setFieldValue('endDate', args);
                  setEndDatePickerOpen(false);
                  if (values.startDate) {
                    if (new Date(args) < new Date(values.startDate)) {
                      setFieldValue('startDate', '');
                    }
                  }
                }}
              />
            )}
            <button
              // onClick={() => onClose()}
              type="submit"
              className={`btn btn-outline-primary rounded w-100 my-2`}
            >
              <span>Submit</span>
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

export default WithLocation(DateRangeSelectorForm);
