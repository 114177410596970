import { Mutation } from 'react-apollo';
import { Formik, Field } from 'formik';
import Switch from 'react-switch';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import Loadable from '@loadable/component';
import gql from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';

const getGuestDisplay = ip => {
  if (ip) {
    const split = ip.split('.');
    if (split) {
      return `Guest ${split[split.length - 1]}`;
    }
  }
  return 'Guest';
};

const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));

const CREATE_GUEST_NOTE = gql`
  mutation createGuestNote(
    $note: String
    $guestId: Int
    $checkInPreference: Date
    $checkOutPreference: Date
    $followUpRequired: FollowUpRequired
    $propertyId: Int
    $reservation: String
  ) {
    createGuestNote(
      data: {
        note: $note
        guestId: $guestId
        checkInPreference: $checkInPreference
        checkOutPreference: $checkOutPreference
        followUpRequired: $followUpRequired
        propertyId: $propertyId
        reservation: $reservation
      }
    ) {
      note
      reservation
      creator {
        userName
        firstName
        lastName
        extension
        emailAddress
        status
        skills {
          key
          name
        }
      }
      createdAt
    }
  }
`;

const validationSchema = Yup.object().shape({
  reservation: Yup.string().min(6, 'Must be at least 6 characters'),
});

const AddNoteForm = ({
  guestId,
  expert,
  ipAddress,
  properties,
  onSuccess,
  noteDetails,
}) => {
  return (
    <Mutation mutation={CREATE_GUEST_NOTE}>
      {(createGuestNote, { data, error, loading }) => (
        <Formik
          // enableReinitialize={true}
          initialValues={{
            guestId: guestId ? guestId : null,
            note: '',
            reservation: '',
            property: null,
            followUp: true,
            startDate: new Date(),
            endDate: new Date(),
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
            try {
              const result = await createGuestNote({
                variables: {
                  note: values.note,
                  guestId: Number(values.guestId),
                  checkInPreference: values.startDate,
                  checkOutPreference: values.endDate,
                  followUpRequired: values.followUp ? 'YES' : 'NO',
                  propertyId: Number(values.property),
                  reservation: values.reservation,
                },
              });
              if (result) {
                setStatus({ success: true });
                onSuccess();
              }
            } catch (err) {
              console.log('err', err);
              setStatus({ error: err });
            }
          }}
        >
          {formikProps => {
            const {
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
              submitCount,
            } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <div className="container p-0">
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-1">
                        <textarea
                          onChange={handleChange}
                          className="pl-2 w-100"
                          name="note"
                          rows="8"
                          placeholder="Enter Note (Markdown Supported)"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6">
                      <div className="form-group">
                        <label className="mb-1">Property</label>
                        <select
                          className="form-control "
                          name="property"
                          onChange={handleChange}
                          id="sel1"
                        >
                          <option name="specialist" defaultValue>
                            Choose One
                          </option>
                          {properties &&
                            properties.map(property => (
                              <option
                                key={property.label}
                                value={Number(property.id)}
                              >
                                {`${property.label} - ${property.name}`}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <div className="mb-1 font-weight-bold">
                          Reservation (if applicable)
                        </div>
                        <input
                          name="reservation"
                          // onChange={handleChange}
                          onChange={e => {
                            setFieldValue('reservation', e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          invalid={errors.reservation && touched.reservation}
                        />
                        <div className="text-danger">
                          {touched.reservation &&
                            errors.reservation &&
                            errors.reservation}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Expert:</label>{' '}
                        <span>
                          {expert ? expert : getGuestDisplay(ipAddress)}
                        </span>
                      </div>
                    </div>
                    <div className="col col-6">
                      <div className="form-group notes-form-datepicker">
                        <label className="mb-1">Dates</label>
                        <br />
                        <div className="d-flex">
                          <DatePicker
                            withPortal
                            className="form-control"
                            selected={values.startDate}
                            startDate={values.startDate}
                            endDate={values.endDate}
                            selectsStart
                            onChange={args => {
                              setFieldValue('startDate', args);
                              setFieldValue(
                                'endDate',
                                new Date(
                                  args.getTime() + 1 * 24 * 60 * 60 * 1000
                                )
                              );
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeHolderText="Select a Date"
                          />
                          <span className="pl-2 pr-2">to</span>
                          <DatePicker
                            withPortal
                            className="form-control"
                            selected={values.endDate}
                            startDate={values.startDate}
                            endDate={values.endDate}
                            selectsEnd
                            onChange={args => {
                              setFieldValue('endDate', args);
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeHolderText="Select a Date"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1">Follow-up required</label>
                        <br />
                        <Switch
                          offColor="#080"
                          onColor="#888"
                          height={22}
                          width={41}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={value => setFieldValue('followUp', value)}
                          checked={values.followUp}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <FormResponse
                        errors={get(status, 'error')}
                        isVisible={
                          get(status, 'error') ||
                          get(status, 'success') ||
                          (Object.keys(errors).length > 0 && submitCount > 0)
                        }
                        alert={
                          Object.keys(errors).length > 0 && submitCount > 0
                        }
                      >
                        {get(status, 'success')}
                      </FormResponse>

                      <ThemedButton
                        type="submit"
                        loading={loading}
                        className="w-100 btn btn-outline-primary"
                      >
                        Save
                      </ThemedButton>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default AddNoteForm;
