import React, { useState } from 'react';
import Layout from '../../layouts';
import ScrollTable from '../../components/ScrollTable';
import Query from '../../components/GraphQL/components/Query';
import LoadingSpinner from '../../components/LoadingSpinner';
import gql from 'graphql-tag';
import moment from 'moment';
import get from 'lodash/get';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from '@reach/router';

const GET_EMPLOYEES = gql`
  {
    employees {
      id
      firstName
      lastName
      extension
      status
      #lastActivityLogDate
      lastActivityLog {
        lastActivityLogDate: createdAt
        lastActivityLogUserAgent: userAgent
      }
      emailAddress
    }
  }
`;

const orderBy = (key, isNumber) => {
  if (key === 'lastActivityLogDate')
    return (a, b) => {
      return (
        new Date(a.lastActivityLogDate || null) -
        new Date(b.lastActivityLogDate || null)
      );
    };

  //   return (a, b) => {
  //     const transformedA = isNumber ? a[Number(key)] : a[key].toLowerCase();
  //     const transformedB = isNumber ? b[Number(key)] : b[key].toLowerCase();
  //     console.log('transformedA', transformedA);
  //     console.log('transformedB', transformedB);
  //     transformedA > transformedB ? 1 : transformedB > transformedA ? -1 : 0;
  //   };
  if (isNumber) {
    const keyAsNumber = Number(key);
    return (a, b) =>
      a[keyAsNumber] > b[keyAsNumber]
        ? 1
        : b[keyAsNumber] > a[keyAsNumber]
        ? -1
        : 0;
  }
  return (a, b) =>
    a[key].toLowerCase() > b[key].toLowerCase()
      ? 1
      : b[key].toLowerCase() > a[key].toLowerCase()
      ? -1
      : 0;
};

const Employees = () => {
  const [sortBy, setSortBy] = useState('lastActivityLogDate');
  const [reverse, setReverse] = useState(false);
  const setOrderByData = sortKey => {
    sortBy === sortKey ? setReverse(!reverse) : setReverse(false);
    setSortBy(() => sortKey);
  };
  const flattenObject = obj => {
    const flattened = {};

    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });

    return flattened;
  };

  return (
    <Layout>
      <Query query={GET_EMPLOYEES} fetchPolicy="no-cache">
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className="container pt-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            );
          if (error) return <p className="mt-2">{error.message}</p>;
          const employeesRaw = get(data, 'employees') || [];
          const employees = employeesRaw.map(employee =>
            flattenObject(employee)
          );

          const sortedEmployees =
            (employees.length &&
              employees.sort(
                (a, b) =>
                  new Date(b.lastActivityLogDate) -
                  new Date(a.lastActivityLogDate)
              )) ||
            null;

          const orderedEmployees = reverse
            ? sortedEmployees.concat().sort(orderBy(sortBy))
            : sortedEmployees
                .concat()
                .sort(orderBy(sortBy))
                .reverse();

          return (
            <div className="container-fluid pt-4">
              <div className="row">
                <div className="col">
                  <div className="d-flex align-items-center mb-3">
                    <h1 className="mr-2 mb-1">Employees</h1>
                    <a
                      href="https://drive.google.com/open?id=1-CVYC-wQS1HKtnYSvQbbtZXczFC9sTa8uepMm2r-DH4"
                      target="_blank"
                    >
                      (Customer Service Schedule)
                    </a>
                  </div>

                  <div
                    className="card pl-3 pr-3 d-block"
                    // style={{ maxWidth: '1100px' }}
                  >
                    <ScrollTable>
                      <table className="table">
                        <thead>
                          <tr className="vertical-align">
                            <th
                              style={{ cursor: 'pointer' }}
                              onClick={() => setOrderByData('firstName')}
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                            >
                              <span>First Name</span>
                              <>
                                {sortBy === 'firstName' && !reverse && (
                                  <ChevronDown size="15" />
                                )}
                                {sortBy === 'firstName' && reverse && (
                                  <ChevronUp size="15" />
                                )}
                              </>
                            </th>
                            <td
                              onClick={() => setOrderByData('lastName')}
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                              style={{ cursor: 'pointer' }}
                            >
                              <span>Last Name</span>
                              <>
                                {sortBy === 'lastName' && !reverse && (
                                  <ChevronDown size="15" />
                                )}
                                {sortBy === 'lastName' && reverse && (
                                  <ChevronUp size="15" />
                                )}
                              </>
                            </td>
                            <td
                              onClick={() =>
                                setOrderByData('lastActivityLogDate')
                              }
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                              style={{ cursor: 'pointer' }}
                            >
                              <span>Last Active</span>
                              <>
                                {sortBy === 'lastActivityLogDate' &&
                                  !reverse && <ChevronDown size="15" />}
                                {sortBy === 'lastActivityLogDate' &&
                                  reverse && <ChevronUp size="15" />}
                              </>
                            </td>
                            <td
                              onClick={() => setOrderByData('emailAddress')}
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                              style={{ cursor: 'pointer' }}
                            >
                              <span>Email Address</span>
                              <>
                                {sortBy === 'emailAddress' && !reverse && (
                                  <ChevronDown size="15" />
                                )}
                                {sortBy === 'emailAddress' && reverse && (
                                  <ChevronUp size="15" />
                                )}
                              </>
                            </td>
                            <td
                              onClick={() => setOrderByData('extension', true)}
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                              style={{ cursor: 'pointer' }}
                            >
                              Extension
                              <>
                                {sortBy === 'extension' && !reverse && (
                                  <ChevronDown size="15" />
                                )}
                                {sortBy === 'extension' && reverse && (
                                  <ChevronUp size="15" />
                                )}
                              </>
                            </td>
                            <td
                              onClick={() =>
                                setOrderByData('lastActivityLogUserAgent', true)
                              }
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                              style={{ cursor: 'pointer' }}
                            >
                              User Agent
                              <>
                                {sortBy === 'lastActivityLogUserAgent' &&
                                  !reverse && <ChevronDown size="15" />}
                                {sortBy === 'lastActivityLogUserAgent' &&
                                  reverse && <ChevronUp size="15" />}
                              </>
                            </td>
                            {/* <td
                              onClick={() => setOrderByData('status')}
                              scope="col"
                              className="align-middle text-center font-weight-bold"
                            >
                              Status
                            </td> */}
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {orderedEmployees &&
                            orderedEmployees.map((employee, index) => {
                              const {
                                firstName,
                                lastName,
                                extension,
                                status,
                                lastActivityLogDate,
                                lastActivityLogUserAgent,
                                id,
                                emailAddress,
                              } = employee;
                              return (
                                <tr key={index}>
                                  <th scope="row">{firstName}</th>
                                  <td className="text-center">{lastName}</td>
                                  {/* <td className="text-center">{status}</td> */}
                                  <td className="text-center">
                                    {lastActivityLogDate && (
                                      <Link to={`${id}/activity`}>
                                        {moment(lastActivityLogDate).format(
                                          'MM/DD/YYYY hh:mm A'
                                        )}
                                      </Link>
                                      // <span>
                                      //   {moment(lastActivityLogDate).format(
                                      //     'MM/DD/YYYY hh:mm A'
                                      //   )}
                                      // </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <a href={`mailto:${emailAddress}`}>
                                      {emailAddress}
                                    </a>
                                  </td>
                                  <td className="text-center">{extension}</td>
                                  <td className="text-left">
                                    {lastActivityLogUserAgent}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </ScrollTable>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </Layout>
  );
};

export default Employees;
