import React, { useEffect, useState } from 'react';
import {
  ReactTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
} from 'src/components/ReactTable';
import tyfrImage from 'src/images/tyfr.svg';
// import CustomCell from './components/CustomCell';
import { withApollo } from 'react-apollo';
import {
  Filter,
  ChevronUp,
  ChevronDown,
  AlertTriangle,
  Check,
  PlusCircle,
} from 'react-feather';

import Modal from 'src/components/Modal';
import TYFRFormLinkIcon from 'src/components/TYFRFormLinkIcon';
import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';
import BTable from 'react-bootstrap/Table';
import gql from 'graphql-tag';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import LoadingSpinner from 'src/components/LoadingSpinner';

import FinalizedOptionsForm from './components/FinalizedOptionsForm';
import RedFlagsForm from './components/RedFlagsForm';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { CSVLink } from 'react-csv';
import RedFlagsButton from './components/RedFlagsButton';
import EditProperty from '../../../edit-property';
import Tooltip from 'src/components/Tooltip';
import guestToThisHome from 'src/images/guest-to-this-home.svg';
import guestToOtherHome from 'src/images/guest-to-other-home.svg';

import HandlebarsProvider, {
  HandlebarsConsumer,
} from 'src/contexts/Handlebars';

import TemplateBuilder from 'src/components/TemplateBuilder';

import './styles.scss';

import {
  getFinalizeStatusLabel,
  useSelectFilter,
  // getTableColumns,
  isFinalizationPeriod,
  getWelcomePeriodClassPrefix,
  // getConfirmedValue,
  getHyperlinkType,
  reduceObjValues,
} from './util';

const FINALIZATION_OPTIONS_AND_FLAGS = gql`
  query getFinalizationOptionsAndRedFlags {
    guestReservationFinalizationStepOptions {
      id
      label
      stage
      sort
    }
    guestReservationRedFlagOptions {
      id
      # type
      label
      sort
    }
  }
`;

const BookingsTable = ({
  client,
  location,
  bookingType,
  tableData,
  isRefetching,
  refetchAllReservations,
  queryData,
  title,
}) => {
  // console.log('tableData', tableData ? tableData[0].node : '');
  const params = new URLSearchParams(location?.search);
  const [welomeSentModalIsOpen, setWelomeSentModalIsOpen] = useState(false);
  const [selectedCellProps, setSelectedCellProps] = useState({});
  const [redFlagsModalIsOpen, setRedFlagsModalIsOpen] = useState(false);
  const [
    guestReservationModalIsOpen,
    setGuestReservationModalIsOpen,
  ] = useState(false);
  const [finalizeStatusModalIsOpen, setFinalizeStatusModalIsOpen] = useState(
    false
  );
  const [propertyKey, setPropertyKey] = useState(null);

  const [
    finalizedStatusAndRedFlagData,
    setFinalizedStatusAndRedFlagData,
  ] = useState(null);
  const isUpcomingCheckins = bookingType === 'upcoming-checkins';
  const isTYFR = bookingType === 'tyfr';

  useEffect(() => {
    const { data } = client
      .query({
        query: FINALIZATION_OPTIONS_AND_FLAGS,
      })
      .then(result => {
        setFinalizedStatusAndRedFlagData(result?.data);
      });
    if (params?.get('templateBuilderType') === 'CHECKIN_MESSAGE') {
      setWelomeSentModalIsOpen(true);
    }
  }, []);

  // const TYFRIcon = ({ reference }) => {
  //   const [tyfrFormModalIsOpen, setTyfrFormModalIsOpen] = useState(false);
  //   return (
  //     <Tooltip
  //       label="TFYR Form Link"
  //       placement="top"
  //       id={'this-home'}
  //       className="ml-2"
  //     >
  //       <button
  //         className="btn btn-link p-0"
  //         onClick={() => {
  //           setTyfrFormModalIsOpen(true);
  //         }}
  //         // href={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${
  //         //   original.reference
  //         // }&token=${encodeURIComponent(original.token)}`}
  //       >
  //         <img src={tyfrImage} />
  //       </button>
  //       {/* <ReservationDetail reference={} */}
  //       <TYFRLinkModal
  //         isOpen={tyfrFormModalIsOpen}
  //         reference={reference}
  //         onClose={() => {
  //           setTyfrFormModalIsOpen(false);
  //         }}
  //       />
  //     </Tooltip>
  //   );
  // };

  const DynamicHyperlink = ({ cell, children }) => {
    const params = new URLSearchParams(location?.search);
    const hyperlink = cell?.column?.hyperlink;

    if (!hyperlink) {
      return children;
    }

    const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
      if (cell.row.original[key]) {
        acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
      }
      return acc;
    }, {});

    //    if (hyperlink == 'reservationId') console.log('cell', cell.column.id);
    switch (hyperlink) {
      case 'redFlag': {
        return (
          <RedFlagsButton
            {...dataForProps}
            cell={cell}
            openModal={() => {
              setSelectedCellProps(dataForProps);
              setRedFlagsModalIsOpen(true);
            }}
          />
        );
      }
      case 'reservationId':
        return (
          <>
            <button
              value={dataForProps}
              onClick={e => {
                console.log('e', e.target.value);
                setGuestReservationModalIsOpen(true);
                setSelectedCellProps(dataForProps);
              }}
              className="btn btn-link p-0 small"
            >
              {children}
            </button>
            {cell.column.id == 'reservationId' && (
              <TYFRFormLinkIcon reference={cell.row.original.reference} />
            )}
          </>
        );
      case 'finalizeStatus': {
        return (
          <button
            onClick={() => {
              setFinalizeStatusModalIsOpen(true);
              setSelectedCellProps(dataForProps);
            }}
            className="btn btn-link p-0 small"
          >
            {children}
          </button>
        );
      }
      case 'welcomeSent': {
        return (
          <button
            onClick={() => {
              params.set('templateBuilderType', 'CHECKIN_MESSAGE');
              params.set('reference', dataForProps?.reference);
              setWelomeSentModalIsOpen(true);
              setSelectedCellProps(dataForProps);
              window.history.replaceState(
                null,
                '',
                window.location.pathname + `?${params.toString()}`
              );
            }}
            className="btn btn-link p-0 small"
          >
            {children}
          </button>
        );
      }
      case 'house#': {
        return (
          <button
            onClick={e => {
              setPropertyKey(dataForProps[`house#`]);
            }}
            className="btn btn-link p-0 small"
          >
            {children}
          </button>
        );
      }
      default:
        return (
          <a
            href={`/guests/${dataForProps?.guestId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
    }
  };

  const getGuestNameComponent = cell => {
    const original = cell?.row?.original;

    const GuestToThisHomeIcon = () => {
      return (
        <Tooltip
          label="Previous guest to this home"
          placement="top"
          id={'this-home'}
          className="ml-2"
        >
          <img src={guestToThisHome} />
        </Tooltip>
      );
    };
    // const TYFRIcon = () => {
    //   const [tyfrFormModalIsOpen, setTyfrFormModalIsOpen] = useState(false);
    //   return (
    //     <Tooltip
    //       label="Thank You for your Reservation (TFYR) Form"
    //       placement="top"
    //       id={'this-home'}
    //       className="ml-2"
    //     >
    //       <button
    //         className="btn btn-link"
    //         onClick={() => {
    //           setTyfrFormModalIsOpen(true);
    //         }}
    //         // href={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${
    //         //   original.reference
    //         // }&token=${encodeURIComponent(original.token)}`}
    //       >
    //         <img src={tyfrImage} />
    //       </button>
    //       {/* <ReservationDetail reference={} */}
    //       <TYFRFormModal
    //         isOpen={tyfrFormModalIsOpen}
    //         reference={original.reference}
    //         onClose={() => {
    //           setTyfrFormModalIsOpen(false);
    //         }}
    //       />
    //     </Tooltip>
    //   );
    // };
    const GuestToAnotherHomeIcon = () => {
      return (
        <Tooltip
          label="Previous guest to other homes"
          placement="top"
          id={'other-home'}
          className="ml-2"
        >
          <img src={guestToOtherHome} />
        </Tooltip>
      );
    };

    if (
      original?.guestStaysAtOtherProperties &&
      original?.guestStaysAtThisProperty
    ) {
      return (
        <>
          <GuestToThisHomeIcon /> <GuestToAnotherHomeIcon />
        </>
      );
    }
    if (original?.guestStaysAtThisProperty) {
      return (
        <>
          <GuestToThisHomeIcon />
        </>
      );
    }
    if (original?.guestStaysAtOtherProperties) {
      return (
        <>
          <GuestToAnotherHomeIcon />
        </>
      );
    }
    return null;
  };

  const DynamicComponent = ({ value, cell, outsideLink }) => {
    const columnDefinition = cell.column.columnDefinition || {};
    const componentKey = cell?.column?.component;

    if (outsideLink) {
      if (cell?.column.id === 'guestName') {
        return getGuestNameComponent(cell);
      }
      return null;
    }

    if (componentKey) {
      const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
        if (cell.row.original[key]) {
          acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
        }
        return acc;
      }, {});

      switch (componentKey) {
        case 'CheckMark': {
          // return getConfirmedValue(value);
          return value;
        }
        case 'RedFlag': {
          return <span className="d-none">{value}</span>;
        }

        default:
          return value;
      }
    }
    return <>{value}</>;
  };

  const CustomCell = ({ value, cell }) => {
    return (
      <>
        <DynamicHyperlink cell={cell}>
          <DynamicComponent value={value} cell={cell} />
        </DynamicHyperlink>
        <DynamicComponent value={value} cell={cell} outsideLink={true} />
      </>
    );
  };

  const getTableColumns = (topLevelDataObjects, isUpcomingCheckins) => {
    console.log('topLevelDataObjects', topLevelDataObjects);
    const getTitleCase = text => {
      var result = text.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };
    const keysNeeded = key => {
      const isWelcomeSentNeeded = isUpcomingCheckins
        ? true
        : key !== 'welcomeSent';
      const isEventTypeNeeded = isUpcomingCheckins ? true : key !== 'eventType';
      return (
        key !== '__typename' &&
        key !== 'createdAt' &&
        key !== 'firstName' &&
        key !== 'lastName' &&
        key !== 'key' &&
        key !== 'reference' &&
        key !== 'reservationCreateDate' &&
        // key !== 'eventType' &&
        key !== 'agreementSigned' &&
        key !== 'confirmationStatus' &&
        key !== 'id' &&
        key !== 'checkInDate' &&
        key !== 'checkOutDate' &&
        key !== 'status' &&
        key !== 'finalizationSteps' &&
        key !== 'redFlags' &&
        key !== 'guestId' &&
        key !== 'maxAdults' &&
        key !== 'largeVehicles' &&
        key !== 'checkInHour' &&
        key !== 'checkOutHour' &&
        key !== 'guestStaysAtThisProperty' &&
        key !== 'guestStaysAtOtherProperties' &&
        key !== 'token' &&
        key !== 'externalConversationUrl' &&
        key !== 'welcomeMessageDateSent' &&
        key !== 'mobilityChallenged' &&
        key !== 'resortAccess' &&
        isWelcomeSentNeeded
      );
    };
    const getComponentKey = key => {
      if (key === 'confirmed') {
        return 'CheckMark';
      }
      if (key === 'redFlag') {
        return 'RedFlag';
      }
      return null;
    };

    return topLevelDataObjects.reduce((acc, column) => {
      console.log('column', column);
      Object.keys(column).forEach(key => {
        const filterOptions = {};

        if (typeof column[key] === 'number') {
          filterOptions.Filter = NumberRangeColumnFilter;
          filterOptions.filter = 'between';
        } else if (
          useSelectFilter(key, topLevelDataObjects) ||
          key === 'redFlag'
        ) {
          filterOptions.Filter = SelectColumnFilter;
          filterOptions.filter = 'includes';
        } else if (['checkIn', 'checkOut', 'dateReserved'].includes(key)) {
          //filterOptions.Filter = SelectColumnFilter;
          //filterOptions.filter = 'includes';

          filterOptions.sortType = (rowA, rowB, columnId, desc) => {
            /* if (rowA > rowB) return 1;
            return -1; */
            const valueA = new Date(rowA.values[columnId]);
            const valueB = new Date(rowB.values[columnId]);

            if (valueA > valueB) return 1;
            return -1;
          };
        }
        if (acc && keysNeeded(key)) {
          const matchingKey = acc.find(accItem => accItem.accessor === key);

          if (!matchingKey) {
            acc.push({
              Header: getTitleCase(key),
              accessor: key,
              Cell: CustomCell,
              hyperlink: getHyperlinkType(key),
              component: getComponentKey(key),
              ...filterOptions,
            });
          }
        }
      });
      return acc;
    }, []);
  };
  const guestReservationFinalizationStepOptions =
    finalizedStatusAndRedFlagData?.guestReservationFinalizationStepOptions;
  const topLevelTableData = tableData
    ? tableData.reduce((acc, edge) => {
        acc.push(
          reduceObjValues(edge?.node, guestReservationFinalizationStepOptions)
        );
        return acc;
      }, [])
    : null;
  const columns = topLevelTableData && getTableColumns(topLevelTableData, true);
  console.log('topLevelTableData1', topLevelTableData);
  console.log('getTableColumns', getTableColumns(topLevelTableData, true));
  console.log('columns', columns);
  const memoizedColumns = React.useMemo(() => columns, []);
  const memoizedData = React.useMemo(() => topLevelTableData, [
    topLevelTableData,
  ]);
  const hasAllTableData = memoizedColumns && memoizedData ? true : false;

  const getCellClassNames = cell => {
    const header = cell?.column?.Header;
    const classNames = [];

    if (cell?.column?.id === 'checkIn') {
      const checkInHour = cell?.row?.original?.checkInHour;

      const checkInTime = moment(cell?.row?.original?.checkInDate).format(
        'hh:mm'
      );
      if (checkInHour !== 17) {
        classNames.push('custom-booking-time');
      }
    }
    if (cell?.column?.id === 'checkOut') {
      const checkOutHour = cell?.row?.original?.checkOutHour;
      const checkOutTime = moment(cell?.row?.original?.checkOutDate).format(
        'hh:mm'
      );

      if (checkOutHour !== 11) {
        classNames.push('custom-booking-time');
      }
    }
    // if (cell.column.columnDefinition?.classNames) {
    //   return cell.column.columnDefinition.classNames;
    // }
    if (isUpcomingCheckins) {
      const welcomePeriod = getWelcomePeriodClassPrefix(cell);
      welcomePeriod && classNames.push(`${welcomePeriod}-alert-period`);
    }
    if (!isUpcomingCheckins && isFinalizationPeriod(cell)) {
      classNames.push('alert-period');
    }
    if (typeof cell.value === 'number') {
      classNames.push('text-right');
    }
    if (
      header === 'Finalize Status' ||
      header === 'Red Flag' ||
      header === 'Confirmed' ||
      header === 'Welcome Sent'
    ) {
      classNames.push('text-center');
    }

    return classNames.join(' ');
  };

  return (
    <>
      {hasAllTableData && guestReservationFinalizationStepOptions ? (
        <section className="d-flex flex-column h-100 m-3">
          <ReactTable
            columns={memoizedColumns}
            data={memoizedData}
            // columns={columns}
            // data={topLevelDataObjects}
          >
            {({
              getTableProps,
              getTableBodyProps,
              headerGroups,
              prepareRow,
              rows,
              sortedRows,
              setFilter,
              columns,
            }) => {
              const generateSortingIndicator = column => {
                return column.isSorted ? (
                  column.isSortedDesc ? (
                    <div>
                      <ChevronDown />
                    </div>
                  ) : (
                    <div>
                      <ChevronUp />
                    </div>
                  )
                ) : (
                  <div className="d-flex flex-column">
                    <ChevronUp />
                    <ChevronDown style={{ marginTop: '-10px' }} />
                  </div>
                );
              };

              const selectListFilterData = columns.find(
                column => column?.id === 'platform'
              );
              return (
                <>
                  <section className="d-flex justify-content-between align-items-center">
                    <h1 className="">
                      {title
                        ? title
                        : isUpcomingCheckins
                        ? 'Upcoming Checkins'
                        : isTYFR
                        ? 'TYFR Report'
                        : 'Recent Bookings'}
                    </h1>
                    <CSVLink
                      filename={`${bookingType}-${moment().format(
                        'YYYYMMDD'
                      )}.csv`}
                      data={sortedRows.map(sortedRow => sortedRow.values)}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Download Report
                      </span>
                    </CSVLink>
                  </section>
                  <div id="table-wrapper" className="w-100 p-0">
                    {isRefetching ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '80vh' }}
                      >
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <BTable
                        className="mb-0 position-relative"
                        id={`${bookingType}-table`}
                        data-sticky-header-table
                        striped
                        bordered
                        hover
                        size="sm"
                        {...getTableProps()}
                      >
                        <thead className="header">
                          {// Loop over the header rows
                          headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {// Loop over the headers in each row
                              headerGroup.headers.map(column => (
                                // Apply the header cell props
                                // Add the column sortBy props

                                <th
                                  key={column?.Header}
                                  className="text-nowrap"
                                >
                                  <div className="d-flex justify-content-between">
                                    <span
                                      style={{
                                        whiteSpace:
                                          column.Header.length < 15
                                            ? 'nowrap'
                                            : 'inherit',
                                      }}
                                    >
                                      {column.render('Header')}
                                    </span>
                                    {/*   Add a sort direction indicator
                                  isSorted sorts in ascending order
                              */}
                                    <TippyTooltip
                                      // options
                                      html={
                                        <div className="d-flex">
                                          <div>{column.render('Filter')}</div>
                                          <div>
                                            <button
                                              className="btn btn-light py-0 ml-2"
                                              {...column.getSortByToggleProps()}
                                            >
                                              {generateSortingIndicator(column)}
                                            </button>
                                          </div>
                                        </div>
                                      }
                                      position="bottom"
                                      interactive
                                      trigger="click"
                                      theme="light"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <Filter
                                        color={
                                          column.filterValue || column.isSorted
                                            ? '#333'
                                            : '#ccc'
                                        }
                                        size={20}
                                      />
                                    </TippyTooltip>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {/* Apply the table body props */}
                        <tbody {...getTableBodyProps()}>
                          {// Loop over the table rows
                          rows.map(row => {
                            // Prepare the row for display
                            prepareRow(row);

                            return (
                              // Apply the row props
                              <tr key={row?.id} {...row.getRowProps()}>
                                {// Loop over the rows cells
                                row.cells.map((cell, index) => {
                                  const title =
                                    cell?.value?.length > 200
                                      ? cell.value
                                      : null;
                                  const isRedFlagColumn =
                                    cell?.column?.Header === 'Red Flag';
                                  const dataForProps = Object.keys(
                                    cell.row.original
                                  ).reduce((acc, key) => {
                                    if (cell.row.original[key]) {
                                      acc[key.replace(/_hidden$/, '')] =
                                        cell.row.original[key];
                                    }
                                    return acc;
                                  }, {});

                                  return (
                                    <td
                                      key={`${index}_${cell?.row?.id}_${cell?.value}`}
                                      style={{
                                        whiteSpace:
                                          cell?.value?.length > 50
                                            ? 'inherit'
                                            : 'nowrap',
                                      }}
                                      className={`${getCellClassNames(cell)}`}
                                      {...cell.getCellProps()}
                                      title={title}
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </BTable>
                    )}
                  </div>

                  <Modal
                    title={`Property ${propertyKey ? propertyKey : ''}`}
                    isOpen={propertyKey ? true : false}
                    onClose={() => {
                      setPropertyKey(null);
                    }}
                    styleType="full"
                    disableForceFocus={true}
                    scrollable={true}
                  >
                    <EditProperty propertyKey={propertyKey} isModal={true} />
                  </Modal>
                  <Modal
                    title={`Red Flags`}
                    isOpen={redFlagsModalIsOpen}
                    onClose={() => {
                      setRedFlagsModalIsOpen(false);
                    }}
                    styleType="large"
                    disableForceFocus={true}
                  >
                    <div id="red-flags-form">
                      <RedFlagsForm
                        selectedRedFlags={selectedCellProps?.redFlags}
                        redFlags={
                          finalizedStatusAndRedFlagData?.guestReservationRedFlagOptions
                        }
                        reference={selectedCellProps?.reference}
                        onSuccess={() => {
                          refetchAllReservations();
                        }}
                      />
                    </div>
                  </Modal>
                  <Modal
                    title={`Template Builder`}
                    isOpen={welomeSentModalIsOpen}
                    onClose={() => {
                      params.delete('templateBuilderType');
                      params.delete('reference');
                      window.history.replaceState(
                        null,
                        '',
                        window.location.pathname + `?${params.toString()}`
                      );
                      setWelomeSentModalIsOpen(false);
                    }}
                    styleType="full"
                    disableForceFocus={true}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <HandlebarsProvider>
                      <HandlebarsConsumer>
                        {props => {
                          return (
                            <TemplateBuilder
                              welcomeSent={
                                selectedCellProps?.welcomeMessageDateSent
                              }
                              contextData={props}
                              definitionId="1"
                              params={`reference=${selectedCellProps?.reference}`}
                              reference={selectedCellProps?.reference}
                              onSuccess={() => {
                                refetchAllReservations();
                                params.delete('templateBuilderType');
                                params.delete('reference');
                                window.history.replaceState(
                                  null,
                                  '',
                                  window.location.pathname +
                                    `?${params.toString()}`
                                );
                              }}
                            />
                          );
                        }}
                      </HandlebarsConsumer>
                    </HandlebarsProvider>
                  </Modal>
                  <Modal
                    title={
                      <p className="font-weight-bold mb-0">
                        Finalized Status ({selectedCellProps?.finalizeStatus})
                        for {selectedCellProps?.reference}
                      </p>
                    }
                    isOpen={finalizeStatusModalIsOpen}
                    onClose={() => {
                      setFinalizeStatusModalIsOpen(false);
                    }}
                    styleType="large"
                    disableForceFocus={true}
                  >
                    <FinalizedOptionsForm
                      reference={selectedCellProps?.reference}
                      finalizationSteps={selectedCellProps?.finalizationSteps}
                      finalizationOptions={
                        guestReservationFinalizationStepOptions
                      }
                      onSuccess={({ finalizationSteps }) => {
                        const updatedFinalizationStepsCellProps = {
                          ...selectedCellProps,
                          finalizationSteps,
                          finalizeStatus: getFinalizeStatusLabel(
                            {
                              finalizationSteps,
                            },
                            guestReservationFinalizationStepOptions
                          ),
                        };
                        setSelectedCellProps(updatedFinalizationStepsCellProps);

                        refetchAllReservations();
                      }}
                    />
                  </Modal>
                  {/* {console.log('selectedCellProps', selectedCellProps)} */}
                  <Modal
                    title={`Guest Reservation (${selectedCellProps?.guestName})`}
                    isOpen={guestReservationModalIsOpen}
                    onClose={() => {
                      setGuestReservationModalIsOpen(false);
                    }}
                    styleType="large"
                    disableForceFocus={true}
                  >
                    <GuestReservationForm
                      onSuccess={updatedSteps => {
                        refetchAllReservations();
                      }}
                      reference={selectedCellProps?.reference}
                      redFlags={selectedCellProps?.redFlags}
                      {...selectedCellProps}
                      guestReservationRedFlagOptions={
                        finalizedStatusAndRedFlagData?.guestReservationRedFlagOptions
                      }
                    />
                  </Modal>
                </>
              );
            }}
          </ReactTable>
          <div className="d-flex my-2">
            {isUpcomingCheckins && (
              <div id="welcome-email-period-one-week" className="mr-2 px-2">
                <span className="small text-muted">
                  Welcome Email Period Within 1 Week
                </span>
              </div>
            )}
            <div id="welcome-email-period" className="mr-2 px-2">
              <span className="small text-muted">
                {isUpcomingCheckins
                  ? 'Welcome Email Period Within 2 Weeks'
                  : 'Finalization Period'}
              </span>
            </div>

            <div id="early-checkin-checkout">
              <span className="small">Early Check In / Late Check Out</span>
            </div>
          </div>
        </section>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '80vh' }}
        >
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default withApollo(BookingsTable);
