import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Modal from 'src/components/Modal';
import useUser from 'src/contexts/useUser';

const REVIEW_AUDIT_MUTATION = gql`
  mutation GuestReviewEmployeeAudit(
    $reviewId: Int
    $status: GuestReservationReviewEmployeeAuditStatusOption
    $auditComment: String
  ) {
    upsertGuestReservationReviewEmployeeAudit(
      where: { reviewId: $reviewId }
      create: { reviewId: $reviewId, status: $status, comment: $auditComment }
      update: { reviewId: $reviewId, status: $status, comment: $auditComment }
    ) {
      id
      status
    }
  }
`;

const GuestReviewEmployeeModal = ({
  reviewId,
  body,
  headline,
  onSuccess,
  children,
  guestName,
}) => {
  const locationData = useLocation();
  const params = new URLSearchParams(locationData.search);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [auditComment, setAuditComment] = useState('');

  useEffect(() => {
    if (params.get('reviewId') == reviewId) {
      setModalIsOpen(true);
    }
  }, [params.get('reviewId')]);

  const [upsertAudit, { data, loading, error }] = useMutation(REVIEW_AUDIT_MUTATION);
  const user = useUser();

  const handleAudit = async (status) => {
    if (status === 'NEGATIVE' && !auditComment) {
      alert('Comment required when assigning Negative.');
      return;
    }

    const result = await upsertAudit({
      variables: { reviewId, status, auditComment },
    });

    if (result.data.upsertGuestReservationReviewEmployeeAudit.id) {
      onSuccess();
      setModalIsOpen(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setModalIsOpen(true)}
        className="btn btn-link p-0 small"
      >
        {children}
      </button>
      <Modal
        title={`Guest Review (${guestName})`}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        styleType="large"
        disableForceFocus={true}
      >
        <p><b>{headline}</b></p>
        <p>{body}</p>
        <div className="text-left text-small pt-2">
          <label><b>Comment</b></label>
          <textarea
            style={{ minHeight: '200px' }}
            className="form-control"
            onChange={(e) => setAuditComment(e.target.value)}
            value={auditComment}
            name="auditComment"
          />
          <div className="text-center py-3">
            <button
              className="btn btn-secondary mr-3"
              onClick={() => handleAudit('OK')}
            >
              OK
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => handleAudit('NEGATIVE')}
            >
              Negative
            </button>
          </div>
          Reviewing as {user.userName || 'anon'} @ {user.ipAddress}
        </div>
      </Modal>
    </>
  );
};

export default GuestReviewEmployeeModal;