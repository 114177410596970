import React, { useContext, useEffect, useState } from 'react';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import gql from 'graphql-tag';
import Layout from 'src/layouts';
import * as moment from 'moment-timezone';
import LogReportTable from './LogReportTable/index';
import { AlertsContext } from 'src/contexts/Alerts';
import GuestReservationModal from 'src/routes/reports/components/BookingsTable/components/GuestReservationModal';
import Tooltip from 'src/components/Tooltip';
import Modal from 'src/components/Modal';
import HvacAlertDisplay from './LogReportTable/components/HvacAlertDisplay';
import { Clock } from 'react-feather';

import {
  Filter,
  ChevronUp,
  ChevronDown,
  AlertTriangle,
  ArrowLeft,
} from 'react-feather';

const PROPERTY_HVAC_REPORT = gql`
  query PropertySensorLogSummaryReport {
    properties(where: { type: STANDARD }) {
      id
      label
      name
      streetAddress1
      ... on Property {
        sensorSummaries {
          sensor {
            sensorUrl
            error {
              type
              errorDurationInMinutes
            }
          }
          alerts {
            type
            summary
            level

            exception {
              createdAt
              uniqueGroupKey
              expiration
              notes
              employee {
                id
                userName
              }
            }
            guestReservation {
              reference
              checkIn
              checkIn
              externalConversationUrl
              guest {
                id
                firstName
                lastName
                primaryPhoneNumber {
                  phoneNumber
                }
              }
            }
          }
          label
          type
          temperature
          humidity
          roomTemperature
          roomHumidity
          minutesExceedingThreshold
          minutesSinceMinThresholdRange
          lastReadingDate
          outsideTemperature
          reservationReference
          checkInReference
          checkOutReference
          propertyOccupancyStatus
          weatherCondition
          uniqueGroupKey
          rollingAvg1Hour
          rollingAvg6Hour
          rollingAvg12Hour
          rollingAvg24Hour
          rollingAvg72Hour
        }
      }
    }
  }
`;

/*


const PROPERTY_HVAC_REPORT = gql`
  query PropertyHVACLogReport {
    propertyHVACLogReport {
      outsideTemperature
      weatherCondition
      propertyOccupancyStatus
      structureLabel
      deviceLabel
      deviceName
      temperature {
        temperatureValue: value
        alert {
          temperatureAlertLevel_hidden: level
          temperatureAlertSummary_hidden: summary
        }
      }
      temperatureDelta {
        temperatureDeltaValue: value
        alert {
          temperatureDeltaAlertLevel_hidden: level
          temperatureDeltaAlertSummary_hidden: summary
        }
      }
      setPoint {
        setPointValue: value
        alert {
          setPointAlertLevel_hidden: level
          setPointAlertSumary_hidden: summary
        }
      }
      hvacStatus {
        hvacStatusValue: value
        alert {
          hvacStatusAlertLevel_hidden: level
          hvacStatusAlertSummary_hidden: summary
        }
      }
      fanStatus {
        fanStatusValue: value
        alert {
          fanStatusAlertLevel_hidden: level
          fanStatusAlertSummary_hidden: summary
        }
      }
      humidity {
        humidityValue: value
        alert {
          humidityAlertLevel_hidden: level
          humidityAlertSummary_hidden: summary
        }
      }
      connectivityStatus {
        connectivityStatusValue: value
        alert {
          connectivityAlertLevel_hidden: level
          connectivityAlertSummary_hidden: summary
        }
      }
      createdAt_hidden: createdAt
      reservationReference
      property {
        streetAddress1
        propertyLabel_hidden: label
      }
    }
  }
`;
*/
const PROPERTY_SENSOR_REPORT_CONNECTION = gql`
  query PropertySensorLogsConnection($where: PropertySensorLogWhereInput) {
    propertySensorLogsConnection(where: $where, first: 900) {
      edges {
        node {
          label
          type
          temperature
          humidity
          roomTemperature
          roomHumidity
          outsideTemperature
          weatherCondition
          propertyOccupancyStatus
          minutesExceedingThreshold
          minutesSinceMinThresholdRange
          reservationReference
          uniqueGroupKey
          createdAt
          switchValue
          property {
            streetAddress1
          }
        }
      }
    }
  }
`;
// const PROPERTY_HVAC_REPORT_CONNECTION = gql`
//   query PropertyHvacReport($where: PropertyHVACLogWhereInput) {
//     propertyHVACLogsConnection(first: 100, where: $where) {
//       edges {
//         node {
//           label
//           property {
//             name
//           }
//         }
//       }
//     }
//   }
// `;
// const PROPERTY_HVAC_REPORT_CONNECTION = gql`
//   query PropertyHvacReport($where: PropertyHVACLogWhereInput) {
//     propertyHVACLogsConnection(first: 100, where: $where) {
//       edges {
//         node {
//           outsideTemperature
//           weatherCondition
//           propertyOccupancyStatus
//           structureLabel
//           deviceName
//           deviceLabel
//           temperature {
//             temperatureValue: value
//             alert {
//               temperatureAlertLevel_hidden: level
//               temperatureAlertSummary_hidden: summary
//             }
//           }
//           temperatureDelta {
//             temperatureDeltaValue: value
//             alert {
//               temperatureDeltaAlertLevel_hidden: level
//               temperatureDeltaAlertSummary_hidden: summary
//             }
//           }
//           setPoint {
//             setPointValue: value
//             alert {
//               setPointAlertLevel_hidden: level
//               setPointAlertSumary_hidden: summary
//             }
//           }
//           hvacStatus {
//             hvacStatusValue: value
//             alert {
//               hvacStatusAlertLevel_hidden: level
//               hvacStatusAlertSummary_hidden: summary
//             }
//           }
//           fanStatus {
//             fanStatusValue: value
//             alert {
//               fanStatusAlertLevel_hidden: level
//               fanStatusAlertSummary_hidden: summary
//             }
//           }
//           humidity {
//             humidityValue: value
//             alert {
//               humidityAlertLevel_hidden: level
//               humidityAlertSummary_hidden: summary
//             }
//           }
//           connectivityStatus {
//             connectivityStatusValue: value
//             alert {
//               connectivityAlertLevel_hidden: level
//               connectivityAlertSummary_hidden: summary
//             }
//           }
//           createdAt_hidden: createdAt

//           reservationReference
//           property {
//             name
//             streetAddress1
//             propertyLabel_hidden: label
//           }
//         }
//       }
//     }
//   }
// `;

const HvacTableWrapper = props => {
  const params = new URLSearchParams(location.search);
  const uniqueGroupKey = params.get('uniqueGroupKey');
  const [hvacAlertData, setHvacAlertData] = useState(null);
  const { state, loading: alertsLoading } = useContext(AlertsContext);

  const hvacAlerts = state?.propertyHVACLogAlerts || null;

  //   useEffect(() => {
  //     $('main').css('overflow-y', 'hidden');
  //   }, []);

  return (
    <Query
      notifyOnNetworkStatusChange={true}
      query={
        uniqueGroupKey
          ? PROPERTY_SENSOR_REPORT_CONNECTION
          : PROPERTY_HVAC_REPORT
      }
      // skip={searchTerm}
      variables={{
        where: uniqueGroupKey ? { uniqueGroupKey: uniqueGroupKey } : null,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, refetch: refetchReport, networkStatus }) => {
        console.log('data', data);
        const hvacLogReport =
          data?.propertySensorLogsConnection?.edges || data?.properties;
        console.log('hvacLogReport', hvacLogReport);

        const flattenedTableData = uniqueGroupKey
          ? (hvacLogReport || []).map(edge => {
              return {
                ...edge.node,
                streetAddress1: edge.node.property.streetAddress1,
              };
            })
          : (hvacLogReport || []).reduce((acc, record) => {
              if (record.sensorSummaries) {
                record.sensorSummaries.forEach(summary => {
                  const cloned = { ...record };
                  delete cloned.sensorSummaries;
                  const sensorUrl = summary.sensor.sensorUrl;
                  acc.push({
                    ...cloned,
                    ...summary,
                    sensorUrl: sensorUrl
                      .replace(/getData\.json/gi, '')
                      .replace(/\/api\/v1\/device\/status/gi, ''),
                  });
                });
              }

              return acc;
            }, []);
        console.log('flattenedTableData', flattenedTableData);
        // const flattenedTableData = (hvacLogReport || []).reduce(
        //   (acc, record) => {
        //     record.sensorSummaries.forEach(summary => {
        //       acc.push({
        //         ...record,
        //         ...summary,
        //       });
        //     });
        //     return acc;
        //   },
        //   []
        // );
        // console.log('flattenedTableData', flattenedTableData);
        // const isRefetching = networkStatus === 4;

        const variableColumns =
          (flattenedTableData || []).length > 0 &&
          !alertsLoading &&
          uniqueGroupKey &&
          flattenedTableData[0].type == 'POWER_SWITCH'
            ? [
                {
                  Header: <>Switch Value</>,
                  accessor: 'switchValue',
                  Cell: ({ value, cell, ...rest }) => {
                    if (cell.row.original.type === 'OFFLINE') {
                      return <div style={{ color: '#aaa' }}>?</div>;
                    }
                    return (
                      <div className={`type-${cell.row.original.type}`}>
                        {value == 1 ? (
                          <>Closed (circuit intact)</>
                        ) : (
                          <span style={{ color: 'red' }}>
                            Open (circuit down)
                          </span>
                        )}
                      </div>
                    );
                  },
                  //cell.row.original.sensor.error?.type
                },
              ]
            : [
                {
                  Header: (
                    <>
                      Air
                      <br />
                      Handler
                      <br />
                      Temp
                    </>
                  ),
                  accessor: 'temperature',
                  Cell: ({ value, cell, ...rest }) => {
                    return (
                      <div className={`type-${cell.row.original.type}`}>
                        {cell.row.original.sensor?.error?.type &&
                        cell.row.original.sensor?.error
                          ?.errorDurationInMinutes > 10 ? (
                          <span style={{ color: 'red' }}>
                            {cell.row.original.sensor?.error?.type} (
                            {
                              cell.row.original.sensor?.error
                                ?.errorDurationInMinutes
                            }{' '}
                            minutes)
                          </span>
                        ) : (
                          value
                        )}
                      </div>
                    );
                  },
                  //cell.row.original.sensor.error?.type
                },
                {
                  Header: (
                    <>
                      Air
                      <br />
                      Handler
                      <br />
                      Humidity
                    </>
                  ),
                  accessor: 'humidity',
                  Cell: ({ value, cell, ...rest }) => {
                    return (
                      <div className={`type-${cell.row.original.type}`}>
                        {cell.row.original.sensor?.error?.type &&
                        cell.row.original.sensor?.error
                          ?.errorDurationInMinutes > 10 ? (
                          <span style={{ color: 'red' }}>
                            {cell.row.original.sensor?.error?.type} (
                            {
                              cell.row.original.sensor?.error
                                ?.errorDurationInMinutes
                            }{' '}
                            minutes)
                          </span>
                        ) : (
                          value
                        )}
                      </div>
                    );
                  },
                  //cell.row.original.sensor.error?.type
                },
                {
                  Header: ({ value, cell, ...rest }) => {
                    return (
                      <>
                        Minutes
                        <br />
                        Over
                        <br />
                        Threshold
                      </>
                    );
                  },
                  accessor: 'minutesExceedingThreshold',
                  Cell: ({ value, cell, ...rest }) => {
                    return (
                      <>
                        {cell.row.original.sensor?.error?.type &&
                        cell.row.original.sensor?.error
                          ?.errorDurationInMinutes > 10 ? (
                          <span style={{ color: 'red' }}>
                            {cell.row.original.sensor?.error?.type}
                          </span>
                        ) : (
                          value
                        )}
                      </>
                    );
                  },
                },
                {
                  Header: (
                    <>
                      Minutes
                      <br />
                      Since
                      <br />
                      Low
                      <br />
                      Temp
                      <br />
                      Range
                    </>
                  ),
                  accessor: 'minutesSinceMinThresholdRange',
                  Cell: ({ value, cell, ...rest }) => {
                    if (!value && value != 0) return <>?</>;
                    return (
                      <>
                        {cell.row.original.sensor?.error?.type &&
                        cell.row.original.sensor?.error
                          ?.errorDurationInMinutes > 10 ? (
                          <span style={{ color: 'red' }}>
                            {cell.row.original.sensor?.error?.type}
                          </span>
                        ) : (
                          <>
                            {value >= 99999 ? '> 7days' : value > 5 ? value : 0}
                          </>
                        )}
                      </>
                    );
                  },
                },
              ];

        return (
          <>
            {hvacAlertData && (
              <Modal
                title={'Temperature Notification'}
                isOpen={hvacAlertData ? true : false}
                onClose={() => setHvacAlertData(null)}
                disableFocusTrap={true}
                styleType="large"
              >
                <HvacAlertDisplay
                  hvacAlertData={hvacAlertData}
                  onSuccess={async () => {
                    console.log('success!');
                    await refetchReport();
                  }}
                />
              </Modal>
            )}
            {hvacLogReport && !alertsLoading ? (
              <LogReportTable
                tableData={flattenedTableData}
                data={flattenedTableData}
                columns={[
                  {
                    Header: 'Property',
                    accessor: 'streetAddress1',
                    enableRowSpan: uniqueGroupKey ? false : true,
                  },
                  {
                    Header: 'Name',
                    accessor: 'label',
                    Cell: ({ value, cell, ...rest }) => {
                      //console.log('cell.row.original', cell.row.original.sensor?.error?.type);
                      // console.log(
                      //   'cell.row.original..alerts',
                      //   cell.row.original.alerts
                      // );

                      return (
                        <>
                          <div
                            className={`d-flex align-items-center type-${cell.row.original.type}`}
                          >
                            <a
                              href={cell.row.original.sensorUrl}
                              className="p-1 d-block"
                              style={{ lineHeight: 0 }}
                              target="_blank"
                            >
                              {cell.row.original.type === 'OFFLINE'
                                ? 'OFFLINE'
                                : value}
                            </a>
                            {!uniqueGroupKey && (
                              <a
                                href={`/reports/hvac?uniqueGroupKey=${cell.row.original.uniqueGroupKey}`}
                                title="View History"
                                style={{ marginRight: '5px' }}
                              >
                                <Clock width={18} height={18} />
                              </a>
                            )}
                            {cell.row.original.alerts &&
                              cell.row.original.alerts.length > 0 && (
                                <button
                                  className="btn btn-link p-0 d-flex align-items-end"
                                  onClick={
                                    () =>
                                      setHvacAlertData({
                                        ...cell.row.original.alerts[0],
                                        ...cell?.row?.original,
                                      })
                                    // alert(cell.row.original.alerts[0].summary)
                                  }
                                >
                                  <Tooltip
                                    label={cell.row.original.alerts[0].summary}
                                    // label={'testing one two three four'}
                                    placement="top"
                                    id={`alert-icon-tooltip-${cell?.row?.id}_${cell?.column?.columnId}`}
                                    // hide={!bodyError}
                                    // className="d-none"
                                    // className="position-absolute"
                                    style={{ maxWidth: '500px' }}
                                  >
                                    <AlertTriangle
                                      data-alert-icon
                                      className={`alert-triangle-${cell.row.original.alerts[0].level}`}
                                      width={18}
                                      height={18}
                                    />
                                  </Tooltip>
                                </button>
                              )}
                          </div>
                          {/* <a
                            href={cell.row.original.sensorUrl}
                            className="p-1 d-block"
                            target="_blank"
                          >
                            {value}
                            {cell.row.original.alerts.length > 0 && <>!!!</>}
                          </a> */}
                        </>
                      );
                    },
                  },
                  ...variableColumns,
                  {
                    Header: (
                      <>
                        Room
                        <br />
                        Temp
                      </>
                    ),
                    accessor: 'roomTemperature',
                    Cell: ({ value, cell, ...rest }) => {
                      if (!value && value != 0) {
                        //cell.element.style.textAlign = 'center';

                        return (
                          <>
                            <div style={{ color: '#aaa' }}>?</div>
                          </>
                        );
                      }
                      return (
                        <>
                          {cell.row.original.sensor?.error?.type &&
                          cell.row.original.sensor?.error
                            ?.errorDurationInMinutes > 10 ? (
                            <span style={{ color: 'red' }}>
                              {cell.row.original.sensor?.error?.type}
                            </span>
                          ) : (
                            <>{value}</>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: (
                      <>
                        Room
                        <br />
                        Humidity
                      </>
                    ),
                    accessor: 'roomHumidity',
                    Cell: ({ value, cell, ...rest }) => {
                      if (!value && value != 0) {
                        //cell.element.style.textAlign = 'center';

                        return (
                          <>
                            <div style={{ color: '#aaa' }}>?</div>
                          </>
                        );
                      }
                      return (
                        <>
                          {cell.row.original.sensor?.error?.type &&
                          cell.row.original.sensor?.error
                            ?.errorDurationInMinutes > 10 ? (
                            <span style={{ color: 'red' }}>
                              {cell.row.original.sensor?.error?.type}
                            </span>
                          ) : (
                            <>{value}</>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: (
                      <>
                        Outside
                        <br />
                        Temp
                      </>
                    ),
                    accessor: 'outsideTemperature',
                    Cell: ({ value, cell, ...rest }) => {
                      return (
                        <>
                          {cell.row.original.sensor?.error?.type &&
                          cell.row.original.sensor?.error
                            ?.errorDurationInMinutes > 10 ? (
                            <span style={{ color: 'red' }}>
                              {cell.row.original.sensor?.error?.type}
                            </span>
                          ) : (
                            value
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: 'Weather',
                    accessor: 'weatherCondition',
                    Cell: ({ value, cell, ...rest }) => {
                      return (
                        <>
                          {cell.row.original.sensor?.error?.type &&
                          cell.row.original.sensor?.error
                            ?.errorDurationInMinutes > 10 ? (
                            <span style={{ color: 'red' }}>
                              {cell.row.original.sensor?.error?.type}
                            </span>
                          ) : (
                            value
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: 'Scene',
                    accessor: 'propertyOccupancyStatus',
                  },
                  {
                    Header: 'Reservation',
                    accessor: 'reservationReference',
                    Cell: ({ value, cell, ...rest }) => {
                      return (
                        <>
                          <GuestReservationModal
                            reservationId={value}
                            guestName={cell.row.original.guestName}
                            customHeader={() => {
                              return (
                                <h5 className="modal-title w-100 font-weight-bold">
                                  Guest Reservation
                                </h5>
                              );
                            }}
                            onSuccess={async () => {
                              console.log('success!');
                              // await getReportData();
                            }}
                          >
                            {value}
                          </GuestReservationModal>
                        </>
                      );
                    },
                  },
                  {
                    Header: uniqueGroupKey
                      ? 'Detection Date/Time'
                      : 'Last Reading (History)',
                    accessor: uniqueGroupKey ? 'createdAt' : 'lastReadingDate',
                    Cell: ({ value, cell, ...rest }) => {
                      return uniqueGroupKey ? (
                        <div className={`type-${cell.row.original.type}`}>
                          {moment
                            .tz(value, 'America/New_York')
                            .format('YYYY-MM-DDTHH:mm:ss') + ' EST'}
                        </div>
                      ) : (
                        <a
                          href={`/reports/hvac?uniqueGroupKey=${cell.row.original.uniqueGroupKey}`}
                        >
                          {moment
                            .tz(value, 'America/New_York')
                            .format('YYYY-MM-DDTHH:mm:ss') + ' EST'}
                        </a>
                      );
                      // return (
                      //   <>
                      //     <DynamicHyperlink cell={cell}>
                      //       <DynamicComponent value={value} cell={cell} />
                      //     </DynamicHyperlink>
                      //     {/* <DynamicComponent value={value} cell={cell} outsideLink={true} /> */}
                      //   </>
                      // );
                    },
                  },
                ]}
                // refetchAllReservations={refetchAllReservations}
                hvacAlerts={hvacAlerts}
                showEventHistory={uniqueGroupKey ? true : false}
              />
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            )}
          </>
        );
      }}
    </Query>
  );
};

const Hvac = () => {
  return (
    <Layout hideMainOverflow={true} metaTitle={'HVAC Logs'}>
      <HvacTableWrapper />
    </Layout>
  );
};

export default Hvac;
