import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import Query from 'src/components/GraphQL/components/Query';
import ChildrenInputs from './components/ChildrenInputs';
import DropdownFilterSelect from 'src/components/Forms/components/DropdownFilterSelect';
import ThemedButton from '../../components/Forms/components/ThemedButton';
import Layout from '../../layouts';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Promise from 'bluebird';

import Loadable from '@loadable/component';

const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));
const UPDATE_GUEST = gql`
  mutation UpdateGuest(
    $guestWhereUniqueInput: GuestWhereUniqueInput!
    $guestUpdateInput: GuestUpdateInput!
  ) {
    updateGuest(where: $guestWhereUniqueInput, data: $guestUpdateInput) {
      firstName
      lastName
      primaryEmailAddress {
        emailAddress
      }
      primaryPhoneNumber {
        phoneNumber
      }
      relatedGuests {
        id
        firstName
      }
    }
  }
`;

const UPDATE_GUEST_RESERVATION = gql`
  mutation updateGuestReservation(
    $reference: String
    $guestReservationUpdateInput: GuestReservationUpdateInput
  ) {
    updateGuestReservation(
      where: { reference: $reference }
      data: $guestReservationUpdateInput
    ) {
      id
      spouseFirstName
    }
  }
`;
const UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST = gql`
  mutation upsertGuestReservationAdditionalGuest(
    $id: Int
    $guestReservationAdditionalGuestUpsertCreateInput: GuestReservationAdditionalGuestUpsertCreateInput!
    $guestReservationAdditionalGuestUpsertUpdateInput: GuestReservationAdditionalGuestUpsertUpdateInput
  ) {
    upsertGuestReservationAdditionalGuest(
      where: { id: $id }
      create: $guestReservationAdditionalGuestUpsertCreateInput
      update: $guestReservationAdditionalGuestUpsertUpdateInput
    ) {
      id
    }
  }
`;

const UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST_CHILD = gql`
  mutation upsertGuestReservationAdditionalGuestChild(
    $id: Int
    $guestReservationAdditionalGuestChildUpsertCreateInput: GuestReservationAdditionalGuestChildUpsertCreateInput!
    $guestReservationAdditionalGuestChildUpsertUpdateInput: GuestReservationAdditionalGuestChildUpsertUpdateInput
  ) {
    upsertGuestReservationAdditionalGuestChild(
      where: { id: $id }
      create: $guestReservationAdditionalGuestChildUpsertCreateInput
      update: $guestReservationAdditionalGuestChildUpsertUpdateInput
    ) {
      id
      firstName
    }
  }
`;

const PROPERTIES_QUERY = gql`
  query Properties {
    properties {
      propertyId: id
      key
      label
      propertyStreetAddress: streetAddress1
      name
      title: name
      type
      sort
      bedrooms
      bathrooms
      sleeps
      group
      type
      ... on PropertyCombo {
        childProperties {
          name
          label
          propertyStreetAddress: streetAddress1
        }
      }
    }
  }
`;
const RESERVATION_LOOKUP = gql`
  query GuestReservation($reference: String) {
    guestReservation(where: { reference: $reference }) {
      id
      reference
      adults
      children
      checkIn
      checkOut
      status
      spouseFirstName
      property {
        propertyId: id
        name
        propertyStreetAddress: streetAddress1
        type
        ... on PropertyCombo {
          childProperties {
            name
            label
            propertyStreetAddress: streetAddress1
          }
        }
      }
      guest {
        id
        firstName
        lastName
        address1
        city
        state
        postalCode
        country
        primaryEmailAddress {
          emailAddress
        }
        primaryPhoneNumber {
          phoneNumber(format: NATIONAL)
        }
      }
      childGuests {
        firstName
        lastName
        age
        id
      }
      additionalGuests {
        id
        firstName
        lastName
        spouseFirstName
        address1
        emailAddress
        phoneNumber
        city
        state
        country
        postalCode
        childGuests {
          id
          firstName
          lastName
          age
        }
      }
    }
  }
`;

const StateOptions = () => {
  return (
    <>
      <option value="">Choose One</option>
      <option value="AL">Alabama</option>
      <option value="AK">Alaska</option>
      <option value="AZ">Arizona</option>
      <option value="AR">Arkansas</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DE">Delaware</option>
      <option value="DC">District Of Columbia</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="IA">Iowa</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="ME">Maine</option>
      <option value="MD">Maryland</option>
      <option value="MA">Massachusetts</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MS">Mississippi</option>
      <option value="MO">Missouri</option>
      <option value="MT">Montana</option>
      <option value="NE">Nebraska</option>
      <option value="NV">Nevada</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NY">New York</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VT">Vermont</option>
      <option value="VA">Virginia</option>
      <option value="WA">Washington</option>
      <option value="WV">West Virginia</option>
      <option value="WI">Wisconsin</option>
      <option value="WY">Wyoming</option>
    </>
  );
};
const upsertAdditionalGuests = async ({
  client,
  setStatus,
  setSubmitting,
  values,
}) => {
  Promise.mapSeries(values?.additionalGuests, async (guest, index) => {
    const mutationInput = {
      address1: guest.address1,
      city: guest.city,
      country: guest.country,
      firstName: guest.firstName,
      lastName: guest.lastName,
      postalCode: guest.postalCode,
      phoneNumber: guest?.phoneNumber || '',
      state: guest.state,
      spouseFirstName: guest?.spouseFirstName || '',
      emailAddress: guest?.emailAddress || '',
      reference: values?.reference || '',
    };
    try {
      if (!values?.additionalGuests[index]?.id) {
        // if new guest
        const createResult = await client.mutate({
          mutation: UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST,
          variables: {
            guestReservationAdditionalGuestUpsertCreateInput: mutationInput,
            id: null,
          },
        });

        const parentId =
          createResult?.data?.upsertGuestReservationAdditionalGuest?.id;

        if (parentId) {
          await upsertChildren({
            setStatus,
            setSubmitting,
            client,
            children: values?.additionalGuests[index]?.childGuests,
            parentId,
            reference: values?.reference,
          });
        }
        setStatus({ success: true, hasReservation: true });
        return createResult;
      } else {
        const updateResult = await client.mutate({
          mutation: UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST,
          variables: {
            guestReservationAdditionalGuestUpsertCreateInput: mutationInput,
            guestReservationAdditionalGuestUpsertUpdateInput: mutationInput,
            id: values?.additionalGuests[index]?.id,
          },
        });

        await upsertChildren({
          setStatus,
          setSubmitting,
          client,
          children: guest?.childGuests,
          parentId: values?.additionalGuests[index]?.id,
          reference: values?.reference,
        });
        setStatus({ success: true, hasReservation: true });
        return updateResult;
      }
    } catch (err) {
      console.log('error!', err);
      setStatus({
        error: 'There was a problem with your submission. Please try again',
        hasReservation: true,
      });
      setSubmitting(false);
    }
  });
};

const upsertChildren = async ({
  client,
  setStatus,
  setSubmitting,
  children,
  parentId,
  reference,
}) => {
  Promise.each(children, async child => {
    const mutationInput = {
      firstName: child.firstName,
      lastName: child.lastName,
      age: child.age || child?.age === 0 ? Number(child.age) : null,
      reference,
    };

    try {
      if (!child?.id) {
        // if new child
        const createResult = await client.mutate({
          mutation: UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST_CHILD,
          variables: {
            guestReservationAdditionalGuestChildUpsertCreateInput: {
              ...mutationInput,
              parentId,
            },
            guestReservationAdditionalGuestChildUpsertUpdateInput: mutationInput,
            id: null,
          },
        });
        setStatus({ success: true, hasReservation: true });
        return createResult;
      } else {
        const updateResult = await client.mutate({
          mutation: UPSERT_GUEST_RESERVATION_ADDITIONAL_GUEST_CHILD,
          variables: {
            guestReservationAdditionalGuestChildUpsertCreateInput: {
              ...mutationInput,
              parentId,
            },
            guestReservationAdditionalGuestChildUpsertUpdateInput: mutationInput,
            id: child?.id,
          },
        });
        setStatus({ success: true, hasReservation: true });
        return updateResult;
      }
    } catch (err) {
      console.log('error!', err);
      setStatus({
        error: 'There was a problem with your submission. Please try again',
        hasReservation: true,
      });
      setSubmitting(false);
    }
  });
};

export default withApollo(({ client }) => {
  const [messageSent, setMessageSent] = useState(false);
  const [messageSending, setMessageSending] = useState(false);
  const [template, setTemplate] = useState('STANDARD');
  // const [enterDataManually, setEnterDataManually] = useState(false);
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const results = await client.query({
        query: PROPERTIES_QUERY,
      });
      setProperties(results.data.properties);
    }
    fetchData();
  });
  // const [additionalGuestCount, setAdditionalGuestCount] = useState(0);

  return (
    <Layout>
      <div className="container-fluid pt-4">
        <Formik
          // enableReinitialize={true}
          initialValues={{
            primaryGuestId: '',
            reference: '',
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            primaryPhoneNumber: '',
            propertyName: '',
            propertyStreetAddress: '',
            propertyId: '',
            checkIn: '',
            checkOut: '',
            address: '',
            city: '',
            state: '',
            postalCode: '',
            childGuests: [],
            initialAdditionalGuests: [],
            additionalGuests: [],
            firstName_secondary: '',
            lastName_secondary: '',
            spouseFirstName: '',
            primaryPhoneNumber_secondary: '',
            childGuests_secondary: [],

            address_secondary: '',
            city_secondary: '',
            state_secondary: '',
            postalCode_secondary: '',
            additionalGuest: false,
          }}
          onSubmit={async (
            values,
            {
              setStatus,
              setSubmitting,
              setFieldValue,
              setErrors,
              status,
              ...rest
            }
          ) => {
            const {
              firstName,
              lastName,
              email,
              primaryPhoneNumber,
              address1,
              emailAddress,
              city,
              state,
              country,
              postalCode,
              reference,
              spouseFirstName,
            } = values;
            try {
              setSubmitting(true);
              let errors;
              const { data } = await client.mutate({
                mutation: UPDATE_GUEST_RESERVATION,
                variables: {
                  reference,
                  guestReservationUpdateInput: {
                    spouseFirstName,
                  },
                },
              });
              const updateGuestMutation = async () => {
                try {
                  const guestUpdateInput = {
                    firstName,
                    lastName,
                    primaryEmailAddress: {
                      emailAddress: emailAddress || null,
                    },
                    primaryPhoneNumber: {
                      phoneNumber: primaryPhoneNumber || null,
                    },
                    address1,
                    city,
                    state,
                    country,
                    postalCode,
                  };

                  await client.mutate({
                    mutation: UPDATE_GUEST,
                    variables: {
                      guestWhereUniqueInput: { id: values?.primaryGuestId },
                      guestUpdateInput,
                    },
                  });
                  setStatus({ success: true, hasReservation: true });
                } catch (err) {
                  console.log('error!', err);
                  setStatus({
                    error:
                      'There was a problem with your submission. Please try again',
                    hasReservation: true,
                  });

                  setSubmitting(false);
                }
              };
              await upsertAdditionalGuests({
                setStatus,
                setSubmitting,
                client,
                values,
              });

              await updateGuestMutation();

              // primary guest children

              if (values?.childGuests?.length > 0) {
                await upsertChildren({
                  setStatus,
                  setSubmitting,
                  client,
                  children: values?.childGuests,
                  parentId: 0,
                  reference: values?.reference,
                });
              }

              setSubmitting(false);

              if (values?.additionalGuests.length === 0 && data) {
                setStatus({ success: true, hasReservation: true });
              }
            } catch (err) {
              setSubmitting(false);
              setStatus({
                error:
                  'There was a problem with your submission. Please try again',
                hasReservation: true,
              });

              console.log('err', err);
            }
          }}
        >
          {({
            errors,
            touched,
            resetForm,
            isSubmitting,
            values,
            status,
            setFieldValue,
            setSubmitting,
            setStatus,
            setErrors,
            handleChange,
            ...rest
          }) => {
            const childrenToList = (children, assumedLastName) => {
              return children
                .reduce((acc, child, index) => {
                  const getChildAgeText = () => {
                    if (child.age || child.age === 0 || child.age === '0') {
                      return child.age === 0 || child.age === '0'
                        ? ` (infant)`
                        : ` (${child.age} yrs)`;
                    }
                    return '';
                  };
                  acc.push(
                    `${child.firstName} ${child.lastName ||
                      assumedLastName}${getChildAgeText()}`
                  );
                  return acc;
                }, [])
                .join(', ');
            };
            const getRevervation = async () => {
              try {
                setSubmitting(true);
                const { data } = await client.query({
                  query: RESERVATION_LOOKUP,
                  variables: {
                    reference: values.reference,
                  },
                  fetchPolicy: 'no-cache',
                });

                if (!data?.guestReservation) {
                  setSubmitting(false);
                  setErrors({
                    reference:
                      'There was no record match found. Please try another reservation code or click on "Don\'t have a reservation code?" to enter your information manually.',
                  });
                }

                const {
                  adults,
                  children,
                  checkIn,
                  checkOut,
                  reference,
                  guest,
                  property,
                  spouseFirstName,
                  childGuests,
                  additionalGuests,
                } = data.guestReservation;

                const {
                  id,
                  firstName,
                  lastName,
                  primaryPhoneNumber,
                  address1,
                  city,
                  state,
                  postalCode,
                  country,
                  primaryEmailAddress,
                } = guest;

                const {
                  name: propertyName,
                  propertyStreetAddress,
                  propertyId,
                  type,
                } = property;

                const addressDisplay =
                  property.type == 'COMBO'
                    ? property.childProperties.find(
                        property => property.label != '894'
                      ).propertyStreetAddress
                    : property.propertyStreetAddress; // https://gitlab.bhipdevelopment.com/bhip/bhip-dashboard/-/issues/289
                setFieldValue('primaryGuestId', guest?.id);
                setFieldValue('initialAdditionalGuests', additionalGuests);
                setFieldValue('additionalGuests', additionalGuests);
                setFieldValue('childGuests', childGuests);
                setFieldValue('spouseFirstName', spouseFirstName);
                setFieldValue('firstName', firstName);
                setFieldValue('lastName', lastName);
                setFieldValue('address1', address1);
                setFieldValue('city', city);
                setFieldValue('state', state);
                setFieldValue('country', country);
                setFieldValue('postalCode', postalCode);
                setFieldValue('propertyName', propertyName);
                setFieldValue('checkIn', checkIn);
                setFieldValue('checkOut', checkOut);
                setFieldValue('propertyStreetAddress', addressDisplay);
                setFieldValue('propertyId', propertyId);
                setFieldValue(
                  'primaryPhoneNumber',
                  primaryPhoneNumber.phoneNumber
                );
                setFieldValue(
                  'emailAddress',
                  primaryEmailAddress?.emailAddress
                );

                setStatus({ hasReservation: true });
                setSubmitting(false);
              } catch (err) {
                console.log('err', err);
                setSubmitting(false);
                setErrors({
                  reference:
                    'Reservation not found. Please contact the system administrator if you are certain this reservation code is valid.',
                });
              }
            };

            return (
              <>
                <div className="row">
                  <div className="col col-12 col-md-8">
                    <div className="card">
                      <div className="card-body p-3">
                        <Form>
                          <div className="form-group">
                            <label>Reservation Code</label>
                            <Field
                              name="reference"
                              type="text"
                              className={`form-control ${errors.reference &&
                                touched.reference &&
                                'is-invalid'}`}
                              value={values.reference}
                              placeholder="ex: HA-1234AB, HM124567"
                            />
                            <ErrorMessage
                              component="div"
                              name="reference"
                              className="invalid-feedback"
                            />
                          </div>

                          <ThemedButton
                            type="submit"
                            // type="button"
                            loading={isSubmitting}
                            className="w-100 btn btn-outline-primary"
                            onClick={getRevervation}
                          >
                            Lookup Reservation
                          </ThemedButton>
                        </Form>

                        {!errors?.reference && status?.hasReservation && (
                          <Form>
                            <h2 className="mt-4 mb-3">Trip Details</h2>
                            <div className="row">
                              <div
                                /*className="col-12 col-xl-6"*/ className="col"
                              >
                                <div className="form-group">
                                  <label>Property</label>
                                  <Field
                                    as="select"
                                    name="propertyId"
                                    className="form-control"
                                    value={values.propertyId}
                                    onChange={e => {
                                      // console.log(
                                      //   'values.propertyId',
                                      //   values.propertyId
                                      // );
                                      const propertyId = e.target.value;
                                      console.log('propertyId', propertyId);
                                      handleChange(e);
                                      const selectedProperty = properties.find(
                                        property =>
                                          property.propertyId == propertyId
                                      );
                                      // console.log(
                                      //   'selectedProperty',
                                      //   selectedProperty
                                      // );
                                      const addressDisplay =
                                        selectedProperty.type == 'COMBO'
                                          ? selectedProperty.childProperties[0]
                                              .propertyStreetAddress
                                          : selectedProperty.propertyStreetAddress;
                                      setFieldValue(
                                        'propertyStreetAddress',
                                        addressDisplay
                                      );

                                      // setFieldValue('propertyId', values.propertyId)
                                    }}
                                  >
                                    <option value="">Choose One</option>
                                    {properties
                                      .filter(
                                        property =>
                                          property.name.indexOf('+') < 0
                                      )
                                      .map((property, index) => {
                                        const addressDisplay =
                                          property.type == 'COMBO'
                                            ? property.childProperties[0]
                                                .propertyStreetAddress
                                            : property.propertyStreetAddress;
                                        return (
                                          <option
                                            key={index}
                                            value={property.propertyId}
                                          >
                                            {property.name} ({addressDisplay})
                                          </option>
                                        );
                                      })}
                                  </Field>
                                </div>
                              </div>
                              <div
                                /*className="col-12 col-xl-6"*/ className="col"
                              >
                                <div className="form-group notes-form-datepicker">
                                  <label>Dates</label>
                                  <div className="d-flex align-items-center">
                                    <DatePicker
                                      className="form-control"
                                      selected={
                                        values.checkIn
                                          ? new Date(values.checkIn)
                                          : null
                                      }
                                      startDate={
                                        values.checkIn
                                          ? new Date(values.checkIn)
                                          : null
                                      }
                                      endDate={
                                        values.checkOut
                                          ? new Date(values.checkOut)
                                          : null
                                      }
                                      selectsStart
                                      onChange={args => {
                                        setFieldValue('checkIn', args);
                                        setFieldValue(
                                          'checkOut',
                                          new Date(
                                            args.getTime() +
                                              1 * 24 * 60 * 60 * 1000
                                          )
                                        );
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeHolderText="Select a Date"
                                    />
                                    <span className="pl-2 pr-2">to</span>
                                    <DatePicker
                                      className="form-control"
                                      selected={
                                        values.checkOut
                                          ? new Date(values.checkOut)
                                          : null
                                      }
                                      startDate={
                                        values.checkIn
                                          ? new Date(values.checkIn)
                                          : null
                                      }
                                      endDate={
                                        values.checkOut
                                          ? new Date(values.checkOut)
                                          : null
                                      }
                                      selectsEnd
                                      onChange={args => {
                                        setFieldValue('checkOut', args);
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeHolderText="Select a Date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h2 className="mt-4 mb-3">Primary Guest</h2>
                            <div className="row">
                              <div className="col col-12 col-lg-4 form-group">
                                <label>First Name</label>
                                <Field
                                  // readOnly={true}
                                  name="firstName"
                                  type="text"
                                  className={`form-control ${errors.firstName &&
                                    touched.firstName &&
                                    'is-invalid'}`}
                                  value={values.firstName}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="firstName"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="col col-12 col-lg-4 form-group">
                                <label>Last Name</label>
                                <Field
                                  // readOnly={true}
                                  name="lastName"
                                  type="text"
                                  className={`form-control ${errors.lastName &&
                                    touched.lastName &&
                                    'is-invalid'}`}
                                  value={values.lastName}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="lastName"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col col-12 col-lg-4 form-group">
                                <label>Spouse Name</label>
                                <Field
                                  name="spouseFirstName"
                                  type="text"
                                  className={`form-control ${errors.spouseFirstName &&
                                    touched.spouseFirstName &&
                                    'is-invalid'}`}
                                  value={values.spouseFirstName || ''}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="lastName"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Phone Number</label>
                              <Field
                                // readOnly={true}
                                name="primaryPhoneNumber"
                                type="text"
                                className={`form-control ${errors.primaryPhoneNumber &&
                                  touched.primaryPhoneNumber &&
                                  'is-invalid'}`}
                                value={values.primaryPhoneNumber}
                              />
                              <ErrorMessage
                                component="div"
                                name="primaryPhoneNumber"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <Field
                                // readOnly={true}
                                name="emailAddress"
                                type="text"
                                className={`form-control ${errors.emailAddress &&
                                  touched.emailAddress &&
                                  'is-invalid'}`}
                                value={values.emailAddress}
                              />
                              <ErrorMessage
                                component="div"
                                name="emailAddress"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group">
                              <label>Address</label>
                              <Field
                                // readOnly={true}
                                name="address1"
                                type="text"
                                className={`form-control ${errors.address1 &&
                                  touched.address1 &&
                                  'is-invalid'}`}
                                value={values.address1}
                              />
                              <ErrorMessage
                                component="div"
                                name="address1"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="row">
                              <div className="col col-12 col-md-3 form-group">
                                <label>City</label>
                                <Field
                                  // readOnly={true}
                                  name="city"
                                  type="text"
                                  className={`form-control ${errors.city &&
                                    touched.city &&
                                    'is-invalid'}`}
                                  value={values.city}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="city"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col col-12 col-md-3 form-group">
                                <label>State</label>
                                <Field
                                  // readOnly={true}
                                  name="state"
                                  as="select"
                                  className={`form-control ${errors.state &&
                                    touched.state &&
                                    'is-state'}`}
                                  value={values.state}
                                >
                                  <StateOptions />
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="state"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col col-12 col-md-3 form-group">
                                <label>Country</label>

                                <Field
                                  // readOnly={true}
                                  name="country"
                                  type="text"
                                  className={`form-control ${errors.country &&
                                    touched.country &&
                                    'is-invalid'}`}
                                  value={values.country}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="country"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col col-12 col-md-3 form-group">
                                <label>Postal Code</label>
                                <Field
                                  // readOnly={true}
                                  name="postalCode"
                                  type="text"
                                  className={`form-control ${errors.postalCode &&
                                    touched.postalCode &&
                                    'is-invalid'}`}
                                  value={values.postalCode}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="postalCode"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <h2 className="mt-4 mb-3">Children</h2>
                              <ChildrenInputs
                                defaultInputGroups={values?.childGuests}
                                onChange={values => {
                                  setFieldValue('childGuests', values);
                                }}
                                assumedLastName={values?.lastName}
                              />
                            </div>

                            <AdditionalGuestFormInputs
                              defaultInputGroups={values?.additionalGuests}
                              onChange={values => {
                                setFieldValue('additionalGuests', values);
                              }}
                            />
                            <FormResponse
                              errors={status?.error}
                              isVisible={status?.error || status?.success}
                            >
                              {status?.success}
                            </FormResponse>
                            <button
                              className="btn btn-primary w-100 mt-4"
                              type="submit"
                              onClick={() => {
                                const transformedChildGuests = values.childGuests.map(
                                  childGuest => {
                                    if (childGuest.lastName === '') {
                                      childGuest.lastName = values.lastName;
                                    }
                                    return childGuest;
                                  }
                                );

                                const transformedAdditionalGuests = values.additionalGuests.map(
                                  additionalGuest => {
                                    const transformedAdditionalGuestChildren = additionalGuest.childGuests.map(
                                      childGuest => {
                                        if (childGuest.lastName === '') {
                                          childGuest.lastName =
                                            additionalGuest.lastName;
                                        }
                                        return childGuest;
                                      }
                                    );
                                    additionalGuest.childGuests = transformedAdditionalGuestChildren;
                                    return additionalGuest;
                                  }
                                );
                              }}
                              disabled={
                                isSubmitting || Object.keys(errors).length > 0
                              }
                            >
                              Save Changes
                            </button>
                          </Form>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col  col-12 col-md-4">
                    <div className="card p-3">
                      <div className="mb-3">
                        <label className="mb-1">Template</label>
                        <select
                          className="form-control"
                          name="template"
                          defaultValue={template}
                          onChange={e => {
                            console.log('e', e?.target?.value);
                            setTemplate(e?.target?.value);
                            // updateTemplate(e);
                          }}
                        >
                          {/* <option hidden>Choose One</option> */}
                          <option
                            // disabled={!platformIsSupported}
                            key={'STANDARD'}
                            value={'STANDARD'}
                          >
                            Standard
                          </option>
                          <option
                            // disabled={!platformIsSupported}
                            key={'GOLF_ONLY'}
                            value={'GOLF_ONLY'}
                          >
                            Golf Access Only
                          </option>
                        </select>
                      </div>
                      <div id="email-template-contents">
                        <div>Hi Melanie, Joe, Vacey & everyone.</div>
                        <br />
                        {template == 'STANDARD' && (
                          <div>
                            These guests will likely come in to the pro shop on{' '}
                            {values.checkIn &&
                              new Intl.DateTimeFormat('en', {
                                weekday: 'long',
                              }).format(new Date(values.checkIn))}{' '}
                            afternoon or{' '}
                            {values.checkIn &&
                              new Intl.DateTimeFormat('en', {
                                weekday: 'long',
                              }).format(
                                new Date(values.checkIn).setDate(
                                  new Date(values.checkIn).getDate() + 1
                                )
                              )}
                            .
                            <br />
                            <br />
                            <div>Thanks!</div>
                            <br />
                            <div>- Al</div>
                          </div>
                        )}
                        {template == 'GOLF_ONLY' && (
                          <div>
                            This is a family staying at a beach house{' '}
                            {values.checkIn &&
                              new Intl.DateTimeFormat('en', {
                                month: 'long',
                                day: 'numeric',
                              }).format(new Date(values.checkIn))}{' '}
                            to{' '}
                            {values.checkOut &&
                              new Intl.DateTimeFormat('en', {
                                month: 'long',
                                day: 'numeric',
                              }).format(new Date(values.checkOut))}
                            .
                            <br />
                            <div>
                              They say they are golfers, and asked to play at
                              the Ocean Club. I'm sending you their names, so
                              that they can call in to book tee times in
                              advance.
                            </div>
                            <br />
                            <div>
                              (If any issues or problems with this, please let
                              me know)
                            </div>
                            <br />
                            <div>Thank you!</div>
                            <br />
                            <div>- Al</div>
                          </div>
                        )}

                        <br />
                        <b>SUMMARY:</b>
                        <div>Ocean Club Membership: Hadhazy</div>
                        <div>Property: {values.propertyStreetAddress}</div>
                        <div>
                          Stay Dates:{' '}
                          {values.checkIn
                            ? new Intl.DateTimeFormat('en', {
                                month: 'short',
                                day: '2-digit',
                              }).format(new Date(values.checkIn))
                            : ''}{' '}
                          -{' '}
                          {values.checkOut
                            ? new Intl.DateTimeFormat('en', {
                                month: 'short',
                                day: '2-digit',
                              }).format(new Date(values.checkOut))
                            : ''}
                        </div>
                        <br />
                        <div>{values.lastName} Group</div>
                        <br />
                        <b>PRIMARY GUEST:</b>

                        <div>
                          {values.firstName}
                          {values.spouseFirstName &&
                            values.spouseFirstName !== '' && (
                              <span> & {values.spouseFirstName}</span>
                            )}
                          <span> {values.lastName}</span>
                        </div>
                        <div>{values.address1}</div>
                        <div>
                          {values.city} {values.state} {values.postalCode}{' '}
                          {values.country !== 'US' &&
                            values.country !== 'USA' &&
                            values.country !== 'United States' &&
                            values.country}
                        </div>

                        <div>Tel: {values.primaryPhoneNumber}</div>

                        <div>{values.emailAddress}</div>

                        <div>
                          {values.childGuests.length > 0 && (
                            <div>
                              Children:{' '}
                              {childrenToList(
                                values.childGuests,
                                values.lastName
                              )}
                            </div>
                          )}
                        </div>
                        {values.additionalGuests.length > 0 &&
                          values.additionalGuests.map(
                            (additionalGuest, index) => {
                              const childGuestsLength =
                                additionalGuest?.childGuests?.length || 0;
                              return (
                                <div key={index}>
                                  <br />
                                  {index === 0 && <b>ADDITIONAL GUESTS:</b>}
                                  <div>
                                    {additionalGuest.firstName}{' '}
                                    {additionalGuest.spouseFirstName &&
                                      additionalGuest.spouseFirstName !==
                                        '' && (
                                        <span>
                                          {' '}
                                          & {additionalGuest.spouseFirstName}
                                        </span>
                                      )}
                                    <span> {additionalGuest.lastName}</span>
                                  </div>
                                  <div>
                                    <div>{additionalGuest.address1}</div>
                                    <div>
                                      {additionalGuest.city}{' '}
                                      {additionalGuest.state}{' '}
                                      {additionalGuest.postalCode}{' '}
                                      {additionalGuest.country !== 'US' &&
                                        additionalGuest.country !== 'USA' &&
                                        additionalGuest.country !==
                                          'United States' &&
                                        additionalGuest.country}
                                    </div>
                                  </div>

                                  <div>Tel: {additionalGuest.phoneNumber}</div>

                                  <div>{additionalGuest.emailAddress}</div>

                                  <div>
                                    {childGuestsLength > 0 && (
                                      <div>
                                        <span>
                                          Children:{' '}
                                          {childrenToList(
                                            additionalGuest.childGuests,
                                            additionalGuest.lastName
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>

                      {messageSent && (
                        <div className="w-100 alert alert-success">
                          Message Sent
                        </div>
                      )}

                      {values.checkIn && (
                        <button
                          className="btn btn-primary mt-3"
                          disabled={messageSending}
                          onClick={async () => {
                            const selectedProperty = properties.find(
                              property =>
                                property.propertyId == values.propertyId
                            );

                            let subject = `Ocean Club Guest Passes: ${
                              values.lastName
                            } Group @ ${
                              selectedProperty.type == 'COMBO'
                                ? selectedProperty.label
                                : values.propertyStreetAddress
                            } (${new Intl.DateTimeFormat('en', {
                              month: 'short',
                              day: '2-digit',
                            }).format(
                              new Date(values.checkIn)
                            )}-${new Intl.DateTimeFormat('en', {
                              month: 'short',
                              day: '2-digit',
                            }).format(new Date(values.checkOut))})`;
                            if (template == 'GOLF_ONLY') {
                              subject = `Golfers at a Beach House: ${values.lastName} Group`;
                            }

                            setMessageSending(true);

                            try {
                              const result = await client.mutate({
                                mutation: gql`
                                  mutation SendEmployeeEmail(
                                    $to: String!
                                    $cc: String
                                    $subject: String
                                    $message: String!
                                  ) {
                                    sendEmployeeEmail(
                                      data: {
                                        to: $to
                                        cc: $cc
                                        subject: $subject
                                        message: $message
                                      }
                                    ) {
                                      messageId
                                    }
                                  }
                                `,
                                variables: {
                                  to: 'stacy@bhipmanagement.com',
                                  cc:
                                    'fredy@bhipmanagement.com,david@bhipmanagement.com,mike@bhipmanagement.com',
                                  subject: subject,
                                  message: document.getElementById(
                                    'email-template-contents'
                                  ).innerHTML,
                                },
                              });
                              setMessageSent(true);
                              setMessageSending(false);
                            } catch (err) {
                              alert('There was a problem with your submission');
                              console.log('err', err);
                            }
                          }}
                        >
                          Send Email To Staff
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
});

const AdditionalGuestFormInputs = ({ onChange, defaultInputGroups }) => {
  const [inputGroups, setInputGroups] = useState(defaultInputGroups || []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const newInputData = inputGroups.map((inputGroup, targetIndex) => {
      if (targetIndex == index) {
        inputGroup[name] = value;
      }
      return inputGroup;
    });

    setInputGroups(newInputData);

    if (onChange) {
      onChange(inputGroups);
    }
  };

  return (
    <>
      {inputGroups.map((inputGroup, index) => {
        return (
          <div key={index} className="row">
            <div className="col">
              <h2 className="mt-4 mb-3">
                Additional Guest / Group #{index + 1} (
                <button
                  type="button"
                  className="btn btn-link px-0"
                  onClick={() => {
                    const newInputData = inputGroups.filter(
                      (targetInput, targetIndex) => {
                        return targetIndex != index;
                      }
                    );
                    setInputGroups(newInputData);
                    if (onChange) {
                      onChange(newInputData);
                    }
                  }}
                >
                  Remove
                </button>
                )
              </h2>
              <div className="row">
                <div className="col col-12 col-lg-4 form-group">
                  <label>First Name</label>
                  <Field
                    name="firstName"
                    type="text"
                    autoFocus={true}
                    // className={`form-control ${errors.firstName_secondary &&
                    //   touched.firstName_secondary &&
                    //   'is-invalid'}`}
                    className={`form-control`}
                    value={inputGroup.firstName || ''}
                    onChange={e => handleChange(e, index)}
                  />
                  {/* <ErrorMessage
                  component="div"
                  name="firstName"
                  className="invalid-feedback"
                /> */}
                </div>

                <div className="col col-12 col-lg-4 form-group">
                  <label>Last Name</label>
                  <Field
                    name="lastName"
                    type="text"
                    // className={`form-control ${errors.lastName_secondary &&
                    //   touched.lastName_secondary &&
                    //   'is-invalid'}`}
                    className={`form-control`}
                    onChange={e => handleChange(e, index)}
                    value={inputGroup.lastName || ''}
                  />
                </div>
                <div className="col col-12 col-lg-4 form-group">
                  <label>Spouse Name</label>
                  <Field
                    name="spouseFirstName"
                    type="text"
                    className={`form-control`}
                    onChange={e => handleChange(e, index)}
                    value={inputGroup.spouseFirstName || ''}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <Field
                  name="phoneNumber"
                  type="text"
                  // className={`form-control ${errors.primaryPhoneNumber_secondary &&
                  //   touched.primaryPhoneNumber_secondary &&
                  //   'is-invalid'}`}
                  className={`form-control`}
                  onChange={e => handleChange(e, index)}
                  value={inputGroup.phoneNumber || ''}
                />
                {/* <ErrorMessage
                component="div"
                name="primaryPhoneNumber"
                className="invalid-feedback"
              /> */}
              </div>
              <div className="form-group">
                <label>Address</label>
                <Field
                  name="address1"
                  type="text"
                  // className={`form-control ${errors.address_secondary &&
                  //   touched.address_secondary &&
                  //   'is-invalid'}`}
                  className={`form-control`}
                  onChange={e => handleChange(e, index)}
                  value={inputGroup.address1 || ''}
                />
                {/* <ErrorMessage
                component="div"
                name="address"
                className="invalid-feedback"
              /> */}
              </div>

              <div className="form-group">
                <label>Email</label>
                <Field
                  name="emailAddress"
                  type="text"
                  // className={`form-control ${errors.address_secondary &&
                  //   touched.address_secondary &&
                  //   'is-invalid'}`}
                  className={`form-control`}
                  onChange={e => handleChange(e, index)}
                  value={inputGroup?.emailAddress || ''}
                />
                {/* <ErrorMessage
                component="div"
                name="address"
                className="invalid-feedback"
              /> */}
              </div>
              <div className="row">
                <div className="col col-12 col-md-3 form-group">
                  <label>City</label>
                  <Field
                    name="city"
                    type="text"
                    // className={`form-control ${errors.city_secondary &&
                    //   touched.city_secondary &&
                    //   'is-invalid'}`}
                    className={`form-control`}
                    onChange={e => handleChange(e, index)}
                    value={inputGroup.city || ''}
                  />
                  {/* <ErrorMessage
                  component="div"
                  name="city"
                  className="invalid-feedback"
                /> */}
                </div>
                <div className="col col-12 col-md-3 form-group">
                  <label>State</label>
                  <Field
                    name="state"
                    as="select"
                    // className={`form-control ${errors.state_secondary &&
                    //   touched.state_secondary &&
                    //   'is-state'}`}
                    className={`form-control`}
                    onChange={e => handleChange(e, index)}
                    value={inputGroup.state || ''}
                  >
                    <option value="">Choose One</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Field>
                  {/* <ErrorMessage
                  component="div"
                  name="state"
                  className="invalid-feedback"
                /> */}
                </div>
                <div className="col col-12 col-md-3 form-group">
                  <label>Country</label>
                  <Field
                    name="country"
                    type="text"
                    className={`form-control`}
                    value={inputGroup?.country || ''}
                    onChange={e => handleChange(e, index)}
                  />
                  {/* <ErrorMessage
                  component="div"
                  name="country"
                  className="invalid-feedback"
                /> */}
                </div>
                <div className="col col-12 col-md-3 form-group">
                  <label>Postal Code</label>
                  <Field
                    name="postalCode"
                    type="text"
                    // className={`form-control ${errors.postalCode_secondary &&
                    //   touched.postalCode_secondary &&
                    //   'is-invalid'}`}
                    className={`form-control`}
                    onChange={e => handleChange(e, index)}
                    value={inputGroup.postalCode}
                  />
                  {/* <ErrorMessage
                  component="div"
                  name="postalCode"
                  className="invalid-feedback"
                /> */}
                </div>
              </div>
              <div className="form-group">
                <h2 className="mt-4 mb-3">Children</h2>
                <ChildrenInputs
                  assumedLastName={inputGroup?.lastName}
                  defaultInputGroups={inputGroup?.childGuests}
                  // prefix="secondary_"
                  onChange={values => {
                    const newInputData = inputGroups.map(
                      (inputGroup, targetIndex) => {
                        if (targetIndex == index) {
                          inputGroup.childGuests = values;
                        }
                        return inputGroup;
                      }
                    );

                    setInputGroups(newInputData);

                    if (onChange) {
                      onChange(inputGroups);
                    }
                  }}
                />
              </div>
              <div className="text-center mt-4">
                {/* <button
          className="btn btn-link"
          onClick={() => {
            addAdditionalGuest();
          }}
        >
          Additional Guest / Group
        </button> */}
              </div>
            </div>
          </div>
        );
      })}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            setInputGroups([
              ...inputGroups,
              {
                firstName: '',
                lastName: '',
                address1: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                // primaryPhoneNumber: '',
                // propertyName: '',
                // propertyStreetAddress: '',
                // checkIn: '',
                // checkOut: '',
                // address: '',
                city: '',
                state: '',
                postalCode: '',
                childGuests: [],
              },
            ]);
          }}
        >
          Additional Guest / Group
        </button>
      </div>
    </>
  );
};

/*
TEMPLATE

SUMMARY: 
Ocean Club Membership:  Hadhazy
Property: 771 SE Macarthur 
Stay Dates:   Oct 2 - Oct 10

Hull Group



PRIMARY GUEST:  

 Andrew and Amanda Hull
5471 S Holiday Dr

Columbus IN 47203

Tel: 812-764-9453

Child: Ava Bott (13 yrs), Kaylah Williams (10 yrs), Rhylan Hull (5 yrs)


ADDITIONAL GUESTS:
Josh and Christine Owens

4317 Westminster Ct.

Columbus, IN 47201

tel: 812-371-1452

Child: Lily Priest (13 yrs), Delila Priest (10 yrs), Ella Owens (4 yrs), Jenson Owens (3 yrs)  
*/
