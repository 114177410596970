import React, { useState } from 'react';
import get from 'lodash/get';
import Modal from 'src/components/Modal';
import LoadingSpinner from 'src/components/LoadingSpinner';
import QuoteGenerator from 'src/components/QuoteGenerator';
import Query from 'src/components/GraphQL/components/Query';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import YouTube from 'src/images/youtube.svg';
import MatterPort from 'src/images/matterport.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import FormResponse from 'src/components/Forms/components/FormResponse';
const validationSchema = Yup.object().shape({
  reference: Yup.string().required('Reservation is required'),
  platformKey: Yup.string().required('Platform is required'),
});
const SCRAPE_GUEST_RESERVATION = gql`
  mutation scrapeGuestReservation($platformKey: String!, $reference: String!) {
    scrapeGuestReservation(
      data: { platformKey: $platformKey, reference: $reference }
    ) {
      job {
        id
        status
      }
    }
  }
`;

const GET_PLATFORMS = gql`
  query Platforms {
    platforms {
      key
      name
    }
  }
`;

export default ({ isOpen, onClose = new Function(), onSuccess, children }) => {
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Modal
        title={`Fetch Reservation Data`}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        disableForceFocus={true}
      >
        <Query query={GET_PLATFORMS}>
          {({ loading, error, data: platformData }) => {
            loading && (
              <div
                className="d-flex justify-content-center align-items-center" /*style={{height: '80vh'}}*/
              >
                <div
                  className="spinner-border"
                  role="status"
                  style={{
                    color: '#CCC',
                    height: '100px',
                    width: '100px',
                  }}
                ></div>
              </div>
            );

            if (error)
              return (
                <div className="container-fluid pt-3">{error.message}</div>
              );
            const platforms = platformData?.platforms;
            return (
              <Mutation mutation={SCRAPE_GUEST_RESERVATION}>
                {(scrapeGuestReservation, { data: scrapedReservationData }) => {
                  return (
                    <Formik
                      initialValues={{
                        reference: '',
                        platformKey: '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, setStatus }
                      ) => {
                        const { platformKey, reference } = values;
                        const referenceUpperCase = reference?.toUpperCase();
                        try {
                          const fetchedScrapedDataResult = await scrapeGuestReservation(
                            {
                              variables: {
                                platformKey,
                                reference: referenceUpperCase,
                              },
                            }
                          );

                          if (
                            fetchedScrapedDataResult?.data
                              ?.scrapeGuestReservation?.job?.status ===
                            'IN_QUEUE'
                          ) {
                            setStatus({
                              success: `Reservation ${referenceUpperCase} is in queue to be scraped. Please wait a few moments.`,
                            });
                          }
                        } catch (err) {
                          console.log('err', err);
                          setStatus({ error: err });
                        }
                      }}
                    >
                      {formikProps => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          status,
                          submitCount,
                        } = formikProps;
                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label>Reservation Code</label>
                              <span className="text-danger ml-1">*</span>
                              <input
                                defaultValue={values?.reference}
                                onChange={handleChange}
                                name="reference"
                                className="form-control"
                                placeholder="Ex: HA-ABC123"
                              />
                              <div className="text-danger">
                                {touched.reference &&
                                  errors.reference &&
                                  errors.reference}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Platform</label>
                              <span className="text-danger ml-1">*</span>

                              <select
                                className="form-control"
                                name="platformKey"
                                onChange={e => {
                                  setFieldValue('platformKey', e.target.value);
                                }}
                              >
                                <option hidden>Choose One</option>
                                {platforms?.map(platform => {
                                  if (platform?.key !== 'bhip') {
                                    const platformIsSupported = [
                                      'airbnb',
                                      'homeaway',
                                    ].includes(platform?.key);
                                    return (
                                      <option
                                        disabled={!platformIsSupported}
                                        key={platform?.key}
                                        value={platform?.key}
                                      >
                                        {`${platform?.name} ${
                                          platformIsSupported
                                            ? ''
                                            : '(Coming Soon)'
                                        }`}
                                      </option>
                                    );
                                  }
                                })}
                              </select>
                              <div className="text-danger">
                                {touched.platformKey &&
                                  errors.platformKey &&
                                  errors.platformKey}
                              </div>
                            </div>
                            <FormResponse
                              errors={status?.error}
                              alert={
                                Object.keys(errors).length > 0 &&
                                submitCount > 0
                              }
                              isVisible={
                                status?.error ||
                                status?.success ||
                                (Object.keys(errors).length > 0 &&
                                  submitCount > 0)
                              }
                            >
                              {get(status, 'success')}
                            </FormResponse>
                            <ThemedButton
                              type="submit"
                              loading={isSubmitting}
                              className="w-100 mb-2 btn btn-primary"
                            >
                              Fetch Reservation Data
                            </ThemedButton>
                          </form>
                        );
                      }}
                    </Formik>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </Modal>
    </>
  );
};
