import React, { useState } from 'react';
import Modal from 'src/components/Modal';
import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';

const GuestReservationModal = ({
  reservationId,
  onSuccess,
  children,
  guestName,
  redFlags,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [title, setTitle] = useState('Guest Reservation');

  return (
    <>
      <button
        onClick={() => {
          setModalIsOpen(true);
        }}
        className="btn btn-link p-0 small"
      >
        {children}
      </button>
      <Modal
        title={title}
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        styleType="large"
        disableForceFocus={true}
      >
        <GuestReservationForm
          onSuccess={onSuccess}
          onDataLoad={guestReservationData => {
            const guestName =
              guestReservationData?.guest.firstName +
              ' ' +
              guestReservationData?.guest.lastName;
            setTitle(`Guest Reservation (${guestName})`);
          }}
          reference={reservationId}
          redFlags={redFlags}
          {...rest}
        />
      </Modal>
    </>
  );
};

export default GuestReservationModal;
