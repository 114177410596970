import React, { useState } from 'react';
import BTable from 'react-bootstrap/Table';
import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';
import CustomThresholds from './components/CustomThresholds';
import HvacAlertDisplay from './components/HvacAlertDisplay';
import {
  ReactTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
} from 'src/components/ReactTable';
import { navigate } from 'gatsby';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { Tooltip as TippyTooltip } from 'react-tippy';
import Tooltip from 'src/components/Tooltip';
import moment from 'moment';
import {
  Filter,
  ChevronUp,
  ChevronDown,
  AlertTriangle,
  ArrowLeft,
} from 'react-feather';
import Modal from 'src/components/Modal';
import { get } from 'lodash';

import './styles.scss';
import { enumToLabel } from 'src/util/index';

const useSelectFilter = (key, recordset) => {
  const unique = [...new Set(recordset.map(x => x[key]))];

  return unique.length < 7;
};

const reduceObjValues = obj => {
  if (!obj) return {};
  // let objectsWithAlerts = {};
  const topLevelObjects = Object.keys(obj).reduce((acc, cur) => {
    if (!Array.isArray(obj[cur]) && typeof obj[cur] === 'object') {
      if (obj[cur] && typeof obj[cur] === 'object') {
        Object.keys(obj[cur]).forEach(nestedKey => {
          if (typeof obj[cur][nestedKey] === 'object') {
            return reduceObjValues(
              { ...acc, ...obj[cur][nestedKey] },
              /*cache*/ {}
            );
          }
        });
      }

      return reduceObjValues({ ...acc, ...obj[cur] }, /*cache*/ {});
    }
    acc[cur] = obj[cur];

    return acc;
  }, {});

  return topLevelObjects;
};
const DynamicHyperlink = ({ cell, children }) => {
  const deviceName = cell?.row?.original?.deviceName;
  const params = new URLSearchParams(location?.search);
  const hyperlink = cell?.column?.hyperlink;

  if (!hyperlink) {
    return children;
  }
  const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
    if (cell.row.original[key]) {
      acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
    }
    return acc;
  }, {});

  switch (hyperlink) {
    default:
      return (
        <a
          href={`/reports/hvac?deviceName=${deviceName}`}
          // target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
  }
};

const getHyperlinkType = key => {
  if (key === 'Updated At (History)') {
    return key;
  }
  return null;
};

const transformObjectValuesForTableDisplay = obj => {
  const objectWithUpdatedLabels = {};
  const objectValues = reduceObjValues(obj);
  const tableValuesNotForDisplay = Object.keys(objectValues).reduce(
    (acc, key) => {
      if (key.indexOf('_hidden') !== -1) {
        acc[key] = objectValues[key];
      }
      return acc;
    },
    {}
  );

  objectWithUpdatedLabels.property = objectValues.streetAddress1;
  objectWithUpdatedLabels.name = `${objectValues.propertyLabel_hidden} ${objectValues.deviceLabel}`;
  objectWithUpdatedLabels.deviceName = objectValues.deviceName;

  objectWithUpdatedLabels['Temp (°F)'] = objectValues.temperatureValue;
  objectWithUpdatedLabels['Setpoint (°F)'] = objectValues.setPointValue;
  objectWithUpdatedLabels['Delta (°F)'] = objectValues.temperatureDeltaValue;
  objectWithUpdatedLabels['Humidity (%)'] = objectValues.humidityValue;
  objectWithUpdatedLabels['Outside Temp (°F)'] =
    objectValues.outsideTemperature;
  objectWithUpdatedLabels.weather = objectValues.weatherCondition;

  objectWithUpdatedLabels['HVAC'] = objectValues.hvacStatusValue;
  objectWithUpdatedLabels.fan = objectValues.fanStatusValue;
  objectWithUpdatedLabels.status = objectValues.connectivityStatusValue;
  objectWithUpdatedLabels.scene = objectValues.propertyOccupancyStatus;
  objectWithUpdatedLabels.reservation =
    objectValues.reservationReference || 'N/A';
  objectWithUpdatedLabels['Updated At (History)'] = moment(
    objectValues.createdAt_hidden
  ).format('MM/DD/YYYY hh:mm A');

  return { ...objectWithUpdatedLabels, ...tableValuesNotForDisplay };
};

const LogReportTable = ({
  data,
  columns,
  tableData,
  isRefetching,
  hvacAlerts,
  showEventHistory,
}) => {
  const [hvacAlertData, setHvacAlertData] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const getReservationNumber = React.useCallback(event => {
    setSelectedReservation(event.target.value);
  }, []);
  const [selectedPropertyHVAC, setSelectedPropertyHVAC] = useState(null);

  const getCellAlert = deviceName => {
    const foundAlert = hvacAlerts.find(
      alert => alert?.deviceName === deviceName
    );
    return foundAlert;
  };
  const CellWithAlert = ({ alertLevel, value, summary, id }) => {
    const cellValue = (
      <span
        className={`${alertLevel ? `hvac-alert-${alertLevel} font-weight-bold` : ''
          }`}
      >
        {value}
      </span>
    );

    return (
      <>
        {alertLevel && summary ? (
          <Tooltip
            label={summary}
            // label={'testing one two three four'}
            placement="top"
            id={`hvac-alert-tooltip-${id}`}
            // hide={!bodyError}
            // className="d-none"
            // className="position-absolute"
            style={{ maxWidth: '500px' }}
          >
            {cellValue}
          </Tooltip>
        ) : (
          <>{cellValue}</>
        )}
      </>
    );
  };
  //   const getComponentKey = key => {
  //     if (key === 'confirmed') {
  //       return 'CheckMark';
  //     }
  //     if (key === 'redFlag') {
  //       return 'RedFlag';
  //     }
  //     return null;
  //   };
  const getShouldShowAlert = (cellAlert, row) => {
    if (!cellAlert) {
      return false;
    }
    if (showEventHistory && row?.index > 0) {
      return false;
    }
    return true;
  };
  const DynamicComponent = ({ value, cell, outsideLink }) => {
    // const columnDefinition = cell.column.columnDefinition || {};
    // const componentKey = cell?.column?.component;

    // if (outsideLink) {
    //   if (cell?.column.id === 'guestName') {
    //     return getGuestNameComponent(cell);
    //   }
    //   return null;
    // }

    if (cell?.column?.Header === 'Reservation' && value !== 'N/A') {
      return (
        <button
          value={value}
          className="btn btn-link p-0  small"
          onClick={getReservationNumber}
        >
          {value}
        </button>
      );
    }
    if (cell?.column?.Header === 'Temp (°F)') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.temperatureAlertLevel_hidden}
          summary={cell?.row?.original?.temperatureAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'Setpoint (°F)') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.setPointAlertLevel_hidden}
          summary={cell?.row?.original?.setPointAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'Delta (°F)') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.temperatureDeltaAlertLevel_hidden}
          summary={cell?.row?.original?.temperatureDeltaAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'Humidity (%)') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.humidityAlertLevel_hidden}
          summary={cell?.row?.original?.humidityAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'HVAC') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.hvacStatusAlertLevel_hidden}
          summary={cell?.row?.original?.hvacStatusAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'Fan') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.fanStatusAlertLevel_hidden}
          summary={cell?.row?.original?.fanStatusAlertSummary_hidden}
        />
      );
    }
    if (cell?.column?.Header === 'Status') {
      return (
        <CellWithAlert
          id={`${cell?.row?.index}_${cell?.column?.columnIndex}`}
          value={value}
          alertLevel={cell?.row?.original?.connectivityAlertLevel_hidden}
          summary={cell?.row?.original?.connectivityAlertSummary_hidden}
        />
      );
    }

    if (cell?.column?.Header === 'Scene' && value) {
      if (value === 'CHECK_IN' || value === 'CHECK_OUT') {
        return value === 'CHECK_IN' ? `Check-in` : 'Check-out';
      }
      return `${enumToLabel(value)}`;
    }

    if (cell?.column?.Header === 'Name') {
      const cellAlert = getCellAlert(cell?.row.original?.deviceName);
      const showAlert = getShouldShowAlert(cellAlert, cell?.row);

      return (
        <div className="d-flex align-items-center">
          <button
            value={value}
            className="btn btn-link p-0  small"
            onClick={() => setSelectedPropertyHVAC(cell?.row?.original)}
          >
            {value}
          </button>
          {showAlert && (
            <button
              className="btn btn-link p-0 d-flex align-items-end"
              onClick={() =>
                setHvacAlertData({ ...cellAlert, ...cell?.row?.original })
              }
            >
              <Tooltip
                label={cellAlert?.summary}
                // label={'testing one two three four'}
                placement="top"
                id={`alert-icon-tooltip-${cell?.row?.id}_${cell?.column?.columnId}`}
                // hide={!bodyError}
                // className="d-none"
                // className="position-absolute"
                style={{ maxWidth: '500px' }}
              >
                <AlertTriangle
                  data-alert-icon
                  className={`alert-triangle-300`}
                  width={18}
                  height={18}
                />
              </Tooltip>
            </button>
          )}
        </div>
      );
    }

    return <>{value}</>;
  };
  const CustomCell = ({ value, cell, ...rest }) => {
    return (
      <>
        <DynamicHyperlink cell={cell}>
          <DynamicComponent value={value} cell={cell} />
        </DynamicHyperlink>
        {/* <DynamicComponent value={value} cell={cell} outsideLink={true} /> */}
      </>
    );
  };

  const getTableColumns = topLevelDataObjects => {
    const getTitleCase = text => {
      var result = text.replace(/([A-Z])/g, '$1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    return topLevelDataObjects.reduce((acc, column, index) => {
      Object.keys(column).forEach((key, index) => {
        const filterOptions = {};

        if (typeof column[key] === 'number') {
          filterOptions.Filter = NumberRangeColumnFilter;
          filterOptions.filter = 'between';
        } else if (useSelectFilter(key, topLevelDataObjects)) {
          filterOptions.Filter = SelectColumnFilter;
          filterOptions.filter = 'includes';
        }
        if (acc && key !== 'deviceName' && key.indexOf('_hidden') === -1) {
          const matchingKey = acc.find(accItem => accItem.accessor === key);

          if (!matchingKey) {
            acc.push({
              columnIndex: index,
              Header: getTitleCase(key),
              accessor: key,
              Cell: CustomCell,
              hyperlink: null,
              hyperlink: getHyperlinkType(key),
              enableRowSpan: key === 'streetAddress1' ? true : false,
              //   component: getComponentKey(key),
              ...filterOptions,
            });
          }
        }
      });
      return acc;
    }, []);
  };

  console.log('tableData', tableData);
  const topLevelTableData = tableData
    ? tableData.reduce((acc, data) => {
      acc.push(transformObjectValuesForTableDisplay(data?.node || data));
      return acc;
    }, [])
    : null;
  // console.log('topLevelTableData1', topLevelTableData);
  console.log('data', data);
  console.log('columns', columns);
  // const columns = topLevelTableData && getTableColumns(topLevelTableData, true);

  const memoizedColumns = React.useMemo(() => columns, []);
  const memoizedData = React.useMemo(() => data, [data]);
  // const memoizedData = React.useMemo(() => topLevelTableData, [
  //   topLevelTableData,
  // ]);

  const hasAllTableData = memoizedColumns && memoizedData ? true : false;
  // const getRowSpanIterator = (rowSpanNumber, cell, rowIndex) => {
  //   const hasMatchingNextRow =
  //     cell?.column?.filteredRows[rowIndex + 1]?.original?.property ===
  //     cell.column.filteredRows[rowIndex].original.property;
  //   if (!hasMatchingNextRow) {
  //     return rowSpanNumber;
  //   }
  //   // update number of rows the td spans based on whether there is a matching next row
  //   let updatedRowSpanNumber = (rowSpanNumber += 1);
  //   return getRowSpanIterator(updatedRowSpanNumber, cell, rowIndex + 1);
  // };
  // const getRowSpanNumberFromStatusReport = (rowIndex, cell) => {
  //   const rowSpanNumber = getRowSpanIterator(1, cell, rowIndex);
  //   return String(rowSpanNumber);
  // };
  // const getIsRepeatedCellFromStatusReport = (rowIndex, cell) => {
  //   // if the current cell was pushed to the begginning of the next table row because of the increased rowspan
  //   // of the cell with the same name of the preceding row then it is a duplicate.
  //   if (
  //     cell.value ===
  //     cell?.column?.filteredRows[rowIndex - 1]?.original?.property
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  // const getRowSpanNumber = (tdIndex, rowIndex, cell) => {
  //   if (tdIndex > 0) {
  //     return '1';
  //   }
  //   if (showEventHistory && tdIndex === 0 && rowIndex === 0) {
  //     // since there is only one property for the event history table just return the length of the data for the row span
  //     return String(memoizedData.length);
  //   } else {
  //     return getRowSpanNumberFromStatusReport(rowIndex, cell);
  //   }
  // };
  // const getIsRepeatedCell = (tdIndex, rowIndex, cell, rowSpanNumber) => {
  //   if (showEventHistory && rowIndex > 0 && tdIndex === 0) {
  //     return true;
  //   }
  //   if (tdIndex > 0 || rowSpanNumber > 1) {
  //     // the first cell of each row will be the only cell that is a possible duplicate
  //     return false;
  //   }
  //   return getIsRepeatedCellFromStatusReport(rowIndex, cell);
  // };
  function useInstance(instance) {
    const { allColumns } = instance;

    let rowSpanHeaders = [];

    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column;

      if (enableRowSpan) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 },
        ];
      }
    });

    Object.assign(instance, { rowSpanHeaders });
  }

  return (
    <>
      {hasAllTableData ? (
        <section className="d-flex flex-column h-100 mx-3 mt-3">
          <ReactTable
            columns={memoizedColumns}
            data={memoizedData}
            // columns={columns}
            // data={topLevelDataObjects}
            hookFunction={hooks => {
              hooks.useInstance.push(useInstance);
            }}
          >
            {({
              getTableProps,
              getTableBodyProps,
              headerGroups,
              prepareRow,
              rows,
              sortedRows,
              setFilter,
              rowSpanHeaders,
              columns,
            }) => {
              const generateSortingIndicator = column => {
                return column.isSorted ? (
                  column.isSortedDesc ? (
                    <div>
                      <ChevronDown />
                    </div>
                  ) : (
                    <div>
                      <ChevronUp />
                    </div>
                  )
                ) : (
                  <div className="d-flex flex-column">
                    <ChevronUp />
                    <ChevronDown style={{ marginTop: '-10px' }} />
                  </div>
                );
              };

              const selectListFilterData = columns.find(
                column => column?.id === 'platform'
              );
              let isDarkRow = true;
              return (
                <>
                  <section className="d-flex justify-content-between align-items-center">
                    {!showEventHistory && (
                      <div className="d-flex align-items-center mb-2">
                        <h1 className="mb-0 mr-2">
                          Automated HVAC Status Report
                        </h1>
                        <span className="small">(Updates every 5 minutes)</span>
                      </div>
                    )}
                    {showEventHistory && (
                      <div className="d-flex align-items-center mb-2">
                        <button
                          // disabled={
                          //   window && window.history && window.history.length <= 1
                          // }
                          onClick={() => {
                            window.location.href = '/reports/hvac';
                            // if (
                            //   window &&
                            //   window.history &&
                            //   window.history.length === 1
                            // ) {
                            //   navigate('/reports/hvac');
                            // } else {
                            //   window.history.back();
                            // }
                          }}
                          className=" p-0 btn"
                        >
                          <ArrowLeft
                            className="align-top text-primary"
                            size="30"
                          />
                        </button>
                        <h1 className="mb-0">Event History</h1>
                      </div>
                    )}
                    {/* <CSVLink
                      filename={`${bookingType}-${moment().format(
                        'YYYYMMDD'
                      )}.csv`}
                      data={sortedRows.map(sortedRow => sortedRow.values)}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Download Report
                      </span>
                    </CSVLink> */}
                  </section>
                  <div id="table-wrapper" className="w-100 p-0">
                    {isRefetching ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '80vh' }}
                      >
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <BTable
                        className="mb-0 position-relative"
                        id={`hvac-table`}
                        data-sticky-header-table
                        // striped
                        bordered
                        hover
                        size="sm"
                        {...getTableProps()}
                      >
                        <thead className="header">
                          {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                              // Apply the header row props
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {// Loop over the headers in each row
                                  headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    // Add the column sortBy props

                                    <th
                                      key={column?.Header}
                                      className="text-nowrap"
                                    >
                                      <div className="d-flex align-items-end justify-content-between">
                                        <span
                                          style={{
                                            whiteSpace:
                                              column.Header.length < 15
                                                ? 'nowrap'
                                                : 'inherit',
                                          }}
                                        >
                                          {column.render('Header')}
                                        </span>
                                        {/*   Add a sort direction indicator
                                    isSorted sorts in ascending order
                                */}
                                        <TippyTooltip
                                          // options
                                          html={
                                            <div className="d-flex">
                                              <div>{column.render('Filter')}</div>
                                              <div>
                                                <button
                                                  className="btn btn-light py-0 ml-2"
                                                  {...column.getSortByToggleProps()}
                                                >
                                                  {generateSortingIndicator(column)}
                                                </button>
                                              </div>
                                            </div>
                                          }
                                          position="bottom"
                                          interactive
                                          trigger="click"
                                          theme="light"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <Filter
                                            color={
                                              column.filterValue || column.isSorted
                                                ? '#333'
                                                : '#ccc'
                                            }
                                            size={20}
                                          />
                                        </TippyTooltip>
                                      </div>
                                    </th>
                                  ))}
                              </tr>
                            ))}
                        </thead>
                        {/* Apply the table body props */}
                        <tbody {...getTableBodyProps()}>
                          {rows.map((row, i) => {
                            prepareRow(row);

                            for (let j = 0; j < row.allCells.length; j++) {
                              let cell = row.allCells[j];
                              let rowSpanHeader = rowSpanHeaders.find(
                                x => x.id === cell.column.id
                              );

                              if (rowSpanHeader !== undefined) {
                                if (
                                  rowSpanHeader.topCellValue === null ||
                                  rowSpanHeader.topCellValue !== cell.value
                                ) {
                                  cell.isRowSpanned = false;
                                  rowSpanHeader.topCellValue = cell.value;
                                  rowSpanHeader.topCellIndex = i;
                                  cell.rowSpan = 1;
                                } else {
                                  rows[rowSpanHeader.topCellIndex].allCells[j]
                                    .rowSpan++;
                                  cell.isRowSpanned = true;
                                }
                              }
                            }
                            return null;
                          })}
                          {// Loop over the table rows

                            rows.map((row, rowIndex) => {
                              // Prepare the row for display
                              // prepareRow(row);

                              return (
                                // Apply the row props
                                <tr
                                  key={row?.id}
                                  {...row.getRowProps(row => {
                                    if (rowIndex == 0) {
                                      return {
                                        className: 'dark',
                                      };
                                    }
                                    if (
                                      rows[rowIndex - 1].original
                                        .streetAddress1 !=
                                      rows[rowIndex].original.streetAddress1
                                    ) {
                                      if (isDarkRow) {
                                        isDarkRow = false;
                                        return '';
                                      }
                                      isDarkRow = true;
                                      return {
                                        className: 'dark',
                                      };
                                    }
                                    // }
                                    return {
                                      className: isDarkRow ? 'dark' : '',
                                    };

                                    // //  if (rows[rowIndex - 1]) {
                                    // // if (rowIndex == 1) {

                                    // // }
                                    // console.log('rowRefs[rowIndex-1]', rowRefs[rowIndex - 1]);
                                    // console.log('rows[rowIndex]', rows[rowIndex]);
                                    // if (
                                    //   rows[rowIndex - 1].original.property != rows[rowIndex].original.property
                                    // ) {
                                    //   if (rows[rowIndex]._isdark) {
                                    //     rows[rowIndex]._isdark = false;
                                    //     return '';
                                    //   }
                                    //   rows[rowIndex]._isdark = true;
                                    //   return 'dark';
                                    // }
                                    // // }
                                    // return rows[rowIndex - 1]._isdark ? 'dark' : '';
                                    return {
                                      className: '',
                                    };
                                  })}
                                >
                                  {// Loop over the rows cells
                                    row.cells.map((cell, tdIndex) => {
                                      if (cell.isRowSpanned) return null;
                                      const title =
                                        cell?.value?.length > 200
                                          ? cell.value
                                          : null;
                                      const isRedFlagColumn =
                                        cell?.column?.Header === 'Red Flag';
                                      const dataForProps = Object.keys(
                                        cell.row.original
                                      ).reduce((acc, key) => {
                                        if (cell.row.original[key]) {
                                          acc[key.replace(/_hidden$/, '')] =
                                            cell.row.original[key];
                                        }
                                        return acc;
                                      }, {});

                                      // const rowSpanNumber = getRowSpanNumber(
                                      //   tdIndex,
                                      //   rowIndex,
                                      //   cell
                                      // );
                                      // const isRepeatedCell = getIsRepeatedCell(
                                      //   tdIndex,
                                      //   rowIndex,
                                      //   cell,
                                      //   Number(rowSpanNumber)
                                      // );
                                      // if (isRepeatedCell) {
                                      //   return null;
                                      // }

                                      return (
                                        <td
                                          rowSpan={cell.rowSpan}
                                          // rowSpan={rowSpanNumber}
                                          key={`${tdIndex}_${cell?.row?.id}_${cell?.value}`}
                                          style={{
                                            whiteSpace:
                                              cell?.value?.length > 50
                                                ? 'inherit'
                                                : 'nowrap',
                                          }}
                                          // className={`${
                                          //   Number(rowSpanNumber) > 1
                                          //     ? 'align-top'
                                          //     : 'align-middle'
                                          // }`}
                                          className={
                                            cell.rowSpan > 1
                                              ? 'align-top custom-padding-top'
                                              : ''
                                          }
                                          {...cell.getCellProps()}
                                          title={title}

                                        >
                                          
                                          {cell.column.id == 'minutesSinceMinThresholdRange' && cell.value >= 120 ?


                                            <TippyTooltip
                                              // options
                                              html={
                                                <div className="text-left">                                                  
                                                  The temperature has not dipped below the 63-degree threshold within {cell.render('Cell')} minutes. While this may raise concerns, it is only classified as an "Alert" during the hours of 10am to 4pm EST.
                                                </div>
                                              }
                                              position="bottom"
                                              interactive

                                              theme="light"
                                              style={{ cursor: 'pointer', textDecoration: 'underline', textDecorationStyle: 'dotted', color: 'orange' }}
                                            >
                                              {cell.render('Cell')}
                                            </TippyTooltip>
                                            :
                                            <>{cell.render('Cell')}</>
                                          }
                                        </td>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </BTable>
                    )}
                  </div>
                </>
              );
            }}
          </ReactTable>
          {/* <div className="d-flex my-2">
            <div id="welcome-email-period" className="mr-2 px-2">
              <span className="small text-muted">
                {isUpcomingCheckins
                  ? 'Welcome Email Period'
                  : 'Finalization Period'}
              </span>
            </div>
            <div id="early-checkin-checkout">
              <span className="small">Early Check In / Late Check Out</span>
            </div>
          </div> */}
          {hvacAlertData && (
            <Modal
              title={'Temperature Notification'}
              isOpen={hvacAlertData ? true : false}
              onClose={() => setHvacAlertData(null)}
              disableFocusTrap={true}
              styleType="large"
            >
              <HvacAlertDisplay hvacAlertData={hvacAlertData} />
            </Modal>
          )}
          <Modal
            title={`Guest Reservation ${guestData
                ? `(${guestData?.firstName} ${guestData?.lastName})`
                : ``
              }`}
            isOpen={selectedReservation ? true : false}
            onClose={() => {
              setSelectedReservation(null);
            }}
            disableForceFocus={true}
            styleType="large"
          >
            <GuestReservationForm
              onDataLoad={guestReservationData => {
                setGuestData(guestReservationData?.guest);
              }}
              reference={selectedReservation}
            />
          </Modal>
          {selectedPropertyHVAC && (
            <Modal
              title={`Global Thresholds`}
              isOpen={selectedPropertyHVAC ? true : false}
              onClose={() => {
                setSelectedPropertyHVAC(null);
              }}
              disableForceFocus={true}
            >
              <CustomThresholds hvacData={selectedPropertyHVAC} />
            </Modal>
          )}
        </section>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '80vh' }}
        >
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
export default LogReportTable;
