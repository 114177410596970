import React, { useState } from 'react';
import moment from 'moment';
import Layout from '../../../layouts';
import ScrollTable from '../../../components/ScrollTable';
import Query from '../../../components/GraphQL/components/Query';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PropertyDetailsModal from '../../properties/components/PropertyDetailsModal';
import { Info } from 'react-feather';
import Tooltip from 'src/components/Tooltip';
import { Helmet } from 'react-helmet';

import gql from 'graphql-tag';

const REPORT = gql`
  {
    propertyVacancies {
      propertyId
      start
      end
      nightsAvailable
      minStay
      property {
        propertyListings {
          platform {
            key
          }
          urlId
        }
        id
        name
        label
        key
        type
      }
    }
  }
`;

const OccupancyAnalyzer = () => {
  const [targetProperty, setTargetProperty] = useState(null);
  const [propertyDetailModalIsOpen, setPropertyDetailModalIsOpen] = useState(
    false
  );
  return (
    <Layout>
      <Query query={REPORT} fetchPolicy="no-cache">
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className="container pt-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            );
          if (error) return <p className="mt-2">{error.message}</p>;

          return (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{`BHIP - Occupancy Analyzer`}</title>
              </Helmet>
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col">
                    <div className="d-flex align-items-center mb-3">
                      <h1 className="mr-2 mb-1">Occupancy Analyzer</h1>
                    </div>
                    <h3>
                      Vacancy Alerts{' '}
                      <Tooltip
                        label={
                          '3 or more nights available with Min Stay set too high.'
                        }
                        placement="top"
                        id={`alert-tooltip`}
                      >
                        <Info
                          width={17}
                          height={17}
                          className="feather-info info info-icon"
                        />
                      </Tooltip>
                    </h3>
                    <div
                      className="card pl-3 pr-3 d-block"
                      style={{ maxWidth: '1100px' }}
                    >
                      <ScrollTable>
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="align-middle font-weight-bold"
                              >
                                Property
                              </th>
                              <td
                                scope="col"
                                className="align-middle text-center"
                              >
                                Dates
                              </td>
                              <td
                                scope="col"
                                className="align-middle text-center"
                              >
                                Min Stay
                              </td>
                              <td
                                scope="col"
                                className="align-middle text-center"
                              >
                                Nights Available
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data.propertyVacancies &&
                              data.propertyVacancies.map((item, index) => {
                                const homeAwayPlatform = item?.property?.propertyListings.find(
                                  listing =>
                                    listing.platform?.key === 'homeaway'
                                );

                                return (
                                  <tr key={index}>
                                    <th
                                      scope="row"
                                      className="align-middle font-weight-normal"
                                    >
                                      <div className="d-flex align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-link p-0 m-0 d-flex align-items-center"
                                          onClick={() => {
                                            // onIconClick({ ...property, id: Number(id) });
                                            setTargetProperty(item.property);
                                            setPropertyDetailModalIsOpen(true);
                                          }}
                                        >
                                          <Info
                                            width={17}
                                            height={17}
                                            className="feather-info info info-icon"
                                          />
                                        </button>
                                        <a
                                          target="_blank"
                                          href={`https://www.vrbo.com/pxcalendars/rates/${homeAwayPlatform?.urlId}`}
                                        >
                                          <span
                                            style={{ verticalAlign: '-1px' }}
                                            id={`property-tooltip-${index}`}
                                            title={item.property.name}
                                          >
                                            {item.property.label}
                                          </span>
                                        </a>
                                      </div>
                                    </th>
                                    <td className="text-center">
                                      {moment(item.start)
                                        .utc()
                                        .format('MM/DD/YYYY')}{' '}
                                      -{' '}
                                      {moment(item.end)
                                        .utc()
                                        .format('MM/DD/YYYY')}
                                    </td>
                                    <td className="text-center">
                                      {item.minStay}
                                    </td>
                                    <td className="text-center align-middle">
                                      {item.nightsAvailable}
                                    </td>
                                  </tr>
                                );
                              })}
                            {data.propertyVacancies.length === 0 && (
                              <tr>
                                <td colSpan="5" align="center">
                                  No Alerts
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </ScrollTable>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Query>
      {targetProperty && (
        <PropertyDetailsModal
          onResetId={() => setTargetProperty(false)}
          propertyId={targetProperty.id}
        />
      )}
    </Layout>
  );
};

export default OccupancyAnalyzer;
