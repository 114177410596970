import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import StarImg from '../../../../images/star.svg';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { AlertTriangle, Check } from 'react-feather';

export const StarIcons = ({ numberOfStars }) => {
  return Array.from({ length: Number(numberOfStars) }).map((_, index) => (
    <img key={index} width="20" src={StarImg} alt="Star" />
  ));
};

export const GuestReviewFeaturedCheckbox = props => {
  const [siteStatus, setSiteStatus] = useState(props.siteStatus);
  const UPDATE_REVIEW_MUTATION = gql`
    mutation updateGuestReservationReview(
      $id: Int
      $siteStatus: ReviewSiteStatusOption
    ) {
      updateGuestReservationReview(
        where: { id: $id }
        data: { siteStatus: $siteStatus }
      ) {
        id
        siteStatus
      }
    }
  `;
  const [updateReview, { loading: updateLoading }] = useMutation(UPDATE_REVIEW_MUTATION);
  
  const updateStatus = async () => {
    const newValue = siteStatus === 'FEATURED' ? 'UNFEATURED' : 'FEATURED';
    const result = await updateReview({
      variables: { id: props.reviewId, siteStatus: newValue },
    });
    console.log('result', result);
    setSiteStatus(newValue);
  };
  
  if (updateLoading) {
    return <LoadingSpinner customDimensions={{ width: '20px', height: '20px' }} />;
  }
  
  return (
    <form>
      <div className="form-group mb-1">
        <div className="custom-control custom-checkbox">
          <input
            name="siteStatus"
            id={`checkbox_${props.reviewId}`}
            className="custom-control-input form-control"
            type="checkbox"
            checked={siteStatus === 'FEATURED'}
            onChange={updateStatus}
          />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor={`checkbox_${props.reviewId}`}
          ></label>
        </div>
      </div>
    </form>
  );
};

export const AlertIcon = ({ value }) => {
  return value === 'Y' ? (
    <AlertTriangle className="text-warning" width={20} height={20} />
  ) : (
    value
  );
};

export const CheckMarkIcon = ({ value }) => {
  return value === 'Y' ? (
    <Check width={20} height={20} className="check-icon" />
  ) : (
    value
  );
};