import React from 'react';
import { Link } from 'gatsby';
import Markdown from 'react-markdown';
import withLocation from 'src/components/WithLocation'
import  './styles.scss'
function hilightWords(replaceString, find) {
  //console.log('replaceString', replaceString)
  var regex; 
  for (var i = 0; i < find.length; i++) {
 //   console.log('find[i]', find[i])
    regex = new RegExp(' ' + find[i] + ' ', "gi");
    replaceString = replaceString.replace(regex, ' **`' + find[i] + '`** ' );
  //  console.log('replaceString', replaceString)
  }
  return replaceString;
};

const GuestsInnerTable = ({ notes, count, location }) => {
  const params = new URLSearchParams(location.search);
  console.log('location', params.get('search'))

  return (
    <>

      <thead>
        <tr>
          <td className="font-weight-bold"   >
            Note
          </td>
          <td className="font-weight-bold text-center ">
            Created At
          </td>
          <td className="font-weight-bold text-center ">
             Name
          </td>
          <td className="font-weight-bold text-center ">
             Expert
          </td>

          <td className="font-weight-bold text-center">Block</td>
       
        </tr>
      </thead>
      <tbody>
        {count === 0 && (
          <tr>
            <td colSpan="5" className="no-leads-heading mt-2">
              No notes to display
            </td>
          </tr>
        )}
        {notes &&
          notes.map((note, index) => (
            <tr
              key={`note-${index + 1}`}
            >
              <td width="25%"><Markdown className="text-xs"  >{params.get('search') ? hilightWords(note.note, (params.get('search') || '').split(' ')) : note.note}</Markdown></td>
              <td className="text-center text-nowrap">{note.createdAt}</td>
              <td className="text-center"><a target="_blank" href={`/guests/${note.guest.id}`}>{note.guest.firstName} {note.guest.lastName}</a></td>
              <td className="text-center text-nowrap">{note.creator?.userName}</td>
 
              <td className="text-center">
                {note.guest.blockType != 'NONE' && note.guest.blockType}
              </td>
          
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default withLocation(GuestsInnerTable);
