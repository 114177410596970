import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import FormResponse from 'src/components/Forms/components/FormResponse';
import * as Yup from 'yup';
import Modal from 'src/components/Modal';
import get from 'lodash.get';

const validationSchema = Yup.object().shape({
  to: Yup.string()
    .email('Please provide a valid email address')
    .required('Please provide a valid email address'),
});

const EsignIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Symbol_32_2"
      data-name="Symbol 32 – 2"
      transform="translate(-985 -194)"
    >
      <rect
        id="Rectangle_386"
        data-name="Rectangle 386"
        width="20"
        height="20"
        rx="2"
        transform="translate(985 194)"
        fill="#d1093a"
      />
      <path
        id="Path_4551"
        data-name="Path 4551"
        d="M80.15,66.194c-.571-1.155-2.628-1.513-4.235-1.606-.465-.027-.969-.027-1.487-.013a31.017,31.017,0,0,1-2.96-3.491c-.027-.04-.066-.093-.093-.133.027-.093.066-.2.093-.292.743-2.31,1.1-4.367.584-5.31a2.866,2.866,0,0,0-.584-.73,1.435,1.435,0,0,0-1.527-.265c-1.049.438-1.752,2.27-.982,4.779a8.717,8.717,0,0,0,.956,1.991,39.99,39.99,0,0,1-1.938,4.553,8.963,8.963,0,0,0-4.712,3.159c-.8,1.235-.106,3.04,1.181,3.04.611,0,1.606-.385,3.252-2.987.372-.6.783-1.314,1.195-2.124a17.747,17.747,0,0,1,2.575-.584,18.93,18.93,0,0,1,2.416-.265c.929.929,1.619,1.54,1.7,1.619a4.383,4.383,0,0,0,2.934,1.221A1.948,1.948,0,0,0,80.15,66.194ZM66.61,68.106c-1.487,2.363-2.151,2.443-2.151,2.443s-.066-.04-.133-.186a.775.775,0,0,1-.08-.345.9.9,0,0,1,.133-.451,6.14,6.14,0,0,1,2.6-2.058C66.863,67.708,66.743,67.907,66.61,68.106Zm3.863-12.531a.013.013,0,0,1,.013-.013c.08,0,.239.146.4.438.252.438.066,1.726-.412,3.4a5.013,5.013,0,0,1-.252-.65C69.637,56.836,70.168,55.708,70.473,55.575Zm1,9.266c-.611.093-1.208.212-1.805.358.412-.889.81-1.819,1.168-2.748l.637.8c.412.5.836.982,1.248,1.434C72.3,64.721,71.88,64.774,71.469,64.84Zm6.9,2.6a3.318,3.318,0,0,1-1.9-.9c-.013,0-.265-.239-.677-.624h.04c2.031.119,2.987.584,3.12.876C79.022,66.911,78.77,67.389,78.371,67.442Z"
        transform="translate(923.075 140.76)"
        fill="#fff"
      />
    </g>
  </svg>
);

const SEND_GUEST_EMAIL = gql`
  mutation SendGuestEmail($to: String!, $message: String!) {
    sendGuestEmail(
      data: {
        to: $to
        subject: "Beach Houses in Paradise Lease Agreement"
        message: $message
      }
    ) {
      messageId
    }
  }
`;

const CopyToClipboardForm = ({ esignUrl }) => {
  return (
    <>
      Location of the agreement the guest must digitally sign:
      <div className="form-group">
        <input
          className="form-control"
          type="textarea"
          name="text"
          id="esignUrl"
          value={esignUrl}
          readOnly
          style={{ height: '108px' }}
        />
      </div>
      <CopyToClipboard text={esignUrl}>
        <button
          type="button"
          color=""
          className="w-100 mt-2 btn btn-outline-primary"
        >
          Copy to Clipboard
        </button>
      </CopyToClipboard>
    </>
  );
};

const Textarea = props => {
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        return (
          <textarea
            className="form-control"
            type="textarea"
            {...props}
            value={field.value}
            onChange={event => {
              form.setFieldValue(props.name, event.target.value);
            }}
          />
        );
      }}
    </Field>
  );
};

const ESIGN_EMAIL_TEMPLATE = `Thank you for your recent reservation with Beach Houses in Paradise.

Please review and agree to the standard terms & conditions for your reservation so that I can finalize your reservation.

The agreement describes the duties and responsibilities of both Beach Houses in Paradise and yourself (and your party):

<a href="\${propertyEsignUrl}">\${propertyEsignUrl}</a>

If you have any questions, please contact us at any time at <a href="mailto:booking@bhipmanagement.com">booking@bhipmanagement.com</a>.

Warm Regards & Happy Travels,

Al Roberts, Property Manager
Beach Houses in Paradise
`;

const Esign = ({ user, children, property, ...rest }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [leaseAgreement, setLeaseAgreement] = useState({
    name: '',
    id: '',
  });

  const fillTemplate = function(templateString, templateVars) {
    var func = new Function(
      ...Object.keys(templateVars),
      'return `' + templateString + '`;'
    );
    return func(...Object.values(templateVars));
  };
  const tokenTransformObject = {
    propertyName: property.name,
    propertyAddress: property.addressDisplay,
  };
  tokenTransformObject.propertyEsignUrl = fillTemplate(
    property.esignUrl,
    tokenTransformObject
  );

  const emailMessageText = fillTemplate(
    ESIGN_EMAIL_TEMPLATE,
    tokenTransformObject
  );

  return (
    <>
      <Mutation mutation={SEND_GUEST_EMAIL}>
        {(sendGuestEmail, { data = {}, error, loading }) => (
          <>
            {user.role === 'ANONYMOUS' ? (
              <a href="/login">{children || <EsignIcon />}</a>
            ) : (
              <a
                href="javascript:void(0);"
                onClick={() => setModalIsOpen(true)}
              >
                {children || <EsignIcon />}
              </a>
            )}
            <Modal
              styleType=""
              onClose={() => setModalIsOpen(false)}
              isOpen={modalIsOpen}
              title={
                sendGuestEmail ? (
                  <>Agreement Sent</>
                ) : (
                  <>{property.name} Lease Agreement</>
                )
              }
            >
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                  to: '',
                  message: emailMessageText,
                  copied: false,
                }}
                onSubmit={(
                  values,
                  { setSubmitting, setStatus, setErrors, setFieldError }
                ) => {
                  sendGuestEmail({
                    variables: {
                      to: values.to,
                      message: values.message,
                    },
                  }).catch(err => err);
                }}
              >
                {formikProps => {
                  const {
                    touched,
                    errors,
                    handleSubmit,
                    values,
                    setFieldValue,
                    handleChange,
                    submitCount,
                    status,
                  } = formikProps;
                  const { sendGuestEmail } = data;
                  return sendGuestEmail ? (
                    <CopyToClipboardForm
                      esignUrl={tokenTransformObject.propertyEsignUrl}
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Recipient:</label>
                        <input
                          className="form-control"
                          name="to"
                          type="input"
                          placeholder="guest@domain.com"
                          onChange={handleChange}
                          //   invalid={!!(touched.to && errors.to)}
                          //   tag={Field}
                        />

                        {!!(touched.to && errors.to) && (
                          <div className="text-danger">{errors.to}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Email:</label>
                        <Textarea name="message" rows="18" />
                      </div>

                      <FormResponse
                        errors={get(status, 'error')}
                        isVisible={
                          get(status, 'error') ||
                          get(status, 'success') ||
                          (Object.keys(errors).length > 0 && submitCount > 0)
                        }
                        alert={
                          Object.keys(errors).length > 0 && submitCount > 0
                        }
                      >
                        {get(status, 'success')}
                      </FormResponse>
                      <ThemedButton
                        loading={loading}
                        type="submit"
                        className="w-100 mt-2 btn btn-outline-primary"
                      >
                        Send
                      </ThemedButton>
                      <div className="text-center text-muted mt-1 mb-1">or</div>
                      {values.copied && (
                        <div className="w-100 alert alert-success">
                          Copied to Clipboard
                        </div>
                      )}
                      <CopyToClipboard
                        text={tokenTransformObject.propertyEsignUrl}
                        onCopy={() => {
                          setFieldValue('copied', true);
                          setTimeout(
                            () => setFieldValue('copied', false),
                            2500
                          );
                        }}
                      >
                        <ThemedButton
                          type="button"
                          className="w-100 btn btn-outline-primary"
                        >
                          Copy URL to Clipboard
                        </ThemedButton>
                      </CopyToClipboard>
                    </form>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </Mutation>
    </>
  );
};

export default Esign;
