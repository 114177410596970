import React, { useEffect, useState } from 'react';
import { enumToLabel } from 'src/util/index';
import { Link } from 'gatsby';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { Autosave, useAutosave } from 'react-autosave';

const getSceneLabel = value => {
  if (value === 'CHECK_IN' || value === 'CHECK_OUT') {
    return value === 'CHECK_IN' ? `Check-in` : 'Check-out';
  }
  return `${enumToLabel(value)}`;
};

const CREATE_EXCEPTION = gql`
  mutation CreatePropertySensorAlertException(
    $uniqueGroupKey: String!
    $exceptionExpirationDate: Date!
    $notes: String
  ) {
    createPropertySensorAlertException(
      data: {
        uniqueGroupKey: $uniqueGroupKey
        exceptionExpirationDate: $expiration
        notes: $notes
      }
    ) {
      id
      uniqueGroupKey
    }
  }
`;

const UPDATE_EXCEPTION_NOTES = gql`
  mutation UpdatePropertySensorAlertExceptionNotes(
    $uniqueGroupKey: String!
    $notes: String!
  ) {
    updatePropertySensorAlertExceptionNotes(
      where: { uniqueGroupKey: $uniqueGroupKey }
      data: { notes: $notes }
    ) {
      id
      uniqueGroupKey
    }
  }
`;

const DELETE_EXCEPTION = gql`
  mutation DeletePropertySensorAlertException($uniqueGroupKey: String!) {
    deletePropertySensorAlertException(
      data: { uniqueGroupKey: $uniqueGroupKey }
    ) {
      error
    }
  }
`;

const HvacAlertDisplay = ({ hvacAlertData, onSuccess = () => {} }) => {
  console.log('hvacAlertData', hvacAlertData);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState(hvacAlertData.exception?.notes);
  const [newNotes, setNewNotes] = useState();
  const [noteSavedTime, setNoteSavedTime] = useState();
  const [
    createException,
    { data, loading: updateLoading, error },
  ] = useMutation(CREATE_EXCEPTION);

  const [
    updateExceptionNotes,
    {
      data: updateNotesData,
      loading: updateNotesLoading,
      error: updateNotesError,
    },
  ] = useMutation(UPDATE_EXCEPTION_NOTES);
  const updateExceptionNotesCallback = React.useCallback(
    data => {
      //  axios.post('myapi/blog/123', { text: newText }).catch(console.error);

      if (data.variables.notes != notes) {
        console.log('UPDATING');
        updateExceptionNotes(data);
      }
    },
    [notes]
  );

  const [
    deleteException,
    { data: deleteMutationPayload, loading: deleteLoading },
  ] = useMutation(DELETE_EXCEPTION);
  useEffect(() => {
    if (data) {
      onSuccess(data);
    }
  }, [data]);
  useEffect(() => {
    if (deleteMutationPayload) {
      onSuccess(true);
    }
  }, [deleteMutationPayload]);
  useEffect(() => {
    setIsLoading(updateLoading);
  }, [updateLoading]);
  useEffect(() => {
    if (updateNotesData) {
      setNoteSavedTime(
        new Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(
          new Date()
        )
      );
    }
  }, [updateNotesData]);
  useEffect(() => {
    setIsLoading(deleteLoading);
  }, [deleteLoading]);
  // useAutosave({
  //   data: {
  //     variables: {
  //       notes: notes,
  //       uniqueGroupKey: hvacAlertData.uniqueGroupKey,
  //     },
  //   },
  //   onSave: updateNotes,
  // });

  const handleNotesChange = e => {
    console.log('e', e.target.value);
    if (e.target.value) {
      setNotes(e.target.value); // TODO: Autosave functionality. https://www.npmjs.com/package/react-autosave
    }
  };

  if (isLoading) {
    return (
      <div className="container py-3">
        <div className="d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  if (data || deleteMutationPayload) {
    return (
      <div className="container py-3">
        <div className="d-flex justify-content-center align-items-center">
          <div className="w-100 alert alert-success">
            Your changes have been saved!
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>
        <p>
          Property:{' '}
          <span className="font-weight-bold">
            {hvacAlertData.streetAddress1}
          </span>
        </p>
      </div>
      <div>
        <p>
          Date/Time:{' '}
          <span className="font-weight-bold">
            {moment(hvacAlertData.createdAt).format('YYYY-MM-DD-hh:mmA')}
          </span>
        </p>
      </div>
      <div>
        <p>
          Device: <span className="font-weight-bold">{hvacAlertData.name}</span>
        </p>
      </div>
      {hvacAlertData.propertyOccupancyStatus && (
        <div>
          <p>
            Scene:{' '}
            <span className="font-weight-bold">
              {getSceneLabel(hvacAlertData.propertyOccupancyStatus)}
            </span>
          </p>
        </div>
      )}
      <div>
        <p>
          Alert:{' '}
          <span className="font-weight-bold">{hvacAlertData.summary}</span>
        </p>
      </div>
      <div>
        <div>
          Snooze:
          <div>
            {hvacAlertData.exception ? (
              <>
                <span>
                  <span style={{ color: 'green' }}>Enabled</span> (created by:{' '}
                  {hvacAlertData.exception.employee.userName} @{' '}
                  {new Intl.DateTimeFormat('en', {
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                  }).format(new Date(hvacAlertData.exception.createdAt))}
                  )
                </span>
                <div className="pb-1">
                  Expires in{' '}
                  <span className="font-weight-bold">
                    {moment
                      .duration(
                        moment(hvacAlertData.exception.expiration).diff(
                          moment(),
                          'minutes'
                        ),
                        'minutes'
                      )
                      .humanize()}
                  </span>{' '}
                  (
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    tabindex="-1"
                    onClick={async () => {
                      const response = await deleteException({
                        variables: {
                          uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                        },
                      });
                      console.log('response', response);
                    }}
                  >
                    Delete
                  </button>
                  )
                </div>
                {notes ? (
                  <div className="pb-1">
                    <div className="form-group">
                      <label>
                        {noteSavedTime && (
                          <span
                            style={{ fontWeight: 'normal', color: 'green' }}
                          >
                            Auto saved: {noteSavedTime}{' '}
                          </span>
                        )}
                      </label>
                      <textarea
                        style={{ minHeight: '100px' }}
                        placeholder="Notes"
                        className="form-control"
                        type="textarea"
                        onChange={handleNotesChange}
                        value={notes}
                        // invalid={
                        //   errors.description && touched.description
                        // }
                        name="notes"
                      />
                      <Autosave
                        data={{
                          variables: {
                            notes: notes,
                            uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                          },
                        }}
                        onSave={updateExceptionNotesCallback}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {' '}
                <div className="pb-1">
                  <div className="form-group">
                    <textarea
                      style={{ minHeight: '100px' }}
                      className="form-control"
                      placeholder="Notes"
                      type="textarea"
                      onChange={e => {
                        setNewNotes(e.target.value);
                      }}
                      value={newNotes}
                      // invalid={
                      //   errors.description && touched.description
                      // }
                      name="newNotes"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="d-flex pb-2">
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                tabindex="-1"
                onClick={async () => {
                  if (!newNotes) {
                    alert('Notes Required');
                    return;
                  }
                  const response = await createException({
                    variables: {
                      uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                      notes: newNotes,
                      exceptionExpirationDate: moment().add('hour', 1),
                    },
                  });
                  console.log('response', response);
                }}
              >
                1 hour
              </button>
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                tabindex="-1"
                onClick={async () => {
                  if (!newNotes) {
                    alert('Notes Required');
                    return;
                  }
                  const response = await createException({
                    variables: {
                      uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                      notes: newNotes,
                      exceptionExpirationDate: moment().add('hour', 8),
                    },
                  });
                  console.log('response', response);
                }}
              >
                8 hours
              </button>
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                tabindex="-1"
                onClick={async () => {
                  if (!newNotes) {
                    alert('Notes Required');
                    return;
                  }
                  const response = await createException({
                    variables: {
                      uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                      notes: newNotes,
                      exceptionExpirationDate: moment().add('hour', 24),
                    },
                  });
                  console.log('response', response);
                }}
              >
                24 hours
              </button>
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                tabindex="-1"
                onClick={async () => {
                  if (!newNotes) {
                    alert('Notes Required');
                    return;
                  }
                  const response = await createException({
                    variables: {
                      uniqueGroupKey: hvacAlertData.uniqueGroupKey,
                      notes: newNotes,
                      exceptionExpirationDate: moment().add('week', 1),
                    },
                  });
                  console.log('response', response);
                }}
              >
                1 week
              </button>
            </div>
          </div>
        </div>
      </div>
      {hvacAlertData.guestReservation && (
        <div>
          <p>
            Guest:{' '}
            <>
              <span>
                <u>
                  <Link
                    to={`/guests/${hvacAlertData.guestReservation?.guest?.id}`}
                  >
                    {hvacAlertData.guestReservation?.guest?.firstName}{' '}
                    {hvacAlertData.guestReservation?.guest?.lastName}
                  </Link>
                </u>
              </span>
              ,{' '}
              <span>
                Phone:{' '}
                {
                  hvacAlertData.guestReservation?.guest?.primaryPhoneNumber
                    ?.phoneNumber
                }
              </span>
              {
                <>
                  {hvacAlertData.guestReservation?.reference && (
                    <span>
                      , Reservation Id:{' '}
                      {hvacAlertData.guestReservation?.reference}
                    </span>
                  )}
                </>
              }
            </>
          </p>
        </div>
      )}
    </div>
  );
};
export default HvacAlertDisplay;
