import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
// import Checkbox from 'src/components/Theme/components/Checkbox'
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import get from 'lodash.get';
import gql from 'graphql-tag';
import FormResponse from 'src/components/Forms/components/FormResponse';
import { Info } from 'react-feather';
import Tooltip from 'src/components/Tooltip';

const UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $propertyWhereInput: PropertyWhereUniqueInput
    $propertyUpdateInput: PropertyUpdateInput!
  ) {
    updateProperty(data: $propertyUpdateInput, where: $propertyWhereInput) {
      id
    }
  }
`;

// const UPDATE_SITEPAGE = gql`
//   mutation updatePage(
//     $SitePageUpdateInput: SitePageUpdateInput
//     $slug: String!
//   ) {
//     updateSitePage(where: { slug: $slug }, data: $SitePageUpdateInput) {
//       title
//       featuredImage {
//         id
//       }
//     }
//   }
// `;

const validationSchema = Yup.object().shape({
  sleeps: Yup.number().required('Sleeps is required'),
  sleepsInBed: Yup.number().required('Sleeps in bed is required'),
  bedrooms: Yup.number().required('Bedrooms are required'),
  bedroomsSemiPrivate: Yup.number().required(
    'Bedrooms semi-private are required'
  ),
  bathrooms: Yup.number().required('Bathrooms are required'),
  bathroomsHalf: Yup.number().required('Bathrooms (Half) are required'),
  maxAdults: Yup.number().required('Adults is required'),
  maxAdultComfort: Yup.number()
    .required('Adults Comfort is required')
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      message: 'Adults Comfort cannot be more than total adults',
      test: function(value) {
        return value <= this.parent.maxAdults;
      },
    }),
  sqft: Yup.number().required('Square feet is required'),
  latitude: Yup.number().required('Latitude is required'),
  longitude: Yup.number().required('Longitude is required'),
});

const PropertyEditForm = ({ property, propertyAmenities }) => {
  // const matterportUrl = property?.virtualTours[0]?.url || '';
  // const youTubeFile = property?.sitePage?.featuredFileCollection?.files.find(
  //   file => {
  //     return file?.mimetype === 'video/youtube';
  //   }
  // );
  // const youtubeUrl = youTubeFile?.fullUrl || '';

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-12 col-lg-12 mt-3">
          <span className="d-flex align-items-center">
            <button
              onClick={() => window.history.back()}
              className="mr-1 p-0 btn"
            >
              <ArrowLeft className="align-top text-primary" size="30" />
            </button>
            <h1 className="d-inline-block mt-2">
              Edit {property.label} - {property.name}
            </h1>
          </span>
          <div className="card p-3 rounded mb-3">
            <Mutation mutation={UPDATE_PROPERTY}>
              {(updateProperty, { data, loading, error }) => (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    description: property.description
                      ? property.description
                      : '',
                    amenities: property?.amenities,
                    sqft: property?.sqft,
                    bedrooms: property?.bedrooms,
                    sleepsInBed: property?.sleepsInBed,
                    sleeps: property.sleeps ? property.sleeps : 0,
                    bedroomsPrivate: property?.bedroomsPrivate || 0,
                    bathrooms: property.bathrooms ? property.bathrooms : 0,
                    bathroomsHalf: property?.bathroomsHalf,
                    bedroomsSemiPrivate: property?.bedroomsSemiPrivate,
                    maxAdults: property?.maxAdults || 0,
                    maxAdultComfort: property?.maxAdultComfort || 0,
                    // maxTravelers: property?.maxTravelers,
                    children: property?.sleeps - property?.maxAdults || 0,
                    combo: property.type === 'COMBO' ? 'Y' : '',
                    esign: '',
                    mistingSystemOptionId: property?.mistingSystemOptionId,
                    latitude: property.latitude ? property.latitude : 0,
                    longitude: property.longitude ? property.longitude : 0,
                    slug: property?.sitePage?.slug || '',
                    bathroomsFull:
                      property?.bathrooms - property?.bathroomsHalf,
                    // matterportUrl,
                    // youtubeUrl,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (
                    values,
                    { setSubmitting, setErrors, setStatus }
                  ) => {
                    const {
                      // description,
                      sqft,
                      // amenities,
                      sleeps,
                      // bedroomsPrivate,
                      bedrooms,
                      bedroomsSemiPrivate,
                      bathrooms,
                      bathroomsHalf,
                      maxAdults,
                      maxAdultComfort,
                      // children,
                      // combo,
                      // esign,
                      mistingSystemOptionId,
                      latitude,
                      longitude,
                      sleepsInBed,
                    } = values;
                    const propertyUpdateInput = {
                      // description,
                      // amenities,
                      sqft,
                      sleeps,
                      sleepsInBed,
                      // bedroomsPrivate,
                      bedrooms,
                      bedroomsSemiPrivate,
                      bathrooms,
                      bathroomsHalf,
                      maxAdults,
                      maxAdultComfort,
                      mistingSystemOptionId: mistingSystemOptionId
                        ? Number(mistingSystemOptionId)
                        : null,
                      // children,
                      // combo,
                      // esign,
                      latitude,
                      longitude,
                    };

                    const propertyWhereInput = {
                      id: property?.id,
                      key: property?.key,
                    };
                    // const SitePageUpdateInput = {};

                    try {
                      const propertyResult = await updateProperty({
                        variables: {
                          propertyUpdateInput,
                          propertyWhereInput,
                        },
                      });
                      // const sitePageResult = await updateSitePage({
                      //   variables: {
                      //     SitePageUpdateInput: {
                      //       featuredSiteFileCollectionId: newFileCollectionId,
                      //     },
                      //     slug,
                      //   },
                      // });
                      if (propertyResult) {
                        setStatus({ success: true });
                      }
                    } catch (err) {
                      console.log('err', err);
                      setStatus({ error: err });
                    }
                  }}
                >
                  {formikProps => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      submitCount,
                      status,
                    } = formikProps;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col col-12 col-lg-4">
                            {/* <div className="form-group">
                              <label>Description</label>
                              <textarea
                                style={{ minHeight: '300px' }}
                                className="form-control"
                                type="textarea"
                                onChange={handleChange}
                                value={values.description}
                                invalid={
                                  errors.description && touched.description
                                }
                                name="description"
                              />
                            </div> */}
                            <div className="form-group">
                              <label>Amenities</label>
                              <div className="row d-justify-content-between px-3">
                                {propertyAmenities.map(amenity => {
                                  return (
                                    <div
                                      className="col col-12 col-sm-6 custom-control custom-checkbox"
                                      key={amenity.key}
                                    >
                                      <input
                                        disabled={true}
                                        checked={values?.amenities.find(
                                          amenityValue =>
                                            amenityValue.key === amenity.key
                                        )}
                                        type="checkbox"
                                        className="custom-control-input form-control"
                                        id={amenity.key}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="d-block custom-control-label"
                                        htmlFor={amenity.key}
                                      >
                                        {amenity.name}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col col-12 col-lg-8">
                            <div className="d-sm-block d-md-flex justify-content-between">
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label>Sleeps</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'sleeps',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const sleepsMinusAdults =
                                      Number(e.target.value) -
                                      values?.maxAdults;
                                    const children =
                                      sleepsMinusAdults > 0
                                        ? sleepsMinusAdults
                                        : 0;
                                    setFieldValue('children', children);
                                    setFieldValue(
                                      'maxAdults',
                                      Number(e.target.value) - children
                                    );
                                  }}
                                  value={values.sleeps}
                                  name="sleeps"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.sleeps &&
                                    errors.sleeps &&
                                    errors.sleeps}
                                </div>
                              </div>
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label>Sleeps In Bed</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  className="form-control"
                                  type="number"
                                  onChange={handleChange}
                                  value={values.sleepsInBed}
                                  name="sleepsInBed"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.sleepsInBed &&
                                    errors.sleepsInBed &&
                                    errors.sleepsInBed}
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-block d-md-flex justify-content-between">
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label>Bedrooms</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.bedrooms}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'bedrooms',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const totalMinusSemiPrivateBedrooms =
                                      Number(e.target.value) -
                                      values?.bedroomsSemiPrivate;
                                    const bedroomsPrivate =
                                      totalMinusSemiPrivateBedrooms > 0
                                        ? totalMinusSemiPrivateBedrooms
                                        : 0;
                                    setFieldValue(
                                      'bedroomsPrivate',
                                      bedroomsPrivate
                                    );
                                    setFieldValue(
                                      'bedroomsSemiPrivate',
                                      Number(e.target.value) - bedroomsPrivate
                                    );
                                  }}
                                  // invalid={errors.bedrooms && touched.bedrooms}
                                  name="bedrooms"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.bedrooms &&
                                    errors.bedrooms &&
                                    errors.bedrooms}
                                </div>
                              </div>
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label className="mr-1">
                                  Bedrooms (Private)
                                </label>

                                <Tooltip
                                  label={
                                    'Bedrooms (Private) is derived from subtracting the semi-private bedrooms from the total bedrooms value'
                                  }
                                  placement="top"
                                  id={`private-bathrooms-tooltip`}
                                  // hide={!isMenuCollapsed}
                                  // className="d-none"
                                >
                                  <Info
                                    width={17}
                                    className="feather-info info info-icon"
                                  />
                                </Tooltip>
                                <input
                                  readOnly
                                  className="form-control"
                                  type="number"
                                  onChange={handleChange}
                                  value={values.bedroomsPrivate}
                                  invalid={
                                    errors.bedroomsPrivate &&
                                    touched.bedroomsPrivate
                                  }
                                  name="bedroomsPrivate"
                                  // placeholder="with a placeholder"
                                />
                              </div>

                              <div className="form-group w-100">
                                <label>Bedrooms (Semi-Private)</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.bedroomsSemiPrivate}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'bedroomsSemiPrivate',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const totalMinusSemiPrivateBedrooms =
                                      values?.bedrooms - Number(e.target.value);
                                    const bedroomsPrivate =
                                      totalMinusSemiPrivateBedrooms > 0
                                        ? totalMinusSemiPrivateBedrooms
                                        : 0;
                                    setFieldValue(
                                      'bedroomsPrivate',
                                      bedroomsPrivate
                                    );
                                    if (
                                      bedroomsPrivate + Number(e.target.value) >
                                      values?.bedrooms
                                    ) {
                                      setFieldValue(
                                        'bedrooms',
                                        bedroomsPrivate + Number(e.target.value)
                                      );
                                    }
                                  }}
                                  name="bedroomsSemiPrivate"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.bedroomsSemiPrivate &&
                                    errors.bedroomsSemiPrivate &&
                                    errors.bedroomsSemiPrivate}
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-block d-md-flex justify-content-between">
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label>Bathrooms</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.bathrooms}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'bathrooms',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const totalMinusHalfBathrooms =
                                      Number(e.target.value) -
                                      values?.bedroomsSemiPrivate;
                                    const bathroomsFull =
                                      totalMinusHalfBathrooms > 0
                                        ? totalMinusHalfBathrooms
                                        : 0;
                                    setFieldValue(
                                      'bathroomsFull',
                                      bathroomsFull
                                    );
                                    setFieldValue(
                                      'bathroomsHalf',
                                      Number(e.target.value) - bathroomsFull
                                    );
                                  }}
                                  name="bathrooms"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.bathrooms &&
                                    errors.bathrooms &&
                                    errors.bathrooms}
                                </div>
                              </div>
                              <div className="form-group mr-0 mr-md-3 w-100">
                                <label className="mr-1">Bathrooms (Full)</label>
                                <Tooltip
                                  label={
                                    'Bathrooms (Full) is derived from subtracting the half-bathrooms from the total bathrooms value'
                                  }
                                  placement="top"
                                  readOnly
                                  id={`bathrooms-full-tooltip`}
                                  // hide={!isMenuCollapsed}
                                  // className="d-none"
                                >
                                  <Info
                                    width={17}
                                    className="feather-info info info-icon"
                                  />
                                </Tooltip>
                                <input
                                  readOnly
                                  value={values.bathroomsFull}
                                  className="form-control"
                                  type="number"
                                  onChange={handleChange}
                                  invalid={
                                    errors.bathroomsFull &&
                                    touched.bathroomsFull
                                  }
                                  name="bathroomsFull"
                                  // placeholder="with a placeholder"
                                />
                              </div>
                              <div className="form-group w-100">
                                <label>Bathrooms (Half)</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.bathroomsHalf}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'bathroomsHalf',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const totalMinusHalfBathrooms =
                                      values?.bathrooms -
                                      Number(e.target.value);
                                    const bathroomsFull =
                                      totalMinusHalfBathrooms > 0
                                        ? totalMinusHalfBathrooms
                                        : 0;
                                    setFieldValue(
                                      'bathroomsFull',
                                      bathroomsFull
                                    );
                                    setFieldValue(
                                      'bathrooms',
                                      Number(bathroomsFull) +
                                        Number(e.target.value)
                                    );
                                    if (
                                      Number(e.target.value) + bathroomsFull >
                                      values?.bathrooms
                                    ) {
                                      setFieldValue(
                                        'bathrooms',
                                        Number(e.target.value) + bathroomsFull
                                      );
                                    }
                                  }}
                                  name="bathroomsHalf"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.bathroomsHalf &&
                                    errors.bathroomsHalf &&
                                    errors.bathroomsHalf}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="form-group w-100 mr-3">
                                <label>Adults</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.maxAdults}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    setFieldValue(
                                      'maxAdults',
                                      e.target.value
                                        ? Number(e.target.value)
                                        : ''
                                    );
                                    const sleepsAdultsDifference =
                                      values?.sleeps - Number(e.target.value);
                                    const children =
                                      sleepsAdultsDifference > 0
                                        ? sleepsAdultsDifference
                                        : 0;

                                    setFieldValue('children', children);

                                    if (
                                      Number(e.target.value) + children >
                                      Number(values?.sleeps)
                                    ) {
                                      setFieldValue(
                                        'sleeps',
                                        Number(e.target.value) +
                                          Number(children)
                                      );
                                    }
                                  }}
                                  name="maxAdults"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.maxAdults &&
                                    errors.maxAdults &&
                                    errors.maxAdults}
                                </div>
                              </div>
                              <div className="form-group w-100 mr-3">
                                <label>Adults Comfort</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.maxAdultComfort}
                                  className="form-control"
                                  type="number"
                                  // onChange={handleChange}
                                  onChange={e => {
                                    const targetValue = e.target.value
                                      ? Number(e.target.value)
                                      : '';
                                    setFieldValue(
                                      'maxAdultComfort',
                                      targetValue
                                    );

                                    // const sleepsAdultsDifference =
                                    //   values?.sleeps - Number(e.target.value);
                                    // const children =
                                    //   sleepsAdultsDifference > 0
                                    //     ? sleepsAdultsDifference
                                    //     : 0;

                                    // setFieldValue('children', children);

                                    // if (
                                    //   Number(e.target.value) + children >
                                    //   Number(values?.sleeps)
                                    // ) {
                                    //   setFieldValue(
                                    //     'sleeps',
                                    //     Number(e.target.value) +
                                    //       Number(children)
                                    //   );
                                    // }
                                  }}
                                  name="maxAdultComfort "
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.maxAdultComfort &&
                                    errors.maxAdultComfort &&
                                    errors.maxAdultComfort}
                                </div>
                              </div>
                              <div className="form-group w-100">
                                <label className="mr-1">Children</label>
                                <Tooltip
                                  label={
                                    'Children is derived from subtracting the total adults from the total sleeps value'
                                  }
                                  placement="top"
                                  id={`children-tooltip`}
                                  // hide={!isMenuCollapsed}
                                  // className="d-none"
                                >
                                  <Info
                                    width={17}
                                    className="feather-info info info-icon"
                                  />
                                </Tooltip>
                                <input
                                  readOnly
                                  className="form-control"
                                  type="number"
                                  value={values.children}
                                  onChange={handleChange}
                                  invalid={errors.children && touched.children}
                                  name="children"
                                  // placeholder="with a placeholder"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Is Combo</label>
                              <select
                                readOnly
                                value={values.combo}
                                className="form-control"
                                type="select"
                                onChange={handleChange}
                                onChange={handleChange}
                                value={values.combo}
                                invalid={errors.combo && touched.combo}
                                name="combo"
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>eSign document</label>
                              <select
                                value={values.esign}
                                readOnly
                                type="select"
                                className="form-control"
                                onChange={handleChange}
                                value={values.esign}
                                onChange={handleChange}
                                invalid={errors.esign && touched.esign}
                                name="esign"
                                id="exampleSelect"
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Misting System</label>
                              <select
                                value={values.mistingSystemOptionId}
                                readOnly
                                type="select"
                                className="form-control"
                                onChange={handleChange}
                                value={values.mistingSystemOptionId}
                                onChange={handleChange}
                                invalid={
                                  errors.mistingSystemOptionId &&
                                  touched.mistingSystemOptionId
                                }
                                name="mistingSystemOptionId"
                                id="exampleSelect"
                              >
                                <option value="-1">Choose One</option>
                                <option value="1">MosquitoNix</option>
                                <option value="2">Mistaway</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Square Feet</label>
                              <span className="text-danger ml-1">*</span>
                              <input
                                value={values.sqft}
                                className="form-control"
                                type="number"
                                // tag={Field}
                                onChange={handleChange}
                                name="sqft"
                                // placeholder="with a placeholder"
                              />
                              <div className="small text-danger mt-1">
                                {touched.sqft && errors.sqft && errors.sqft}
                              </div>
                            </div>
                            <div className="d-sm-block d-md-flex justify-content-between">
                              <div className="form-group mr-3 w-100">
                                <label>Latitude</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.latitude}
                                  className="form-control"
                                  type="number"
                                  // tag={Field}

                                  onChange={handleChange}
                                  name="latitude"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.latitude &&
                                    errors.latitude &&
                                    errors.latitude}
                                </div>
                              </div>
                              <div className="form-group w-100">
                                <label>Longitude</label>
                                <span className="text-danger ml-1">*</span>
                                <input
                                  value={values.longitude}
                                  className="form-control"
                                  type="number"
                                  // tag={Field}
                                  onChange={handleChange}
                                  invalid={
                                    errors.longitude && touched.longitude
                                  }
                                  name="longitude"
                                  // placeholder="with a placeholder"
                                />
                                <div className="small text-danger mt-1">
                                  {touched.longitude &&
                                    errors.longitude &&
                                    errors.longitude}
                                </div>
                              </div>
                            </div>
                            {/* <div className="form-group">
                              <label>Slug</label>
                              <input
                                value={values.slug}
                                className="form-control"
                                // tag={Field}
                                onChange={handleChange}
                                invalid={errors.slug && touched.slug}
                                name="slug"
                                // placeholder="with a placeholder"
                              />
                            </div>
                            <div className="form-group">
                              <label>Virtual Tour Url</label>
                              <input
                                value={values.matterportUrl}
                                className="form-control"
                                onChange={handleChange}
                                invalid={
                                  errors.matterportUrl && touched.matterportUrl
                                }
                                name="matterportUrl"
                              />
                            </div>
                            <div className="form-group">
                              <label>YouTube Url</label>
                              <input
                                value={values.youtubeUrl}
                                name="youtubeUrl"
                                className="form-control"
                                onChange={handleChange}
                                invalid={
                                  errors.youtubeUrl && touched.youtubeUrl
                                }
                              />
                            </div> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <FormResponse
                              errors={get(status, 'error')}
                              isVisible={
                                get(status, 'error') ||
                                get(status, 'success') ||
                                (Object.keys(errors).length > 0 &&
                                  submitCount > 0)
                              }
                              alert={
                                Object.keys(errors).length > 0 &&
                                submitCount > 0
                              }
                            >
                              {get(status, 'success')}
                            </FormResponse>
                            <ThemedButton
                              type="submit"
                              loading={isSubmitting}
                              // onClick={() => alert('Feature coming soon!')}
                              className="w-100 mb-2 btn btn-outline-primary"
                            >
                              {!isSubmitting && 'Save'}
                            </ThemedButton>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyEditForm;
