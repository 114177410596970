import React, { useState } from 'react';

const HandlebarsContext = React.createContext();

const HandlebarsProvider = ({ children }) => {
  const [templateBody, setTemplateBody] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const [inputFields, setInputFields] = useState(null);
  const [definitionRevisions, setDefinitionRevisions] = useState(null);
  return (
    <HandlebarsContext.Provider
      value={{
        state: {
          templateBody,
          templateData,
          inputFields,
          definitionRevisions,
        },
        setTemplateBody: value => {
          setTemplateBody(value);
        },
        setTemplateData: value => {
          setTemplateData(value);
        },
        setInputFields: value => {
          setInputFields(value);
        },
        setDefinitionRevisions: value => {
          setDefinitionRevisions(value);
        },
      }}
    >
      {children}
    </HandlebarsContext.Provider>
  );
};

const HandlebarsConsumer = HandlebarsContext.Consumer;

export default HandlebarsProvider;
export { HandlebarsConsumer };
