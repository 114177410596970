import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import Layout from 'src/layouts';
import ListingComplete from 'src/images/listing-complete.svg';
import ListingInProgress from 'src/images/listing-in-progress.svg';
import ListingIncomplete from 'src/images/listing-incomplete.svg';
import Modal from 'src/components/Modal';
import PropertyListingForm from './components/PropertyListingForm';
import Tooltip from 'src/components/Tooltip';
import { Link } from '@reach/router';
import LoadingSpinner from 'src/components/LoadingSpinner';
import get from 'lodash/get';

const fieldsRequired = ['icalUrl', 'propertyUrl', 'urlId'];

const GET_PROPERTY_DATA = gql`
  {
    platforms {
      id
      name
      key
      url
    }
    properties {
      id
      name
      key
      label
      type
      sleeps
      sitePage {
        slug
        featuredImage {
          file {
            fullUrl
          }
        }
      }
    }
    propertyListings {
      id
      icalUrl
      propertyUrl
      urlId
      platform {
        id
        key
      }
      property {
        id
        key
        name
        label
      }
    }
  }
`;

const getPropertyStatus = (propertyListings, platformKey, propertyKey) => {
  // const matchObj = {
  //   total: 0,
  //   id: '',
  // }

  const matches = propertyListings.reduce((acc, channel) => {
    if (
      propertyKey === channel.property.key &&
      platformKey === channel.platform.key
    ) {
      fieldsRequired.forEach(field => {
        if (channel[field] !== null && channel[field] !== '') {
          return (acc += 1);
        }
      });
    }
    return acc;
  }, 0);
  if (matches === 0) {
    return (
      <Tooltip
        label="Listing incomplete. Click to edit"
        placement="top"
        id={`${platformKey}-${propertyKey}`}
      >
        <img
          className="icon-listing-incomplete"
          data-tooltip
          src={ListingIncomplete}
          id={`${platformKey}-${propertyKey}`}
        />
      </Tooltip>
    );
  } else if (matches < fieldsRequired.length) {
    return (
      <Tooltip
        label="Listing in progress. Click to edit."
        placement="top"
        id={`${platformKey}-${propertyKey}`}
      >
        <img
          className="icon-listing-in-progress"
          src={ListingInProgress}
          id={`${platformKey}-${propertyKey}`}
        />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        label="Listing complete. Click to edit."
        placement="top"
        id={`${platformKey}-${propertyKey}`}
      >
        <img
          className="icon-listing-complete"
          src={ListingComplete}
          id={`${platformKey}-${propertyKey}`}
        />
      </Tooltip>
    );
  }
};

const getChannelDataByKeys = (
  propertyListings,
  platformKey,
  propertyKey,
  propertyId,
  platformId,
  propertyName
) => {
  const found = propertyListings.find(
    item =>
      item.platform.key === platformKey && item.property.key === propertyKey
  );
  if (!found) {
    return {
      id: null,
      platform: {
        id: platformId,
        key: platformKey,
      },
      property: {
        id: propertyId,
        key: propertyKey,
        name: propertyName,
      },
    };
  }
  return found;
};

const Settings = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [propertyModalData, setPropertyModalData] = useState({});
  const [platformModalData, setPlatformModalData] = useState({});
  const initializeModal = (property, platform) => {
    setModalIsOpen(true);
    setPropertyModalData(property);
    setPlatformModalData(platform);
  };

  return (
    <Layout metaTitle="Settings">
      <Query
        query={GET_PROPERTY_DATA}
        // fetchPolicy='no-cache'
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            );
          if (error) return <p>Could not fetch data</p>;
          const { platforms, properties, propertyListings } = data;

          const singleProperties = properties.filter(
            property => property.type !== 'COMBO'
          );
          const propertyCombos = properties.filter(
            property => property.type === 'COMBO'
          );

          const propertyListingData =
            !!propertyModalData && !!platformModalData
              ? getChannelDataByKeys(
                  propertyListings,
                  platformModalData.key,
                  propertyModalData.key,
                  propertyModalData.id,
                  platformModalData.id,
                  propertyModalData.name
                )
              : {};
          return (
            <>
              <Modal
                isOpen={modalIsOpen}
                title={
                  !!propertyModalData
                    ? `${propertyModalData.label} - ${propertyModalData.name} - ${platformModalData.name}`
                    : 'N/A'
                }
                onClose={() => setModalIsOpen(false)}
              >
                <PropertyListingForm
                  platformId={
                    propertyListingData.platform
                      ? propertyListingData.platform.id
                      : null
                  }
                  key={platformModalData.key + '_' + propertyModalData.key}
                  label={propertyModalData.label}
                  {...propertyListingData}
                  onSuccess={() => {
                    refetch();
                  }}
                  platform={platformModalData}
                />
              </Modal>
              <div className="container-fluid property-channels m-auto">
                <h1 className="pt-4 property-channels-heading">Settings</h1>
                <div className="row">
                  <div className="col col-xl-8">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <td className="font-weight-bold text-center house-number align-middle text-nowrap">
                              House #
                            </td>
                            <td className="font-weight-bold text-left align-middle">
                              Name
                            </td>
                            {platforms.map(platform => (
                              <td
                                className="font-weight-bold text-center align-middle"
                                key={platform.key}
                              >
                                {platform.name}
                              </td>
                            ))}
                            <td className="font-weight-bold text-center align-middle">
                              Settings
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {singleProperties.map((property, index) => {
                            const fullUrl =
                              get(
                                property,
                                'sitePage.featuredImage.file.fullUrl'
                              ) || null;
                            return (
                              <tr key={property.key}>
                                <td
                                  key={property.key}
                                  className="house-number text-center align-middle"
                                >
                                  {property.label}
                                </td>
                                <td
                                  className="property-name text-left align-middle"
                                  key={property.name}
                                >
                                  {/*<Info size={18}/>*/}
                                  <span className="align-middle">
                                    {property.name}
                                  </span>
                                </td>
                                {platforms.map(platform => {
                                  const propertyListingData = getChannelDataByKeys(
                                    propertyListings,
                                    platform.key,
                                    property.key,
                                    property.id,
                                    platform.id,
                                    property.name
                                  );

                                  return (
                                    <td
                                      className="text-center align-middle"
                                      key={platform.key + '_' + property.key}
                                    >
                                      <button
                                        onClick={() =>
                                          initializeModal(property, platform)
                                        }
                                        className="btn btn-link"
                                      >
                                        {getPropertyStatus(
                                          propertyListings,
                                          platform.key,
                                          property.key
                                        )}
                                      </button>
                                    </td>
                                  );
                                })}
                                <td
                                  className="text-center align-middle"
                                  key={`settings_${property.key}`}
                                >
                                  <>
                                    {fullUrl && property.sleeps ? (
                                      <>
                                        <Tooltip
                                          label="Listing complete. Click to edit."
                                          placement="top"
                                          id={property.key}
                                        >
                                          <Link
                                            to={`/properties/${property.key}`}
                                            className="btn btn-link"
                                          >
                                            <img
                                              className="icon-listing-complete"
                                              src={ListingComplete}
                                              id={`settings-complete-${property.key}`}
                                            />
                                          </Link>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip
                                          label="Listing incomplete. Click to edit."
                                          placement="top"
                                          id={property.key}
                                        >
                                          <Link
                                            to={`/properties/${property.key}?mode=edit`}
                                            className="btn btn-link"
                                          >
                                            <img
                                              className="icon-listing-incomplete btn"
                                              src={ListingIncomplete}
                                              id={`settings-incomplete-${property.key}`}
                                            />
                                          </Link>
                                        </Tooltip>
                                      </>
                                    )}
                                  </>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <h1 className="pt-4 property-channels-heading">
                      Property Combos
                    </h1>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <td className="font-weight-bold text-center house-number align-middle">
                              House #
                            </td>
                            <td className="font-weight-bold text-left align-middle">
                              Name
                            </td>
                            {platforms.map(platform => (
                              <td
                                className="font-weight-bold text-center align-middle"
                                key={platform.key}
                              >
                                {platform.name}
                              </td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {propertyCombos.map((property, index) => (
                            <tr key={property.key}>
                              <td
                                key={property.key}
                                className="house-number text-center align-middle"
                              >
                                {property.label}
                              </td>
                              <td
                                className="property-name text-left align-middle"
                                key={property.name}
                              >
                                {/*<Info size={18}/>*/}
                                <span className="align-middle">
                                  {property.name}
                                </span>
                              </td>
                              {platforms.map(platform => {
                                const propertyListingData = getChannelDataByKeys(
                                  propertyListings,
                                  platform.key,
                                  property.key,
                                  property.id,
                                  platform.id,
                                  property.name
                                );
                                return (
                                  <td
                                    className="text-center align-middle"
                                    key={platform.key + '_' + property.key}
                                  >
                                    <button
                                      onClick={() =>
                                        initializeModal(property, platform)
                                      }
                                      className="btn btn-link"
                                    >
                                      <>
                                        {getPropertyStatus(
                                          propertyListings,
                                          platform.key,
                                          property.key
                                        )}
                                      </>
                                    </button>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <style jsx>{`
                            :global(.property-channels-tooltip) {
                              font-size: 12px;
                            }
                          `}</style> */}
              </div>
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default Settings;
