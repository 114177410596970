import React, { useState } from 'react';
import './styles.scss';
import { ChevronLeft, ChevronRight } from 'react-feather';
import {
  formatDateWithDashes,
  getDatesInMonth,
  monthNames,
  dayNames,
} from '../../util/dateHelper';

const DayCell = ({
  onClick,
  dayInMonth,
  classList,
  index,
  onMouseEnter = () => {},
}) => {
  return (
    <div
      data-date={formatDateWithDashes(dayInMonth)}
      className={classList.join(' ')}
      onClick={onClick}
      key={`day_${index}`}
      onMouseEnter={e => {
        onMouseEnter(e);
      }}
    >
      {index + 1}
    </div>
  );
};

const Month = ({
  monthNum,
  year,
  onDayRender,
  targetDate,
  index,
  dateHoverRange,
  resetRangeOnClick,
}) => {
  const targetDateClone = new Date(targetDate);
  var dtmMonth = targetDateClone.getMonth();
  var daysInMonth = getDatesInMonth(
    targetDateClone.getMonth(),
    targetDateClone.getFullYear()
  );

  var skipLength = daysInMonth[0].getDay();
  var preLength = daysInMonth.length + skipLength;
  var postLength = function() {
    if (preLength % 7 === 0) {
      return 0;
    } else {
      if (preLength < 35) {
        return 35 - preLength;
      } else {
        return 42 - preLength;
      }
    }
  };

  return (
    <div key={`_${index}`} className="month">
      <h3>
        {monthNames[targetDateClone.getMonth()] +
          ' ' +
          targetDateClone.getFullYear()}
      </h3>
      {dayNames.map((dayName, i) => {
        return (
          <div key={`key_${i}`} className="dow">
            {dayName}
          </div>
        );
      })}

      {[...Array(skipLength * 1).keys()].map((_, index) => {
        return (
          <div key={`_${index}_`} className="dummy-day">
            {
              //daysPrevMonth.length - (skipLength - (index + 1))
            }
          </div>
        );
      })}
      {daysInMonth.map((dayInMonth, index) => {
        var today = new Date(new Date().setHours(0, 0, 0, 0));

        let classList = ['day'];
        var dow = new Date(dayInMonth).getDay();
        var dateParsed = Date.parse(dayInMonth);
        var todayParsed = Date.parse(today);

        if (dateParsed === todayParsed) classList.push('today');
        if (dateParsed > todayParsed) classList.push('future');
        if (dateParsed < todayParsed) classList.push('past');

        if (dow === 0 || dow === 6) classList.push('weekend');

        if (onDayRender) {
          return onDayRender({
            dayInMonth,
            classList,
            index,
            dateHoverRange,
            resetRangeOnClick,
          });
        }

        return (
          <DayCell
            dayInMonth={dayInMonth}
            classList={classList}
            key={index}
            index={index}
            resetRangeOnClick={resetRangeOnClick}
            dateHoverRange={dateHoverRange}
          />
        );
      })}
      {[...Array(postLength()).keys()].map((_, index) => {
        return <div key={`__${index}`} className="dummy-day"></div>;
      })}
    </div>
  );
};

const DateRangePicker = props => {
  const {
    numMonths,
    onDayRender,
    dateHoverRange,
    resetRangeOnClick,
    onResetDates,
    showClearDates,
    startDate,
    endDate,
  } = props;
  const date = startDate ? new Date(startDate) : new Date();

  const today = new Date();
  const [monthCount, setMonthCount] = useState(0);
  let monthToDisplay = new Date(date.getFullYear(), date.getMonth(), 1);

  monthToDisplay.setMonth(monthToDisplay.getMonth() + monthCount);
  return (
    <div id="calendar">
      <div className="d-lg-flex d-block align-items-center">
        <div>
          <button
            className="btn"
            disabled={monthToDisplay.getMonth() === today.getMonth()}
            onClick={() => {
              setMonthCount(prevMonthCount => (prevMonthCount -= 1));
            }}
          >
            <ChevronLeft size={35} />
          </button>
        </div>
        <div className="d-lg-flex d-block justify-content-center">
          {[...Array(numMonths * 1).keys()].map((_, index) => {
            const monthToDisplayClone = new Date(
              monthToDisplay.getFullYear(),
              monthToDisplay.getMonth(),
              1
            );

            monthToDisplayClone.setMonth(
              monthToDisplayClone.getMonth() + index
            );

            return (
              <div key={index}>
                <Month
                  dateHoverRange={dateHoverRange}
                  targetDate={monthToDisplayClone}
                  index={index}
                  onDayRender={onDayRender}
                  resetRangeOnClick={resetRangeOnClick}
                />
              </div>
            );
          })}
        </div>
        <div>
          <button
            className="btn"
            onClick={() => {
              setMonthCount(prevMonthCount => (prevMonthCount += 1));
            }}
          >
            <ChevronRight size={35} />
          </button>
        </div>
      </div>
      {showClearDates && (
        <div className="text-right">
          <button
            className="btn btn-link"
            onClick={() => {
              onResetDates();
            }}
          >
            <b>Clear Dates</b>
          </button>
        </div>
      )}
    </div>
  );
};

DateRangePicker.defaultProps = {
  numMonths: '2',
  onResetDates: () => {},
  startDate: null,
  endDate: null,
};

export default DateRangePicker;
