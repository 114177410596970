import React, { useRef, useEffect } from 'react';
import debounce from 'src//util/debounce';
import WithLocation from 'src/components/WithLocation';

const BiaxialGrid = ({
  topLeftCornerContent,
  headerRow,
  firstColumn,
  innerContent,
  location,
  onLoad,
  bottomMargin = 0,
}) => {
  useEffect(() => {
    const handleGridScroll = e => {
      firstColumnRef.current.scrollTop = innerContentRef.current.scrollTop;
      headerRowRef.current.scrollLeft = innerContentRef.current.scrollLeft;
    };
    const handleNestedContentScroll = () => {
      innerContentRef.current.scrollTop = firstColumnRef.current.scrollTop;
    };
    const handleHeaderRowScroll = () => {
      innerContentRef.current.scrollLeft = headerRowRef.current.scrollLeft;
    };

    const handleGridHeightResize = () => {
      const pageHeight = $(window).height() - bottomMargin;

      const navBarHeight =
        window.innerWidth <= 992 ? $('#mobile-navbar').height() : 0;
      const filterWrapper = $('#filter-wrapper').height() || 0;

      const calendarNavHeight = $('#calendar-navbar').height() || 0;

      const chipsWrapper = $('#chips-list-wrapper').height() || 0;
      const legendWrapper = $('#calendar-legend').outerHeight() || 0;

      const gridHeight =
        pageHeight -
        calendarNavHeight -
        navBarHeight -
        filterWrapper -
        chipsWrapper -
        legendWrapper -
        1;

      $('.grid').css('height', `${gridHeight}px`);
    };

    handleGridHeightResize();

    innerContentRef.current.addEventListener(
      'scroll',
      debounce(handleGridScroll, 100)
    );
    firstColumnRef.current.addEventListener(
      'scroll',
      debounce(handleNestedContentScroll, 100)
    );
    headerRowRef.current.addEventListener(
      'scroll',
      debounce(handleHeaderRowScroll, 100)
    );

    window.addEventListener('resize', handleGridHeightResize);

    onLoad(innerContentRef);

    return () => {
      $(window).off('click');
      $('.resource-link').off('click');
      innerContentRef.current.removeEventListener('scroll', handleGridScroll);
      firstColumnRef.current.removeEventListener(
        'scroll',
        handleNestedContentScroll
      );
      headerRowRef.current.removeEventListener('scroll', handleHeaderRowScroll);
      window.removeEventListener('resize', handleGridHeightResize);
    };
  }, [bottomMargin]);
  const innerContentRef = useRef(null);
  const firstColumnRef = useRef(null);
  const headerRowRef = useRef(null);
  return (
    <section className="position-relative">
      <div className="grid">
        <div
          id="top-corner"
          className="table table-bordered mb-0 p-2 d-flex align-items-center justify-content-center"
        >
          {topLeftCornerContent}
        </div>
        <div ref={headerRowRef} id="grid-header-row" /*id="date-bar"*/>
          {headerRow}
        </div>
        <div ref={firstColumnRef} id="grid-first-column" /*id="properties"*/>
          {firstColumn}
        </div>
        <div ref={innerContentRef} id="grid-nested-content" /*id='date-grid'*/>
          {innerContent}
        </div>
      </div>
    </section>
  );
};
BiaxialGrid.defaultProps = {
  onLoad: () => {},
};

export default WithLocation(BiaxialGrid);
