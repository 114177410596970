import { Mutation, useQuery } from 'react-apollo';
import { Formik, Field } from 'formik';
import Switch from 'react-switch';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import Loadable from '@loadable/component';
import gql from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';

const GET_PAYMENT_REQUEST = gql`
query GuestReservationPaymentRequest ($id: Int!) {
  guestReservationPaymentRequest(where: {id: $id}){
    id
    amount
    externalInvoiceId
  }
}`;

const UPDATE_PAYMENT_REQUEST = gql`
  mutation UpdateGuestReservationPaymentRequest(
    $id: Int
    $amount: Float
    $externalInvoiceId: Int

  ) {
    updateGuestReservationPaymentRequest(
      data: {
        amount: $amount
        externalInvoiceId: $externalInvoiceId

      }
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`;
// const validationSchema = Yup.object().shape({
//   reservation: Yup.string().min(6, 'Must be at least 6 characters'),
// });

const PaymentRequestEditForm = ({
  paymentRequestId,
  expert,
  ipAddress,
  properties,
  onSuccess,
}) => {

  const { data: paymentRequestData, loading: paymentRequestLoading } = useQuery(
    GET_PAYMENT_REQUEST,
    {
      variables: { id: paymentRequestId },
      fetchPolicy: 'no-cache',
    }
  );
  if (paymentRequestLoading) {
    return <>Loading</>
  }

  const { guestReservationPaymentRequest } = paymentRequestData;
  console.log('paymentRequestData', paymentRequestData)
  console.log('paymentRequestData', guestReservationPaymentRequest)
  console.log('paymentRequestData', guestReservationPaymentRequest.amount)
  return (
    <>

      <Mutation mutation={UPDATE_PAYMENT_REQUEST}>
        {(updateGuestReservationPaymentRequest, { data, error, loading }) => (
          <Formik
            // enableReinitialize={true}
            initialValues={{
              id: paymentRequestId,
              externalInvoiceId: guestReservationPaymentRequest.externalInvoiceId,
              amount: guestReservationPaymentRequest.amount

            }}
            //  validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
              try {
                const result = await updateGuestReservationPaymentRequest({
                  variables: {
                    id: paymentRequestId,
                    externalInvoiceId: externalInvoiceId,
                  },
                });
                if (result) {
                  setStatus({ success: true });
                  onSuccess();
                }
              } catch (err) {
                console.log('err', err);
                setStatus({ error: err });
              }
            }}
          >
            {formikProps => {
              const {
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
                isSubmitting,
                status,
                submitCount,
              } = formikProps;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="container p-0">


                    <div className="row">
                      <div className="col">
                        <div className="form-group mb-2">
                          <div className="mb-1 font-weight-bold">
                            External Invoice ID
                          </div>
                          <input
                            name="externalInvoiceId"
                            // onChange={handleChange}
                            onChange={e => {
                              setFieldValue('externalInvoiceId', e.target.value);
                            }}
                            type="text"
                            value={values.externalInvoiceId}
                            className="form-control"
                            placeholder="INV-XXXXXX"
                            invalid={errors.externalInvoiceId && touched.externalInvoiceId}
                          />
                          <div className="text-danger">
                            {touched.externalInvoiceId &&
                              errors.externalInvoiceId &&
                              errors.externalInvoiceId}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group mb-2">
                          <div className="mb-2 font-weight-bold">
                            Amount
                          </div>
                          <input
                            name="amount"
                            // onChange={handleChange}
                            onChange={e => {
                              setFieldValue('amount', e.target.value);
                            }}
                            value={values.amount}
                            type="text"
                            className="form-control"

                            invalid={errors.amount && touched.amount}
                          />
                          <div className="text-danger">
                            {touched.amount &&
                              errors.amount &&
                              errors.amount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group my-2">

                          <ThemedButton
                            type="submit"
                            loading={loading}
                            className="w-100 btn btn-outline-primary"
                          >
                            Save
                          </ThemedButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </Mutation>
    </>);
};

export default PaymentRequestEditForm;
