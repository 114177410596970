import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import DropdownFilterSelect from 'src/components/Forms/components/DropdownFilterSelect';
import DropdownFilterForm from 'src/components/Forms/components/DropdownFilterForm';
//import { navigate } from 'gatsby';
import { navigate, useLocation } from '@reach/router';
import { getPropertyFilterOptions, getPropertyLabel } from '../../util';
import WithLocation from 'src/components/WithLocation';
import {
  addDays,
  getAllMonthsWithinRange,
  getNumberOfDaysInRange,
} from 'src/util/dateHelper';

import CalendarLinkToggle from '../CalendarLinkToggle';
import DateNavigator from '../DateNavigator';
import ChipsList from '../../../properties/components/ChipsList';

import moment from 'moment';

import './styles.scss';

const SubMenu = ({ children, title = 'Show / Hide' }) => {
  const ref = useRef(null);
  useEffect(() => {
    // $(ref.current).on('click', function(event) {
    //   $(ref.current).slideToggle();
    //   event.stopPropagation();
    // });

    $(ref.current).on('click', function(event) {
      console.log('event', event.target);
      console.log('event', event.target.dataset);
      console.log('event', event.target.type);

      if (!event.target.dataset.close && event.target.type != 'checkbox') {
        event.stopPropagation();
      }
    });

    // $(window).on('click', function() {
    //   $(ref.current).slideUp();
    // });
  });
  return (
    <>
      <button
        class="sub-menu-button"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></button>

      <div
        class="dropdown-menu p-2"
        ref={ref}
        aria-labelledby="dropdownMenuButton"
      >
        <header class="d-flex justify-content-between border-bottom mx-2 pb-1">
          <b>{title}</b>

          <button
            data-close="true"
            tabindex="-1"
            class="btn close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" data-close="true">
              ×
            </span>
          </button>
        </header>
        {children}
      </div>
    </>
  );
};

const possibleFilters = [
  'bedrooms',
  'sleeps',
  'baths',
  'amenities',
  'checkIn',
  'checkOut',
  'flexibilityInDays',
  'group',
  'property',
];
const filterKeyToLabelMap = {
  bedrooms: 'Bedrooms',
  baths: 'Baths',
  sleeps: 'Sleeps',
  flexibilityInDays: 'Flexible',
  group: 'Group',
  property: 'Property',
};

const CalendarNavigation = ({
  location,
  propertyGroups,
  properties,
  filteredPropertyList,
  totalDateRange,
  startDate,
  numberOfDaysInRange,
  dateRange,
  setStartDate,
  setNumberOfDaysInRange,
  setCustomDateRangeApplied,
  customDateRangeApplied,
  setDatePickerModalIsOpen,
  onFilterSelect,
  onGroupFilterSelect,
  onDateChange,
  currentDate,
  calendarInstance,
  onFormSubmit,
  onClearFilters,
  propertyAmenities,
  showRates,
  showMinStay,
  showRateDistribution,
}) => {
  console.log('showRatesReceivedValue', showRates);
  const params = new URLSearchParams(location.search);

  const isMulticalendar = params.get('displaymode') === 'multicalendar';
  const getChipsData = (amenities, urlParams) => {
    const amenityObject = amenities.reduce((acc, amenity) => {
      if (!acc[amenity.key]) {
        if (amenity.key === 'swimSpa') {
          acc.plungePool = 'Plunge Pool';
        } else {
          acc[amenity.key] = amenity.name;
        }
      }
      return acc;
    }, {});
    return possibleFilters.reduce((acc, arg) => {
      if (urlParams.get(arg)) {
        if (arg === 'amenities') {
          urlParams
            .get(arg)
            .split(',')
            .forEach(amenity => {
              acc.push({
                key: amenity,
                label: amenityObject[amenity],
                parentKey: arg,
              });
            });
        } else if (arg === 'group' || arg === 'property') {
          acc.push({
            key: arg,
            label: `${filterKeyToLabelMap[arg]} ${urlParams
              .get(arg)
              .toUpperCase()}`,
            parentKey: false,
            value: urlParams.get(arg),
            hasContext: true,
          });
          // } else if (urlParams.get('preserveCheckIn') && arg === 'checkIn') {
        } else if (arg === 'checkIn') {
          const checkInDate = new Date(urlParams.get(arg));
          const checkOutDate = new Date(urlParams.get('checkOut'));
          const checkInDateWithOffset = new Date(
            checkInDate.getTime() +
              Math.abs(checkInDate.getTimezoneOffset() * 60000)
          );
          const checkOutDateWithOffset = new Date(
            checkOutDate.getTime() +
              Math.abs(checkOutDate.getTimezoneOffset() * 60000)
          );
          acc.push({
            key: arg,
            label: `${checkInDateWithOffset.toLocaleString('en-us', {
              month: 'long',
              day: 'numeric',
            })} - ${checkOutDateWithOffset.toLocaleString('en-us', {
              month: 'long',
              day: 'numeric',
            })}, ${checkOutDateWithOffset.getFullYear()}`,
            parentKey: false,
            value: urlParams.get(arg),
          });
        } else if (arg === 'flexibilityInDays') {
          acc.push({
            key: arg,
            label: `${
              urlParams.get(arg) === '7' ? 'Flexible +/- 1' : 'Flexible +/- 2'
            }`,
            value: urlParams.get(arg),
            parentKey: false,
          });
        }
      }

      return acc;
    }, []);
  };

  const chipsData = getChipsData(propertyAmenities, params);

  const onClearChipFilter = selectedChip => {
    if (selectedChip.key === 'clear') {
      chipsData.forEach(chip => {
        $('input:checkbox').each((index, value) => {
          $(value).prop('checked', false);
        });
      });
      if (!isMulticalendar) {
        onClearFilters(selectedChip.key);
        return;
      }
      params.delete('amenities');
      params.delete('group');
      params.delete('end');

      navigate(window.location.pathname + `?${params.toString()}`);
    } else if (selectedChip.parentKey) {
      const args = params.get(selectedChip.parentKey);
      const argsArray = args.split(',');
      const updatedArgsArray = argsArray
        .slice(0, argsArray.indexOf(selectedChip.key))
        .concat(argsArray.slice(argsArray.indexOf(selectedChip.key) + 1));

      if (updatedArgsArray.length === 0) {
        params.delete('amenities');
      } else {
        params.set('amenities', updatedArgsArray.join(','));
      }
      navigate(window.location.pathname + `?${params.toString()}`);
      $(`input:checkbox[name=${selectedChip.key}]`).each((index, value) => {
        $(value).prop('checked', false);
      });
      !isMulticalendar && onClearFilters(updatedArgsArray.join(','));
    } else if (selectedChip?.key === 'group') {
      if (!isMulticalendar) {
        onClearFilters(selectedChip.key);
        return;
      }
      params.delete('group');
      navigate(window.location.pathname + `?${params.toString()}`);
    }
  };
  const formatDatePickerLabel = (startDate, days) => {
    const endDate = addDays(startDate, days - 1);
    const startDateSplit = startDate.toUTCString().split(' ');
    const endDateSplit = endDate.toUTCString().split(' ');
    const isStartAndEndDateTheSameMonthAndYear = () => {
      const areStartAndEndMonthsTheSame = startDateSplit[2] === endDateSplit[2];
      const areStartAndEndYearsTheSame = startDateSplit[3] === endDateSplit[3];

      if (!areStartAndEndMonthsTheSame || !areStartAndEndYearsTheSame) {
        return false;
      }
      return true;
    };
    return `${startDateSplit[2]} ${startDateSplit[1]} - ${
      isStartAndEndDateTheSameMonthAndYear() ? '' : endDateSplit[2]
    } ${endDateSplit[1]}, ${endDateSplit[3]}`;
  };
  const isNextButtonDisabled = () => {
    if (!params.get('end')) {
      if (
        moment(params.get('start'))
          .endOf('month')
          .isSameOrAfter(
            moment(totalDateRange[totalDateRange.length - 1]).endOf('month')
          ) ||
        moment(dateRange[dateRange.length - 1]).format('YYYYMMDD') ===
          moment(totalDateRange[totalDateRange.length - 1]).format('YYYYMMDD')
      ) {
        return true;
      }
      return false;
      // return true;
    }
    if (
      moment(params.get('end')).isSameOrAfter(
        moment(totalDateRange[totalDateRange.length - 1])
      )
    ) {
      return true;
    }
    return false;
  };
  const getDateDropdownOptions = () => {
    const months = getAllMonthsWithinRange(
      moment(totalDateRange[0]).format('YYYY/MM/DD'),
      moment(totalDateRange[totalDateRange.length - 1]).format('YYYY/MM/DD')
    );
    return months.reduce((acc, item) => {
      const date = new Date(item);

      const label = `${date.toLocaleString('en-us', {
        month: 'long',
      })} ${date.getFullYear()}`;

      acc.push({
        key: date,
        label,
      });
      return acc;
    }, []);
  };
  const isPrevButtonDisabled = () => {
    if (
      moment(startDate)
        .utc()
        .format('YYYYMMDD') ===
        moment(new Date())
          .utc()
          .format('YYYYMMDD') ||
      moment(startDate).isBefore(moment(new Date()))
      //  ||
      // !params.get('end')
    ) {
      return true;
    }
  };
  const applyFilters = (filter, filterType) => {
    switch (filterType) {
      case 'group':
        {
          params.set('group', filter);
          params.delete('amenities');
          params.delete('property');
          navigate(window.location.pathname + `?${params.toString()}`);
        }
        break;
      case 'amenities':
        {
          const amenities = `${Object.keys(filter)
            .map(key => key)
            .join()}`;
          params.set('amenities', amenities);
          params.delete('group');
          params.delete('property');
          navigate(window.location.pathname + `?${params.toString()}`);
        }
        break;
      case 'property': {
        params.set('property', filter);
        // params.set('view', 'month');
        params.delete('group');
        params.delete('amenities');
      }
      default: {
        // calendarInstance.refetchResources();
        return;
      }
    }
    // calendarInstance.refetchResources();
  };

  const DropdownPropertyFilter = () => {
    return (
      <div className="property-dropdown-wrapper">
        <DropdownFilterSelect
          hasReponsiveLabel={true}
          selectedFilter={
            params.get('property')
              ? getPropertyLabel(properties, params.get('property'))
              : 'All Properties'
          }
          calendar={true}
          options={getPropertyFilterOptions(properties)}
          defaultLabel={'All Properties'}
          onFilterSelect={property => {
            if (!isMulticalendar) {
              onFilterSelect(property);
              return;
            }

            if (property) {
              applyFilters(property, 'property');
              navigate(window.location.pathname + `?${params.toString()}`);
            } else {
              params.delete('property');
              navigate(window.location.pathname + `?${params.toString()}`);
            }
          }}
        />
      </div>
    );
  };
  const filtersPresent = params.get('group') || params.get('amenities');
  const isWeekView = !params.get('property') && params.get('view') !== 'month';

  return (
    <section
      className="d-flex justify-content-between align-items-center row px-2"
      id="calendar-navbar"
    >
      {/* <div className="d-flex d-lg-none align-items-center mr-2">
        <DropdownPropertyFilter />
      </div> */}

      <div className="d-flex align-items-center">
        {/* <div className="d-none d-lg-flex mr-2">
          <DropdownPropertyFilter />
        </div> */}
       
      </div>

      <div className="order-3 order-lg-4">
        {/* <div className="d-inline-block">
          <CalendarLinkToggle location={location} />
        </div> */}

        {isMulticalendar && (
          <SubMenu>
            <div className="px-2 pt-2 pb-1" style={{ width: '270px' }}>
              <div className="form-group  ">
                <div className="custom-control custom-switch ">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="showRates"
                    className="custom-control-input"
                    id="ratesSwitch"
                    checked={showRates}
                    onChange={e => {
                      console.log('!IN');
                      // //setFieldValue('rememberMe', e.target.value)
                      const params = new URLSearchParams(location.search);
                      // console.log('useLocation()', useLocation());
                      //  console.log('params', params);
                      console.log('showRates', showRates);
                      console.log('switchTo', (!showRates).toString());
                      params.set('showrates', (!showRates).toString());
                      console.log('params', params.toString());
                      navigate(`${location.pathname}?${params.toString()}`);
                    }}
                  />
                  <label
                    className="custom-control-label link-primary"
                    htmlFor="ratesSwitch"
                    style={{ color: '#0069b4' }}
                  >
                    Rates
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="showMinStay"
                    className="custom-control-input"
                    id="minStaySwitch"
                    checked={showMinStay}
                    onChange={e => {
                      console.log('!IN');
                      // //setFieldValue('rememberMe', e.target.value)
                      const params = new URLSearchParams(location.search);
                      // console.log('useLocation()', useLocation());
                      //  console.log('params', params);
                      console.log('showMinStay', showMinStay);
                      console.log('switchTo', (!showMinStay).toString());
                      params.set('showminstay', (!showMinStay).toString());
                      console.log('params', params.toString());
                      navigate(`${location.pathname}?${params.toString()}`);
                    }}
                  />
                  <label
                    className="custom-control-label "
                    style={{ color: '#0069b4' }}
                    htmlFor="minStaySwitch"
                  >
                    Min Stay
                  </label>
                </div>
              </div>
              <div className="form-group mb-0">
                <div className="custom-control custom-switch">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="showRateDistribution"
                    className="custom-control-input"
                    id="rateDistributionSwitch"
                    checked={showRateDistribution}
                    onChange={e => {
                      console.log('!IN');
                      // //setFieldValue('rememberMe', e.target.value)
                      const params = new URLSearchParams(location.search);
                      // console.log('useLocation()', useLocation());
                      //  console.log('params', params);
                      console.log('showRateDistribution', showRateDistribution);
                      params.set(
                        'showratedistribution',
                        (!showRateDistribution).toString()
                      );
                      console.log(
                        '(!showRateDistribution).toString()',
                        (!showRateDistribution).toString()
                      );
                      navigate(`${location.pathname}?${params.toString()}`);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    style={{ color: '#0069b4' }}
                    htmlFor="rateDistributionSwitch"
                  >
                    Rate Distribution
                  </label>
                </div>
              </div>
            </div>
          </SubMenu>
        )}
      </div>

      <div
        id="calendar-datepicker-wrapper"
        className=" d-flex justify-content-center order-4 order-lg-3"
      >
        {isMulticalendar ? (
          <>
            <button
              className="btn btn-link px-0"
              onClick={() => {
                const startDate = new Date();
                params.delete('end');
                params.set('start', moment(new Date()).format('YYYYMMDD'));
                setStartDate(
                  moment()
                    .startOf('day')
                    .toDate()
                );
                setNumberOfDaysInRange(
                  getNumberOfDaysInRange(
                    startDate,
                    moment(startDate)
                      .add(6, 'months')
                      .format('YYYYMMDD')
                  )
                );
                navigate(window.location.pathname + `?${params.toString()}`);
              }}
            >
              Today
            </button>
            <button
              className="btn btn-link px-1"
              disabled={isPrevButtonDisabled()}
              onClick={() => {
                const getUpdatedStartDate = () => {
                  if (params.get('end')) {
                    // custom range from datepicker
                    return customDateRangeApplied
                      ? moment(params.get('start'))
                          .subtract(numberOfDaysInRange, 'days')
                          .utc()
                          .format('YYYYMMDD')
                      : moment(params.get('start')) // decrement after month is selected
                          .subtract(1, 'month')
                          .startOf('month')
                          .utc()
                          .format('YYYYMMDD');
                  }
                  if (moment(params.get('start')).isBefore(new Date())) {
                    return moment().format('YYYYMMDD');
                  }
                  // default 6 month decrement
                  return moment(params.get('start'))
                    .subtract(6, 'months')
                    .utc()
                    .format('YYYYMMDD');
                };

                const updatedStartDate = getUpdatedStartDate();
                const getUpdatedEndDate = () => {
                  if (
                    !customDateRangeApplied &&
                    moment(updatedStartDate).isSameOrBefore(new Date())
                  ) {
                    // there is no way to backtrack passed the todays date with default 6 month navigation, therefore, it is assumed if there is not a custom date range, you are decrementing by 2 month intervals
                    return moment(new Date())
                      .add(2, 'months')
                      .endOf('month')
                      .format('YYYYMMDD');
                  }
                  if (!customDateRangeApplied) {
                    // backtrack one month, but dispay 2 months in the future
                    return moment(updatedStartDate)
                      .add(1, 'month')
                      .endOf('month')
                      .format('YYYYMMDD');
                  }
                  return params.get('end')
                    ? moment(updatedStartDate)
                        .add(numberOfDaysInRange - 1, 'days')
                        .utc()
                        .format('YYYYMMDD')
                    : moment(updatedStartDate)
                        .add(6, 'months')
                        .format('YYYYMMDD');
                };

                const updatedEndDate = getUpdatedEndDate();

                if (moment(updatedStartDate).isBefore(moment(new Date()))) {
                  params.set('start', moment(new Date()).format('YYYYMMDD'));
                  setStartDate(new Date());
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      moment(new Date()).format('YYYYMMDD'),
                      moment(updatedEndDate).format('YYYYMMDD'),
                      params
                    )
                  );
                  params.set('end', updatedEndDate);
                } else {
                  params.set('start', updatedStartDate);
                  setStartDate(new Date(moment(updatedStartDate).utc()));
                  params.get('end') && params.set('end', updatedEndDate);
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      moment(updatedStartDate).format('YYYYMMDD'),
                      moment(updatedEndDate).format('YYYYMMDD'),
                      params
                    )
                  );
                }

                navigate(window.location.pathname + `?${params.toString()}`);
              }}
            >
              <ChevronLeft color="#0069b4" />
            </button>
            {/* <DropdownFilterSelect
              selectedFilter={formatDatePickerLabel(
                startDate,
                numberOfDaysInRange
              )}
              customToggleButtonStyles={'btn btn-outline-primary my-2'}
              customDropdownClasses={`btn btn-link d-block custom-dropdown-item py-0plus text-left`}
              dropdownMenuAlignmentClass={'dropdown-menu-center'}
              calendar={true}
              options={getDateDropdownOptions()}
              defaultLabel={formatDatePickerLabel(
                startDate,
                numberOfDaysInRange
              )}
              onFilterSelect={selectedOption => {
                if (selectedOption === 'date range') {
                  setDatePickerModalIsOpen(true);
                  return;
                }
                // const formattedEndDate = moment(selectedOption)
                //   .endOf('month')
                //   .format('YYYYMMDD');
                const formattedEndDate = moment(selectedOption)
                  .add(1, 'months')
                  .endOf('month')
                  .format('YYYYMMDD');
                const isSelectedStartDateBeforeToday = moment(
                  selectedOption
                ).isBefore(moment(new Date()));
                const isEndDateAfterTheLastDayOfTheDateRange = moment(
                  formattedEndDate
                ).isAfter(moment(totalDateRange[totalDateRange.length - 1]));

                const setDateRangeNumberToEndDateFromTodaysDate = () => {
                  const formattedTodayDate = moment(new Date()).format(
                    'YYYYMMDD'
                  );
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      formattedTodayDate,
                      formattedEndDate,
                      params
                    )
                  );
                };
                const setEndDateURLParams = endDate => {
                  params.set('end', endDate);
                };

                if (isSelectedStartDateBeforeToday) {
                  // this will prevent the calendar from displaying days that fall before the 2 year calendar date range
                  setStartDate(new Date());
                  setDateRangeNumberToEndDateFromTodaysDate();
                  setEndDateURLParams(formattedEndDate);
                  params.set('start', moment().format('YYYYMMDD'));
                } else if (isEndDateAfterTheLastDayOfTheDateRange) {
                  // this will prevent the calendar from displaying dates that are after the last day of the 2 year date range
                  const adjustedWithinRangeEndDate =
                    totalDateRange[totalDateRange.length - 1];
                  setStartDate(selectedOption);
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      selectedOption,
                      adjustedWithinRangeEndDate,
                      params
                    )
                  );
                  params.set(
                    'end',
                    moment(adjustedWithinRangeEndDate).format('YYYYMMDD')
                  );
                  params.set(
                    'start',
                    moment(selectedOption).format('YYYYMMDD')
                  );
                } else {
                  setStartDate(selectedOption);
                  params.set(
                    'start',
                    moment(selectedOption).format('YYYYMMDD')
                  );
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      selectedOption,
                      formattedEndDate,
                      params
                    )
                  );
                  params.set('end', formattedEndDate);
                }
                navigate(window.location.pathname + `?${params.toString()}`);
                setCustomDateRangeApplied(false);
              }}
              extendedOptions={[
                {
                  key: 'date range',
                  label: 'or Select a Date Range',
                },
              ]}
            /> */}
            <button
              disabled={isNextButtonDisabled()}
              onClick={() => {
                const lastDayOfTotalRange = moment(
                  totalDateRange[totalDateRange.length - 1]
                );

                const getUpdatedStartDate = () => {
                  if (params.get('end')) {
                    return moment(params.get('start'))
                      .add(numberOfDaysInRange, 'days')
                      .utc()
                      .format('YYYYMMDD');
                  }
                };

                const getUpdatedEndDate = updatedStartDate => {
                  if (params.get('end')) {
                    if (customDateRangeApplied) {
                      // custom date range from date picker
                      return moment(updatedStartDate)
                        .add(numberOfDaysInRange - 1, 'days')
                        .utc()
                        .format('YYYYMMDD');
                    }
                    // month has been selected from dropdown
                    return moment(updatedStartDate)
                      .add(1, 'months') // added
                      .endOf('month')
                      .format('YYYYMMDD');
                  }
                  // default 6 month increment
                  return moment(updatedStartDate)
                    .add(6, 'months') // added
                    .format('YYYYMMDD');
                };

                const updatedStartDate = params.get('end')
                  ? moment(params.get('start'))
                      .add(numberOfDaysInRange, 'days')
                      .utc()
                      .format('YYYYMMDD')
                  : moment(params.get('start'))
                      .add(6, 'months')
                      // .startOf('month')
                      .utc()
                      .format('YYYYMMDD');
                const updatedEndDate = getUpdatedEndDate(updatedStartDate);

                if (moment(updatedEndDate).isAfter(lastDayOfTotalRange)) {
                  // if set range is longer than total available dates at the current date position, you will not be able to navigate to see the remaining dates
                  params.set('start', updatedStartDate);
                  params.get('end') &&
                    params.set('end', lastDayOfTotalRange.format('YYYYMMDD'));
                  setStartDate(new Date(moment(updatedStartDate).utc()));
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      updatedStartDate,
                      lastDayOfTotalRange.format('YYYYMMDD'),
                      params
                    )
                  );
                } else {
                  params.set('start', updatedStartDate);
                  params.get('end') && params.set('end', updatedEndDate);
                  setStartDate(new Date(moment(updatedStartDate).utc()));
                  setNumberOfDaysInRange(
                    getNumberOfDaysInRange(
                      updatedStartDate,
                      updatedEndDate,
                      params
                    )
                  );
                }

                navigate(window.location.pathname + `?${params.toString()}`);
              }}
              className="btn btn-link pl-1"
            >
              <ChevronRight color="#0069b4" />
            </button>{' '}
          </>
        ) : (
          <DateNavigator
            numberOfDaysInRange={numberOfDaysInRange}
            currentDate={currentDate}
            calendarInstance={calendarInstance}
            onDateChange={(startDate = new Date(), direction) =>
              onDateChange(startDate, direction)
            }
          />
        )}
      </div>
      {filtersPresent && isWeekView && (
        <div className="col col-12 order-3 order-lg-4 px-0">
          <ChipsList
            shouldToggleResultsLabel={true}
            filteredProperties={filteredPropertyList}
            location={location}
            amenities={propertyAmenities}
            onClearChipFilter={onClearChipFilter}
            customWrapperStyles={'mx-1 mx-lg-2'}
          />
        </div>
      )}
    </section>
  );
};

CalendarNavigation.defaultProps = {
  onFilterSelect: () => {},
  onGroupFilterSelect: () => {},
  onDateChange: () => {},
};

export default WithLocation(CalendarNavigation);
