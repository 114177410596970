import React from 'react';
import moment from 'moment';
import { navigate } from 'gatsby';
import Loadable from '@loadable/component';
import './styles.scss';
import { Sliders, ChevronLeft, ChevronRight } from 'react-feather';
import { addDays } from 'src/util/dateHelper';

const DatePicker =
  typeof window !== 'undefined' && Loadable(() => import('../DatePicker'));

const getCalendarViewTitle = (viewStart, viewEnd, viewType) => {
  if (viewType === 'month') {
    return moment(viewStart)
      .startOf('month')
      .format('MMMM  YYYY');
  }
  const startDate = new Date(viewStart);
  const endDate = new Date(viewEnd);
  const startDateSplit = startDate.toUTCString().split(' ');
  const endDateSplit = endDate.toUTCString().split(' ');

  const isStartAndEndDateTheSameMonthAndYear = () => {
    const areStartAndEndMonthsTheSame = startDateSplit[2] === endDateSplit[2];
    const areStartAndEndYearsTheSame = startDateSplit[3] === endDateSplit[3];

    if (!areStartAndEndMonthsTheSame || !areStartAndEndYearsTheSame) {
      return false;
    }
    return true;
  };
  return `${startDateSplit[2]} ${startDateSplit[1]} - ${
    isStartAndEndDateTheSameMonthAndYear() ? '' : endDateSplit[2]
  } ${endDateSplit[1]}, ${endDateSplit[3]}`;
};

const DateNavigator = ({
  calendarInstance,
  onDateChange,
  onViewChange,
  currentDate,
  numberOfDaysInRange,
}) => {
  const params = new URLSearchParams(window.location.search);
  const startDate = params.get('start');
  const viewStart =
    moment(startDate).format('YYYY-MM-DD') === 'Invalid date'
      ? moment().format('YYYY-MM-DD')
      : moment(startDate).format('YYYY-MM-DD');
  const viewType = params.get('view');
  const viewEnd = moment(viewStart)
    .clone()
    .add(13, 'days');

  return (
    <div id="date-navigator-wrapper" className="d-flex align-items-center">
      <div className="text-center py-1 py-md-auto">
        <button
          className="btn btn-link px-0"
          onClick={() => {
            // const params = new URLSearchParams(window.location.search);
            // calendarInstance.gotoDate(moment().format('YYYY-MM-DD'));
            // params.set('start', moment().format('YYYY-MM-DD'));
            // navigate(window.location.pathname + `?${params.toString()}`);
            calendarInstance.gotoDate(moment().toDate());
            onDateChange(moment().toDate(), 'today');
          }}
        >
          <span className="small">Today</span>
        </button>
        <button
          className="btn btn-link px-1"
          onClick={() => {
            const viewStart =
              calendarInstance.view.type === 'month'
                ? moment(calendarInstance.view.currentStart)
                    .subtract(1, 'month')
                    .toDate()
                : moment(calendarInstance.view.currentStart)
                    .subtract(1, 'days')
                    .toDate();

            onDateChange(viewStart, 'prev');
          }}
        >
          <span>
            <ChevronLeft className="chevron-left" />
          </span>
        </button>
      </div>
      <div className="text-center">
        <div className="react-datepicker-wrapper d-inline-block">
          <DatePicker
            popperPlacement="bottom-start"
            popperModifiers={{
              // offset: {
              //   enabled: true,
              //   offset: '5px, 10px'
              // },
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: true, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: 'viewport',
              },
            }}
            customInput={
              <button className="btn btn-outline-primary my-2">
                <span className="">
                  {getCalendarViewTitle(viewStart, viewEnd, viewType)}
                  {/* {formatDatePickerLabel(
                    new Date(viewStart),
                    numberOfDaysInRange
                  )} */}
                </span>
                {/* <span className="caret align-middle down ml-1 mt-2"></span>
                <span className="caret up align-middle ml-1 mb-2"></span> */}
              </button>
            }
            selected={
              viewStart
                ? new Date(moment(viewStart).format('YYYY/MM/DD'))
                : new Date(moment().format('YYYY/MM/DD'))
            }
            selectsStart
            onChange={args => {
              onDateChange(moment(args).toDate(), 'today');
            }} // this url is referenced by URLSearchParams in buildQueryObjectFromUrl on the second function call}
            dateFormat="yyyy/MM/dd"
            placeHolderText="Select a start date"
          />
        </div>
        <button
          className="btn btn-link pl-1"
          onClick={() => {
            const viewStart =
              calendarInstance.view.type === 'month'
                ? moment(calendarInstance.view.currentStart)
                    .add(1, 'month')
                    .toDate()
                : moment(calendarInstance.view.currentStart)
                    .add(1, 'days')
                    .toDate();
            onDateChange(viewStart, 'next');
          }}
        >
          <span>
            <ChevronRight className="chevron-right" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default DateNavigator;
