import React from 'react';

/**
 * 
Temp (°F)
Red = 78°F and above

Delta (°F)
Blue = up to 1°F
Orange = 2 - 4°F
Red = 5°F and above

Humidity (%)
Red = Under 40%, above 70%
 */

const CustomThresholds = ({ hvacAlertData }) => {
  return (
    <div>
      <div className="mb-2">
        <p className="mb-0">
          <b>Temp (°F)</b>
        </p>
        <p className="hvac-alert-300 mb-0">Red = 78°F and above</p>
      </div>
      <div className="mb-2">
        <p className="mb-0">
          <b>Delta (°F)</b>
        </p>
        <p className="hvac-alert-100 mb-0">Green = up to 1°F</p>
        <p className="hvac-alert-200 mb-0">Orange = 2 - 4°F</p>
        <p className="hvac-alert-300 mb-0">Red = 5°F and above</p>
      </div>
      <div className="mb-2">
        <p className="mb-0">
          <b>Humidity (%)</b>
        </p>
        <p className="hvac-alert-300 mb-0">Red = Under 40%, above 70%</p>
      </div>
    </div>
  );
};
export default CustomThresholds;
