import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { overflowIndicator } from '../../../../util/overflowIndicator';
import {
  updateUrlWithCommaDelimitedString,
  updateUrl,
} from '../../../../util/urlHelper';
import { navigate } from 'gatsby';
const filterKeyToLabelMap = {
  bedrooms: 'Bedrooms',
  baths: 'Baths',
  sleeps: 'Sleeps',
  flexibilityInDays: 'Flexible',
  group: 'Group',
  property: 'Property',
};
import ChipClose from 'src/images/chip-close.svg';
import ArrowScrollRight from 'src/images/arrow-scroll-right.svg';
const possibleFilters = [
  'bedrooms',
  'sleeps',
  'baths',
  'amenities',
  'checkIn',
  'checkOut',
  'flexibilityInDays',
  'group',
  'property',
];

const getChipsData = (amenities, urlParams) => {
  const amenityObject = amenities.reduce((acc, amenity) => {
    if (!acc[amenity.key]) {
      if (amenity.key === 'swimSpa') {
        acc.plungePool = 'Plunge Pool';
      } else {
        acc[amenity.key] = amenity.name;
      }
    }
    return acc;
  }, {});
  return possibleFilters.reduce((acc, arg) => {
    if (urlParams.get(arg)) {
      if (arg === 'amenities') {
        urlParams
          .get(arg)
          .split(',')
          .forEach(amenity => {
            acc.push({
              key: amenity,
              label: amenityObject[amenity],
              parentKey: arg,
            });
          });
      } else if (arg === 'group' || arg === 'property') {
        acc.push({
          key: arg,
          label: `${filterKeyToLabelMap[arg]} ${urlParams
            .get(arg)
            .toUpperCase()}`,
          parentKey: false,
          value: urlParams.get(arg),
          hasContext: true,
        });
        // } else if (urlParams.get('preserveCheckIn') && arg === 'checkIn') {
      } else if (arg === 'checkIn') {
        const checkInDate = new Date(urlParams.get(arg));
        const checkOutDate = new Date(urlParams.get('checkOut'));
        const checkInDateWithOffset = new Date(
          checkInDate.getTime() +
            Math.abs(checkInDate.getTimezoneOffset() * 60000)
        );
        const checkOutDateWithOffset = new Date(
          checkOutDate.getTime() +
            Math.abs(checkOutDate.getTimezoneOffset() * 60000)
        );
        acc.push({
          key: arg,
          label: `${checkInDateWithOffset.toLocaleString('en-us', {
            month: 'long',
            day: 'numeric',
          })} - ${checkOutDateWithOffset.toLocaleString('en-us', {
            month: 'long',
            day: 'numeric',
          })}, ${checkOutDateWithOffset.getFullYear()}`,
          parentKey: false,
          value: urlParams.get(arg),
        });
      } else if (arg === 'flexibilityInDays') {
        acc.push({
          key: arg,
          label: `${
            urlParams.get(arg) === '7' ? 'Flexible +/- 1' : 'Flexible +/- 2'
          }`,
          value: urlParams.get(arg),
          parentKey: false,
        });
      }
    }

    return acc;
  }, []);
};

const ChipsList = ({
  location,
  amenities,
  setPropertyTypeValue,
  loading,
  filteredProperties,
  onUpdate,
  onClearChipFilter,
  shouldToggleResultsLabel,
  customWrapperStyles,
}) => {
  useEffect(() => {
    overflowIndicator();
    return () => {
      $('[data-fixed-row]').unbind('scroll');
      $(window).unbind('resize');
    };
  }, [location.search]);
  const urlParams = new URLSearchParams(location.search);
  const chipsData = getChipsData(amenities, urlParams);

  if (chipsData.length > 0) {
    chipsData.unshift({ key: 'clear', label: 'Clear All', parentKey: false });
  }
  const getFormFilters = argsArray => {
    return argsArray.reduce((acc, arg) => {
      acc[arg] = 'Y';
      return acc;
    }, {});
  };
  const clearFilter = selectedChip => {
    if (selectedChip.key === 'clear') {
      chipsData.forEach(chip => {
        // if (chip.hasContext) {
        //   setPropertyTypeValue(chip.key, '');
        // }
        $('input:checkbox').each((index, value) => {
          $(value).prop('checked', false);
        });
      });
      navigate(
        `/properties${
          urlParams.get('mode') ? `?mode=${urlParams.get('mode')}` : ``
        }`
      );
      onUpdate({}, true);
    } else if (selectedChip.parentKey) {
      const args = urlParams.get(selectedChip.parentKey);
      const argsArray = args.split(',');
      const updatedArgsArray = argsArray
        .slice(0, argsArray.indexOf(selectedChip.key))
        .concat(argsArray.slice(argsArray.indexOf(selectedChip.key) + 1));
      updateUrlWithCommaDelimitedString(
        'properties',
        updatedArgsArray.join(','),
        'amenities'
      );
      $(`input:checkbox[name=${selectedChip.key}]`).each((index, value) => {
        $(value).prop('checked', false);
      });

      onUpdate(getFormFilters(updatedArgsArray));
    } else {
      const paramsObject = chipsData.reduce((acc, chip) => {
        if (chip.value) {
          if (
            selectedChip.key === 'checkIn' ||
            selectedChip.key === 'flexibilityInDays'
          ) {
            acc['checkIn'] = '';
            acc['checkOut'] = '';
            acc['flexibilityInDays'] = '';
          } else if (chip.key === selectedChip.key) {
            acc[chip.key] = '';
          } else {
            acc[chip.key] = chip.value;
          }
        }
        return acc;
      }, {});
      // console.log('paramsObject', paramsObject);
      // setPropertyTypeValue(selectedChip.key, '');
      onUpdate(null, true);
      updateUrl('properties', paramsObject);
    }
  };

  const shouldShowResultsLabel = () => {
    if (shouldToggleResultsLabel && chipsData.length > 0) {
      return true;
    }
    if (shouldToggleResultsLabel && chipsData.length === 0) {
      return false;
    }
    if (loading) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`position-relative ${
        customWrapperStyles ? customWrapperStyles : 'mx-3'
      }`}
    >
      <div
        data-fixed-row
        className={`${styles.chipsRow}  d-flex align-items-center`}
      >
        <div data-scrollable-row-overflow className="d-flex align-items-center">
          <span className="text-nowrap">
            {shouldShowResultsLabel() && (
              <>{filteredProperties.length} Results</>
            )}
          </span>
          {chipsData.map((chip, index) => {
            return (
              <div
                data-end-of-list={index + 1 === chipsData.length ? true : false}
                key={chip.key}
                data-chip
                className={`d-flex align-items-center border py-1 py-lg-0 ml-1plus ${styles.chip}`}
              >
                <div className="pl-1plus">
                  <span className="text-nowrap" key={chip.key}>
                    {chip.label}
                  </span>
                </div>
                <span
                  className="p-0 p-lg-1"
                  onClick={() => {
                    if (onClearChipFilter) {
                      onClearChipFilter(chip);
                      return;
                    }
                    clearFilter(chip);
                  }}
                >
                  <img
                    src={ChipClose}
                    height="30"
                    width="30"
                    className={styles.chipSVG}
                  />
                </span>
              </div>
            );
          })}
        </div>
        <div
          data-overflow-indicator
          style={{ display: 'none' }}
          className={`${styles.overflowArrow} position-absolute`}
        >
          <img src={ArrowScrollRight} width="8" height="14" />
        </div>
      </div>
    </div>
  );
};

ChipsList.defaultProps = {
  onUpdate: () => {},
};

export default ChipsList;
