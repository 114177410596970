import React, { useState } from 'react';
import TemplateBuilder from 'src/components/TemplateBuilder';
import HandlebarsProvider, {
  HandlebarsConsumer,
} from 'src/contexts/Handlebars';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { enumToLabel } from 'src/util';
import Layout from 'src/layouts';
import { navigate } from 'gatsby';

const TEMPLATE_BUILDER_DEFINITIONS = gql`
  query TemplateBuilderDefinitions {
    templateBuilderDefinitions {
      id
      type
    }
  }
`;

const TemplateBuilderPage = () => {
  const [definitionId, setDefinitionId] = useState(null);
  return (
    <Layout metaTitle={'Template Builder'} mainCustomClasses={'pb-0 bg-white'}>
      <div className="w-100 h-100 d-flex flex-column">
        <Query query={TEMPLATE_BUILDER_DEFINITIONS}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container pt-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <LoadingSpinner />
                  </div>
                </div>
              );
            const definitions = data?.templateBuilderDefinitions;

            return (
              <HandlebarsProvider>
                <HandlebarsConsumer>
                  {props => {
                    const { setTemplateBody, setTemplateData } = props;
                    const params = new URLSearchParams(window.location?.search);
                    const builderType = params.get('templateBuilderType');
                    const defaultValue =
                      definitions.find(
                        definition => definition?.type === builderType
                      )?.id || null;

                    return (
                      <>
                        <div className="m-3">
                          <select
                            className="form-control w-auto"
                            // name="platformKey"
                            defaultValue={defaultValue}
                            onChange={e => {
                              const definitionType = definitions.find(
                                definition =>
                                  definition?.id === Number(e.target.value)
                              )?.type;

                              setDefinitionId(Number(e.target.value));
                              setTemplateData(null);
                              setTemplateBody(null);
                              params.set('templateBuilderType', definitionType);

                              //   navigate(
                              //     window.location.pathname +
                              //       `?${params.toString()}`
                              //   );
                              window.history.replaceState(
                                null,
                                '',
                                window.location.pathname +
                                  `?${params.toString()}`
                              );
                            }}
                          >
                            <option hidden>Choose One</option>
                            {definitions?.map(option => {
                              return (
                                <option
                                  // disabled={!platformIsSupported}
                                  key={option?.id}
                                  value={option?.id}
                                >
                                  {enumToLabel(option?.type)}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <TemplateBuilder
                          contextData={props}
                          definitionId={definitionId || defaultValue}
                          // params={`reference=${reservationId}`}
                          params={`reference=''`}
                        />
                      </>
                    );
                  }}
                </HandlebarsConsumer>
              </HandlebarsProvider>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default TemplateBuilderPage;
