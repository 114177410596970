import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import DropdownFilterSelect from 'src/components/Forms/components/DropdownFilterSelect';
import DropdownFilterForm from 'src/components/Forms/components/DropdownFilterForm';
import { navigate } from 'gatsby';
import { getPropertyFilterOptions, getPropertyLabel } from '../../../util';
import WithLocation from '../../../../../components/WithLocation';
import {
  addDays,
  getAllMonthsWithinRange,
  getNumberOfDaysInRange,
} from '../../../../../util/dateHelper';
import CalendarLinkToggle from '../../CalendarLinkToggle';
import moment from 'moment';

const CalendarNavigation = ({
  location,
  propertyGroups,
  properties,
  filteredPropertyList,
  totalDateRange,
  startDate,
  numberOfDaysInRange,
  dateRange,
  setStartDate,
  setNumberOfDaysInRange,
  //   getNumberOfDaysInRange,
  setCustomDateRangeApplied,
  customDateRangeApplied,
  setDatePickerModalIsOpen,
  defaultDateRangeNumber,
}) => {
  const params = new URLSearchParams(location.search);
  const formatDatePickerLabel = (startDate, days) => {
    const endDate = addDays(startDate, days - 1);
    const startDateSplit = startDate.toUTCString().split(' ');
    const endDateSplit = endDate.toUTCString().split(' ');
    return `${startDateSplit[2]} ${startDateSplit[1]} - ${endDateSplit[2]} ${
      endDateSplit[1]
    }, ${endDateSplit[3]}`;
  };
  const isNextButtonDisabled = () => {
    if (!params.get('end')) {
      if (
        moment(params.get('start'))
          .endOf('month')
          .isSameOrAfter(
            moment(totalDateRange[totalDateRange.length - 1]).endOf('month')
          ) ||
        moment(dateRange[dateRange.length - 1]).format('YYYYMMDD') ===
          moment(totalDateRange[totalDateRange.length - 1]).format('YYYYMMDD')
      ) {
        return true;
      }
      return false;
      // return true;
    }
    if (
      moment(params.get('end')).isSameOrAfter(
        moment(totalDateRange[totalDateRange.length - 1])
      )
    ) {
      return true;
    }
    return false;
  };
  const getDateDropdownOptions = () => {
    const months = getAllMonthsWithinRange(
      moment(totalDateRange[0]).format('YYYY/MM/DD'),
      moment(totalDateRange[totalDateRange.length - 1]).format('YYYY/MM/DD')
    );
    return months.reduce((acc, item) => {
      const date = new Date(item);

      const label = `${date.toLocaleString('en-us', {
        month: 'long',
      })} ${date.getFullYear()}`;

      acc.push({
        key: date,
        label,
      });
      return acc;
    }, []);
  };
  const isPrevButtonDisabled = () => {
    if (
      moment(startDate)
        .utc()
        .format('YYYYMMDD') ===
        moment(new Date())
          .utc()
          .format('YYYYMMDD') ||
      moment(startDate).isBefore(moment(new Date()))
      //  ||
      // !params.get('end')
    ) {
      return true;
    }
  };
  const applyFilters = (filter, filterType) => {
    switch (filterType) {
      case 'group':
        {
          params.set('group', filter);
          params.delete('amenities');
          params.delete('property');
          navigate(window.location.pathname + `?${params.toString()}`);
        }
        break;
      case 'amenities':
        {
          const amenities = `${Object.keys(filter)
            .map(key => key)
            .join()}`;
          params.set('amenities', amenities);
          params.delete('group');
          params.delete('property');
          navigate(window.location.pathname + `?${params.toString()}`);
        }
        break;
      case 'property': {
        params.set('property', filter);
        // params.set('view', 'month');
        params.delete('group');
        params.delete('amenities');
      }
      default: {
        // calendarInstance.refetchResources();
        return;
      }
    }
    // calendarInstance.refetchResources();
  };
  return (
    <section
      className="d-flex justify-content-between align-items-center row"
      id="calendar-navbar"
    >
      <div className="col-8 col-lg-6 col d-flex align-items-center justify-content-between justify-content-lg-start">
        <DropdownFilterSelect
          selectedFilter={
            params.get('property')
              ? getPropertyLabel(filteredPropertyList, params.get('property'))
              : 'All Properties'
          }
          calendar={true}
          options={getPropertyFilterOptions(filteredPropertyList)}
          defaultLabel={'All Properties'}
          onFilterSelect={property => {
            if (property) {
              applyFilters(property, 'property');
              navigate(window.location.pathname + `?${params.toString()}`);
            } else {
              params.delete('property');
              navigate(window.location.pathname + `?${params.toString()}`);
            }
          }}
        />
        <DropdownFilterForm
          dropdownMenuAlignmentClass={'dropdown-menu-right'}
          filtersApplied={params.get('group') || params.get('amenities')}
          propertyGroups={propertyGroups}
          propertiesFromParent={properties}
          onGroupFilterSelect={filter => {
            applyFilters(filter, 'group');
          }}
          hideFilterByProperty={true}
          groupDropdownOptions={propertyGroups}
          onClearFilters={() => {
            params.delete('amenities');
            params.delete('group');
            params.delete('end');

            navigate(window.location.pathname + `?${params.toString()}`);
          }}
          onFormSubmit={formFilters => {
            applyFilters(formFilters, 'amenities');
          }}
        />
      </div>

      <div className="d-flex col-4 col-lg-4 order-lg-2 order-lg-3 col justify-content-end align-items-center">
        <CalendarLinkToggle location={location} />
        {/* <DropdownFilterForm
          dropdownMenuAlignmentClass={'dropdown-menu-right'}
          filtersApplied={params.get('group') || params.get('amenities')}
          propertyGroups={propertyGroups}
          propertiesFromParent={properties}
          onGroupFilterSelect={filter => {
            applyFilters(filter, 'group');
          }}
          hideFilterByProperty={true}
          groupDropdownOptions={propertyGroups}
          onClearFilters={() => {
            params.delete('amenities');
            params.delete('group');
            params.delete('end');

            navigate(window.location.pathname + `?${params.toString()}`);
          }}
          onFormSubmit={formFilters => {
            applyFilters(formFilters, 'amenities');
          }}
        /> */}
      </div>

      <div className="d-flex col-12 col-lg-2 col justify-content-center">
        <button
          className="btn btn-link px-0"
          onClick={() => {
            const startDate = new Date();
            params.delete('end');
            params.set('start', moment(new Date()).format('YYYYMMDD'));
            setStartDate(
              moment()
                .startOf('day')
                .toDate()
            );
            setNumberOfDaysInRange(
              getNumberOfDaysInRange(
                startDate,
                moment(startDate)
                  .add(6, 'months')
                  .format('YYYYMMDD')
              )
            );
            navigate(window.location.pathname + `?${params.toString()}`);
          }}
        >
          Today
        </button>

        <button
          className="btn btn-link px-1"
          disabled={isPrevButtonDisabled()}
          onClick={() => {
            const getUpdatedStartDate = () => {
              if (params.get('end')) {
                // custom range from datepicker
                return customDateRangeApplied
                  ? moment(params.get('start'))
                      .subtract(numberOfDaysInRange, 'days')
                      .utc()
                      .format('YYYYMMDD')
                  : moment(params.get('start')) // decrement after month is selected
                      .subtract(1, 'month')
                      .startOf('month')
                      .utc()
                      .format('YYYYMMDD');
              }
              if (moment(params.get('start')).isBefore(new Date())) {
                return moment().format('YYYYMMDD');
              }
              // default 6 month decrement
              return moment(params.get('start'))
                .subtract(6, 'months')
                .utc()
                .format('YYYYMMDD');
            };

            const updatedStartDate = getUpdatedStartDate();
            const getUpdatedEndDate = () => {
              if (
                !customDateRangeApplied &&
                moment(updatedStartDate).isSameOrBefore(new Date())
              ) {
                // there is no way to backtrack passed the todays date with default 6 month navigation, therefore, it is assumed if there is not a custom date range, you are decrementing by 2 month intervals
                return moment(new Date())
                  .add(2, 'months')
                  .endOf('month')
                  .format('YYYYMMDD');
              }
              if (!customDateRangeApplied) {
                // backtrack one month, but dispay 2 months in the future
                return moment(updatedStartDate)
                  .add(1, 'month')
                  .endOf('month')
                  .format('YYYYMMDD');
              }
              return params.get('end')
                ? moment(updatedStartDate)
                    .add(numberOfDaysInRange - 1, 'days')
                    .utc()
                    .format('YYYYMMDD')
                : moment(updatedStartDate)
                    .add(6, 'months')
                    .format('YYYYMMDD');
            };

            const updatedEndDate = getUpdatedEndDate();

            if (moment(updatedStartDate).isBefore(moment(new Date()))) {
              params.set('start', moment(new Date()).format('YYYYMMDD'));
              setStartDate(new Date());
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(
                  moment(new Date()).format('YYYYMMDD'),
                  moment(updatedEndDate).format('YYYYMMDD'),
                  params
                )
              );
              params.set('end', updatedEndDate);
            } else {
              params.set('start', updatedStartDate);
              setStartDate(new Date(moment(updatedStartDate).utc()));
              params.get('end') && params.set('end', updatedEndDate);
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(
                  moment(updatedStartDate).format('YYYYMMDD'),
                  moment(updatedEndDate).format('YYYYMMDD'),
                  params
                )
              );
            }

            navigate(window.location.pathname + `?${params.toString()}`);
          }}
        >
          <ChevronLeft color="#0069b4" />
        </button>

        <DropdownFilterSelect
          // selectedFilter={formatDatePickerLabel(startDate, numberOfDaysInRange)}
          selectedFilter={'Feb 12 - 25, 2021'}
          customToggleButtonStyles={'btn btn-outline-primary my-2'}
          customDropdownClasses={`btn btn-link d-block custom-dropdown-item py-0plus text-left`}
          dropdownMenuAlignmentClass={'dropdown-menu-center'}
          calendar={true}
          options={getDateDropdownOptions()}
          defaultLabel={formatDatePickerLabel(startDate, numberOfDaysInRange)}
          onFilterSelect={selectedOption => {
            if (selectedOption === 'date range') {
              setDatePickerModalIsOpen(true);
              return;
            }
            // const formattedEndDate = moment(selectedOption)
            //   .endOf('month')
            //   .format('YYYYMMDD');
            const formattedEndDate = moment(selectedOption)
              .add(1, 'months')
              .endOf('month')
              .format('YYYYMMDD');
            const isSelectedStartDateBeforeToday = moment(
              selectedOption
            ).isBefore(moment(new Date()));
            const isEndDateAfterTheLastDayOfTheDateRange = moment(
              formattedEndDate
            ).isAfter(moment(totalDateRange[totalDateRange.length - 1]));

            const setDateRangeNumberToEndDateFromTodaysDate = () => {
              const formattedTodayDate = moment(new Date()).format('YYYYMMDD');
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(
                  formattedTodayDate,
                  formattedEndDate,
                  params
                )
              );
            };
            const setEndDateURLParams = endDate => {
              params.set('end', endDate);
            };

            if (isSelectedStartDateBeforeToday) {
              // this will prevent the calendar from displaying days that fall before the 2 year calendar date range
              setStartDate(new Date());
              setDateRangeNumberToEndDateFromTodaysDate();
              setEndDateURLParams(formattedEndDate);
              params.set('start', moment().format('YYYYMMDD'));
            } else if (isEndDateAfterTheLastDayOfTheDateRange) {
              // this will prevent the calendar from displaying dates that are after the last day of the 2 year date range
              const adjustedWithinRangeEndDate =
                totalDateRange[totalDateRange.length - 1];
              setStartDate(selectedOption);
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(
                  selectedOption,
                  adjustedWithinRangeEndDate,
                  params
                )
              );
              params.set(
                'end',
                moment(adjustedWithinRangeEndDate).format('YYYYMMDD')
              );
              params.set('start', moment(selectedOption).format('YYYYMMDD'));
            } else {
              setStartDate(selectedOption);
              params.set('start', moment(selectedOption).format('YYYYMMDD'));
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(selectedOption, formattedEndDate, params)
              );
              params.set('end', formattedEndDate);
            }
            navigate(window.location.pathname + `?${params.toString()}`);
            setCustomDateRangeApplied(false);
          }}
          extendedOptions={[
            {
              key: 'date range',
              label: 'or Select a Date Range',
            },
          ]}
        />
        <button
          disabled={isNextButtonDisabled()}
          onClick={() => {
            const lastDayOfTotalRange = moment(
              totalDateRange[totalDateRange.length - 1]
            );

            const getUpdatedStartDate = () => {
              if (params.get('end')) {
                return moment(params.get('start'))
                  .add(numberOfDaysInRange, 'days')
                  .utc()
                  .format('YYYYMMDD');
              }
            };

            const getUpdatedEndDate = updatedStartDate => {
              if (params.get('end')) {
                if (customDateRangeApplied) {
                  // custom date range from date picker
                  return moment(updatedStartDate)
                    .add(numberOfDaysInRange - 1, 'days')
                    .utc()
                    .format('YYYYMMDD');
                }
                // month has been selected from dropdown
                return moment(updatedStartDate)
                  .add(1, 'months') // added
                  .endOf('month')
                  .format('YYYYMMDD');
              }
              // default 6 month increment
              return moment(updatedStartDate)
                .add(6, 'months') // added
                .format('YYYYMMDD');
            };

            const updatedStartDate = params.get('end')
              ? moment(params.get('start'))
                  .add(numberOfDaysInRange, 'days')
                  .utc()
                  .format('YYYYMMDD')
              : moment(params.get('start'))
                  .add(6, 'months')
                  // .startOf('month')
                  .utc()
                  .format('YYYYMMDD');
            const updatedEndDate = getUpdatedEndDate(updatedStartDate);
            // const updatedEndDate =
            //   params.get('end') && customDateRangeApplied
            //     ? moment(updatedStartDate)
            //         .add(numberOfDaysInRange - 1, 'days')
            //         .utc()
            //         .format('YYYYMMDD')
            //     : moment(updatedStartDate)
            //         .add(6, 'months') // added
            //         .endOf('month')
            //         .format('YYYYMMDD');
            if (moment(updatedEndDate).isAfter(lastDayOfTotalRange)) {
              // if set range is longer than total available dates at the current date position, you will not be able to navigate to see the remaining dates
              params.set('start', updatedStartDate);
              params.get('end') &&
                params.set('end', lastDayOfTotalRange.format('YYYYMMDD'));
              setStartDate(new Date(moment(updatedStartDate).utc()));
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(
                  updatedStartDate,
                  lastDayOfTotalRange.format('YYYYMMDD'),
                  params
                )
              );
            } else {
              params.set('start', updatedStartDate);
              params.get('end') && params.set('end', updatedEndDate);
              setStartDate(new Date(moment(updatedStartDate).utc()));
              setNumberOfDaysInRange(
                getNumberOfDaysInRange(updatedStartDate, updatedEndDate, params)
              );
            }

            navigate(window.location.pathname + `?${params.toString()}`);
          }}
          className="btn btn-link pl-1"
        >
          <ChevronRight color="#0069b4" />
        </button>
      </div>
    </section>
  );
};

export default WithLocation(CalendarNavigation);
