import React, { useEffect } from 'react';
import { Mutation } from 'react-apollo';
import Tooltip from 'src/components/Tooltip';
// import { useMutation } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import gql from 'graphql-tag';
import get from 'lodash/get';
import Chips from 'react-chips';
import { withApollo } from 'react-apollo';
import Promise from 'bluebird';
import CurrencyInput from 'react-currency-input-field';
import toggleOn from 'src/images/toggle-on.svg';
import toggleOff from 'src/images/toggle-off.svg';
import { Info } from 'react-feather';
import './styles.scss';

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

const GET_GUESTS = gql`
  query AllGuests($id_starts_with: Int, $first: Int) {
    guestsConnection(
      where: { id_starts_with: $id_starts_with }
      first: $first
    ) {
      edges {
        node {
          id
          firstName
          lastName
          primaryEmailAddress {
            emailAddress
          }
        }
      }
    }
  }
`;

const CREATE_GUEST = gql`
  mutation createGuest($guestCreateInput: GuestCreateInput!) {
    createGuest(data: $guestCreateInput) {
      firstName
      lastName
      primaryEmailAddress {
        emailAddress
      }
      primaryPhoneNumber {
        phoneNumber
      }
    }
  }
`;

const CREATE_GUEST_RELATION = gql`
  mutation CreateGuestRelation($guestId: Int!, $relatedGuestId: Int!) {
    createGuestRelationship(
      data: { guestId: $guestId, relatedGuestId: $relatedGuestId }
    ) {
      id
      # relatedGuests {
      #   id
      #   firstName
      # }
    }
  }
`;
const DELETE_GUEST_RELATION = gql`
  mutation DeleteGuestRelation($guestId: Int!, $relatedGuestId: Int!) {
    deleteGuestRelationship(
      data: { guestId: $guestId, relatedGuestId: $relatedGuestId }
    ) {
      id
    }
  }
`;

const UPDATE_GUEST = gql`
  mutation UpdateGuest(
    $guestWhereUniqueInput: GuestWhereUniqueInput!
    $guestUpdateInput: GuestUpdateInput!
  ) {
    updateGuest(where: $guestWhereUniqueInput, data: $guestUpdateInput) {
      firstName
      lastName
      homeownerDetail {
        estimatedHomeValue
      }
      primaryEmailAddress {
        emailAddress
      }
      primaryPhoneNumber {
        phoneNumber
      }
      relatedGuests {
        id
        firstName
      }
    }
  }
`;

const GuestForm = ({ modalData, sources, onSuccess, client }) => {
  const validationSchema = Yup.object().shape({
    //   reservation: Yup.string()
    //       .min(6, 'Must be at least 6 characters'),
    firstName: Yup.string().required('First name is required'),
    email: Yup.string().email('Must be a valid email'),
    originConversationURL: Yup.string().when('source', {
      // is: false, // alternatively: (val) => val == true
      is: val => !modalData && (Number(val) == 4 || Number(val) === 1),
      then: Yup.string().required('Origin Conversation URL is required'),
    }),
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   'Enter a valid URL'
    // ),
  });
  // const [
  //   createGuestRelationship,
  //   {
  //     data: createGuestRelationshipData,
  //     loading: createGuestRelationshipLoading,
  //   },
  // ] = useMutation(CREATE_GUEST_RELATION);
  // const [
  //   removeGuestRelationship,
  //   {
  //     data: deleteGuestRelationshipData,
  //     loading: deleteguestRelationshipLoading,
  //   },
  // ] = useMutation(UPDATE_GUEST_RELATION);
  const relatedGuests = modalData?.relatedGuests || [];
  const chips = relatedGuests.reduce((acc, guest) => {
    guest?.id && acc.push(guest?.id);
    return acc;
  }, []);

  useEffect(() => {
    $('#add-new-vrp-collapse').collapse({
      toggle: false,
    });
    return () => {
      $('#add-new-vrp-collapse').collapse('dispose');
    };
  }, []);

  return (
    <Mutation
      fetchPolicy="no-cache"
      mutation={modalData ? UPDATE_GUEST : CREATE_GUEST}
    >
      {(guestMutation, { data, loading, error }) => (
        <Mutation mutation={CREATE_GUEST_RELATION}>
          {(createGuestRelationshipMutation, { data, loading, error }) => (
            <Mutation mutation={DELETE_GUEST_RELATION}>
              {(deleteGuestRelationshipMutation, { data, loading, error }) => (
                <Formik
                  // enableReinitialize={true}
                  initialValues={{
                    firstName: get(modalData, 'firstName') || '',
                    lastName: get(modalData, 'lastName') || '',
                    blockType: get(modalData, 'blockType') || 'NONE',
                    email:
                      get(modalData, 'primaryEmailAddress.emailAddress') || '',
                    address: modalData?.address1 || '',
                    address2: modalData?.address2 || '',
                    city: modalData?.city || '',
                    state: modalData?.state || '',
                    postalCode: modalData?.postalCode || '',
                    phoneNumber:
                      get(modalData, 'primaryPhoneNumber.phoneNumber') || '',
                    source: get(modalData, 'guestSource.id') || '',
                    originConversationURL:
                      modalData?.originConversationURL || '',
                    id: get(modalData, 'id') || '',
                    chips,
                    isHomeowner: modalData?.homeownerDetail ? true : false,
                    estimatedHomeValue: modalData?.homeownerDetail
                      ?.estimatedHomeValue
                      ? modalData?.homeownerDetail?.estimatedHomeValue
                      : '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (
                    values,
                    { setSubmitting, setStatus, setErrors }
                  ) => {
                    const getEstimatedHomeValue = () => {
                      if (values?.isHomeowner) {
                        return {
                          homeownerDetail: {
                            estimatedHomeValue:
                              values?.estimatedHomeValue !== ''
                                ? Number(values?.estimatedHomeValue)
                                : null,
                          },
                        };
                      }
                      return {
                        homeownerDetail: null,
                      };
                    };
                    const estimatedHomeValue = getEstimatedHomeValue();
                    const formData = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      blockType: values.blockType,
                      primaryEmailAddress: {
                        emailAddress: values.email || null,
                      },
                      primaryPhoneNumber: {
                        phoneNumber: String(values.phoneNumber) || null,
                      },
                      guestSourceId: Number(values.source),
                      address1: values.address,
                      address2: values.address2,
                      city: values.city,
                      state: values.state,
                      postalCode: values.postalCode,
                      originConversationURL: values?.originConversationURL,
                      ...estimatedHomeValue,
                    };

                    const chipsToRemove = chips.reduce((acc, chip) => {
                      const foundChip = values?.chips.find(
                        formChip => formChip === chip
                      );
                      if (!foundChip) {
                        acc.push(chip);
                      }
                      return acc;
                    }, []);
                    const chipsToCreate = values?.chips.reduce((acc, chip) => {
                      const foundChip = chips.find(
                        formChip => formChip === chip
                      );
                      if (!foundChip) {
                        acc.push(chip);
                      }
                      return acc;
                    }, []);

                    try {
                      const updateGuest = async () => {
                        return modalData
                          ? await guestMutation({
                              variables: {
                                guestWhereUniqueInput: { id: values.id },
                                guestUpdateInput: formData,
                              },
                            })
                          : await guestMutation({
                              variables: {
                                guestCreateInput: formData,
                              },
                            });
                      };

                      const handleAssociatedAccountsMutation = async (
                        associatedAccountsMutation,
                        guestId,
                        chips
                      ) => {
                        return await Promise.each(
                          chips,
                          chip => {
                            return associatedAccountsMutation({
                              variables: {
                                guestId: Number(guestId),
                                relatedGuestId: chip,
                              },
                            })
                              .then(result => {
                                return result;
                              })
                              .catch(err => {
                                // console.log('err1', err);
                                setStatus({ error: err });
                              });
                          }
                          // { concurrency: 1 }
                        )
                          .then(submittedFiles => {
                            return submittedFiles;
                            // console.log('submittedFiles', submittedFiles);
                          })
                          .catch(err => {
                            console.log('err2', err);
                            setStatus({ error: err });
                          });
                      };
                      const isAssociatedAccountUpdateRequired =
                        chipsToCreate.length > 0 || chipsToRemove.length > 0;
                      const createAssociatedAccount = chipsToCreate.length > 0;
                      const removeAssociatedAccount = chipsToRemove.length > 0;
                      const updateAssociatedAccounts = async () => {
                        if (
                          createAssociatedAccount &&
                          removeAssociatedAccount
                        ) {
                          const removeAssociatedAccountsUpdate = await handleAssociatedAccountsMutation(
                            deleteGuestRelationshipMutation,
                            values?.id,
                            chipsToRemove
                          );
                          const createAssociatedAccountsUpdate = await handleAssociatedAccountsMutation(
                            createGuestRelationshipMutation,
                            values?.id,
                            values.chips
                          );
                          if (
                            removeAssociatedAccountsUpdate &&
                            createAssociatedAccountsUpdate
                          ) {
                            return true;
                          }
                        }
                        if (removeAssociatedAccount) {
                          const removeAssociatedAccountsUpdate = await handleAssociatedAccountsMutation(
                            deleteGuestRelationshipMutation,
                            values?.id,
                            chipsToRemove
                          );
                          if (removeAssociatedAccountsUpdate) {
                            return true;
                          }
                        }
                        if (createAssociatedAccount) {
                          const createAssociatedAccountsUpdate = await handleAssociatedAccountsMutation(
                            createGuestRelationshipMutation,
                            values?.id,
                            values.chips
                          );
                          if (createAssociatedAccountsUpdate) {
                            return true;
                          }
                        }
                        return false;
                      };

                      const updatedGuest = await updateGuest();

                      // if (chipsToRemove.length > 0) {
                      // const associatedAccountsDeleted =
                      //   chipsToRemove.length > 0
                      //     ? await handleAssociatedAccountsMutation(
                      //         deleteGuestRelationshipMutation,
                      //         values?.id,
                      //         chipsToRemove
                      //       )
                      //     : false;

                      // }

                      // if (chipsToCreate.length > 0) {

                      // const associatedAccountsUpdated =
                      //   chipsToCreate.length > 0
                      //     ? await handleAssociatedAccountsMutation(
                      //         createGuestRelationshipMutation,
                      //         values?.id,
                      //         values.chips
                      //       )
                      //     : false;
                      if (updatedGuest) {
                        if (isAssociatedAccountUpdateRequired) {
                          setSubmitting(true);
                          const updateAssociateGuestsComplete = await updateAssociatedAccounts();
                          if (updateAssociateGuestsComplete) {
                            setSubmitting(false);
                            setStatus({ success: true });
                            onSuccess();
                          }
                        } else {
                          setStatus({ success: true });
                          onSuccess();
                        }
                      }

                      // }
                    } catch (err) {
                      setStatus({ error: err });
                      console.log('err', err);
                    }
                  }}
                >
                  {formikProps => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      status,
                      submitCount,
                    } = formikProps;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="container">
                          <div className="row">
                            <div className="col col-6 pr-0 pr-md-4 pl-0 mb-2">
                              <div className="form-group">
                                <label className="mb-1">First Name</label>
                                <input
                                  value={values.firstName}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="firstName"
                                  type="text"
                                  placeholder={
                                    values.firstName
                                      ? values.firstName
                                      : 'Enter a first name'
                                  }
                                  // invalid={
                                  //   errors.firstName && touched.firstName
                                  // }
                                  tabIndex="1"
                                />
                                <div className="text-danger">
                                  {touched.firstName &&
                                    errors.firstName &&
                                    errors.firstName}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">Email</label>
                                <input
                                  value={values.email}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="email"
                                  type="email"
                                  placeholder={
                                    values.email
                                      ? values.email
                                      : 'Enter an email'
                                  }
                                  // invalid={errors.email && touched.email}
                                  tabIndex="3"
                                />
                                <div className="text-danger">
                                  {touched.email &&
                                    errors.email &&
                                    errors.email}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">Address</label>
                                <input
                                  value={values.address}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="address"
                                  type="text"
                                  placeholder={
                                    values.address
                                      ? values.address
                                      : 'Enter your address'
                                  }
                                  // invalid={errors.address && touched.address}
                                  tabIndex="5"
                                />
                                <div className="text-danger">
                                  {touched.address &&
                                    errors.address &&
                                    errors.address}
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="mb-1">State</label>
                                <select
                                  name="state"
                                  defaultValue={values.state}
                                  className="form-control"
                                  onChange={handleChange}
                                  // invalid={errors.state && touched.state}
                                  tabIndex="7"
                                >
                                  <option value="">Choose One</option>
                                  <option value="AL">Alabama</option>
                                  <option value="AK">Alaska</option>
                                  <option value="AZ">Arizona</option>
                                  <option value="AR">Arkansas</option>
                                  <option value="CA">California</option>
                                  <option value="CO">Colorado</option>
                                  <option value="CT">Connecticut</option>
                                  <option value="DE">Delaware</option>
                                  <option value="DC">
                                    District Of Columbia
                                  </option>
                                  <option value="FL">Florida</option>
                                  <option value="GA">Georgia</option>
                                  <option value="HI">Hawaii</option>
                                  <option value="ID">Idaho</option>
                                  <option value="IL">Illinois</option>
                                  <option value="IN">Indiana</option>
                                  <option value="IA">Iowa</option>
                                  <option value="KS">Kansas</option>
                                  <option value="KY">Kentucky</option>
                                  <option value="LA">Louisiana</option>
                                  <option value="ME">Maine</option>
                                  <option value="MD">Maryland</option>
                                  <option value="MA">Massachusetts</option>
                                  <option value="MI">Michigan</option>
                                  <option value="MN">Minnesota</option>
                                  <option value="MS">Mississippi</option>
                                  <option value="MO">Missouri</option>
                                  <option value="MT">Montana</option>
                                  <option value="NE">Nebraska</option>
                                  <option value="NV">Nevada</option>
                                  <option value="NH">New Hampshire</option>
                                  <option value="NJ">New Jersey</option>
                                  <option value="NM">New Mexico</option>
                                  <option value="NY">New York</option>
                                  <option value="NC">North Carolina</option>
                                  <option value="ND">North Dakota</option>
                                  <option value="OH">Ohio</option>
                                  <option value="OK">Oklahoma</option>
                                  <option value="OR">Oregon</option>
                                  <option value="PA">Pennsylvania</option>
                                  <option value="RI">Rhode Island</option>
                                  <option value="SC">South Carolina</option>
                                  <option value="SD">South Dakota</option>
                                  <option value="TN">Tennessee</option>
                                  <option value="TX">Texas</option>
                                  <option value="UT">Utah</option>
                                  <option value="VT">Vermont</option>
                                  <option value="VA">Virginia</option>
                                  <option value="WA">Washington</option>
                                  <option value="WV">West Virginia</option>
                                  <option value="WI">Wisconsin</option>
                                  <option value="WY">Wyoming</option>
                                </select>

                                <div className="text-danger">
                                  {touched.state &&
                                    errors.state &&
                                    errors.state}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">Zip Code</label>
                                <input
                                  value={values.postalCode}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="postalCode"
                                  type="text"
                                  placeholder={
                                    values.postalCode
                                      ? values.postalCode
                                      : 'Enter a zip code'
                                  }
                                  //   tag={Field}
                                  // invalid={
                                  //   errors.postalCode && touched.postalCode
                                  // }
                                  tabIndex="10"
                                />
                                <div className="text-danger">
                                  {touched.postalCode &&
                                    errors.postalCode &&
                                    errors.postalCode}
                                </div>
                              </div>
                              <div
                                id="source-form-input-wrapper"
                                className="form-group"
                              >
                                <label className="mb-1">Source</label>
                                {console.log('values.source', values.source)}
                                <select
                                  tabIndex="12"
                                  name="source"
                                  type="select"
                                  value={values.source}
                                  // invalid={errors.source && touched.source}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  /*onChange={(value) => setFieldValue('source', value)}*/ className="form-control "
                                >
                                  {/* { !modalData && <option value={modalData && modalData.guestSource ? String(modalData.guestSource.id) : ''} label={values.source ? values.source : 'Choose One'}/> } */}
                                  <option key="choose-source" value="">
                                    Choose One
                                  </option>
                                  {sources &&
                                    sources.map((source, index) => (
                                      <option
                                        key={`${source.label}-${index + 1}`}
                                        value={source.id}
                                      >
                                        {source.name}
                                      </option>
                                    ))}
                                </select>
                                {errors.source && touched.source && (
                                  <span className="select-list-error">
                                    {touched.source &&
                                      errors.source &&
                                      errors.source}
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="mb-1">
                                  Associated Accounts
                                </label>

                                <Chips
                                  tabIndex="13"
                                  placeholder={'Start typing in a guest ID'}
                                  type="text"
                                  value={values.chips}
                                  onChange={chips => {
                                    const transformedChips = chips.reduce(
                                      (acc, chip) => {
                                        if (typeof chip === 'string') {
                                          const split = chip.split(' ');
                                          acc.push(Number(split[0]));
                                          return acc;
                                        }
                                        acc.push(chip);
                                        return acc;
                                      },
                                      []
                                    );

                                    setFieldValue('chips', transformedChips);
                                  }}
                                  fetchSuggestionsThrushold={1}
                                  fetchSuggestions={async (value, callback) => {
                                    // someAsynCall(callback)
                                    const { data } = await client.query({
                                      query: GET_GUESTS,
                                      variables: {
                                        id_starts_with: Number(value),
                                        first: 20,
                                      },
                                      fetchPolicy: 'no-cache',
                                    });
                                    const guests =
                                      data?.guestsConnection?.edges || [];
                                    const ids = guests.reduce((acc, guest) => {
                                      acc.push(
                                        `${guest?.node?.id} (${titleCase(
                                          guest?.node?.firstName
                                        )} ${titleCase(guest?.node?.lastName)})`
                                      );
                                      return acc;
                                    }, []);
                                    return ids;

                                    // if (ids.length > 0) {
                                    //   callback(ids);
                                    // } else {
                                    //   callback([]);
                                    // }
                                    // callback();
                                  }}
                                  // suggestions={[]}
                                />
                              </div>
                            </div>
                            <div className="col col-6 pl-0 pl-sm-4 pr-0 mb-2">
                              <div className="form-group">
                                <label className="mb-1">Last Name</label>
                                <input
                                  value={values.lastName}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="lastName"
                                  type="text"
                                  placeholder={
                                    values.lastName
                                      ? values.lastName
                                      : 'Enter a last name'
                                  }
                                  //   tag={Field}
                                  // invalid={errors.lastName && touched.lastName}
                                  tabIndex="2"
                                />
                                <div className="text-danger">
                                  {touched.lastName &&
                                    errors.lastName &&
                                    errors.lastName}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">Phone Number</label>
                                <input
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="phoneNumber"
                                  type="string"
                                  placeholder={
                                    values.phoneNumber
                                      ? values.phoneNumber
                                      : 'Enter a phone number'
                                  }
                                  //   tag={Field}
                                  tabIndex="4"
                                  // invalid={
                                  //   errors.reservation && touched.reservation
                                  // }
                                />
                                <div className="text-danger">
                                  {touched.phoneNumber &&
                                    errors.phoneNumber &&
                                    errors.phoneNumber}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">
                                  Address (extended)
                                </label>
                                <input
                                  value={values.address2}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="address2"
                                  type="text"
                                  placeholder={
                                    values.address2
                                      ? values.address2
                                      : 'Enter your extended address'
                                  }
                                  // invalid={errors.address2 && touched.address2}
                                  tabIndex="6"
                                />
                                <div className="text-danger">
                                  {touched.address2 &&
                                    errors.address2 &&
                                    errors.address2}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">City</label>
                                <input
                                  value={values.city}
                                  onChange={handleChange}
                                  className="form-control"
                                  name="city"
                                  type="text"
                                  placeholder={
                                    values.city ? values.city : 'Enter a city'
                                  }
                                  //   tag={Field}
                                  // invalid={errors.city && touched.city}
                                  tabIndex="9"
                                />
                                <div className="text-danger">
                                  {touched.city && errors.city && errors.city}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-1">Block Type</label>
                                <select
                                  tabIndex="10"
                                  name="blockType"
                                  type="select"
                                  value={values.blockType}
                                  // invalid={
                                  //   errors.blockType && touched.blockType
                                  // }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  /*onChange={(value) => setFieldValue('source', value)}*/ className="form-control "
                                >
                                  <option key="no-block" value="NONE">
                                    None
                                  </option>
                                  <option key="hard-block" value="HARD">
                                    Hard
                                  </option>
                                  <option key="soft-block" value="SOFT">
                                    Soft
                                  </option>
                                  <option key="no-refunds" value="NO_REFUNDS">
                                    No Refunds
                                  </option>
                                </select>
                                {errors.blockType && touched.blockType && (
                                  <span className="select-list-error">
                                    {touched.blockType &&
                                      errors.blockType &&
                                      errors.blockType}
                                  </span>
                                )}
                              </div>
                              <div className="form-group _mb-0">
                                <div className="d-flex align-items-end">
                                  <label className="mb-1 mr-1">
                                    Origin Conversation URL(s)
                                  </label>
                                  <Tooltip
                                    label={
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            'The URL of a conversation thread from the origin VRP<br />Examples:<br/>https://www.airbnb.com/hosting/p/inbox/folder/all/thread/954561740/details<br/>https://www.vrbo.com/rm/121.4225514.4631357/conversation/ef560bab-03ae-4ef5-b243-732a23db1d8b<br /><br />Note: Multiple origin URLs can be added by separating each with a comma.',
                                        }}
                                      ></div>
                                    }
                                    className="mb-1 mt-0"
                                    placement="top"
                                    id={`conversation-url-tooltip`}
                                    style={{
                                      maxWidth: '500px',
                                    }}
                                  >
                                    <Info
                                      style={{}}
                                      className="text-primary"
                                      size="18"
                                    />
                                  </Tooltip>
                                </div>
                                <input
                                  defaultValue={values.originConversationURL}
                                  onChange={handleChange}
                                  // onChange={e => {
                                  //   console.log('e.target.value', e.target.value);
                                  //   // setFieldValue(
                                  //   //   'originConversationUrl',
                                  //   //   e.target.value
                                  //   // );
                                  // }}
                                  className="form-control"
                                  name="originConversationURL"
                                  type="text"
                                  placeholder={
                                    values.originConversationURL
                                      ? values.originConversationURL
                                      : 'Enter the origin conversation URL'
                                  }
                                  //   tag={Field}
                                  // invalid={
                                  //   errors.originConversationURL && touched.originConversationURL
                                  // }
                                  tabIndex="12"
                                />
                                <div className="text-danger mb-1">
                                  {touched.originConversationURL &&
                                    errors.originConversationURL &&
                                    errors.originConversationURL}
                                </div>
                              </div>
                              <div className="form-group">
                                <div>
                                  <button
                                    type="button"
                                    tabIndex="14"
                                    // href={getCalendarToggleLinkPath()}
                                    onClick={() => {
                                      setFieldValue(
                                        'isHomeowner',
                                        !values?.isHomeowner
                                      );
                                      if (values?.isHomeowner) {
                                        setFieldValue('estimatedHomeValue', '');
                                      }
                                    }}
                                    className="btn btn-link p-0"
                                  >
                                    {values?.isHomeowner ? (
                                      <img width={'28px'} src={toggleOn} />
                                    ) : (
                                      <img
                                        width={'28px'}
                                        className="text-primary"
                                        src={toggleOff}
                                      />
                                    )}
                                  </button>
                                  <label
                                    className="mb-1"
                                    onClick={() => {
                                      setFieldValue(
                                        'isHomeowner',
                                        !values?.isHomeowner
                                      );
                                      if (values?.isHomeowner) {
                                        setFieldValue('estimatedHomeValue', '');
                                      }
                                    }}
                                  >
                                    Is Homeowner
                                  </label>
                                </div>
                                <div>
                                  <CurrencyInput
                                    disabled={
                                      !values?.isHomeowner ? true : false
                                    }
                                    className="form-control"
                                    // id="input-example"
                                    name="estimatedHomeValue"
                                    placeholder="Estimated Home Value"
                                    value={
                                      values?.estimatedHomeValue
                                        ? values?.estimatedHomeValue
                                        : ''
                                    }
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => {
                                      setFieldValue(
                                        'estimatedHomeValue',
                                        value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <FormResponse
                              errors={get(status, 'error')}
                              isVisible={
                                get(status, 'error') ||
                                get(status, 'success') ||
                                (Object.keys(errors).length > 0 &&
                                  submitCount > 0)
                              }
                              alert={
                                Object.keys(errors).length > 0 &&
                                submitCount > 0
                              }
                            >
                              {get(status, 'success')}
                            </FormResponse>
                            <ThemedButton
                              type="submit"
                              loading={loading || isSubmitting}
                              className="w-100 btn btn-outline-primary"
                            >
                              Save
                            </ThemedButton>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default withApollo(GuestForm);
