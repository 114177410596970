export function keyToLabel(key) {
  let transformed = key.replace(/_/g, ' ');
  const result = transformed.split(' ').map(piece => {
    const transformedPiece =
      (piece.match(/[A-Z]/g) || []).length === piece.length
        ? piece + ' '
        : piece.replace(/([A-Z])/g, ' $1');

    return transformedPiece.charAt(0).toUpperCase() + transformedPiece.slice(1);
  });
  return result.join('');
}
export function enumToLabel(str) {
  var frags = str.split('_');
  for (var i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
  }
  return frags.join(' ');
}
