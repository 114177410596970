export const overflowIndicator = () => {
  $('[data-overflow-indicator]').hide();
  const scrollableRowWidth = $('[data-scrollable-row-overflow]').width();
  const fixedRowWidth = $('[data-fixed-row]').width();
  const initialScrollLeftPos = $('[data-fixed-row]').scrollLeft();
  const totalFixedRowAndScrollLeftWidth = initialScrollLeftPos + fixedRowWidth;
  const toggleIndicatorDisplay = (
    totalFixedRowAndScrollLeftWidth,
    scrollableRowWidth
  ) => {
    if (totalFixedRowAndScrollLeftWidth === scrollableRowWidth) {
      $('[data-overflow-indicator]').hide();
    } else if (totalFixedRowAndScrollLeftWidth + 1 < scrollableRowWidth) {
      $('[data-overflow-indicator]').show();
    } else {
      $('[data-overflow-indicator]').hide();
    }
  };
  toggleIndicatorDisplay(totalFixedRowAndScrollLeftWidth, scrollableRowWidth);
  const onScroll = () => {
    const scrollLeftPos = $('[data-fixed-row]').scrollLeft();
    const _scrollableRowWidth = $('[data-scrollable-row-overflow]').width();
    const _fixedRowWidth = $('[data-fixed-row]').width();
    const totalFixedRowAndScrollLeftWidth = scrollLeftPos + _fixedRowWidth;
    toggleIndicatorDisplay(
      totalFixedRowAndScrollLeftWidth,
      _scrollableRowWidth
    );
  };

  $('[data-fixed-row]').on('scroll', onScroll);
  $(window).on('resize', onScroll);
};
