import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

const DynamicInputs = props => {
  const {
    prefix,
    placeholder,
    max = 10,
    numVisible = 0,
    onChange,
    defaultInputGroups,
    assumedLastName = '',
  } = props;

  // const defaultValues = JSON.parse(values || '[]').filter(value =>
  //   value ? true : false
  // );
  // const [inputs, setInputs] = useState(
  //   // Array.from({ length: max }).map((_, index) => {
  //   //     const counter = index + 1;
  //   //     return {
  //   //         index,
  //   //         counter,
  //   //         hidden: counter > numVisible,
  //   //         value: ""
  //   //     };
  //   // })
  //   Array.from({
  //     length:
  //       numVisible > defaultValues.length ? numVisible : defaultValues.length,
  //   }).map((_, index) => {
  //     const counter = index + 1;
  //     return {
  //       index,
  //       counter,
  //       // hidden: counter > numVisible,
  //       value: defaultValues[index] || '',
  //     };
  //   })
  // );

  const [inputGroups, setInputGroups] = useState(defaultInputGroups || []); //[
  //{ firstName: '', lastName: '', age: '' },
  //]

  return (
    <>
      {inputGroups.map((inputGroup, index) => {
        return (
          <div key={index} className="row">
            <div className={`col-4 form-group mb-3`}>
              <label>First Name</label>
              <input
                type="text"
                name={`${prefix ? prefix : ''}child_first_name[]`}
                className="form-control m-input"
                autoComplete="off"
                value={inputGroup.firstName}
                autoFocus={true}
                onChange={event => {
                  const newInputData = inputGroups.map(
                    (inputGroup, targetIndex) => {
                      if (targetIndex == index) {
                        inputGroup.firstName = event.target.value;
                      }
                      return inputGroup;
                    }
                  );
                  setInputGroups(newInputData);
                  if (onChange) {
                    onChange(inputGroups);
                  }
                }}
              />
            </div>
            <div className={`col-4 form-group mb-3`}>
              <label>Last Name</label>
              <input
                type="text"
                name={`${prefix ? prefix : ''}child_first_name[]`}
                className="form-control m-input"
                autoComplete="off"
                value={inputGroup.lastName}
                onChange={event => {
                  const newInputData = inputGroups.map(
                    (inputGroup, targetIndex) => {
                      if (targetIndex == index) {
                        inputGroup.lastName = event.target.value;
                      }
                      return inputGroup;
                    }
                  );
                  setInputGroups(newInputData);
                  if (onChange) {
                    onChange(inputGroups);
                  }
                }}
              />
              <span className="text-muted small">
                Leave blank to use {assumedLastName}
              </span>
            </div>
            <div className={`col-4 form=group mb-3`}>
              <label className="d-block">Age</label>

              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  name={`${prefix ? prefix : ''}child_age[]`}
                  maxLength="2"
                  className="form-control  d-inline-block"
                  autoComplete="off"
                  style={{ minWwdth: '80px' }}
                  value={
                    inputGroup.age || inputGroup.age === 0 ? inputGroup.age : ''
                  }
                  onChange={event => {
                    const newInputData = inputGroups.map(
                      (inputGroup, targetIndex) => {
                        if (targetIndex == index) {
                          inputGroup.age = event.target.value;
                        }
                        return inputGroup;
                      }
                    );
                    setInputGroups(newInputData);

                    if (onChange) {
                      onChange(inputGroups);
                    }
                  }}
                />

                <button
                  type="button"
                  className="btn btn-secondary d-inline-block ml-2"
                  onClick={() => {
                    const newInputData = inputGroups.filter(
                      (targetInput, targetIndex) => {
                        return targetIndex != index;
                      }
                    );
                    setInputGroups(newInputData);
                    if (onChange) {
                      onChange(newInputData);
                    }
                  }}
                >
                  Remove
                </button>
              </div>

              <span className="text-muted small">0 for infant</span>
            </div>
          </div>
        );
      })}

      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => {
          setInputGroups([
            ...inputGroups,
            { firstName: '', lastName: '', age: '' },
          ]);
        }}
      >
        Add Child
      </button>
    </>
  );
};

export default DynamicInputs;
