import React, { useState } from 'react';
// import Query from 'src/components/GraphQL/components/Query';
import { Query } from 'react-apollo';
// import { useQuery } from '@apollo/react-hooks';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Location } from '@reach/router';
import Layout from 'src/layouts';
import ThemedPager from 'src/components/ThemedPager';
// import GuestTable from '../guests/components/GuestTable';
import LeadsTable from './components/LeadsTable';
import moment from 'moment';
import './styles.scss';
import { navigate, Link } from 'gatsby';
import Tooltip from 'src/components/Tooltip';
import Modal from 'src/components/Modal';
import FormatNewLineToText from 'src/components/FormatNewLineToText';
import DropdownSelector from 'src/components/Forms/components/DropdownSelector';
import DropdownFilterSelect from 'src/components/Forms/components/DropdownFilterSelect';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { ExternalLink, Search, UserPlus, AlertTriangle } from 'react-feather';
import { AlertsConsumer } from 'src/contexts/Alerts';
import get from 'lodash/get';
import { Info } from 'react-feather';
import PropertyDetailsModal from '../properties/components/PropertyDetailsModal';
import QuoteGenerator from 'src/components/QuoteGenerator';
import { formatDateWithDashes } from 'src/util/dateHelper';
import styles from '../guests/styles.module.scss';
const options = ['SENT', 'INPROGRESS', 'SPAM', 'CLOSED', 'ALL', 'OPEN'];
import debounce from '../../util/debounce';
import { updateUrl } from 'src/util/urlHelper/index';
import * as queryString from 'query-string';
import Logo from '../../images/favicon.png';
import { Helmet } from 'react-helmet';
import ThemedTable from 'src/components/ThemedTable/index.js';
import GuestsInnerTable from 'src/routes/guests/components/GuestsInnerTable';
import SearchInput from 'src/components/SearchInput';

import {
  apiDataToLabelMap,
  labelToUrlKeyMap,
  urlToGraphQLValueMap,
  urlToLabelKeyMap,
} from './maps';

const ALL_GUESTS = gql`
  query AllGuests($first: Int, $skip: Int, $searchTerm: String) {
    guestsConnection(
      where: { searchTerm: $searchTerm }
      first: $first
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          id
          firstName
          lastName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
          }
          guestSource {
            id
            name
          }
        }
      }
    }
    guestSources {
      key
      name
      id
    }
  }
`;

const UPDATE_GUEST_LEAD_STATUS = gql`
  mutation UpdateGuestLead($id: Int, $status: GuestLeadStatus) {
    updateGuestLead(where: { id: $id }, data: { status: $status }) {
      id
      status
    }
  }
`;

const GUEST_LEAD = gql`
  query GuestLead($id: Int) {
    guestLead(where: { id: $id }) {
      id
      name
      email
      message
      ipAddress
      status
      leadDate
      uid
      source
      referrer
      origin
      phoneNumber
      localTraveler
      checkInPreference
      checkOutPreference
      numberOfAdults
      numberOfChildren
      numberOfPets
      numberOfBedrooms
      numberOfBathrooms
      numberOfVehicles
      numberOfVisitors
      guestGroupTypes {
        label
        id
      }
      guestMatch {
        id
      }
      relatedLeads {
        id
        email
        phoneNumber
        checkInPreference
        name
        message
        leadDate
        status
        source
      }
      propertyQuote {
        rate
      }
      targetProperty {
        id
        name
        label
      }
    }
  }
`;

const GUEST_LEADS = gql`
  query GuestLeads(
    $first: Int!
    $skip: Int!
    $status: GuestLeadStatus
    $searchTerm: String
    $orderBy: [GuestLeadOrderByInput]
  ) {
    guestLeadsConnection(
      orderBy: $orderBy
      where: { status: $status, searchTerm: $searchTerm }
      first: $first
      skip: $skip
    ) {
      edges {
        node {
          id
          # name
          email
          message
          status
          leadDate
          uid
          source
          origin
          referrer: originSource
          checkInPreference
          relatedLeads {
            id
            email
            phoneNumber
            name
            message
            checkInPreference
            leadDate
            status
            source
          }
          guestMatch {
            id
          }
        }
      }
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const QuoteGeneratorModal = ({ selectedLeadData }) => {
  const [quoteGeneratorModalIsOpen, setQuoteGeneratorModalIsOpen] = useState(
    false
  );
  const checkIn = formatDateWithDashes(selectedLeadData.checkInPreference);
  const checkOut = formatDateWithDashes(selectedLeadData.checkOutPreference);
  return (
    <>
      <button
        onClick={() => setQuoteGeneratorModalIsOpen(true)}
        className="btn btn-link p-0"
      >
        Check Availability
      </button>
      <Modal
        isOpen={quoteGeneratorModalIsOpen}
        title={selectedLeadData && `${selectedLeadData.targetProperty.name}`}
        styleType="large"
        disableForceFocus={true}
        onClose={() => {
          setQuoteGeneratorModalIsOpen(false);
        }}
      >
        <QuoteGenerator
          propertyId={selectedLeadData && selectedLeadData.targetProperty.id}
          checkIn={checkIn}
          // checkIn={'2020-03-01'}
          checkOut={checkOut}
          // checkOut={'2020-03-04'}
        />
      </Modal>
    </>
  );
};

const PropertyDetails = ({ id, selectedLeadData }) => {
  const [targetPropertyId, setTargetPropertyId] = useState(false);
  const name = get(selectedLeadData, 'targetProperty.name') || null;
  const label = get(selectedLeadData, 'targetProperty.label') || null;

  return (
    <>
      <p className="mb-1">
        <span className="label font-weight-bold">Target Property:</span>{' '}
        <span>
          {name} - {label}
        </span>
        <span
          className="ml-1"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setTargetPropertyId(id);
          }}
        >
          <Info size={18} />
        </span>
      </p>
      {targetPropertyId && (
        <PropertyDetailsModal
          onResetId={() => {
            setTargetPropertyId(false);
          }}
          propertyId={targetPropertyId}
        />
      )}
    </>
  );
};

const getSelectedFilter = found => {
  if (found === '') {
    return 'action-required';
  }
  return found;
};

const updateLead = async (
  id,
  updateGuestLead,
  selectedValue,
  setUpdatedStatus
) => {
  const [status] = Object.entries(apiDataToLabelMap).find(
    ([key, value]) => selectedValue === value
  );
  try {
    const result = await updateGuestLead({
      variables: {
        id,
        status: status || '',
      },
    });
    // if (result) alert('Update was successful!');
    setUpdatedStatus(result.data.updateGuestLead.status);
  } catch (err) {
    // alert('There was a problem with your submission')
  }
};

const Leads = ({ location }) => {
  const recordsPerPage = 10;
  const params = new URLSearchParams(location.search);
  const urlId = params.get('id') ? Number(params.get('id')) : null;
  const [modalIsOpen, setModalIsOpen] = useState(urlId ? true : false);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  // const [selectedLead, setSelectedLead] = useState(null);
  // const guestMatchId = targetProperty
  //   ? get(targetProperty, 'guestMatch.id') || null
  //   : null;

  const searchTerm = params.get('search') || null;
  // const [pageNumber, setPageNumber] = useState(Number(params.get('page')) || 1);

  const leadsPageNumber = Number(params.get('leadsPage')) || 1;
  const guestsPageNumber = Number(params.get('guestsPage')) || 1;
  const selectedFilter = params.get('filter') || 'action-required';
  const queryParams = queryString.parse(location.search);

  return (
    <Location>
      {({ location, navigate }) => {
        return (
          <Layout metaTitle="Leads">
            <Mutation mutation={UPDATE_GUEST_LEAD_STATUS}>
              {(updateGuestLead, { data }) => (
                <div className="container-fluid pt-3 leads-page">
                  <div
                    className="row leads-filter-container" /*className="leads-filter-container float-right mb-2"*/
                  >
                    <div
                      className="d-lg-flex justify-content-between w-100 mx-3" /*className="col col-12 col-lg-4 mb-3"*/
                    >
                      {!searchTerm && <h1 className="heading">Leads</h1>}
                      {searchTerm && (
                        <h1 className="heading">Guests Relations</h1>
                      )}
                      <div className="d-flex ">
                        <span className="position-relative mr-2 flex-grow-1">
                          <SearchInput
                            placeHolder={`Name, Email, Phone#`}
                            customIconStyle={{ right: '5px', top: '7px' }}
                            value={searchTerm}
                            onChange={value => {
                              const debounced = debounce(function() {
                                const queryParamsClone = { ...queryParams };
                                delete queryParamsClone.guestsPage;
                                delete queryParamsClone.leadsPage;
                                delete queryParamsClone.filter;
                                delete queryParamsClone.id;

                                updateUrl(
                                  'leads',
                                  {
                                    ...queryParamsClone,
                                    search: value,
                                  },
                                  true
                                );
                              }, 500);
                              debounced();
                            }}
                          />
                          <Link to="/notes">Search Notes</Link>
                        </span>
                        <DropdownFilterSelect
                          tooltipData={'Click to update status filter'}
                          id={'dropdown-filter-tooltip'}
                          defaultLabel={'Filter by status'}
                          options={options.map(
                            option => apiDataToLabelMap[option]
                          )}
                          selectedFilter={
                            selectedFilter &&
                            apiDataToLabelMap[selectedFilter.toUpperCase()]
                              ? apiDataToLabelMap[selectedFilter.toUpperCase()]
                              : 'Action Required'
                          }
                          onFilterSelect={selectedFilter => {
                            const labelToUrlKeyMapEntries = Object.entries(
                              labelToUrlKeyMap
                            );
                            const found = labelToUrlKeyMapEntries.find(
                              entry => entry[0] === selectedFilter
                            );
                            const key = found ? found[1] : null;
                            const queryParamsClone = { ...queryParams };
                            delete queryParamsClone.search;
                            delete queryParamsClone.leadsPage;
                            delete queryParamsClone.guestsPage;
                            delete queryParamsClone.id;

                            updateUrl('leads', { ...queryParams, filter: key });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Query
                    variables={{
                      searchTerm:
                        (searchTerm || {}).length > 3 ? searchTerm : null,
                      first: recordsPerPage,
                      skip: (guestsPageNumber - 1) * recordsPerPage,
                    }}
                    query={ALL_GUESTS}
                    fetchPolicy="no-cache"
                    skip={searchTerm === null}
                  >
                    {({
                      loading: guestsLoading,
                      error: guestsError,
                      data: guestsData,
                    }) => {
                      const { guestsConnection, guestSources } =
                        guestsData || {};
                      const status = selectedFilter
                        ? urlToGraphQLValueMap[selectedFilter]
                        : 'OPEN';
                      return (
                        <>
                          <Query
                            variables={{
                              first: recordsPerPage ? recordsPerPage : 10,
                              skip: (leadsPageNumber - 1) * 10,
                              status: status,
                              orderBy: 'checkInPreference_ASC', // status == 'OPEN' ?  'checkInPreference_ASC' : 'leadDate_DESC'
                              searchTerm:
                                (searchTerm || {}).length > 3
                                  ? searchTerm
                                  : null,
                            }}
                            query={GUEST_LEADS}
                          >
                            {({
                              loading: leadsLoading,
                              error: leadsError,
                              data: leadsData,
                            }) => {
                              const { guestLeadsConnection } = leadsData || {};
                              const transformGuestData = guestsConnection => {
                                return guestsConnection.edges.reduce(
                                  (acc, edge) => {
                                    acc.push(edge.node);
                                    return acc;
                                  },
                                  []
                                );
                              };
                              const count =
                                guestsConnection?.aggregate?.count || 0;
                              const guests =
                                guestsConnection &&
                                transformGuestData(guestsConnection);

                              // const relatedLeads =
                              //   get(
                              //     guestLeadsConnection,
                              //     'edges[0]node.relatedLeads'
                              //   ) || [];

                              // if (leadsLoading)
                              //   return (
                              //     <div className="container pt-3">
                              //       <div
                              //         className="d-flex justify-content-center align-items-center"
                              //         style={{ height: '80vh' }}
                              //       >
                              //         <LoadingSpinner />
                              //       </div>
                              //     </div>
                              //   );
                              // if (leadsError)
                              //   return (
                              //     <div className="container-fluid pt-3">
                              //       {leadsError.graphQLErrors[0].message}
                              //     </div>
                              //   );
                              return (
                                // <div className="container-fluid pt-3 leads-page">
                                <>
                                  <div className="container mt-3">
                                    {guestsLoading && (
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '80vh' }}
                                      >
                                        <LoadingSpinner />
                                      </div>
                                    )}
                                  </div>

                                  <div>
                                    {guestsError && (
                                      <div className="container-fluid pt-3">
                                        {guestsError.message}
                                        <a
                                          href="#"
                                          onClick={() => {
                                            const queryParamsClone = {
                                              ...queryParams,
                                            };
                                            delete queryParamsClone.guestsPage;
                                            delete queryParamsClone.leadsPage;
                                            updateUrl(
                                              {
                                                ...queryParams,
                                                search: searchTerm,
                                              },
                                              true
                                            );
                                          }}
                                        >
                                          Try again
                                        </a>
                                        .
                                      </div>
                                    )}
                                  </div>

                                  <div>
                                    {/* {guests && !guestsLoading && (
                                      <div className="">
                                        <GuestTable guests={guests} />
                                      </div>
                                    )} */}
                                    {guests && !guestsLoading && (
                                      <ThemedTable>
                                        <GuestsInnerTable
                                          guests={guests}
                                          count={count}
                                        />
                                      </ThemedTable>
                                    )}
                                    {guestsConnection &&
                                      guestsConnection.aggregate.count > 10 && (
                                        <div className="">
                                          <ThemedPager
                                            totalRecords={
                                              guestsConnection.aggregate.count
                                            }
                                            dataConnection={guestsConnection}
                                            recordsPerPage={recordsPerPage}
                                            pageNumber={guestsPageNumber}
                                            changePageNumber={pageNumber => {
                                              updateUrl('leads', {
                                                ...queryParams,
                                                guestsPage: pageNumber,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                  {searchTerm && (
                                    <h1 className="heading mt-4">Leads</h1>
                                  )}

                                  {leadsLoading && (
                                    <div className="container mt-3">
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '80vh' }}
                                      >
                                        <LoadingSpinner />
                                      </div>
                                    </div>
                                  )}

                                  {!leadsLoading && guestLeadsConnection && (
                                    <LeadsTable
                                      // relatedLeads={relatedLeads}
                                      updatedStatus={updatedStatus}
                                      // selectedLead={selectedLead}
                                      onRowClick={(lead, hasRelatedLead) => {
                                        const statusforUrl =
                                          labelToUrlKeyMap[
                                            apiDataToLabelMap[lead.status]
                                          ];
                                        // setSelectedLead({
                                        //   ...lead,
                                        //   emailDate: lead && lead.leadDate,
                                        //   hasRelatedLead,
                                        // });
                                        setModalIsOpen(true);
                                        setUpdatedStatus(lead.status);
                                        updateUrl(
                                          'leads',
                                          {
                                            ...queryParams,
                                            // leadStatus: statusforUrl,
                                            id: lead.id,
                                          },
                                          true
                                        );

                                        // updateUrl(
                                        //   'leads',
                                        //   {
                                        //     ...queryParams,
                                        //     leadStatus: statusforUrl,
                                        //     id: lead.id,
                                        //   },
                                        //   true
                                        // );
                                      }}
                                      count={
                                        guestLeadsConnection.aggregate.count
                                      }
                                      nodes={guestLeadsConnection.edges.map(
                                        edge => edge.node
                                      )}
                                    />
                                  )}

                                  {guestLeadsConnection &&
                                    guestLeadsConnection.aggregate.count >
                                      10 && (
                                      <ThemedPager
                                        totalRecords={
                                          guestLeadsConnection.aggregate.count
                                        }
                                        dataConnection={guestLeadsConnection}
                                        recordsPerPage={recordsPerPage}
                                        pageNumber={leadsPageNumber}
                                        changePageNumber={pageNumber => {
                                          updateUrl('leads', {
                                            ...queryParams,
                                            leadsPage: pageNumber,
                                          });
                                        }}
                                      />
                                    )}
                                  <Query
                                    variables={{
                                      // id: selectedLead
                                      //   ? selectedLead.id
                                      //   : urlId,
                                      id: urlId,
                                    }}
                                    query={GUEST_LEAD}
                                    skip={!urlId}
                                    errorPolicy="all"
                                    fetchPolicy="no-cache"
                                  >
                                    {({
                                      loading,
                                      error,
                                      data: leadData,
                                      refetch,
                                    }) => {
                                      const validationErrors = error
                                        ? get(
                                            error,
                                            'graphQLErrors[0].extensions.exception.validationErrors[0].message'
                                          ) || null
                                        : null;
                                      const { guestLead } = leadData || {};
                                      const selectedLeadData = guestLead;

                                      const formatPhoneNumber = phoneNumberString => {
                                        var cleaned = (
                                          '' + phoneNumberString
                                        ).replace(/\D/g, '');
                                        var match = cleaned.match(
                                          /^(1|)?(\d{3})(\d{3})(\d{4})$/
                                        );
                                        if (match) {
                                          var intlCode = '';
                                          return [
                                            intlCode,

                                            match[2],
                                            '-',
                                            match[3],
                                            '-',
                                            match[4],
                                          ].join('');
                                        }
                                        return null;
                                      };

                                      const guestMatchId =
                                        get(
                                          selectedLeadData,
                                          'guestMatch.id'
                                        ) || null;
                                      const relatedLeads =
                                        get(selectedLeadData, 'relatedLeads') ||
                                        null;
                                      const numberOfPets =
                                        get(selectedLeadData, 'numberOfPets') ||
                                        null;
                                      const numberOfChildren =
                                        get(
                                          selectedLeadData,
                                          'numberOfChildren'
                                        ) || null;
                                      const numberOfAdults =
                                        get(
                                          selectedLeadData,
                                          'numberOfAdults'
                                        ) || null;

                                      return (
                                        <>
                                          {guestLead && urlId && (
                                            <Helmet>
                                              <meta charSet="utf-8" />
                                              <title>{`BHIP - Lead - ${guestLead.name
                                                .toLowerCase()
                                                .split(' ')
                                                .map(
                                                  word =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(' ')} - ${
                                                guestLead.id
                                              }`}</title>
                                            </Helmet>
                                          )}
                                          {modalIsOpen && (
                                            <Modal
                                              onClose={() => {
                                                setModalIsOpen(false);
                                                const queryParamsClone = {
                                                  ...queryParams,
                                                };

                                                delete queryParamsClone.id;
                                                delete queryParamsClone.leadStatus;
                                                //                                                setSelectedLead(null);
                                                updateUrl(
                                                  'leads',
                                                  {
                                                    ...queryParamsClone,
                                                  },
                                                  true
                                                );
                                              }}
                                              isOpen={modalIsOpen}
                                              // title="Contact"
                                              customHeader={() => {
                                                return (
                                                  <div className="d-flex align-items-end">
                                                    <h5 className="modal-title w-100">
                                                      Contact
                                                    </h5>
                                                  </div>
                                                );
                                              }}
                                              styleType="large"
                                              disableForceFocus={true}
                                              footerContent={
                                                <div className="w-100">
                                                  {
                                                    <div className="w-100">
                                                      {selectedLeadData &&
                                                        selectedLeadData.message && (
                                                          <>
                                                            <span className="label font-weight-bold">
                                                              Message:
                                                            </span>{' '}
                                                            <FormatNewLineToText
                                                              text={
                                                                selectedLeadData.message
                                                              }
                                                            />
                                                          </>
                                                        )}
                                                    </div>
                                                  }
                                                  {selectedLeadData &&
                                                    selectedLeadData
                                                      .relatedLeads.length >
                                                      0 && (
                                                      <div className="leads-modal-table pt-3">
                                                        <div className="mb-1">
                                                          <span className="label font-weight-bold mb-2">
                                                            Related Leads:
                                                          </span>
                                                        </div>

                                                        <LeadsTable
                                                          customClass={
                                                            'border-0'
                                                          }
                                                          customTableClass={`px-0`}
                                                          updatedStatus={
                                                            updatedStatus
                                                          }
                                                          selectedLead={
                                                            selectedLeadData
                                                          }
                                                          onRowClick={(
                                                            lead,
                                                            hasRelatedLead
                                                          ) => {
                                                            const statusforUrl =
                                                              labelToUrlKeyMap[
                                                                apiDataToLabelMap[
                                                                  lead.status
                                                                ]
                                                              ];

                                                            // window.open(
                                                            //   `/leads?id=${
                                                            //     lead.id
                                                            //   }${
                                                            //     statusforUrl &&
                                                            //     statusforUrl !==
                                                            //       ''
                                                            //       ? `&leadStatus=${statusforUrl}`
                                                            //       : ''
                                                            //   }`,
                                                            //   '_blank'
                                                            // );
                                                            window.open(
                                                              `/leads?id=${lead.id}`,
                                                              '_blank'
                                                            );
                                                          }}
                                                          nodes={
                                                            selectedLeadData.relatedLeads
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                </div>
                                              }
                                            >
                                              <div className="container">
                                                <div className="row position-relative">
                                                  {!loading &&
                                                    !selectedLeadData &&
                                                    modalIsOpen && (
                                                      <div className="container mt-3">
                                                        <div
                                                          className="d-flex justify-content-center align-items-center"
                                                          // style={{ height: '80vh' }}
                                                        >
                                                          <span>
                                                            We're sorry, there
                                                            are no leads
                                                            available with the
                                                            provided ID.
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                  <div className="col-8-md pl-0 w-100">
                                                    <div className="container mt-3">
                                                      {loading && (
                                                        <div
                                                          className="d-flex justify-content-center align-items-center"
                                                          style={{
                                                            height: '80vh',
                                                          }}
                                                        >
                                                          <LoadingSpinner />
                                                        </div>
                                                      )}
                                                    </div>
                                                    {/* <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                      height: '80vh',
                                                    }}
                                                  >
                                                    <LoadingSpinner />
                                                  </div> */}

                                                    {selectedLeadData &&
                                                      selectedLeadData.name &&
                                                      !loading && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            From:
                                                          </span>{' '}
                                                          <span>
                                                            {
                                                              selectedLeadData.name
                                                            }
                                                          </span>
                                                          {guestMatchId && (
                                                            // <a
                                                            //   className="badge badge-success align-middle ml-2"
                                                            //   href={`/guests/${guestMatchId}`}
                                                            //   target="_blank"
                                                            // >
                                                            //   <span className="mr-1 align-middle">
                                                            //     Guest Found
                                                            //   </span>
                                                            //   {
                                                            //     <ExternalLink
                                                            //       size="15"
                                                            //       // className="ml-2"
                                                            //     />
                                                            //   }
                                                            // </a>
                                                            <a
                                                              title="View guest"
                                                              className=" align-bottom ml-2"
                                                              href={`/guests/${guestMatchId}`}
                                                              target="_blank"
                                                            >
                                                              <img
                                                                className="logo"
                                                                src={Logo}
                                                                height="28"
                                                                width="24"
                                                              />
                                                            </a>
                                                          )}
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.email && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Email:
                                                          </span>{' '}
                                                          <a
                                                            href={`https://www.google.com/search?&q=${selectedLeadData.email}`}
                                                            target="_blank"
                                                          >
                                                            {
                                                              selectedLeadData.email
                                                            }
                                                          </a>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.phoneNumber && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Phone Number:
                                                          </span>{' '}
                                                          <a
                                                            target="_blank"
                                                            href={`https://www.google.com/search?&q=${formatPhoneNumber(
                                                              selectedLeadData.phoneNumber
                                                            )}`}
                                                          >
                                                            {
                                                              selectedLeadData.phoneNumber
                                                            }
                                                          </a>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.localTraveler && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Local Travelers:
                                                          </span>{' '}
                                                          <span>
                                                            {
                                                              selectedLeadData.localTraveler
                                                            }
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.emailDate && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Date:
                                                          </span>{' '}
                                                          <span>
                                                            {moment(
                                                              selectedLeadData.emailDate
                                                            )
                                                              .utc()
                                                              .format(
                                                                'MM/DD/YYYY'
                                                              )}{' '}
                                                            at{' '}
                                                            {moment
                                                              .utc(
                                                                selectedLeadData.emailDate
                                                              )
                                                              .format(
                                                                'hh:mm a'
                                                              )}
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.referrer && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Referrer:
                                                          </span>{' '}
                                                          <span>
                                                            {
                                                              selectedLeadData.referrer
                                                            }
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.origin && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Origin:
                                                          </span>{' '}
                                                          <span>
                                                            {
                                                              selectedLeadData.origin
                                                            }
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.ipAddress && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            IP Address:
                                                          </span>{' '}
                                                          <a
                                                            href={`https://whatismyipaddress.com/ip/${selectedLeadData.ipAddress}`}
                                                            target="_blank"
                                                          >
                                                            {
                                                              selectedLeadData.ipAddress
                                                            }
                                                          </a>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.checkInPreference && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Check In Preference:
                                                          </span>{' '}
                                                          <span>
                                                            {moment(
                                                              selectedLeadData.checkInPreference
                                                            )
                                                              .utc()
                                                              .format(
                                                                'MM/DD/YYYY'
                                                              )}
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.checkOutPreference && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Check Out
                                                            Preference:
                                                          </span>{' '}
                                                          <span>
                                                            {moment(
                                                              selectedLeadData.checkOutPreference
                                                            )
                                                              .utc()
                                                              .format(
                                                                'MM/DD/YYYY'
                                                              )}
                                                          </span>
                                                        </p>
                                                      )}
                                                    {selectedLeadData &&
                                                      selectedLeadData.targetProperty && (
                                                        <PropertyDetails
                                                          selectedLeadData={
                                                            selectedLeadData
                                                          }
                                                          id={
                                                            selectedLeadData
                                                              .targetProperty.id
                                                          }
                                                        />
                                                      )}

                                                    <>
                                                      {selectedLeadData && (
                                                        <div className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Availability:
                                                          </span>{' '}
                                                          {validationErrors && (
                                                            <>
                                                              <span>
                                                                {
                                                                  validationErrors
                                                                }
                                                              </span>
                                                              {/* <span className="pl-1">
                                                                (
                                                                <>
                                                                  <QuoteGeneratorModal
                                                                    selectedLeadData={
                                                                      selectedLeadData
                                                                    }
                                                                  />
                                                                </>
                                                                )
                                                              </span> */}
                                                            </>
                                                          )}
                                                          {guestLead &&
                                                            guestLead.propertyQuote && (
                                                              <span className="text-success">
                                                                Available
                                                              </span>
                                                            )}
                                                          {guestLead &&
                                                            !guestLead.propertyQuote &&
                                                            !validationErrors && (
                                                              <span>N/A</span>
                                                            )}
                                                        </div>
                                                      )}
                                                      {numberOfAdults && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Adults:
                                                          </span>{' '}
                                                          <span>
                                                            {numberOfAdults}
                                                          </span>
                                                        </p>
                                                      )}
                                                      {numberOfChildren && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Children:
                                                          </span>{' '}
                                                          <span>
                                                            {numberOfChildren}
                                                          </span>
                                                        </p>
                                                      )}

                                                      {numberOfPets && (
                                                        <p className="mb-1">
                                                          <span className="label font-weight-bold">
                                                            Pets:
                                                          </span>{' '}
                                                          <span>
                                                            {numberOfPets}
                                                          </span>
                                                        </p>
                                                      )}
                                                      {selectedLeadData &&
                                                        selectedLeadData.numberOfBedrooms && (
                                                          <p className="mb-1">
                                                            <span className="label font-weight-bold">
                                                              Bedrooms:
                                                            </span>{' '}
                                                            <span>
                                                              {
                                                                selectedLeadData.numberOfBedrooms
                                                              }
                                                            </span>
                                                          </p>
                                                        )}
                                                      {selectedLeadData &&
                                                        selectedLeadData.numberOfBathrooms && (
                                                          <p className="mb-1">
                                                            <span className="label font-weight-bold">
                                                              Bathrooms:
                                                            </span>{' '}
                                                            <span>
                                                              {
                                                                selectedLeadData.numberOfBathrooms
                                                              }
                                                            </span>
                                                          </p>
                                                        )}
                                                      {selectedLeadData &&
                                                        selectedLeadData.numberOfVehicles && (
                                                          <p className="mb-1">
                                                            <span className="label font-weight-bold">
                                                              Vehicles:
                                                            </span>{' '}
                                                            <span>
                                                              {
                                                                selectedLeadData.numberOfVehicles
                                                              }
                                                            </span>
                                                          </p>
                                                        )}
                                                      {selectedLeadData &&
                                                        selectedLeadData.numberOfVisitors && (
                                                          <p className="mb-1">
                                                            <span className="label font-weight-bold">
                                                              Visitors:
                                                            </span>{' '}
                                                            <span>
                                                              {
                                                                selectedLeadData.numberOfVisitors
                                                              }
                                                            </span>
                                                          </p>
                                                        )}
                                                      {selectedLeadData &&
                                                        selectedLeadData
                                                          .guestGroupTypes
                                                          .length > 0 && (
                                                          <p className="mb-1">
                                                            <span className="label font-weight-bold">
                                                              Group Type:
                                                            </span>{' '}
                                                            {selectedLeadData.guestGroupTypes
                                                              .map(
                                                                groupType =>
                                                                  groupType.label
                                                              )
                                                              .join(', ')}
                                                          </p>
                                                        )}
                                                    </>
                                                  </div>

                                                  <div className="col p-0 w-100 position-absolute">
                                                    {selectedLeadData && (
                                                      <div
                                                        style={{ right: '0px' }}
                                                        className="background-colored-dropdown position-absolute"
                                                      >
                                                        <p className="label font-weight-bold mb-0">
                                                          Choose Status:
                                                        </p>
                                                        <div className="background-colored-dropdown ">
                                                          <AlertsConsumer>
                                                            {({ refetch }) => {
                                                              const getFilterSelected = status => {
                                                                // if (urlId) {
                                                                //   return apiDataToLabelMap[
                                                                //     updatedStatus
                                                                //   ]
                                                                //     ? apiDataToLabelMap[
                                                                //         updatedStatus
                                                                //       ]
                                                                //     : urlToLabelKeyMap[
                                                                //         queryParams
                                                                //           .leadStatus
                                                                //       ];
                                                                // }
                                                                // return apiDataToLabelMap[
                                                                //   updatedStatus
                                                                // ];
                                                                return apiDataToLabelMap[
                                                                  status
                                                                ];
                                                              };

                                                              return (
                                                                <DropdownSelector
                                                                  id={
                                                                    (data || {})
                                                                      .id
                                                                  }
                                                                  className="float-right"
                                                                  selectedOption={getFilterSelected(
                                                                    selectedLeadData.status
                                                                  )}
                                                                  // className="custom-dropdown"
                                                                  defaultValue={
                                                                    'Action Required'
                                                                  }
                                                                  options={options.reduce(
                                                                    (
                                                                      acc,
                                                                      option
                                                                    ) => {
                                                                      if (
                                                                        apiDataToLabelMap[
                                                                          option
                                                                        ] !==
                                                                        'Show All'
                                                                      ) {
                                                                        acc.push(
                                                                          apiDataToLabelMap[
                                                                            option
                                                                          ]
                                                                        );
                                                                      }
                                                                      return acc;
                                                                    },
                                                                    []
                                                                  )}
                                                                  onSelect={async selectedValue => {
                                                                    const statusforUrl =
                                                                      labelToUrlKeyMap[
                                                                        selectedValue
                                                                      ];

                                                                    const result = await updateLead(
                                                                      selectedLeadData.id,
                                                                      updateGuestLead,
                                                                      selectedValue,
                                                                      setUpdatedStatus
                                                                    );

                                                                    // updateUrl(
                                                                    //   'leads',
                                                                    //   {
                                                                    //     ...queryParams,
                                                                    //     leadStatus: statusforUrl,
                                                                    //   }
                                                                    // );

                                                                    await refetch();
                                                                  }}
                                                                />
                                                              );
                                                            }}
                                                          </AlertsConsumer>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Modal>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Query>

                                  {/* </div> */}
                                </>
                              );
                            }}
                          </Query>
                        </>
                      );
                    }}
                  </Query>
                </div>
              )}
            </Mutation>
          </Layout>
        );
      }}
    </Location>
  );
};

export default Leads;
