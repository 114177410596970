import React from 'react';
import GuestTable from '../GuestTable';
import Query from 'src/components/GraphQL/components/Query';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
import ThemedTable from 'src/components/ThemedTable/index.js';
import GuestsInnerTable from 'src/routes/guests/components/GuestsInnerTable';

const RELATED_GUESTS = gql`
  query RelatedGuests($id: Int) {
    guest(where: { id: $id }) {
      id
      firstName
      lastName
      relatedGuests {
        id
        firstName
        lastName
        primaryEmailAddress {
          emailAddress
        }
        primaryPhoneNumber {
          phoneNumber
        }
        guestSource {
          id
          name
        }
      }
    }
  }
`;

const RelatedGuests = ({ id }) => {
  return (
    <Query
      variables={{
        id,
      }}
      query={RELATED_GUESTS}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, refetch }) => {
        const relatedGuests = data?.guest?.relatedGuests;
        if (loading)
          return (
            <div className="container-fluid pt-3">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            </div>
          );
        if (error)
          return (
            <div className="container-fluid pt-3">
              {error.message}
              <a
                href="#"
                onClick={() => {
                  updateUrl(searchTerm);
                }}
              >
                Try again
              </a>
              .
            </div>
          );
        // return <GuestTable guests={relatedGuests} />;
        return (
          <ThemedTable>
            <GuestsInnerTable guests={relatedGuests} count={null} />{' '}
          </ThemedTable>
        );
      }}
    </Query>
  );
};

export default RelatedGuests;
