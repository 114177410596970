export const formDataToArray = form => {
  const data = new FormData(form);

  return Array.from(data.entries()).reduce((acc, [key, value]) => {
    if (key) {
      acc.push(`${key}`);
    }
    return acc;
  }, []);
};
