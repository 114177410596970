import React from 'react';
import toggleOn from 'src/images/toggle-on.svg';
import toggleOff from 'src/images/toggle-off.svg';

const CalendarLinkToggle = ({ location }) => {
  const params = new URLSearchParams(location?.search);

  const isMultiCalendar = params.get('displaymode') === 'multicalendar';
  const getCalendarToggleLinkPath = () => {
    const startDate = params.get('start');
    // const endDate = params.get('end');
    const displayMode = isMultiCalendar ? `` : `&displaymode=multicalendar`;
    return `/calendar?start=${startDate}${displayMode}`;
  };
  return (
    <div>
      <a
        href={getCalendarToggleLinkPath()}
        className="mr-2 d-flex align-items-center"
      >
        {isMultiCalendar ? (
          <img width={'28px'} src={toggleOn} />
        ) : (
          <img width={'28px'} className="text-primary" src={toggleOff} />
        )}
        <span className="ml-1">Multicalendar</span>
      </a>
    </div>
  );
};

export default CalendarLinkToggle;
