import React, { useMemo } from 'react';

import moment from 'moment';

import Markdown from 'react-markdown';
const getGuestDisplay = ip => {
  if (ip) {
    const split = ip.split('.');
    if (split) {
      return `Guest ${split[split.length - 1]}`;
    }
  }
  return '';
};

const NoteDetails = ({ noteDetails }) => {
  const expert = noteDetails.expert ? noteDetails.expert : expert;
  return (
    <>
      <div className="mb-3">
        {noteDetails.message ? <Markdown>{noteDetails.message}</Markdown> : 'No Notes.'}
      </div>
      <div className="mb-3">
        <span>
          <b>Property:</b> {noteDetails.propertyLabel}
        </span>
      </div>
      <div className="mb-3">
        <span>
          <b>Dates:</b>{' '}
          {noteDetails.checkInPreference && noteDetails.checkOutPreference
            ? `${moment(noteDetails.checkInPreference).format(
                'MM/DD/YYYY'
              )} to ${moment(noteDetails.checkOutPreference).format(
                'MM/DD/YYYY'
              )}`
            : 'None.'}
        </span>
      </div>
      <div className="mb-3">
        <span>
          <b>Reservation:</b>{' '}
          {noteDetails.reservation ? noteDetails.reservation : 'None.'}
        </span>
      </div>
      <div className="mb-3">
        <span>
          <b>Expert:</b> {expert ? expert : getGuestDisplay(ipAddress)}
        </span>
      </div>
      <div className="mb-3">
        <span>
          <b>Created:</b> {noteDetails.dateCreated} at {noteDetails.timeCreated}
        </span>
      </div>
    </>
  );
};

export default NoteDetails;
