import React, { useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import Query from 'src/components/GraphQL/components/Query';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Loadable from '@loadable/component';
import FormResponse from 'src/components/Forms/components/FormResponse';
import LoadingSpinner from 'src/components/LoadingSpinner';

import { ExternalLink } from 'react-feather';
import FinalizedOptionsForm from 'src/routes/reports/components/BookingsTable/components/FinalizedOptionsForm';
import RedFlagsForm from 'src/routes/reports/components/BookingsTable/components/RedFlagsForm';
import Modal from 'src/components/Modal';
//import './styles.scss';
import { Info } from 'react-feather';
import Tooltip from 'src/components/Tooltip';
import moment from 'moment';
import { enumToLabel } from 'src/util';

import * as Yup from 'yup';
import { update } from 'lodash';

// import { stubTrue } from 'lodash';

const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));

// const PROPERTIES_QUERY = gql`
//   query Properties {
//     properties {
//       id
//       name
//     }
//   }
// `;
const validationSchema = Yup.object().shape({
  reference: Yup.string().required('Reference is required'),
  platformId: Yup.string().required('Platform is required'),
  propertyId: Yup.string().required('Property is required'),
  checkIn: Yup.string().required('Check in is required'),
  checkOut: Yup.string().required('Check out is required'),
  reservationCreateDate: Yup.string()
    .required('Reservation create date is required')
    .nullable(),
  status: Yup.string().required('Status is required'),
});

const CREATE_RESERVATION_QUERY = gql`
  query CreateReservationQuery {
    platforms {
      id
      name
      key
    }

    properties {
      id
      name
      propertyListings {
        id
      }
      # key
    }
    guestReservationStatusOptions {
      type
    }
    resortAccess: __type(name: "GuestReservationResortAccessOption") {
      name
      enumValues {
        name
      }
    }
    midStayClean: __type(name: "GuestReservationMidStayCleanOption") {
      name
      enumValues {
        name
      }
    }
    mobilityChallenged: __type(
      name: "GuestReservationMobilityChallengedOption"
    ) {
      name
      enumValues {
        name
      }
    }
    largeVehicles: __type(name: "GuestReservationLargeVehicleOption") {
      name
      enumValues {
        name
      }
    }
    dateSquatterExempt: __type(name: "GuestReservationDateSquatterExempt") {
      name
      enumValues {
        name
      }
    }
    confirmationStatus: __type(name: "GuestReservationConfirmationStatus") {
      name
      enumValues {
        name
      }
    }
    eventType: __type(name: "GuestReservationEventType") {
      name
      enumValues {
        name
      }
    }
    cancellationTypeOptions: __type(
      name: "GuestReservationCancellationTypeOption"
    ) {
      name
      enumValues {
        name
      }
    }
    agreementSigned: __type(name: "GuestReservationAgreementSigned") {
      name
      enumValues {
        name
      }
    }
  }
`;

const CREATE_GUEST_RESERVATION = gql`
  mutation CreateGuestReservation(
    $guestReservationCreateInput: GuestReservationCreateInput!
  ) {
    createGuestReservation(data: $guestReservationCreateInput) {
      guest {
        id
      }
    }
  }
`;

const UPDATE_GUEST_RESERVATION = gql`
  mutation UpdateGuestReservation(
    $guestWhereUniqueInput: GuestReservationWhereUniqueInput!
    $guestReservationUpdateInput: GuestReservationUpdateInput
  ) {
    updateGuestReservation(
      where: $guestWhereUniqueInput
      data: $guestReservationUpdateInput
    ) {
      guest {
        id
      }
    }
  }
`;

const GUEST_RESERVATION = gql`
  query GuestReservation($reference: String!) {
    guestReservationFinalizationStepOptions {
      id
      label
      stage
      sort
    }
    guestReservationRedFlagOptions {
      id
      # type
      label
      sort
    }
    guestReservationStatusOptions {
      type
    }
    guestReservation(where: { reference: $reference }) {
      id
      reference
      status
      dateSquatterExempt
      vehicles
      largeVehicles
      resortAccess
      midStayClean
      mobilityChallenged
      externalConversationUrl
      cancellationType
      propertyListing {
        id
      }
      property {
        name
        id
        amenities {
          key
        }
      }
      geoRelatedReservations {
        reference
        externalConversationUrl
        guest {
          id
        }
      }
      platform {
        id
        name
      }
      guest {
        firstName
        lastName
      }
      checkIn
      checkOut
      reservationCreateDate
      visitors
      children
      adults
      pets
      agreementSigned
      eventType
      confirmationStatus
      status
      finalizationSteps {
        id
        label
        readOnly
      }
      redFlags {
        id
        label
        type
      }
    }
    resortAccess: __type(name: "GuestReservationResortAccessOption") {
      name
      enumValues {
        name
      }
    }
    midStayClean: __type(name: "GuestReservationMidStayCleanOption") {
      name
      enumValues {
        name
      }
    }
    mobilityChallenged: __type(
      name: "GuestReservationMobilityChallengedOption"
    ) {
      name
      enumValues {
        name
      }
    }
    largeVehicles: __type(name: "GuestReservationLargeVehicleOption") {
      name
      enumValues {
        name
      }
    }
    dateSquatterExempt: __type(name: "GuestReservationDateSquatterExempt") {
      name
      enumValues {
        name
      }
    }
    confirmationStatus: __type(name: "GuestReservationConfirmationStatus") {
      name
      enumValues {
        name
      }
    }
    eventType: __type(name: "GuestReservationEventType") {
      name
      enumValues {
        name
      }
    }
    cancellationTypeOptions: __type(
      name: "GuestReservationCancellationTypeOption"
    ) {
      name
      enumValues {
        name
      }
    }
    agreementSigned: __type(name: "GuestReservationAgreementSigned") {
      name
      enumValues {
        name
      }
    }
  }
`;

const PROPERTY_LISTINGS = gql`
  query PropertyListings {
    propertyListings {
      id
      property {
        id
      }
      platform {
        id
      }
    }
  }
`;

const GuestReservationForm = ({
  reference,
  onSuccess,
  onDataLoad,
  guestId,
  guestReservationRedFlagOptions,
  ...rest
}) => {
  const [finalizationStepsState, setFinalizationStepsState] = useState(null);
  const [finalizeStatusModalIsOpen, setFinalizeStatusModalIsOpen] = useState(
    false
  );
  const [redFlagsModalIsOpen, setRedFlagsModalIsOpen] = useState(false);
  const [redFlagsState, setRedFlagsState] = useState(null);
  return (
    <Query
      // variables={{
      //   reference,
      //   skip: !reference,
      // }}
      query={CREATE_RESERVATION_QUERY}
    >
      {({ loading, error, data: createReservationQueryData, refetch }) => {
        const platforms = createReservationQueryData?.platforms;
        const createFormAgreementOptions =
          createReservationQueryData?.agreementSigned?.enumValues;
        const createFormDateSquatterOptions =
          createReservationQueryData?.dateSquatterExempt?.enumValues;
        const createFormConfirmationStatusOptions =
          createReservationQueryData?.confirmationStatus?.enumValues;
        const createFormEventTypeOptions =
          createReservationQueryData?.eventType?.enumValues;
        const createFormCancellationTypeOptions =
          createReservationQueryData?.cancellationTypeOptions?.enumValues;
        const createFormLargeVehicleOptions =
          createReservationQueryData?.largeVehicles?.enumValues;
        const createFormMobilityChallengedOptions =
          createReservationQueryData?.mobilityChallenged?.enumValues;
        const createFormMidStayCleanOptions =
          createReservationQueryData?.midStayClean?.enumValues;
        const createResortAccessFormOptions =
          createReservationQueryData?.resortAccess?.enumValues;
        const createGuestReservationStatusOptions =
          createReservationQueryData?.guestReservationStatusOptions;
        const allProperties = createReservationQueryData?.properties || [];

        const propertyOptions = allProperties.reduce((acc, property) => {
          if (property.propertyListings.length > 0) {
            acc.push(property);
          }
          return acc;
        }, []);

        return (
          <Query
            variables={{
              skip: !reference,
            }}
            query={PROPERTY_LISTINGS}
          >
            {({ loading, error, data: propertyListingsData, refetch }) => {
              const propertyListings = propertyListingsData?.propertyListings;
              const isUpdate = reference ? true : false;

              return (
                <Query
                  variables={{
                    reference,
                  }}
                  skip={!isUpdate}
                  query={GUEST_RESERVATION}
                  fetchPolicy={'no-cache'}
                >
                  {({
                    loading,
                    error,
                    data: guestReservationData,
                    refetch: refetchReservation,
                  }) => {
                    if (loading)
                      return (
                        <div style={{ maxHeight: '200px' }}>
                          <div className="d-flex justify-content-center position-relative mt-2">
                            <LoadingSpinner customHeight={0} />
                          </div>
                        </div>
                      );
                    if (error) {
                      return (
                        <div className="conatiner-fluid pt-3">
                          {error.message}
                        </div>
                      );
                    }
                    const reservation = guestReservationData?.guestReservation;

                    const finalizationStepOptions =
                      guestReservationData?.guestReservationFinalizationStepOptions;
                    const redFlagOptions =
                      guestReservationData?.guestReservationRedFlagOptions;
                    const dateSquatterFormOptions =
                      guestReservationData?.dateSquatterExempt?.enumValues ||
                      createFormDateSquatterOptions;
                    const agreementSignedFormOptions =
                      guestReservationData?.agreementSigned?.enumValues ||
                      createFormAgreementOptions;
                    const confirmationStatusFormOptions =
                      guestReservationData?.confirmationStatus?.enumValues ||
                      createFormConfirmationStatusOptions;
                    const eventTypeFormOptions =
                      guestReservationData?.eventType?.enumValues ||
                      createFormEventTypeOptions;
                    const cancellationTypeFormOptions =
                      guestReservationData?.cancellationTypeOptions
                        ?.enumValues || createFormCancellationTypeOptions;
                    const largeVehiclesFormOptions =
                      guestReservationData?.largeVehicles?.enumValues ||
                      createFormLargeVehicleOptions;
                    const mobilityChallengedFormOptions =
                      guestReservationData?.mobilityChallenged?.enumValues ||
                      createFormMobilityChallengedOptions;
                    const midStayCleanFormOptions =
                      guestReservationData?.midStayClean?.enumValues ||
                      createFormMidStayCleanOptions;
                    const resortAccessFormOptions =
                      guestReservationData?.resortAccess?.enumValues ||
                      createResortAccessFormOptions;
                    const guestReservationStatusOptions =
                      guestReservationData?.guestReservationStatusOptions ||
                      createGuestReservationStatusOptions;

                    onDataLoad(reservation);

                    const getFinalizeStatusLabel = finalizationSteps => {
                      // const allSteps = addDefaultValuesToFinalizeSteps(topLevelTableData);
                      if (
                        finalizationSteps?.length ===
                        finalizationStepOptions?.length
                      ) {
                        return 'Complete';
                      }
                      return `${finalizationSteps?.length || 0} of ${
                        finalizationStepOptions?.length
                      }`;
                    };

                    const getInitialFormValue = (name, defaultValue) => {
                      switch (name) {
                        case 'agreementSigned': {
                          return reservation?.agreementSigned || undefined;
                        }
                        case 'eventType': {
                          if (reservation) {
                            return reservation?.eventType || undefined;
                          }
                          return undefined;
                        }
                        case 'confirmationStatus': {
                          if (reservation) {
                            return reservation?.confirmationStatus || undefined;
                          }
                          return undefined;
                        }
                        case 'dateSquatterExempt': {
                          if (reservation) {
                            return reservation?.dateSquatterExempt || undefined;
                          }
                          return undefined;
                        }
                        case 'largeVehicles': {
                          if (reservation) {
                            return reservation?.largeVehicles || undefined;
                          }
                          return undefined;
                        }
                        case 'mobilityChallenged': {
                          if (reservation) {
                            // console.log('reservation', reservation);
                            return reservation?.mobilityChallenged || undefined;
                          }
                          return undefined;
                        }
                        case 'midStayClean': {
                          if (reservation) {
                            return reservation?.midStayClean || undefined;
                          }
                          return undefined;
                        }
                        case 'resortAccess': {
                          if (reservation) {
                            return reservation?.resortAccess || undefined;
                          }
                          return undefined;
                        }
                        default:
                          return undefined;
                      }
                    };

                    return (
                      <>
                        <Mutation
                          mutation={
                            isUpdate
                              ? UPDATE_GUEST_RESERVATION
                              : CREATE_GUEST_RESERVATION
                          }
                        >
                          {(
                            guestReservationMutation,
                            {
                              data: updatedGuestReservation,
                              error: mutationError,
                            }
                          ) => {
                            // const tomorrow = new Date();
                            // tomorrow.setDate(tomorrow.getDate() + 1);

                            return (
                              <Formik
                                validationSchema={validationSchema}
                                // enableReinitialize={true}
                                initialValues={{
                                  reference: reference || '',
                                  propertyName:
                                    reservation?.property?.name || '',
                                  // propertyId: isUpdate ? '1' : '', // 1 is an arbitrary number so that theres a value present so it doesn't trip up the validation in update mode
                                  // platformId: isUpdate ? '1' : '', // 1 is an arbitrary number so that theres a value present so it doesn't trip up the validation in update mode

                                  propertyId: isUpdate
                                    ? reservation?.property?.id
                                    : '',
                                  platformId: isUpdate
                                    ? reservation?.platform?.id
                                    : '',
                                  platformKey: '',
                                  checkIn: reservation?.checkIn || null,
                                  checkOut: reservation?.checkOut || null,
                                  reservationCreateDate:
                                    reservation?.reservationCreateDate || '',
                                  visitors: reservation?.visitors || 0,
                                  vehicles: reservation?.vehicles || 0,
                                  largeVehicles: getInitialFormValue(
                                    'largeVehicles'
                                  ),
                                  children: reservation?.children || 0,
                                  adults: reservation?.adults || 0,
                                  pets: reservation?.pets || 0,
                                  agreementSigned: getInitialFormValue(
                                    'agreementSigned'
                                  ),

                                  eventType: getInitialFormValue('eventType'),
                                  // resortAccess: reservation?.resortAccess || 'NO',
                                  resortAccess: getInitialFormValue(
                                    'resortAccess'
                                  ),
                                  confirmationStatus: getInitialFormValue(
                                    'confirmationStatus'
                                  ),
                                  cancellationType:
                                    reservation?.cancellationType,
                                  status: reservation?.status || 'BOOKING',
                                  mobilityChallenged: getInitialFormValue(
                                    'mobilityChallenged'
                                  ),
                                  midStayClean: getInitialFormValue(
                                    'midStayClean'
                                  ),
                                  dateSquatterExempt: getInitialFormValue(
                                    'dateSquatterExempt'
                                  ),
                                  finalizationSteps: reservation?.finalizationSteps
                                    ? reservation?.finalizationSteps
                                    : null,
                                }}
                                onSubmit={async (
                                  values,
                                  {
                                    setStatus,
                                    setSubmitting,
                                    setFieldValue,
                                    setErrors,
                                  }
                                ) => {
                                  delete values.finalizationSteps;
                                  try {
                                    const {
                                      // propertyListingId,
                                      propertyId,
                                      platformId,
                                      children,
                                      checkIn,
                                      checkOut,
                                      adults,
                                      confirmationStatus,
                                      eventType,
                                      visitors,
                                      pets,
                                      agreementSigned,
                                      dateSquatterExempt,
                                      cancellationType,
                                      status,
                                      reference,
                                      reservationCreateDate,
                                      largeVehicles,
                                      midStayClean,
                                      mobilityChallenged,
                                      vehicles,
                                      resortAccess,
                                    } = values;

                                    const getPropertyListingId = () => {
                                      const foundPropertyListing = propertyListings.find(
                                        listing => {
                                          if (
                                            Number(propertyId) ===
                                              listing?.property?.id &&
                                            Number(platformId) ===
                                              listing?.platform?.id
                                          ) {
                                            return listing;
                                          }
                                        }
                                      );
                                      if (foundPropertyListing) {
                                        return foundPropertyListing?.id;
                                      }
                                      return null;
                                    };
                                    const propertyListingId = isUpdate
                                      ? reservation?.propertyListing?.id
                                      : getPropertyListingId();

                                    setSubmitting(true);

                                    const mutationSharedInput = {
                                      guestId,
                                      propertyListingId,
                                      adults: adults === '' ? null : adults,
                                      children:
                                        children === '' ? null : children,
                                      checkIn,
                                      checkOut,
                                      confirmationStatus: confirmationStatus
                                        ? confirmationStatus
                                        : null,
                                      agreementSigned: agreementSigned || null,
                                      eventType: eventType ? eventType : null,
                                      status,
                                      visitors:
                                        visitors === '' ? null : visitors,
                                      pets: pets === '' ? null : pets,
                                      vehicles:
                                        vehicles === '' ? null : vehicles,
                                      largeVehicles:
                                        largeVehicles === ''
                                          ? null
                                          : largeVehicles,
                                      midStayClean: midStayClean || null,
                                      mobilityChallenged:
                                        mobilityChallenged || null,
                                      resortAccess: resortAccess || null,
                                    };

                                    const getMutationResult = async () => {
                                      if (isUpdate) {
                                        return await guestReservationMutation({
                                          variables: {
                                            guestWhereUniqueInput: {
                                              reference,
                                            },
                                            guestReservationUpdateInput: {
                                              dateSquatterExempt,
                                              cancellationType,
                                              ...mutationSharedInput,
                                            },
                                          },
                                        });
                                      } else {
                                        return await guestReservationMutation({
                                          variables: {
                                            guestReservationCreateInput: {
                                              guestId,
                                              propertyListingId,
                                              reference,
                                              checkIn,
                                              checkOut,
                                              reservationCreateDate,
                                              ...mutationSharedInput,
                                            },
                                          },
                                        });
                                      }
                                    };

                                    const mutationResult = await getMutationResult();

                                    if (mutationResult) {
                                      setStatus({ success: true });
                                      setSubmitting(false);
                                      onSuccess();
                                      refetchReservation();
                                    }
                                  } catch (err) {
                                    console.log('err', err);

                                    setSubmitting(false);
                                    setStatus({ error: err });
                                  }

                                  return true;
                                }}
                              >
                                {({
                                  errors,
                                  touched,
                                  resetForm,
                                  isSubmitting,
                                  values,
                                  status,
                                  setFieldValue,
                                  setSubmitting,
                                  submitCount,
                                }) => {
                                  const hasFinalizationSteps =
                                    values?.finalizationSteps ||
                                    reservation?.finalizationSteps ||
                                    finalizationStepsState;
                                  const StatusColumn = () => {
                                    return (
                                      <div className="col col-12 col-md-6">
                                        <div className="form-group">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                              <label className="">Status</label>
                                              <span className="text-danger ml-1">
                                                *
                                              </span>
                                            </div>

                                            {hasFinalizationSteps && (
                                              <div className="mb-2">
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    setFinalizeStatusModalIsOpen(
                                                      true
                                                    );
                                                  }}
                                                  className="btn btn-link p-0 small"
                                                >
                                                  Finalized Status (
                                                  {getFinalizeStatusLabel(
                                                    finalizationStepsState ||
                                                      reservation?.finalizationSteps
                                                  )}
                                                  )
                                                </button>
                                              </div>
                                            )}
                                          </div>

                                          <Field
                                            as="select"
                                            name="status"
                                            // readOnly={true}
                                            // value={values?.platformKey}
                                            // onChange={handleChange}
                                            className={`form-control ${errors.status &&
                                              touched.status &&
                                              submitCount > 0 &&
                                              'is-invalid hide-background-image'}`}
                                          >
                                            <option hidden value="">
                                              Choose One
                                            </option>
                                            {guestReservationStatusOptions &&
                                              guestReservationStatusOptions.map(
                                                (option, index) => {
                                                  if (
                                                    !option?.type ||
                                                    option?.type === ''
                                                  ) {
                                                    return null;
                                                  }

                                                  return (
                                                    <option
                                                      key={index}
                                                      value={option?.type}
                                                      // defaultValue={true}
                                                    >
                                                      {enumToLabel(
                                                        option?.type
                                                      )}
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </Field>

                                          <ErrorMessage
                                            component="div"
                                            name="status"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                    );
                                  };
                                  return (
                                    <Form id="guest-reservation-form">
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Guest ID</label>
                                            
                                            <Field
                                              readOnly={isUpdate ? true : false}
                                              name="guestId"
                                              type="text"
                                              className={`form-control ${errors.guestId &&
                                                touched.guestId}`}
                                              value={values.guestId}
                                              placeholder="ex: HA-24567"
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="guestId"
                                              className="invalid-feedback"
                                            />
                                            {/* <div className="small text-danger mt-1">
                                            {touched.reference &&
                                              errors.reference &&
                                              errors.reference}
                                          </div> */}
                                          </div>
                                        </div>
</div>
                                      <FormResponse
                                        errors={status?.error}
                                        isVisible={
                                          status?.error ||
                                          status?.success ||
                                          (Object.keys(errors).length > 0 &&
                                            submitCount > 0)
                                        }
                                        alert={
                                          Object.keys(errors).length > 0 &&
                                          submitCount > 0
                                        }
                                      >
                                        {status?.success}
                                      </FormResponse>
                                      <ThemedButton
                                        type="submit"
                                        loading={loading || isSubmitting}
                                        className="w-100 btn btn-outline-primary"
                                      >
                                        Save Changes
                                      </ThemedButton>
                                    </Form>
                                  );
                                }}
                              </Formik>
                            );
                          }}
                        </Mutation>

                        <Modal
                          title={`Red Flags`}
                          isOpen={redFlagsModalIsOpen}
                          onClose={() => {
                            setRedFlagsModalIsOpen(false);
                          }}
                          styleType="large"
                          disableForceFocus={true}
                        >
                          <div id="red-flags-form">
                            <RedFlagsForm
                              selectedRedFlags={
                                redFlagsState || reservation?.redFlags
                              }
                              redFlags={
                                guestReservationRedFlagOptions || redFlagOptions
                              }
                              reference={reference}
                              onSuccess={updatedRedFlags => {
                                setRedFlagsState(updatedRedFlags);
                              }}
                            />
                          </div>
                        </Modal>

                        <Modal
                          title={
                            <p className="font-weight-bold mb-0">
                              Finalized Status (
                              {getFinalizeStatusLabel(
                                finalizationStepsState ||
                                  reservation?.finalizationSteps
                              )}
                              )
                            </p>
                          }
                          isOpen={finalizeStatusModalIsOpen}
                          onClose={() => {
                            setFinalizeStatusModalIsOpen(false);
                          }}
                          styleType="large"
                          disableForceFocus={true}
                        >
                          <FinalizedOptionsForm
                            reference={reference}
                            finalizationSteps={
                              finalizationStepsState ||
                              reservation?.finalizationSteps
                            }
                            finalizationOptions={finalizationStepOptions}
                            onSuccess={({ finalizationSteps }) => {
                              setFinalizationStepsState(finalizationSteps);
                            }}
                          />
                        </Modal>
                      </>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

GuestReservationForm.defaultProps = {
  onSuccess: () => {},
  onDataLoad: () => {},
};

export default GuestReservationForm;
