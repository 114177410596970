import React from 'react';
import Pagination from 'rc-pagination/lib';
import 'rc-pagination/assets/index.css';
import '../../styles/custom-rc-pagination.scss';
// import './style.scss'

const ThemedPager = ({
  dataConnection,
  pageNumber,
  changePageNumber,
  recordsPerPage,
  totalRecords,
}) => {
  return (
    <div>
      <Pagination
        pageSize={recordsPerPage}
        className="pagination mt-2 mb-2"
        defaultCurrent={pageNumber}
        showTitle={false}
        total={totalRecords}
        itemRender={(current, type, element) => {
          if (type === 'next') {
            return (
              <button
                className="btn next-button"
                onClick={() => changePageNumber(pageNumber + 1)}
                disabled={pageNumber * recordsPerPage >= totalRecords}
              >
                Next
              </button>
            );
          }
          if (type === 'page') {
            return (
              <button
                className={`btn page-button ${
                  current === pageNumber ? 'active' : ''
                }`}
                onClick={() => changePageNumber(current)}
              >
                {current.toString()}
              </button>
            );
          } else if (type === 'prev') {
            return (
              <button
                className="btn prev-button"
                onClick={() => changePageNumber(pageNumber - 1)}
                disabled={pageNumber - 1 === 0}
              >
                Prev
              </button>
            );
          }
          return element;
        }}
      />
      {/* <style jsx>{`
               div :global(.rc-pagination-next, .rc-pagination-jump-next, .rc-pagination-jump-prev, .rc-pagination-prev, .rc-pagination-prev-disabled, .rc-pagination-next-disabled, .rc-pagination-item) {
                    height: inherit;
                    color: ${theme.colors.primary};
                    margin: 0;
                    border-color: none;
                    border-radius: 0;
                  }
                  div :global(.rc-pagination-next, .rc-pagination-prev) {
                    border: none;
                  }
                  div :global(.next-button, .next-button:active, .prev-button, .prev-button:active) {
                    border: none;
                    color: ${theme.colors.primary} !important;
                    background: unset !important;
                  }
                  div :global(.page-button) {
                    background: ${theme.colors.white};
                    color: ${theme.colors.primary};
                    border: none;
                    border-radius: 0;
                  }
                  div :global(.page-button:focus, .page-button:active) {
                    background: ${theme.colors.primary} !important;
                  }
                  div :global(.page-button.active) {
                    background: ${theme.colors.primary} !important;
                    color: ${theme.colors.white} !important;
                  }
                  div :global(li) {
                    border-color: ${theme.colors['gray-200']}
                  }
                  div :global(.rc-pagination-item + li) {
                      border-left:none;
                  }
                  div :global(.rc-pagination-item:hover button) {

                    background: ${theme.colors.primary};
                    color: ${theme.colors.white};
                  }
            `}</style> */}
    </div>
  );
};

export default ThemedPager;
