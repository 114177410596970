import React from 'react';
import GuestMemoriesTable from './components/GuestMemoriesTable';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import gql from 'graphql-tag';

import Layout from 'src/layouts';

const GUESTS_MEMORIES = gql`
  query GuestsMemories(
    # $where: GuestReservationWhereInput
    # $reservationCreateDate_gte: Date
    # $reservationCreateDate_lte: Date
    # # $checkIn_gte: Date
    # $first: Int!
    $skip: Int!
    $orderBy: [GuestMemoryOrderByInput]
  ) {
    guestMemoriesConnection(
      #   where: $where
      #   first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          token
          emailAddress
          fiveStarRating
          body
          status
          property {
            name
            label
          }

          photos {
            parentFolder
            resourceId
          }
        }
      }
    }
    statusTypes: __type(name: "GuestMemoryStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;

const GuestMemories = () => {
  const today = new Date();

  return (
    <Layout hideMainOverflow={true} metaTitle={'Guest Memories'}>
      <Query
        variables={{
          //   first: 60,
          skip: 0,
          orderBy: 'createdAt_DESC',
        }}
        notifyOnNetworkStatusChange={true}
        query={GUESTS_MEMORIES}
        // skip={searchTerm}
        fetchPolicy="no-cache"
      >
        {({
          loading,
          error,
          data,
          refetch: refetchMemories,
          networkStatus,
        }) => {
          const edges = data?.guestMemoriesConnection?.edges;

          const isRefetching = networkStatus === 4;
          const statusTypeEnumValues = data?.statusTypes?.enumValues.reduce(
            (acc, item) => {
              acc.push(item?.name);
              return acc;
            },
            []
          );

          return (
            <>
              {edges ? (
                <GuestMemoriesTable
                  tableData={edges}
                  statusTypes={statusTypeEnumValues}
                  refetchMemories={refetchMemories}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              )}
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default GuestMemories;
