import React, { useState, useEffect, useRef } from 'react';
import DateRangeSelectForm from './components/DateRangeSelectForm';
import BiaxialGrid from 'src/components/BiaxialGrid';
import './styles.scss';
import Tooltip from 'src/components/Tooltip';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import LoadingSpinner from 'src/components/LoadingSpinner';
import earlyPayout from '../../../../images/early-payout.svg';
import { navigate, useLocation } from '@reach/router';
import {
  formatDateWithDashes,
  addDays,
  getDateRange,
  getDaysInMonth,
  // getAllMonthsWithinRange,
} from '../../../../util/dateHelper';
import Modal from 'src/components/Modal';
import { AlertTriangle } from 'react-feather';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { enumToLabel } from 'src/util';
import { getNumberOfDaysInRange } from '../../../../util/dateHelper';
import moment from 'moment';
import { getResourcesToShow } from '../../util';
import PropertyDetailsModal from '../../../properties/components/PropertyDetailsModal';
import debounce from '../../../../util/debounce';
//import ResourceEventCells from './components/ResourceEventCells';
import ResourceEventDateCells from './components/ResourceEventDateCells';
import CalendarNavigation from './components/CalendarNavigation';
import { cs } from 'date-fns/esm/locale';
import Nav from '../Nav';
import { ieVersion } from '../../../../util/browserCheck';
import ResizeObserverPolyfill from '../../../../util/resizeObserverPolyfill';
import toggleOn from 'src/images/toggle-on.svg';
import toggleOff from 'src/images/toggle-off.svg';
const CALENDAR_DATE_ALERTS = gql`
  query CalendarDateAlerts {
    calendarDateAlerts {
      id
      type
      summary
      calendarDate
    }
  }
`;

const CALENDAR_DATA = gql`
  query CalendarData {
    holidayCalendarDates(where: { holidayType: TRADITIONAL }) {
      date
      holidayCode
    }
    calendarDateAlerts {
      id
      type
      summary
      calendarDate
    }
  }
`;

const sleep = async timeout => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

const MinStayToggle = ({ showMinStay, onClick }) => {
  const locationData = useLocation();
  // console.log('locationData', locationData);

  return (
    <a
      href="javascript: void(0);"
      onClick={() => {
        const params = new URLSearchParams(locationData.search);
        // console.log('useLocation()', useLocation());
        //  console.log('params', params);
        params.set('showminstay', (!showMinStay).toString());
        //console.log('params', params);
        navigate(`${locationData.pathname}?${params.toString()}`);
        // setShowMinStay(!showMinStay);
        onClick(!showMinStay);
      }}
      className="mr-2 d-flex align-items-center text-primary"
    >
      {showMinStay ? (
        <img width={'28px'} src={toggleOn} />
      ) : (
        <img width={'28px'} className="text-primary" src={toggleOff} />
      )}
      <span className="ml-1">Minimum night-stay</span>
    </a>
  );
};
const AvailabilityCalendar = ({
  resources,
  properties,
  propertyGroups,
  events,
  days,
  location,
  defaultDateRangeNumber,
  propertyAmenities,
  ...rest
}) => {
  const numberOfDaysForCalendarDisplay = defaultDateRangeNumber || days;
  const params = new URLSearchParams(useLocation().search); //new URLSearchParams(location.search);

  const [showMinStay, setShowMinStay] = useState(
    params.get('showminstay') != 'false'
  );
  const [showRateDistribution, setShowRateDistribution] = useState(
    params.get('showratedistribution') != 'false'
  );
  const [showRates, setShowRates] = useState(
    params.get('showrates') != 'false'
  );

  useEffect(() => {
    setShowRates(params.get('showrates') != 'false');
    setShowMinStay(params.get('showminstay') != 'false');
    setShowRateDistribution(params.get('showratedistribution') != 'false');
  }, [
    params.get('showrates'),
    params.get('showminstay'),
    params.get('showratedistribution'),
  ]);
  console.log('showRates1', showRates);
  const defaultStartDate = new Date(
    params.get('start')
      ? moment(params.get('start'))
          .utc()
          .format('YYYY/MM/DD')
      : ''
  );

  const tdSpacer = useRef(null);
  // const gridNestedContent = useRef(null);
  // const gridNestedTable = useRef(null);
  const resourceSpacer = useRef(null);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [savingImage, setSavingImage] = useState(false);
  const [nestedTableElement, setNestedTableElement] = useState(null);
  const [numberOfDaysInRange, setNumberOfDaysInRange] = useState(
    startDate && params.get('end')
      ? getNumberOfDaysInRange(startDate, params.get('end'))
      : numberOfDaysForCalendarDisplay
  );

  const [targetProperty, setTargetProperty] = useState(null);
  const [datePickerModalIsOpen, setDatePickerModalIsOpen] = useState(false);
  const [customDateRangeApplied, setCustomDateRangeApplied] = useState(true);

  const dateRange = getDateRange(
    moment(startDate)
      .utc()
      .format('MM/DD/YYYY'),
    numberOfDaysInRange
  );
  const totalDateRange = getDateRange(
    moment(new Date())
      .utc()
      .format('MM/DD/YYYY'),
    days
  );

  const filteredPropertyList = resources.filter(
    resource => resource
    //resource => resource.key !== 'tb'
    //resource => resource.key === '953' || resource.key === '1065'
  );
  const filteredResources = getResourcesToShow(
    filteredPropertyList,
    params,
    properties
  );

  useEffect(() => {
    var isFirefox = typeof InstallTrigger !== 'undefined';

    if (isFirefox) {
      $(resourceSpacer.current).css('height', '10px');
    } else {
      $(resourceSpacer.current).css('min-width', '0px');
    }

    $('.resource-link').on('click', e => {
      if ($(e.target).hasClass('list-item')) {
        e.preventDefault();
      }
    });

    return () => {
      $(window).off('click');
      $('.resource-link').off('click');
    };
  }, [params.get('start')]);

  const PropertyResourceDisplay = ({ hideAddress = false, resource }) => {
    return (
      <div
        id="resource-wrapper"
        className="d-block d-lg-flex text-black justify-content-between align-items-center py-0 py-lg-2 pl-2 pr-0"
        title={`${resource.bedrooms}BR/${resource.bathrooms}BA/${resource.sleeps}SL ${resource.title}`}
        // style={{ height: '40px' }}
      >
            <div className="d-flex  align-items-center">

        <div
          onClick={() => {
            setTargetProperty(resource);
          }}
          className="resource-title text-primary resource-link d-lg-flex align-items-center p-0 m-0"
        >
          <div className="d-flex align-items-center">
            <div>
              <span className="text-primary" style={{ whiteSpace: 'nowrap' }}>
                {!hideAddress && resource.label} {resource.title.substr(0,10)}                
              </span>
            </div>           
          </div>
        </div>
        <span
          
          className="ml-lg-1 resource-text mt-1"
        >{`${resource.bedrooms}BR/${resource.bathrooms}BA/${resource.sleeps}SL`}</span>
        </div>
         <div>{resource.masterPlatformListing?.payoutType == 'EARLY' && <><img title="Early Payout" src={earlyPayout} className={`mr-1`} /></>}</div>
      
      </div>
    );
  };

  const getShouldAbbreviateDate = (date, dayDisplay, index) => {
    if (index === 0) {
      const endOfMonth = moment(date)
        .endOf('month')
        .format('YYYYMMDD');
      const currentDate = moment(date).format('YYYYMMDD');
      return endOfMonth === currentDate;
    }
    return (
      dateRange[dateRange.length - 1].toString().split(' ')[2] === '01' &&
      dayDisplay === '1'
    );
  };
  const MonthTds = ({ dateRange }) => {
    useEffect(() => {
      var isFirefox = typeof InstallTrigger !== 'undefined';
      const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;
      const $gridNestedContent = $('#grid-nested-content');
      const $gridNestedTable = $('#grid-nested-content .table-bordered');
      const observerCallback = entries => {
        if ($gridNestedContent.height() < $gridNestedTable.height()) {
          isFirefox
            ? $(tdSpacer.current).css('min-width', '8px')
            : $(tdSpacer.current).css('min-width', '4px');
        } else {
          $(tdSpacer.current).css('min-width', '0px');
          $(tdSpacer.current).css('border-left', 'none');
        }
      };

      let resizeObserver = isEdge
        ? new ResizeObserverPolyfill(observerCallback)
        : new ResizeObserver(observerCallback);
      $gridNestedContent[0] && resizeObserver.observe($gridNestedContent[0]);
      return () => {
        $gridNestedContent[0] &&
          resizeObserver.unobserve($gridNestedContent[0]);
      };
    }, []);
    return dateRange.map((date, index) => {
      const dateString = date.toString().split(' ');

      const daySplit = dateString[2].split('');

      const dayDisplay = daySplit[0] === '0' ? daySplit[1] : dateString[2];

      const shouldAbbreviateDate = getShouldAbbreviateDate(
        date,
        dayDisplay,
        index
      );

      const firstDayOfRange = index === 0;
      const firstDayOfMonth = date.getDate() === 1;
      if (firstDayOfRange || firstDayOfMonth) {
        return (
          <td
            key={`${index}_${date.getDate()}`}
            className={`month-header position-relative ${
              addDays(date, 1) === 1 ? 'last-day-of-month' : ''
            } ${index === 0 && 'first-month-cell'}`}
            colSpan={
              firstDayOfRange
                ? getNumberOfDaysInRange(
                    moment(date).format('YYYYMMDD'),
                    moment(date)
                      .endOf('month')
                      .format('YYYYMMDD')
                  )
                : getDaysInMonth(date.getMonth(), date.getFullYear())
            }
          >
            {date.getDate() === 1 || index === 0 ? (
              <span className="position-sticky pl-1">
                {' '}
                {`${date.toLocaleString('en-us', {
                  month: shouldAbbreviateDate ? 'short' : 'long',
                })} ${shouldAbbreviateDate ? '' : date.getFullYear()}`}
              </span>
            ) : (
              <span className="position-sticky"></span>
            )}
          </td>
        );
      }
    });
  };

  const DateTds = ({
    dateRange,
    showTooltip,
    calendarDateAlerts,
    holidayCalendarDates = [],
  }) => {
    return dateRange.map((date, index) => {
      const isHoliday = holidayCalendarDates.find(holidayCalendarDate => {
        const currentDate = moment(date)
          .utc()
          .format('YYYY-MM-DD');
        if (currentDate === holidayCalendarDate.date) {
          return true;
        }
      });
      // if (isHoliday) {
      //   console.log('isHoliday', isHoliday);
      //   console.log('date', date);
      // }
      const dateAlert = calendarDateAlerts
        ? calendarDateAlerts.find(alert => {
            const alertDate = moment(alert?.calendarDate)
              .utc()
              .format('YYYY-MM-DD');
            const currentDate = moment(date)
              .utc()
              .format('YYYY-MM-DD');
            if (moment(alertDate).isSame(moment(currentDate))) {
              return alert;
            }
          })
        : null;

      const dateString = date.toString().split(' ');

      const daySplit = dateString[2].split('');

      const dayDisplay = daySplit[0] === '0' ? daySplit[1] : dateString[2];
      const InnerContent = (
        <div className="w-100">
          {' '}
          <span>{`${dateString[0].slice(0, 2)}`}</span>
          <div>{`${dayDisplay}`}</div>
        </div>
      );

      return (
        <td
          key={`${index}_${date.getDate()}`}
          title={`${
            isHoliday ? 'Holiday: ' + isHoliday.holidayCode.toUpperCase() : ''
          }`}
          className={`date-cell-wrapper ${isHoliday ? 'date-is-holiday' : ''}`}
        >
          <table
            // className={`${
            //   date.getDate() === 1 ? 'border-left' : ''
            // } nested-table h-100 w-100`}
            className={` nested-table h-100 w-100`}
          >
            <tbody>
              <tr>
                <td
                  key={index}
                  data-date={formatDateWithDashes(date)}
                  className={`position-relative align-middle`}
                  style={{
                    // width: '7.1428%',
                    width: '60px',
                    // minWidth: '55px',
                    textAlign: 'center',
                    lineHeight: 1.2,
                    position: 'relative',
                  }}
                >
                  {showTooltip && dateAlert ? (
                    <Tooltip
                      label={
                        <div>
                          <b>{enumToLabel(dateAlert?.type)}</b>
                          <p className="mb-0">{dateAlert?.summary}</p>
                        </div>
                      }
                      placement="bottom"
                      id={`calendar-tooltip-${index}-${dateString}`}
                    >
                      <i
                        style={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          top: '-9px',
                          zIndex: 10,
                        }}
                      >
                        <AlertTriangle
                          width={15}
                          height={15}
                          className={`d-flex align-items-center alert-triangle-300`}
                        />
                      </i>

                      {InnerContent}
                      <div
                        style={{
                          height: '2px',
                          backgroundColor: '#fd6b6a',
                          position: 'absolute',
                          width: '40px',
                        }}
                      ></div>
                    </Tooltip>
                  ) : (
                    InnerContent
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      );
    });
  };

  const resourcesShownOnCalendar = filteredResources.filter(
    resource => resource.showResource === true
  );

  return (
    <>
      <section id="availability-calendar-page">
        {console.log('showRatesPassIn', showRates)}
        <Nav
          // propertiesToShow={resourcesShownOnCalendar}
          properties={resources}
          propertyGroups={propertyGroups}
          filteredPropertyList={resourcesShownOnCalendar}
          totalDateRange={totalDateRange}
          startDate={startDate}
          numberOfDaysInRange={numberOfDaysInRange}
          dateRange={dateRange}
          customDateRangeApplied={customDateRangeApplied}
          setStartDate={date => setStartDate(date)}
          defaultDateRangeNumber={defaultDateRangeNumber}
          setNumberOfDaysInRange={days => {
            setNumberOfDaysInRange(days);
          }}
          // getNumberOfDaysInRange={days => getNumberOfDaysInRange(days)}
          setCustomDateRangeApplied={dateRangeApplied =>
            setCustomDateRangeApplied(dateRangeApplied)
          }
          setDatePickerModalIsOpen={isModalOpen =>
            setDatePickerModalIsOpen(isModalOpen)
          }
          days={numberOfDaysForCalendarDisplay}
          propertyAmenities={propertyAmenities}
          showMinStay={showMinStay}
          showRates={showRates}
          showRateDistribution={showRateDistribution}
          // onOptionChange={(key, value) => {

          // }}
        />

        <Query query={CALENDAR_DATA} fetchPolicy="no-cache">
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              );
            if (error) return <p>Error: ${error.message}</p>;

            const { calendarDateAlerts, holidayCalendarDates } = data || {};
            console.log('holidayCalendarDates', holidayCalendarDates);
            return (
              <BiaxialGrid
                onLoad={data => {
                  setNestedTableElement(data);
                }}
                bottomMargin={showRateDistribution ? 40 : 0}
                topLeftCornerContent={
                  <span className="font-weight-bold text-black">{`${resourcesShownOnCalendar.length} Properties`}</span>
                }
                headerRow={
                  <table
                    className="table table-bordered mb-0 h-100 d-block"
                    style={{ width: 'fit-content' }}
                  >
                    <tbody>
                      <tr className="month-header-row">
                        <MonthTds dateRange={dateRange} />
                      </tr>
                      <tr className="">
                        <DateTds
                          dateRange={dateRange}
                          calendarDateAlerts={calendarDateAlerts}
                          holidayCalendarDates={holidayCalendarDates}
                          showTooltip={true}
                        />

                        <td ref={tdSpacer} id="date-bar-horizontal-spacer"></td>
                      </tr>
                    </tbody>
                  </table>
                }
                firstColumn={
                  <table className="table table-bordered mb-0">
                    <tbody>
                      {filteredResources.map((resource, index) => {
                        return (
                          resource.showResource && (
                            <tr key={index}>
                              <td
                                className="p-0"
                                style={{ fontWeight: 'normal' }}
                              >
                                <PropertyResourceDisplay resource={resource} />
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                }
                innerContent={
                  <table className="table table-bordered mb-0">
                    <tbody>
                      {/* <tr
            id="image-capture-tr month-header-row"
            className="_show-for-image-capture"
          > */}
                      {/* <td></td> */}
                      <tr className="month-header-row image-capture-tr top-row show-for-image-capture">
                        <td rowSpan="2"></td>
                        <MonthTds dateRange={dateRange} />
                      </tr>
                      <tr className="show-for-image-capture image-capture-tr">
                        <DateTds dateRange={dateRange} />
                      </tr>
                      {/* </tr> */}
                      {filteredResources.map((resource, index) => {
                        return (
                          resource.showResource && (
                            <tr key={index}>
                              <td
                                className="show-for-image-capture"
                                style={{ fontWeight: 'normal' }}
                              >
                                <PropertyResourceDisplay
                                  hideAddress={true}
                                  resource={resource}
                                />
                              </td>
                              <ResourceEventDateCells
                                resource={resource}
                                events={events}
                                dateRange={dateRange}
                                showMinStay={showMinStay}
                                showRates={showRates}
                                showRateDistribution={showRateDistribution}
                              />
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                }
              />
            );
          }}
        </Query>

        {targetProperty && (
          <PropertyDetailsModal
            onResetId={() => setTargetProperty(false)}
            propertyId={targetProperty.id}
          />
        )}
        <Modal
          title={'Select a date range'}
          isOpen={datePickerModalIsOpen}
          onClose={() => setDatePickerModalIsOpen(false)}
        >
          <DateRangeSelectForm
            startDate={startDate}
            setNumberOfDaysInRange={days => setNumberOfDaysInRange(days)}
            datePickerModalIsOpen={datePickerModalIsOpen}
            setDatePickerModalIsOpen={modalIsOpen =>
              setDatePickerModalIsOpen(modalIsOpen)
            }
            setCustomDateRangeApplied={dateRangeApplied =>
              setCustomDateRangeApplied(dateRangeApplied)
            }
            setStartDate={date => setStartDate(date)}
            totalDateRange={totalDateRange}
          />
        </Modal>
        {showRateDistribution && (
          <div className="rated-distribution-legend">
            <div>Combined rate percentage of single homes:</div>
            <span className="text-red">&lt;95%</span>{' '}
            <span className="text-orange ml-1">&gt;101%</span>
          </div>
        )}
        <a
          className="circle"
          onClick={() => {
            // setCaptureModelOpen(true);
            console.log('SAVING.....');
            setSavingImage(true);
            const dataTable = document.querySelector(
              '#grid-nested-content table'
            );
            // const cloned = dataTable.cloneNode(true);
            // cloned.style.backgroundColor = 'red';
            html2canvas(dataTable, {
              width: dataTable.getBoundingClientRect().width + 200,
              height:
                dataTable.getBoundingClientRect().height +
                60 +
                nestedTableElement.current.scrollTop, // We could eliminate hard-coded and instead detect.
              scrollY: nestedTableElement.current.scrollTop,
              onclone: async element => {
                console.log('dataTable (onclone)', dataTable);
                console.log(
                  'dataTableWidth',
                  dataTable.getBoundingClientRect().width + 200
                );
                console.log(
                  'dataTableHeight',
                  dataTable.getBoundingClientRect().height + 60
                );
                console.log('cloned element from onclone function', element);
                const imageCaptureElements = element.querySelectorAll(
                  '.show-for-image-capture'
                );
                console.log('imageCaptureElements', imageCaptureElements);

                imageCaptureElements.forEach(node => {
                  console.log('REMOVING');
                  node.classList.remove('show-for-image-capture');
                });
                console.log(
                  'imageCaptureElements removed class',
                  imageCaptureElements
                );

                console.log('elementsRemoved...waiting 500 milliseconds!!');
                await sleep(500);
                console.log('done waiting');
                //element.querySelectorAll('thead').remove();
              },
            }).then(canvas => {
              console.log('canvas', canvas);
              canvas.toBlob(function(blob) {
                saveAs(blob, `calendar-${Date.now()}.png`);
              });
              setSavingImage(false);
            });
          }}
        >
          {savingImage ? (
            <div className="spinner-border"></div>
          ) : (
            <img
              src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjMuMiIvPgogICAgPHBhdGggZD0iTTkgMkw3LjE3IDRINGMtMS4xIDAtMiAuOS0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yaC0zLjE3TDE1IDJIOXptMyAxNWMtMi43NiAwLTUtMi4yNC01LTVzMi4yNC01IDUtNSA1IDIuMjQgNSA1LTIuMjQgNS01IDV6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPgo="
              alt="Capture Table"
              className="css-1bgbwga"
            ></img>
          )}
        </a>
        {/* <div id="calendar-legend" className="legend d-flex">
          <div className="holiday-legend mr-2 px-2">
            <span className="small text-muted">Holiday</span>
          </div>

          <div>
            <MinStayToggle
              showMinStay={showMinStay}
              onClick={val => {
                setShowMinStay(val);
              }}
            />
          </div>
        </div> */}
      </section>
    </>
  );
};

export default AvailabilityCalendar;

/*
import React from 'react';
import toggleOn from 'src/images/toggle-on.svg';
import toggleOff from 'src/images/toggle-off.svg';

const CalendarLinkToggle = ({ location }) => {
  const params = new URLSearchParams(location?.search);

  const isMultiCalendar = params.get('displaymode') === 'multicalendar';
  const getCalendarToggleLinkPath = () => {
    const startDate = params.get('start');
    // const endDate = params.get('end');
    const displayMode = isMultiCalendar ? `` : `&displaymode=multicalendar`;
    return `/calendar?start=${startDate}${displayMode}`;
  };
  return (
    <div>
      <a
        href={getCalendarToggleLinkPath()}
        className="mr-2 d-flex align-items-center"
      >
        {isMultiCalendar ? (
          <img width={'28px'} src={toggleOn} />
        ) : (
          <img width={'28px'} className="text-primary" src={toggleOff} />
        )}
        <span className="ml-1">Multicalendar</span>
      </a>
    </div>
  );
};

export default CalendarLinkToggle;

*/
