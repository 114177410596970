import React, { useState } from 'react';
import Modal from 'src/components/Modal';
import TemplateBuilder from 'src/components/TemplateBuilder';
import HandlebarsProvider, { HandlebarsConsumer } from 'src/contexts/Handlebars';

const TemplateBuilderCheckInMessageModal = ({
  reservationId,
  onSuccess,
  children,
}) => {
  const params = new URLSearchParams(window.location?.search);
  const templateModalParam =
    params.get('templateBuilderType') &&
    params.get('reference') === reservationId;
  const [modalIsOpen, setModalIsOpen] = useState(templateModalParam ? true : false);

  return (
    <>
      <button
        onClick={() => {
          params.set('templateBuilderType', 'CHECKIN_MESSAGE');
          params.set('reference', reservationId);
          window.history.replaceState(
            null,
            '',
            window.location.pathname + `?${params.toString()}`
          );
          setModalIsOpen(true);
        }}
        className="btn btn-link p-0 small"
      >
        {children}
      </button>
      <Modal
        title={`Template Builder`}
        isOpen={modalIsOpen}
        onClose={() => {
          params.delete('templateBuilderType');
          params.delete('reference');
          window.history.replaceState(
            null,
            '',
            window.location.pathname + `?${params.toString()}`
          );
          setModalIsOpen(false);
        }}
        styleType="full"
        disableForceFocus={true}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <HandlebarsProvider>
          <HandlebarsConsumer>
            {props => (
              <TemplateBuilder
                contextData={props}
                definitionId="1"
                params={`reference=${reservationId}`}
              />
            )}
          </HandlebarsConsumer>
        </HandlebarsProvider>
      </Modal>
    </>
  );
};

export default TemplateBuilderCheckInMessageModal;