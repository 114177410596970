import { decimalToDateTime } from 'src/util/dateHelper';
import { Check } from 'react-feather';
import moment from 'moment';

export const getConfirmedValue = value => {
  switch (value) {
    case 'CONFIRMED':
      return (
        <Check
          width={20}
          height={20}
          className="check-icon" /*onClick={(e) => props.onIconClick(e)}*/
        />
      );
    case 'NOT_REQUIRED':
      return 'N/R';
    default:
      return '';
  }
};
export const isFinalizationPeriod = cell => {
  const dateReserved = moment(
    cell?.row?.original?.dateReserved,
    'MM-DD-YYYY'
  ).format();
  const threeDaysAfterReservation = moment(dateReserved)
    .add(3, 'days')
    .format();
  const today = moment().format('YYYY-MM-DD');

  if (
    moment(dateReserved) < moment(today).subtract('days', 7)
    // moment(today, 'YYYY-MM-DD').isBetween(
    //   dateReserved,
    //   threeDaysAfterReservation,
    //   undefined,
    //   '[]'
    // )
  ) {
    return true;
  }
  return false;
};

export const getWelcomePeriodClassPrefix = cell => {
  const checkInDate = cell?.row?.original?.checkInDate;
  const twoWeeksBeforeCheckIn = moment(checkInDate)
    .subtract(14, 'days')
    .format();
  const today = moment().format('YYYY-MM-DD');
  const oneWeekBeforeCheckIn = moment(checkInDate)
    .subtract(7, 'days')
    .format();

  if (
    moment(today, 'YYYY-MM-DD').isBetween(
      twoWeeksBeforeCheckIn,
      checkInDate,
      undefined,
      '[]'
    )
  ) {
    return moment(today, 'YYYY-MM-DD').isBetween(
      oneWeekBeforeCheckIn,
      checkInDate,
      undefined,
      '(]' // '(' is non inclusive, so if today is on the 7th passed day from check in it will be considered within 2 weeks
    )
      ? 'within-week'
      : 'within-two-weeks';
  }
  return false;
};

export const useSelectFilter = (key, recordset) => {
  const unique = [...new Set(recordset.map(x => x[key]))];

  return unique.length < 7;
};

export const getHyperlinkType = key => {
  if (
    key === 'finalizeStatus' ||
    key === 'guestName' ||
    key === 'redFlag' ||
    key === 'house#' ||
    key === 'welcomeSent'
  ) {
    return key;
  }

  if (
    key === 'adults' ||
    key === 'children' ||
    key === 'pets' ||
    key === 'visitors' ||
    key === 'vehicles' ||
    key === 'reservationId' ||
    key === 'confirmed'
  ) {
    return 'reservationId';
  }

  return null;
};

export const getFinalizeStatusLabel = (
  topLevelTableData,
  guestReservationFinalizationStepOptions
) => {
  // const guestReservationFinalizationStepOptions =
  //   finalizedStatusAndRedFlagData?.guestReservationFinalizationStepOptions;
  const steps = topLevelTableData?.finalizationSteps;
  if (steps?.length === guestReservationFinalizationStepOptions?.length) {
    return 'Complete';
  }
  return guestReservationFinalizationStepOptions
    ? `${steps?.length} of ${guestReservationFinalizationStepOptions?.length}`
    : ``;
};
export const reduceObjValues = (
  obj,
  guestReservationFinalizationStepOptions
) => {
  if (!obj) return {};
  const objectValues = Object.keys(obj).reduce((acc, cur) => {
    if (!Array.isArray(obj[cur]) && typeof obj[cur] === 'object') {
      return reduceObjValues({ ...acc, ...obj[cur] }, /*cache*/ {});
    }
    acc[cur] = obj[cur];

    return acc;
  }, {});
  const checkInDateTime = decimalToDateTime(
    String(objectValues?.checkInHour || 17)
  );
  const checkOutDateTime = decimalToDateTime(
    String(objectValues?.checkOutHour || 11)
  );

  // objectValues.redFlag = objectValues?.redFlags;
  objectValues.checkInHour = objectValues?.checkInHour || 17;
  objectValues.checkOutHour = objectValues?.checkOutHour || 11;
  objectValues.redFlag = objectValues?.redFlags?.length > 0 ? '🚩' : ' ';
  objectValues.finalizeStatus = getFinalizeStatusLabel(
    objectValues,
    guestReservationFinalizationStepOptions
  );
  // objectValues.finalizeStatus = objectValues?.finalizationSteps;
  objectValues.confirmed =
    objectValues?.confirmationStatus === 'CONFIRMED' ? '✔️' : '🚫';
  objectValues.welcomeSent = objectValues.welcomeMessageDateSent
    ? moment(objectValues.welcomeMessageDateSent).format('MM/DD/YYYY')
    : '🚫';
  objectValues.reservationId = objectValues?.reference;
  objectValues.dateReserved = moment(
    objectValues?.reservationCreateDate
  ).format('MM/DD/YYYY');
  objectValues.checkIn = `${moment(objectValues?.checkInDate).format(
    'MM/DD/YYYY'
  )} at ${moment(checkInDateTime).format('hh:mm a')}`;
  objectValues.checkOut = `${moment(objectValues?.checkOutDate).format(
    'MM/DD/YYYY'
  )} at ${moment(checkOutDateTime).format('hh:mm a')}`;
  objectValues['house#'] = objectValues?.key;
  objectValues.guestName = `${objectValues?.firstName} ${objectValues?.lastName}`;
  objectValues.guestId = objectValues?.guestId;
  objectValues.adults = objectValues.adults ? objectValues.adults : 0;
  objectValues.children = objectValues.children ? objectValues.children : 0;
  objectValues.pets = objectValues.pets ? objectValues.pets : 0;
  objectValues.visitors = objectValues.visitors ? objectValues.visitors : 0;
  objectValues.vehicles = objectValues.vehicles ? objectValues.vehicles : 0;

  return {
    ...objectValues,
    //   ...cache,
  };
};
