import React from 'react';
import Layout from 'src/layouts';
import LoginForm from 'src/components/Forms/components/LoginForm';

const Login = () => {
  return (
    <Layout>
      <div className="container mt-4 ml-0 mb-0 mr-0">
        <div className="row">
          <div className="col col-12 col-md-6">
            <LoginForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
