import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const ScrollTable = ({
  children,
  stickyHeader,
  style,
  className,
  ...props
}) => {
  const [isOffsetY, setIsOffsetY] = useState(false);

  const applyStickyHeader = () => {
    const fixedTheadRows = document.querySelectorAll(
      '[data-fixed-row] thead tr'
    );
    const stickyTheadRows = document.querySelectorAll(
      '[data-sticky-header] thead tr'
    );

    const setStickyHeaderWidths = () => {
      //const widths = [];
      fixedTheadRows.forEach((row, rowIndex) => {
        row.childNodes.forEach((element, index) => {
          // widths.push(element.offsetWidth);
          stickyTheadRows[rowIndex].childNodes[
            index
          ].style.minWidth = `${element.offsetWidth}px`;
          //stickyTheadRows[0].childNodes[0].style.display = `inline-block`; // hacky fix.
        });
      });

      // fixedTheadRows.childNodes.forEach((element, index) => {
      //   widths.push(element.offsetWidth);
      //   stickyTheadRows.childNodes[
      //     index
      //   ].style.minWidth = `${element.offsetWidth}px`;
      //   stickyTheadRows.childNodes[0].style.display = `inline-block`;
      // });
      //console.log('widths', widths);
    };
    setStickyHeaderWidths();
    const scrollContainer = document.querySelector(
      '[data-scroll-table-container]'
    );
    const stickyHeaderTable = document.querySelector('[data-sticky-header]');
    scrollContainer.addEventListener('scroll', function(event) {
      stickyHeaderTable.style.top = `${event.target.scrollTop}px`;
      setIsOffsetY(event.target.scrollTop > 0);
    });
    window.addEventListener('resize', setStickyHeaderWidths);
    document
      .querySelector('[data-fixed-row]')
      .addEventListener('scroll', function(event) {
        stickyHeaderTable.style.left = `${event.target.scrollLeft * -1}px`;
      });
  };

  useEffect(() => {
    // const onHorizontalScroll = () => {
    //   const $fixedRowPosition =
    //     $(`[data-scroll-table-container]`).position() || {};

    //   const leftPosition = $fixedRowPosition.left;
    //   const $tableHeadersTh = $('[data-scrollable-row] table thead th');

    //   if (leftPosition === 0) {
    //     $tableHeadersTh.each((index, item) => {
    //       $(item).css('visibility', 'visible');
    //     });
    //   } else {
    //     $tableHeadersTh.each((index, item) => {
    //       $(item).css('position', 'relative');
    //       if (index > 0) {
    //         console.log('item', item);
    //         $(item).css('visibility', 'hidden');
    //       }
    //     });
    //   }
    // };
    // $('[data-fixed-row]').on('scroll', function(e) {
    //   onHorizontalScroll();
    // });

    if (stickyHeader) {
      applyStickyHeader();
    }
    return () => {
      $('[data-fixed-row]').unbind('scroll');
      $(window).unbind('resize');
    };
  });

  // const customStyles = {};
  // if (height) {
  //   customStyles.height = height;
  //   customStyles.overflowY = 'scroll';
  // }

  return (
    <>
      <div
        data-scroll-table-container
        className={`${className} ${styles.scrollTable}  ${
          isOffsetY ? styles.offsetY : ''
        }`}
        style={style}
      >
        {stickyHeader && (
          <div data-sticky-header className={styles.stickyHeader}>
            {children}
          </div>
        )}
        <div data-fixed-row className={styles.fixedThTableWrapper}>
          {children}
        </div>
        <div data-scrollable-row className={`${styles.scrolledTdTableWrapper}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ScrollTable;
