import React, { useEffect, useState } from 'react';
import Layout from 'src/layouts';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
//import PropertyEditForm from './components/PropertyEditForm';
import moment from 'moment';
import { withApollo, Mutation } from 'react-apollo';
//import './styles.scss';
import LoadingSpinner from 'src/components/LoadingSpinner';

const BHIPInvoice_QUERY = gql`
  query BHIPInvoice($bhipInvoiceWhereUniqueInput: BHIPInvoiceWhereUniqueInput) {
    bhipInvoice(where: $bhipInvoiceWhereUniqueInput) {
      id
      property {
        name
      }
      checkIn
      checkOut
      firstName
      lastName
      emailAddress
      address
      city
      state
      postalCode
      country
      phoneNumber
      numberOfAdults
      numberOfChildren
      numberOfPets
      numberOfVehicles
      numberOfVisitors

      propertyQuote
      additionalDetails
    }
  }
`;

const BHIPInvoice = ({ reference, client, isModal }) => {
  const bhipInvoiceWhereUniqueInput = { reference: reference };

  return (
    <>
      <Query
        query={BHIPInvoice_QUERY}
        variables={{ bhipInvoiceWhereUniqueInput }}
      >
        {({ data, loading, error }) => {
          if (loading)
            return (
              <div className="container pt-4">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            );
          if (error) return <p className="mt-2">{error.message}</p>;
          if (!data) return null;
          // if (!data.property) {
          //   return (
          //     <div className="container-fluid">
          //       <div className="row">
          //         <div className="col mt-4">
          //           <span>There are no properties with the provided ID.</span>
          //         </div>
          //       </div>
          //     </div>
          //   );
          // }
          return (
            <Layout metaTitle="Invoices">
              <div className="container-fluid">
                <div className="row">
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
              </div>
            </Layout>
          );
        }}
      </Query>
    </>
  );
};

export default withApollo(BHIPInvoice);
