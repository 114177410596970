import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Layout from 'src/layouts';

import moment from 'moment';

/**
 *  Converts date range to object (e.g., 2019-01-07, 2019-01-09 => { '2019-01-07': 'Y', '2019-01-08': 'Y', '2019-01-09': 'Y'})
 * */
const getDatesInRange = (startDate, stopDate) => {
  var dateObj = {};
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateObj[moment(currentDate).format('YYYY-MM-DD')] = 'Y';
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateObj;
};

/**
Created in a way that is very specific to our use case.

eventCacher.get(calendarEventWhereInput, options )
options: { lazyLoadCalendarEventWhereInputs: [calendarEventWhereInput] }

calendarEventWhereInput:
  This will try to pull from cache or fetch from graphql.
options.lazyLoadCalendarEventWhereInputs:
  Accepts an array of calendarEventWhereInput which will lazy load
  after calendarEventWhereInput is retrieved. This technique give control of lazy loading ranges to the user
*/
const eventCacher = client => {
  const CACHE = { events: {}, queriedDates: {} };

  const fetchEvents = async calendarEventWhereInput => {
    const datesInQueryRange = getDatesInRange(
      calendarEventWhereInput.event_within.start,
      calendarEventWhereInput.event_within.end
    );
    console.log('datesInQueryRange', datesInQueryRange);
    const allDatesInCache =
      typeof Object.keys(datesInQueryRange).find(dateInQueryRange => {
        return !CACHE.queriedDates[dateInQueryRange];
      }) === 'undefined'; // Works fine to determine if all dates for request are already in cache, but could be written in a way easier to reason about.
    console.log('allDatesInCache', allDatesInCache);
    if (allDatesInCache) {
      console.log('all in cached', Object.values(CACHE.events));
      return Object.values(CACHE.events);
    } else {
      const events = await client.query({
        query: CALENDAR_EVENTS_QUERY,
        variables: {
          calendarEventWhereInput,
        },
      });

      const eventsByUid = events.data.calendarEvents.reduce((acc, event) => {
        acc[event.uid] = event;
        return acc;
      }, {});
      // console.log('datesInQueryRange', datesInQueryRange);
      CACHE.events = {
        ...CACHE.events,
        ...eventsByUid,
      };
      CACHE.queriedDates = {
        ...CACHE.queriedDates,
        ...datesInQueryRange,
      };
      return events;
    }
  };

  const eventCache = {
    get: async (
      calendarEventWhereInput,
      { lazyLoadCalendarEventWhereInputs = [] }
    ) => {
      const datesInQueryRange = getDatesInRange(
        calendarEventWhereInput.event_within.start,
        calendarEventWhereInput.event_within.end
      );
      const events = await fetchEvents(calendarEventWhereInput);
      const lazyLoadInputs = Array.isArray(lazyLoadCalendarEventWhereInputs)
        ? lazyLoadCalendarEventWhereInputs
        : [lazyLoadCalendarEventWhereInputs];
      console.log('lazyLoadInputs', lazyLoadInputs.length);
      const lazyLoadFunctions = lazyLoadInputs.map(lazyLoadInput =>
        fetchEvents(lazyLoadInput)
      );
      console.log(lazyLoadFunctions);
      Promise.all(lazyLoadFunctions).then(() => {});

      return CACHE;
    },
  };
  // const eventsToCacheEntries = (calendarEventWhereInput, events) => {
  //   const datesInQueryRange = getDatesInRange(
  //     calendarEventWhereInput.event_within.start,
  //     calendarEventWhereInput.event_within.end
  //   );
  //   const cacheEntries = datesInQueryRange.reduce((acc, dateInQueryRange) => {
  //     return
  //   }, {});
  //   return cacheEntries;
  // };
  return eventCache;
};

const CALENDAR_EVENTS_QUERY = gql`
  query CalendarEvents($calendarEventWhereInput: CalendarEventWhereInput) {
    calendarEvents(where: $calendarEventWhereInput) {
      id
      uid
      start
      end
      summary
      debug
      propertyListing {
        id
        icalUrl
        propertyUrl
        lastSync
        platform {
          name
          key
          url
          color
        }
        property {
          id
          name
          label
          key
          type
        }
      }
    }
  }
`;

const Debug = ({ client }) => {
  const eventCache = eventCacher(client);
  console.log('eventCache', eventCache);

  const fetchForCalendar = async (start, end) => {
    const cacheDays = moment(end).diff(moment(start), 'days');
    const lazyLoadPaddingInDays = 5;
    const previousDatesLazyStart = moment(start)
      .subtract(cacheDays, 'days')
      .subtract(lazyLoadPaddingInDays, 'days')
      .format('YYYY-MM-DD');
    const nextDatesLazyEnd = moment(end)
      .add(cacheDays, 'days')
      .add(lazyLoadPaddingInDays, 'days')
      .format('YYYY-MM-DD');
    const results = await eventCache.get(
      {
        event_within: {
          start: start,
          end: end,
        },
      },
      {
        lazyLoadCalendarEventWhereInputs: [
          {
            event_within: {
              start: previousDatesLazyStart,
              end: start,
            },
          },
          {
            event_within: {
              start: end,
              end: nextDatesLazyEnd,
            },
          },
        ],
      }
    );
    console.log('results', results);
  };
  useEffect(() => {
    (async () => {
      const start = '2019-01-01';
      const end = '2019-01-07';
      const events = await fetchForCalendar(start, end);
    })();
  });
  return (
    <Layout>
      <div className="container ml-0 mb-0 mr-0">
        <div className="row">
          <div className="col col-12 col-md-6">DEBUG!</div>
          <button
            onClick={() => {
              fetchForCalendar('2019-01-08', ' 2019-01-16');
            }}
          >
            GOTO 2019-01-08 - 2019-01-16
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default withApollo(Debug);
