import React, { useState } from 'react';
// import Query from 'src/components/GraphQL/components/Query';
import { Query, useQuery } from 'react-apollo';
// import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Location } from '@reach/router';
import Layout from 'src/layouts';
import ThemedPager from 'src/components/ThemedPager';
// import GuestTable from '../guests/components/GuestTable';
import WebformsTable from './components/WebformsTable';
import moment from 'moment';
import './styles.scss';
import { navigate } from 'gatsby';
import Tooltip from 'src/components/Tooltip';
import Modal from 'src/components/Modal';
import FormatNewLineToText from 'src/components/FormatNewLineToText';
import DropdownSelector from 'src/components/Forms/components/DropdownSelector';
import DropdownFilterSelect from 'src/components/Forms/components/DropdownFilterSelect';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { ExternalLink, Search, UserPlus, AlertTriangle } from 'react-feather';
import { AlertsConsumer } from 'src/contexts/Alerts';
import get from 'lodash/get';
import { Info } from 'react-feather';
import PropertyDetailsModal from '../properties/components/PropertyDetailsModal';
import QuoteGenerator from 'src/components/QuoteGenerator';
import { formatDateWithDashes } from 'src/util/dateHelper';
import withLocation from 'src/components/WithLocation';

import styles from '../guests/styles.module.scss';
const options = ['SENT', 'INPROGRESS', 'SPAM', 'CLOSED', 'ALL', 'OPEN'];
import debounce from '../../util/debounce';
import { updateUrl } from 'src/util/urlHelper/index';
import * as queryString from 'query-string';
import Logo from '../../images/favicon.png';
import { Helmet } from 'react-helmet';
import ThemedTable from 'src/components/ThemedTable/index.js';
import GuestsInnerTable from 'src/routes/guests/components/GuestsInnerTable';
import SearchInput from 'src/components/SearchInput';

const FORM_ENTRIES = gql`
  query GuestReservationFormEntriesConnection($first: Int, $skip: Int) {
    guestReservationFormEntriesConnection(first: $first, skip: $skip) {
      edges {
        node {
          id
          reference
          payload
          createdAt
        }
      }
    }
  }
`;
const FORM_ENTRY = gql`
  query GuestReservationFormEntry($id: Int) {
    guestReservationFormEntry(where: { id: $id }) {
      id
      reference
      payload
      createdAt
      reservation {
        id
        checkIn
        checkOut
        guest {
          firstName
          lastName
          id
        }
        property {
          name
        }
        platform {
          name
        }
      }
    }
  }
`;

const ReservationDetail = ({
  reference,
  propertyName,
  platformName,
  checkIn,
  checkOut,
}) => {
  return (
    <>
      <div className="row">
        <div className="col col-12 col-md-6">
          <div className="form-group">
            <label>Reference</label>
            <span className="text-danger ml-1">*</span>
            <input
              readOnly={true}
              name="reference"
              type="text"
              className={`form-control`}
              value={reference}
            />
          </div>
        </div>

        <div className="col col-12 col-md-6">
          <div className="form-group">
            <label>Platform</label>
            <span className="text-danger ml-1">*</span>
            <input
              name="platformName"
              readOnly={true}
              type="text"
              className={`form-control`}
              value={platformName}
              placeholder=""
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-12 col-md-6">
          <div className="form-group">
            <label>Property</label>
            <span className="text-danger ml-1">*</span>
            <input
              name="propertyName"
              readOnly={true}
              type="text"
              className={`form-control`}
              value={propertyName}
              placeholder=""
            />
          </div>
        </div>
        <div className="col col-12 col-md-6">
          <div className="form-group d-flex" id="dates-form-group">
            <div className="mr-3 w-100">
              <label>Check In</label>
              <span className="text-danger ml-1">*</span>
              <input
                name="checkIn"
                readOnly={true}
                type="text"
                className={`form-control`}
                value={checkIn ? moment(checkIn).format('MM/DD/YYYY') : ''}
                placeholder=""
              />
            </div>
            <div className="w-100">
              <label>Check Out</label>
              <span className="text-danger ml-1">*</span>
              <input
                name="checkOut"
                readOnly={true}
                type="text"
                className={`form-control`}
                value={checkOut ? moment(checkOut).format('MM/DD/YYYY') : ''}
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Webforms = ({ location, webformEntryId, ...props }) => {
  console.log('props', props);
  // const recordsPerPage = 10;
  const params = new URLSearchParams(location.search);
  // const urlId = params.get('id') ? Number(params.get('id')) : null;
  // const [modalIsOpen, setModalIsOpen] = useState(urlId ? true : false);

  const { data, error, loading, refetch } = webformEntryId
    ? useQuery(FORM_ENTRY, {
        variables: { id: Number(webformEntryId) },
      })
    : useQuery(FORM_ENTRIES, {
        variables: { first: 50, skip: 0 },
      }); //

  // const [selectedLead, setSelectedLead] = useState(null);
  // const guestMatchId = targetProperty
  //   ? get(targetProperty, 'guestMatch.id')  || null
  //   : null;

  const searchTerm = params.get('search') || null;
  // const [pageNumber, setPageNumber] = useState(Number(params.get('page')) || 1);

  // const leadsPageNumber = Number(params.get('leadsPage')) || 1;
  // const guestsPageNumber = Number(params.get('guestsPage')) || 1;
  // const selectedFilter = params.get('filter') || 'action-required';
  const queryParams = queryString.parse(location.search);
  const reference = data?.guestReservationFormEntry?.reference;
  const payload = data?.guestReservationFormEntry?.payload;
  const reservation = data?.guestReservationFormEntry?.reservation;
  const createdAt = data?.guestReservationFormEntry?.createdAt;

  const sum = (...all) => {
    return all.reduce((acc, val) => {
      acc = acc + (Number(val) || 0);
      return acc;
    }, 0);
  };

  return (
    <Layout metaTitle="Webforms">
      {loading ? (
        <>
          <div className="m-auto h-100 d-flex justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        </>
      ) : webformEntryId ? (
        <div className="pt-3 container-fluid">
          <h1> TYFR Form Response</h1>

          <div className="row">
            <div className="col col-lg-8">
              <div className="text-right">
                Submitted
                {createdAt && (
                  <span style={{ fontWeight: 'normal', marginLeft: '5px' }}>
                    {moment(createdAt).format('MM/DD/YYYY')} at{' '}
                    {moment(createdAt).format('hh:mm a')}
                  </span>
                )}{' '}
                by{' '}
                <a target="_blank" href={`/guests/${reservation.guest.id}`}>
                  {reservation.guest.firstName} {reservation.guest.lastName}
                </a>
              </div>
              <div className="card mb-3 pl-3 pr-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <ReservationDetail
                        propertyName={reservation.property.name}
                        platformName={reservation.platform.name}
                        reference={reference}
                        checkIn={reservation.checkIn}
                        checkOut={reservation.checkOut}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col col-lg-6 list-spacing">
                          <div>
                            <b>Guest Information:</b>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Adults Staying Confirmed</strong>
                            <span>{payload.adultsStayingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Adults Staying Tentative</strong>
                            <span>{payload.adultsStayingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.adultsStayingConfirmed,
                                payload.adultsStayingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Toddlers Staying Confirmed</strong>
                            <span>{payload.toddlersStayingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Toddlers Staying Tentative</strong>
                            <span>{payload.toddlersStayingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Children Staying Confirmed</strong>
                            <span>{payload.childrenStayingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Children Staying Tentative</strong>
                            <span>{payload.childrenStayingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.toddlersStayingConfirmed,
                                payload.toddlersStayingPossible,
                                payload.childrenStayingConfirmed,
                                payload.childrenStayingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Pets Staying Confirmed</strong>
                            <span>{payload.petsStayingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Pets Staying Tentative</strong>
                            <span>{payload.petsStayingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.petsStayingConfirmed,
                                payload.petsStayingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Vehicles Staying Confirmed</strong>
                            <span>{payload.vehiclesStayingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Vehicles Staying Tentative</strong>
                            <span>{payload.vehiclesStayingPossible || 0}</span>
                          </div>
                          <div class="">
                            <strong>Large Vehicles Staying</strong>
                            <div>{payload.largeVehiclesStaying || 'N/A'}</div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.vehiclesStayingConfirmed,
                                payload.vehiclesStayingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div className="mt-3">
                            <b>Events & Special Occasions:</b>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Has Special Occasion</strong>
                            <span>{payload.hasSpecialOccasion || 0}</span>
                          </div>
                          <div class="">
                            <strong>Events & Special Occasion Details</strong>
                            <div>{payload.specialOccasionDetails || 'N/A'}</div>
                          </div>
                          <hr />
                          <div className="mt-3">
                            <b>Other Reservations:</b>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Has Other Reservations</strong>
                            <span>{payload.hasOtherReservations || 0}</span>
                          </div>
                          <div class="">
                            <strong>Other Reservation Details</strong>
                            <div>
                              {payload.otherReservationsDetails || 'N/A'}
                            </div>
                          </div>
                          <hr />
                          <div className="mt-3">
                            <b>Contact Information:</b>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Primary Residence Name</strong>
                            <span>{payload.primaryResidenceName || 'N/A'}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Address</strong>
                            <span>{payload.address1 || ''}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>City</strong>
                            <span>{payload.city || ''}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>State</strong>
                            <span>{payload.state || ''}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Postal Code</strong>
                            <span>{payload.postalCode || ''}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Country</strong>
                            <span>{payload.country || ''}</span>
                          </div>
                        </div>
                        <div className="col col-lg-6  list-spacing">
                          <div>
                            <b>Visitor Information:</b>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Adults Visiting Confirmed</strong>
                            <span>{payload.adultsVisitingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Adults Visiting Tentative</strong>
                            <span>{payload.adultsVisitingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.adultsVisitingConfirmed,
                                payload.adultsVisitingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Toddlers Visiting Confirmed</strong>
                            <span>
                              {payload.toddlersVisitingConfirmed || 0}
                            </span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Toddlers Visiting Tentative</strong>
                            <span>{payload.toddlersVisitingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Children Visiting Confirmed</strong>
                            <span>
                              {payload.childrenVisitingConfirmed || 0}
                            </span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Children Visiting Tentative</strong>
                            <span>{payload.childrenVisitingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.toddlersVisitingConfirmed,
                                payload.toddlersVisitingPossible,
                                payload.childrenVisitingConfirmed,
                                payload.childrenVisitingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Pets Visiting Confirmed</strong>
                            <span>{payload.petsVisitingConfirmed || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Pets Visiting Tentative</strong>
                            <span>{payload.petsVisitingPossible || 0}</span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.petsVisitingConfirmed,
                                payload.petsVisitingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          <div class="d-flex justify-content-between">
                            <strong>Vehicles Visiting Confirmed</strong>
                            <span>
                              {payload.vehiclesVisitingConfirmed || 0}
                            </span>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Vehicles Visiting Tentative</strong>
                            <span>{payload.vehiclesVisitingPossible || 0}</span>
                          </div>
                          <div class="">
                            <strong>Large Vehicles Visiting</strong>
                            <div>{payload.largeVehiclesVisiting || 'N/A'}</div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <strong>Total</strong>
                            <span>
                              {sum(
                                payload.vehiclesVisitingConfirmed,
                                payload.vehiclesVisitingPossible
                              )}
                            </span>
                          </div>
                          <hr />
                          {Array.from({ length: 10 }).map((_, index) => {
                            if (!payload['visitorStartDate_' + (index + 1)])
                              return null;
                            return (
                              <>
                                <div class="list-spacing">
                                  <div class="d-flex justify-content-between">
                                    <strong>
                                      Visitor Start Date {index + 1}
                                    </strong>
                                    <span>
                                      {
                                        payload[
                                          'visitorStartDate_' + (index + 1)
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <strong>
                                      Visitor End Date {index + 1}
                                    </strong>
                                    <span>
                                      {payload['visitorEndDate_' + (index + 1)]}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <strong>
                                      Visitor Start Time {index + 1}
                                    </strong>
                                    <span>
                                      {
                                        payload[
                                          'visitorStartTime_' + (index + 1)
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <strong>
                                      Visitor End Time {index + 1}
                                    </strong>
                                    <span>
                                      {payload['visitorEndTime_' + (index + 1)]}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          <div class="list-spacing">
                            <div>
                              <strong>Additional Visiting Details:</strong>
                            </div>
                            <div>
                              {payload.additionDetailsVisiting || 'N/A'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <WebformsTable data={data.guestReservationFormEntriesConnection} />
        </>
      )}
    </Layout>
  );
};

export default withLocation(Webforms);
