import React, { useState } from 'react';
import {
  Grid,
  Inbox,
  Calendar,
  CheckCircle,
  CheckSquare,
  Info,
} from 'react-feather';
import Query from 'src/components/GraphQL/components/Query';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
import ScrollTable from 'src/components/ScrollTable';
import get from 'lodash/get';
import UserProvider, { UserConsumer } from 'src/contexts/User';
import Esign from './components/Esign';
import Layout from 'src/layouts';
import { Helmet } from 'react-helmet';
import PropertyDetailsModal from 'src/routes/properties/components/PropertyDetailsModal';

const PROPERTY_CHANNEL_QUERY = gql`
  {
    platforms {
      id
      key
      name
    }
    properties {
      id
      name
      key
      label
      type
      esignUrl
      propertyListings {
        propertyUrl
        urlId
        property {
          label
        }
        platform {
          name
          key
        }
      }
    }
  }
`;
const getBookingComUrlStrings = (columnName, urlId) => {
  if (urlId === 'Row1') {
    switch (columnName) {
      case 'Reservations':
        return 'https://admin.booking.com/hotel/hoteladmin/groups/reservations/index.html?hotel_account_id=5723862&lang=xu';
      default:
        return '';
    }
  }
  if (urlId === 'Row2') {
    switch (columnName) {
      case 'Dashboard':
        return 'https://admin.booking.com/hotel/hoteladmin/index-hotel-home.html?hotel_account_id=5723862&lang=xu';
      default:
        return '';
    }
  }
  switch (columnName) {
    case 'Dashboard':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/home.html?hotel_id=${urlId}&lang=xu`;
    case 'Inbox':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/messaging_inbox.html?hotel_id=${urlId}&lang=xu`;
    case 'Calendar':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/availability_calendar.html?room_id=212835601&lang=xu&hotel_id=${urlId}&view_mode=MONTHLY`;
    case 'Reservations':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/search_reservations.html?stay_from=2018-11-28&type=arrival&stay_to=2019-12-31&hotel_id=${urlId}&lang=xu&reservation_status=ok`;
    case 'Blocked':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/search_reservations.html?type=arrival&lang=xu&hotel_id=${urlId}&stay_from=2018-11-28&stay_to=2019-12-31`;
    default:
      return '';
  }
};
const getAirBnbUrlStrings = (columnName, urlId) => {
  if (urlId === 'Row1') {
    switch (columnName) {
      case 'Inbox':
        return 'https://www.airbnb.com/hosting/inbox';
      case 'Calendar':
        return 'https://www.airbnb.com/multicalendar';
      case 'Reservations':
        return 'https://www.airbnb.com/hosting/reservations/all';
      default:
        return '';
    }
  }
  if (urlId === 'Row2') {
    switch (columnName) {
      case 'Dashboard':
        return 'https://www.airbnb.com/hosting';
      case 'Inbox':
        return 'https://www.airbnb.com/hosting/inbox';
      case 'Calendar':
        return 'https://www.airbnb.com/multicalendar';
      case 'Reservations':
        return 'https://www.airbnb.com/hosting/reservations/upcoming';
      case 'Blocked':
        return 'https://www.airbnb.com/hosting/reservations/all';
      default:
        return '';
    }
  }
  switch (columnName) {
    case 'Dashboard':
      return `https://www.airbnb.com/manage-your-space/${urlId}/details`;
    case 'Inbox':
      return `https://www.airbnb.com/hosting/inbox`;
    case 'Calendar':
      return `https://www.airbnb.com/multicalendar/${urlId}`;
    case 'Reservations':
      return `https://www.airbnb.com/hosting/reservations/upcoming`;
    case 'Blocked':
      return `https://www.airbnb.com/hosting/reservations/all`;
    default:
      return '';
  }
};
const getTripAdvisorUrlStrings = (columnName, urlId) => {
  if (urlId === 'Row1') {
    switch (columnName) {
      case 'Inbox':
        return 'https://rentals.tripadvisor.com/inbox';
      case 'Reservations':
        return 'https://rentals.tripadvisor.com/bookings/manage';
      default:
        return '';
    }
  }
  if (urlId === 'Row2') {
    switch (columnName) {
      case 'Dashboard':
        return 'https://rentals.tripadvisor.com/inbox';
      case 'Reservations':
        return 'https://rentals.tripadvisor.com/bookings/manage';
      default:
        return '';
    }
  }
  switch (columnName) {
    case 'Dashboard':
      return ``;
    case 'Inbox':
      return `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/messaging_inbox.html?hotel_id=${urlId}&lang=xu`;
    case 'Calendar':
      return `https://rentals.tripadvisor.com/calendar/${urlId}`;
    case 'Reservations':
      return ``;
    case 'Blocked':
      return ``;
    default:
      return '';
  }
};
const getPlatformHomePageUrlString = platformName => {
  switch (platformName) {
    case 'airbnb':
      return 'https://www.airbnb.com/hosting';
    case 'homeaway':
      return 'https://admin.vrbo.com/haod/properties.html';
    case 'bookingcom':
      return 'https://admin.booking.com/?lang=xu&utm_source=extranet_login_footer&utm_medium=frontend';
    case 'tripadvisor':
      return 'https://rentals.tripadvisor.com/';
    default:
      return '';
  }
};

const getHomeAwayUrlStrings = (columnName, urlId) => {
  if (urlId === 'Row1') {
    switch (columnName) {
      case 'Inbox':
        return 'https://www.homeaway.com/rm';
      case 'Calendar':
        return 'https://admin.vrbo.com/gd/calendars/reservations/';
      case 'Reservations':
        return 'https://admin.vrbo.com/rm/f-reservations/';
      default:
        return '';
    }
  }
  if (urlId === 'Row2') {
    switch (columnName) {
      case 'Dashboard':
        return 'https://www.homeaway.com/gd/dash/';
      case 'Inbox':
        return 'https://www.homeaway.com/rm/';
      case 'Calendar':
        return 'https://www.homeaway.com/pxcalendars/reservations/';
      case 'Reservations':
        return 'https://www.homeaway.com/rm/f-reservations/';
      case 'Blocked':
        return 'https://www.homeaway.com/rm/p-1/f-reservations/sm-stayDate/so-desc/st-all-messages/';
      default:
        return '';
    }
  }
  switch (columnName) {
    case 'Dashboard':
      return `https://www.homeaway.com/gd/dash/${urlId}`;
    case 'Inbox':
      return `https://www.homeaway.com/rm//l-${urlId}`;
    case 'Calendar':
      return `https://www.homeaway.com/pxcalendars/reservations/${urlId}`;
    case 'Reservations':
      return `https://www.homeaway.com/rm/f-reservations/l-${urlId}`;
    case 'Blocked':
      return `https://www.homeaway.com/rm/p-1/f-reservations/l-${urlId}//sm-stayDate/so-desc/st-all-messages/`;
    default:
      return '';
  }
};

const getUrl = (platformName, columnName, urlId) => {
  if (columnName === 'Home') {
    return getPlatformHomePageUrlString(platformName);
  }
  switch (platformName) {
    case 'airbnb':
      return getAirBnbUrlStrings(columnName, urlId);
    case 'homeaway':
      return getHomeAwayUrlStrings(columnName, urlId);
    case 'bookingcom':
      return getBookingComUrlStrings(columnName, urlId);
    case 'tripadvisor':
      return getTripAdvisorUrlStrings(columnName, urlId);
    default:
      return '';
  }
};

const QuickLink = ({ platformKey, user }) => {
  const [selectedProperty, setSelectedProperty] = useState({});
  return (
    <Layout>
      <UserProvider>
        <UserConsumer>
          {({ state }) => {
            return (
              <Query query={PROPERTY_CHANNEL_QUERY}>
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <div className="container pt-3">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: '80vh' }}
                        >
                          <LoadingSpinner />
                        </div>
                      </div>
                    );
                  if (error) return <p className="mt-2">{error.message}</p>;
                  const { platforms, properties } = data;
                  const platformData = platforms.find(
                    platform => platform.key === platformKey
                  );

                  // const { propertyListings } = platformData;
                  const singleProperties = properties.filter(
                    property => property.type !== 'COMBO'
                  );
                  const propertyCombos = properties.filter(
                    property => property.type === 'COMBO'
                  );
                  return (
                    <div className="container-fluid pt-4">
                      <Helmet>
                        <title>BHIP - {platformData.name}</title>
                      </Helmet>
                      <div className="row">
                        <div className="col">
                          <h1 className="m-0">
                            {platformData.key === 'homeaway' && 'VRBO/'}
                            {platformData.name}
                          </h1>
                          <a
                            className="small"
                            href={getUrl(platformData.key, 'Home')}
                          >
                            Log into{' '}
                            {platformData.key === 'homeaway' && 'VRBO/'}
                            {platformData.name}
                          </a>
                          <div
                            className="card pl-3 pr-3 d-block"
                            style={{ maxWidth: '1100px' }}
                          >
                            <ScrollTable>
                              <table className="table">
                                <thead>
                                  <tr className="vertical-align">
                                    <th
                                      scope="col"
                                      className="align-middle text-center"
                                    >
                                      House#
                                    </th>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Name
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Dashboard
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Inbox
                                      <div className="font-weight-normal">
                                        (Conversation)
                                      </div>
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Calendar
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Reservations
                                      <div className="font-weight-normal">
                                        (current &amp; upcoming)
                                      </div>
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Reservations
                                      <div className="font-weight-normal">
                                        (All blocked/cancelled)
                                      </div>
                                    </td>
                                    <td
                                      scope="col"
                                      className="align-middle text-center font-weight-bold"
                                    >
                                      Send Agreement
                                    </td>
                                  </tr>
                                </thead>
                                <tbody className="text-center">
                                  <tr>
                                    <th></th>
                                    <td className="text-center">(All)</td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Dashboard',
                                        'Row1'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Dashboard',
                                            'Row1'
                                          )}
                                          target="_blank"
                                        >
                                          <Grid color="#C77AFE" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(platformKey, 'Inbox', 'Row1') !==
                                        '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Inbox',
                                            'Row1'
                                          )}
                                          target="_blank"
                                        >
                                          <Inbox color="#238BF3" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Calendar',
                                        'Row1'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Calendar',
                                            'Row1'
                                          )}
                                          target="_blank"
                                        >
                                          <Calendar color="#F99626" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Reservations',
                                        'Row1'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Reservations',
                                            'Row1'
                                          )}
                                          target="_blank"
                                        >
                                          <CheckSquare
                                            color="#47DA5D"
                                            size={25}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Blocked',
                                        'Row1'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Blocked',
                                            'Row1'
                                          )}
                                          target="_blank"
                                        >
                                          <CheckCircle
                                            color="#FF3823"
                                            size={25}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <th>ALL</th>
                                    <td className="text-center">ALL</td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Dashboard',
                                        'Row2'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Dashboard',
                                            'Row2'
                                          )}
                                          target="_blank"
                                        >
                                          <Grid color="#C77AFE" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(platformKey, 'Inbox', 'Row2') !==
                                        '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Inbox',
                                            'Row2'
                                          )}
                                          target="_blank"
                                        >
                                          <Inbox color="#238BF3" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Calendar',
                                        'Row2'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Calendar',
                                            'Row2'
                                          )}
                                          target="_blank"
                                        >
                                          <Calendar color="#F99626" size={25} />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Reservations',
                                        'Row2'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Reservations',
                                            'Row2'
                                          )}
                                          target="_blank"
                                        >
                                          <CheckSquare
                                            color="#47DA5D"
                                            size={25}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td>
                                      {getUrl(
                                        platformKey,
                                        'Blocked',
                                        'Row2'
                                      ) !== '' && (
                                        <a
                                          href={getUrl(
                                            platformKey,
                                            'Blocked',
                                            'Row2'
                                          )}
                                          target="_blank"
                                        >
                                          <CheckCircle
                                            color="#FF3823"
                                            size={25}
                                          />
                                        </a>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                  {singleProperties.map(property => {
                                    const propertyListing = {
                                      ...property,
                                      ...(property.propertyListings.find(
                                        channel =>
                                          channel.property.label ===
                                          property.label
                                      ) || property),
                                    };
                                    return (
                                      <tr
                                        key={
                                          propertyListing.property
                                            ? propertyListing.property.label
                                            : propertyListing.label
                                        }
                                      >
                                        <th>
                                          {propertyListing.property
                                            ? propertyListing.property.label
                                            : propertyListing.label}
                                        </th>
                                        <td className="text-left info text-nowrap">
                                          {
                                            <>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                  setSelectedProperty(property);
                                                }}
                                              >
                                                <Info size={18} />
                                              </a>
                                              <span className="ml-1">
                                                {propertyListing.name}
                                              </span>
                                            </>
                                          }
                                        </td>
                                        <td>
                                          {propertyListing.urlId && (
                                            <a
                                              href={getUrl(
                                                platformKey,
                                                'Dashboard',
                                                propertyListing.urlId
                                              )}
                                              target="_blank"
                                            >
                                              <Grid color="#C77AFE" size={25} />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          {propertyListing.urlId && (
                                            <a
                                              href={getUrl(
                                                platformKey,
                                                'Inbox',
                                                propertyListing.urlId
                                              )}
                                              target="_blank"
                                            >
                                              <Inbox
                                                color="#238BF3"
                                                size={25}
                                              />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          {propertyListing.urlId && (
                                            <a
                                              href={getUrl(
                                                platformKey,
                                                'Calendar',
                                                propertyListing.urlId
                                              )}
                                              target="_blank"
                                            >
                                              <Calendar
                                                color="#F99626"
                                                size={25}
                                              />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          {propertyListing.urlId && (
                                            <a
                                              href={getUrl(
                                                platformKey,
                                                'Reservations',
                                                propertyListing.urlId
                                              )}
                                              target="_blank"
                                            >
                                              <CheckSquare
                                                color="#47DA5D"
                                                size={25}
                                              />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          {propertyListing.urlId && (
                                            <a
                                              href={getUrl(
                                                platformKey,
                                                'Blocked',
                                                propertyListing.urlId
                                              )}
                                              target="_blank"
                                            >
                                              <CheckCircle
                                                color="#FF3823"
                                                size={25}
                                              />
                                            </a>
                                          )}
                                        </td>
                                        <td>
                                          <Esign
                                            user={state.userData}
                                            property={property}
                                            // router={router}
                                          ></Esign>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </ScrollTable>
                          </div>
                          <div className="mt-4">
                            <h1>Property Combos</h1>
                            <div
                              className="card d-block pl-3 pr-3"
                              style={{ maxWidth: '1100px' }}
                            >
                              <ScrollTable>
                                <table className="table">
                                  <thead>
                                    <tr className="vertical-align">
                                      <th
                                        scope="col"
                                        className="align-middle text-center"
                                      >
                                        House#
                                      </th>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Name
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Dashboard
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Inbox
                                        <div className="font-weight-normal">
                                          (Conversation)
                                        </div>
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Calendar
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Reservations
                                        <div className="font-weight-normal">
                                          (current &amp; upcoming)
                                        </div>
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Reservations
                                        <div className="font-weight-normal">
                                          (All blocked/cancelled)
                                        </div>
                                      </td>
                                      <td
                                        scope="col"
                                        className="align-middle text-center font-weight-bold"
                                      >
                                        Send Agreement
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                    {propertyCombos.map(property => {
                                      const propertyListing = {
                                        ...property,
                                        ...(property.propertyListings.find(
                                          channel =>
                                            channel.property.label ===
                                            property.label
                                        ) || property),
                                      };
                                      return (
                                        <tr
                                          key={
                                            propertyListing.property
                                              ? propertyListing.property.label
                                              : propertyListing.label
                                          }
                                        >
                                          <th>
                                            {propertyListing.property
                                              ? propertyListing.property.label
                                              : propertyListing.label}
                                          </th>
                                          <td className="text-left info text-nowrap">
                                            <span className="ml-1">
                                              {propertyListing.name}
                                            </span>
                                          </td>
                                          <td>
                                            {propertyListing.urlId && (
                                              <a
                                                href={getUrl(
                                                  platformKey,
                                                  'Dashboard',
                                                  propertyListing.urlId
                                                )}
                                                target="_blank"
                                              >
                                                <Grid
                                                  color="#C77AFE"
                                                  size={25}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            {propertyListing.urlId && (
                                              <a
                                                href={getUrl(
                                                  platformKey,
                                                  'Inbox',
                                                  propertyListing.urlId
                                                )}
                                                target="_blank"
                                              >
                                                <Inbox
                                                  color="#238BF3"
                                                  size={25}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            {propertyListing.urlId && (
                                              <a
                                                href={getUrl(
                                                  platformKey,
                                                  'Calendar',
                                                  propertyListing.urlId
                                                )}
                                                target="_blank"
                                              >
                                                <Calendar
                                                  color="#F99626"
                                                  size={25}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            {propertyListing.urlId && (
                                              <a
                                                href={getUrl(
                                                  platformKey,
                                                  'Reservations',
                                                  propertyListing.urlId
                                                )}
                                                target="_blank"
                                              >
                                                <CheckSquare
                                                  color="#47DA5D"
                                                  size={25}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            {propertyListing.urlId && (
                                              <a
                                                href={getUrl(
                                                  platformKey,
                                                  'Blocked',
                                                  propertyListing.urlId
                                                )}
                                                target="_blank"
                                              >
                                                <CheckCircle
                                                  color="#FF3823"
                                                  size={25}
                                                />
                                              </a>
                                            )}
                                          </td>
                                          <td>
                                            <Esign
                                              user={state.userData}
                                              property={property}
                                              //   router={router}
                                            ></Esign>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </ScrollTable>
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedProperty && (
                        <PropertyDetailsModal
                          onResetId={() => {
                            setSelectedProperty(false);
                          }}
                          propertyId={selectedProperty.id}
                        />
                      )}
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </UserConsumer>
      </UserProvider>
    </Layout>
  );
};

export default QuickLink;
