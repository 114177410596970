import filter from 'lodash/filter';
const locationKeyToLabelMap = {
  // "bedrooms" : "Bedrooms",
  // "bathrooms" : "Bathrooms",
  oceanfront: 'Oceanfront',
  hottub: 'Hot Tub',
  riverfront: 'Riverfront',
  elevator: 'Elevator',
  dock: 'Dock',
  riverfrontRecreationalArea: 'Riverfront Recreational Area',
  pool: 'Pool',
  swimSpa: 'Swim Spa',
  resortAccess: 'Resort Access',
  puttingGreen: 'Putting Green',
};

const getUpdatedPropertyList = (properties, amenityFilters) => {
  const updatedPropertiesByAmenityFilter = properties.map(property => {
    const amenityObj = {};
    property.amenities.forEach(amenity => {
      amenityObj[amenity['key']] = amenity.value;
      // amenityObj[`${amenity['key']}_sort`] = amenity.sort;
    });

    return { ...property, ...amenityObj };
  });

  if (amenityFilters) {
    // return _.filter(updatedPropertiesByAmenityFilter, amenityFilters);
    return filter(updatedPropertiesByAmenityFilter, amenityFilters);
  } else {
    return updatedPropertiesByAmenityFilter;
  }
};

const propertyFilterByAmenity = (properties, amenityFilters) => {
  const propertiesWithoutCombos = properties.filter(
    property => property.type !== 'COMBO'
  );
  const updatedPropertiesByAmenityFilter = getUpdatedPropertyList(
    propertiesWithoutCombos,
    amenityFilters
  );

  // const sortedAmenities = .sort(
  //   (a, b) =>
  //     new Date(b.lastActivityLogDate) -
  //     new Date(a.lastActivityLogDate)
  // )

  const amenityTotals = updatedPropertiesByAmenityFilter.reduce(
    (acc, property) => {
      Object.entries(locationKeyToLabelMap).forEach(key => {
        if (!acc[key[0]]) {
          acc[key[0]] = {
            key: key[0],
            label: key[1],
            total: property[key[0]] && property[key[0]] === 'Y' ? 1 : 0,
          };
        } else if (property[key[0]] && property[key[0]] === 'Y') {
          acc[key[0]].total += 1;
        }
      });
      return acc;
    },
    {}
  );

  return { updatedPropertiesByAmenityFilter, amenityTotals };
};

export default propertyFilterByAmenity;
