import React, { useState } from 'react';
import { Search } from 'react-feather';
import styles from './styles.module.scss';

const SearchInput = ({
  value,
  onChange = () => {},
  customIconStyle,
  placeHolder,
}) => {
  const [searchTerm, setSearchTerm] = useState(value || null);
  return (
    <>
      <input
        id="table-search-input"
        placeholder={placeHolder}
        value={searchTerm || ''}
        className={`${styles.searchInput}float-right form-control`}
        onChange={e => {
          const value = e.target.value;
          setSearchTerm(value);
          onChange(value);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            const value = e.target.value;
            onChange(value);
          }
        }}
      />
      <Search
        style={customIconStyle}
        className={`${styles.searchIcon} position-absolute`}
      />
    </>
  );
};
export default SearchInput;
