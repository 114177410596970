export const getPropertyLabel = (properties, propertyKey) => {
  const found = (properties || []).find(
    property => property.key === propertyKey
  );
  if (found) {
    return `${found.key} - ${found.name}`;
  }
  return 'All Properties';
};

export const getPropertyFilterOptions = properties => {
  const propertyOptions = (properties || []).reduce((acc, property) => {
    // if (property.type !== 'COMBO') {
    //   acc.push({
    //     key: property.key,
    //     label: `${property.key} - ${property.name}`,
    //   });
    // }
    acc.push({
      key: property.key,
      label: `${property.key} - ${property.name}`,
    });
    return acc;
  }, []);
  return [{ key: null, label: 'All Properties' }, ...propertyOptions];
};
export const getResourcesToShow = (resources, params, properties) => {
  return (
    resources &&
    resources.map(resource => {
      if (params.get('group')) {
        const resourceBelongsToGroup = properties.find(property => {
          return (
            property.id === resource.id &&
            resource.group &&
            resource.group === params.get('group')
          );
        });
        const showResource = resourceBelongsToGroup ? true : false;
        return {
          ...resource,
          showResource,
        };
      }
      if (params.get('property')) {
        const showResource =
          resource.key === params.get('property') ? true : false;
        return {
          ...resource,
          showResource,
        };
      }
      if (params.get('amenities')) {
        const resourceAmenityMatches = resource.amenities.reduce(
          (acc, resourceAmenity) => {
            if (
              params
                .get('amenities')
                .split(',')
                .filter(amenity => resourceAmenity.key === amenity).length > 0
            ) {
              acc += 1;
            }
            return acc;
          },
          0
        );
        const showResource =
          resourceAmenityMatches === params.get('amenities').split(',').length
            ? true
            : false;
        return {
          ...resource,
          showResource,
        };
      }
      return {
        ...resource,
        showResource: true,
      };
    })
  );
};
