import React from 'react';
const FormatNewLineToText = ({ text }) => {
  return text.split('\n').map((item, key) => {
    return (
      <p key={key}>
        {item}
        <br />
      </p>
    );
  });
};

export default FormatNewLineToText;
