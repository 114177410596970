import React from 'react';
import Tooltip from 'src/components/Tooltip';
import moment from 'moment';
import {
  apiDataToLabelMap,
  labelToUrlKeyMap,
  urlToGraphQLValueMap,
} from '../../maps';
import { Users } from 'react-feather';
import get from 'lodash.get';
import './styles.scss';

const getHasRelatedLead = (allLeads, currentLead) => {
  const relatedLeads = get(currentLead, 'relatedLeads') || [];
  // const foundRelatedLead =
  //   relatedLeads.length > 0
  //     ? relatedLeads.find(relatedLead => {
  //         return currentLead.node.email === relatedLead.email;
  //       })
  //     : null;

  const foundRelatedLead = relatedLeads.length > 0 ? true : false;

  if (foundRelatedLead) return true;

  // const foundMultipleLeadsByEmail = allLeads.filter(
  //   lead => currentLead.node.email === lead.node.email
  // );
  // if (foundMultipleLeadsByEmail.length > 1) {
  //   return true;
  // }
  // return false;
};

const LeadsTable = ({
  onRowClick,
  selectedLead = null,
  updatedStatus = null,
  nodes,
  count,
  customClass = '',
  customTableClass = '',
}) => {
  return (
    <div className={`card rounded table-responsive ${customClass}`}>
      <table className={`leads-table table px-3 ${customTableClass}`}>
        <thead>
          <tr>
            <th>From</th>
            <th>Message</th>
            <th>CI Preference</th>
            <th>Date</th>
            <th>Referrer</th>
            <th className="status-header" id="Popover1">
              <Tooltip
                label="Click dropdown to update status"
                placement="top"
                id={`leads-status-tooltip`}
              >
                <span id="update-status">Status</span>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {count === 0 && (
            <tr>
              <td colSpan="5" className="no-leads-heading mt-2">
                No leads to display
              </td>
            </tr>
          )}
          {nodes.map((lead, index) => {
            console.log('lead', lead);
            const hasRelatedLead = getHasRelatedLead(nodes, lead);
            const updatedLeadByStatus =
              selectedLead && selectedLead.id === lead.id;

            return (
              <tr
                key={index}
                onClick={() => {
                  onRowClick(lead, hasRelatedLead);
                  // setSelectedLead({
                  //   ...lead.node,
                  //   emailDate: lead.node && lead.node.leadDate,
                  // });
                  // setModalIsOpen(true);
                  // setUpdatedStatus(lead.node.status);
                }}
              >
                <td className="d-flex align-items-center">
                  <span>{lead.email}</span>
                  {hasRelatedLead && (
                    <span title="Multiple inquiries">
                      <Users size={17} className="primary ml-1" />
                    </span>
                  )}
                </td>
                <td>
                  {lead.message.length > 50
                    ? `${lead.message.slice(0, 50)}...`
                    : lead.message}
                </td>

                <td>
                  {lead.checkInPreference
                    ? moment(lead.checkInPreference)
                        .utc()
                        .format('MM/DD/YYYY')
                    : 'N/A'}
                </td>
                <td>
                  {moment(lead.leadDate)
                    .utc()
                    .format('MM/DD/YYYY hh:mm A')}
                </td>
                <td>{lead.referrer}</td>
                <td className="status">
                  <span
                    className="font-weight-bold"
                    data-class={
                      apiDataToLabelMap[
                        updatedLeadByStatus ? updatedStatus : lead.status
                      ]
                        ? apiDataToLabelMap[
                            updatedLeadByStatus ? updatedStatus : lead.status
                          ]
                        : 'Action Required'
                    }
                  >
                    {apiDataToLabelMap[
                      updatedLeadByStatus ? updatedStatus : lead.status
                    ]
                      ? apiDataToLabelMap[
                          updatedLeadByStatus ? updatedStatus : lead.status
                        ]
                      : 'Action Required'}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsTable;
