import { Mutation } from 'react-apollo';
import { Formik, Field } from 'formik';
import Switch from 'react-switch';
import * as Yup from 'yup';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import Loadable from '@loadable/component';
import gql from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';

const getGuestDisplay = ip => {
  if (ip) {
    const split = ip.split('.');
    if (split) {
      return `Guest ${split[split.length - 1]}`;
    }
  }
  return 'Guest';
};

const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));

const CREATE_GUEST_NOTE = gql`
  mutation createGuestNote(
    $note: String
    $guestId: Int
    $checkInPreference: Date
    $checkOutPreference: Date
    $followUpRequired: FollowUpRequired
    $propertyId: Int
    $reservation: String
  ) {
    createGuestNote(
      data: {
        note: $note
        guestId: $guestId
        checkInPreference: $checkInPreference
        checkOutPreference: $checkOutPreference
        followUpRequired: $followUpRequired
        propertyId: $propertyId
        reservation: $reservation
      }
    ) {
      note
      reservation
      creator {
        userName
        firstName
        lastName
        extension
        emailAddress
        status
        skills {
          key
          name
        }
      }
      createdAt
    }
  }
`;

const UPSERT_GUEST_PREFERENCE = gql`
  mutation upsertGuestPreference($note: String, $guestId: Int) {
    upsertGuestPreference(
      create: { note: $note, guestId: $guestId }
      update: { note: $note, guestId: $guestId }
      where: { guestId: $guestId }
    ) {
      id
      firstName
      preferences {
        note
        updatedAt
        employee {
          userName
        }
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  reservation: Yup.string().min(6, 'Must be at least 6 characters'),
});

const AddNoteForm = ({
  guestId,
  expert,
  ipAddress,
  properties,
  onSuccess,
  note,
}) => {
  return (
    <Mutation mutation={UPSERT_GUEST_PREFERENCE}>
      {(upsertGuestPreference, { data, error, loading }) => (
        <Formik
          // enableReinitialize={true}
          initialValues={{
            guestId: guestId ? guestId : null,
            note: note,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
            try {
              const result = await upsertGuestPreference({
                variables: {
                  note: values.note,
                  guestId: Number(values.guestId),
                },
              });
              if (result) {
                setStatus({ success: true });
                onSuccess();
              }
            } catch (err) {
              console.log('err', err);
              setStatus({ error: err });
            }
          }}
        >
          {formikProps => {
            const {
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
              submitCount,
            } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <div className="container p-0">
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-1">
                        <textarea
                          onChange={handleChange}
                          className="pl-2 w-100 form-control"
                          name="note"
                          value={values.note}
                          rows="8"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <FormResponse
                        errors={get(status, 'error')}
                        isVisible={
                          get(status, 'error') ||
                          get(status, 'success') ||
                          (Object.keys(errors).length > 0 && submitCount > 0)
                        }
                        alert={
                          Object.keys(errors).length > 0 && submitCount > 0
                        }
                      >
                        {get(status, 'success')}
                      </FormResponse>

                      <ThemedButton
                        type="submit"
                        loading={loading}
                        className="w-100 btn btn-outline-primary"
                      >
                        Save
                      </ThemedButton>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default AddNoteForm;
