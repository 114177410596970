import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from '@reach/router';
import axios from 'axios';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import Layout from 'src/layouts';
import { ReactTable, NumberRangeColumnFilter, SelectColumnFilter } from 'src/components/ReactTable';
import BTable from 'react-bootstrap/Table';
import LoadingSpinner from 'src/components/LoadingSpinner';
import Modal from 'src/components/Modal';
import { Info, ChevronUp, ChevronDown, Filter } from 'react-feather';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './styles.scss';

import { getToken, formatColumnData, getCellClassNames, generateSortingIndicator, useSelectFilter } from './utils';
import { GuestReservationModal, PaymentRequestEditModal, GuestReviewEmployeeModal, TemplateBuilderCheckInMessageModal } from './modals';
import { DynamicComponent, DynamicHyperlink } from './components/DynamicHandlers';

const DynamicReport = ({ location }) => {
  const [reportData, setReportData] = useState(null);
  const [columnDefinitions, setColumnDefinitions] = useState(null);
  const [reportTypeData, setReportTypeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportDescriptionModalIsOpen, setReportDescriptionModalIsOpen] = useState(false);
  const pathSplit = location.pathname.split('/');
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const defaultFilters = params.get('filter')
    ? Object.fromEntries(new URLSearchParams(decodeURIComponent(params.get('filter'))))
    : {};

  useEffect(() => {
    setLoading(true);
    getReportData();
    getReportLabel();
  }, [location?.pathname]);

  const getReportData = async () => {
    const token = getToken();
    

    const result = await axios.get(
      `${process.env.BHIP_API_URL}/reports/${
        pathSplit[pathSplit.length - 1]
      }${searchParams}`,
      { headers: { Authorization: `Bearer: ${token}` } }
    );

    const resultData = result.data;

    if (resultData.error) {
      // console.log('resultData', resultData);
      if (resultData.error == 'ACCESS_DENIED') {
        //   console.log('result3', result);
        window.location.href = '/login';
        return;
      }
    }
    const transformedReportData = resultData?.report?.reduce((acc, item) => {
      const reducedObj = Object.entries(item).reduce(
        (innerAcc, [key, value]) => {
          if (value === '') {
            innerAcc[key] = ' ';
          } else {
            innerAcc[key] = value;
          }
          return innerAcc;
        },
        {}
      );
      acc.push(reducedObj);
      return acc;
    }, []);

    // setReportData(resultData?.report);
    setReportData(transformedReportData);

    setColumnDefinitions(resultData?.column_definitions);
    setLoading(false);    
    // Implement the API call to fetch report data
    // Update state variables: setReportData, setColumnDefinitions, setLoading
  };

  const getReportLabel = async () => {
    const token = getToken();

      const result = await axios.get(`${process.env.BHIP_API_URL}/reports/`, {
        headers: { Authorization: `Bearer: ${token}` },
      });
console.log('result1', result)
      const resultData = result.data;

      const routes = result?.data?.routes;

      if (resultData.error) {
        if (resultData.error == 'ACCESS_DENIED') {
          window.location.href = '/login';
          return;
        }
      }
      const foundMatch =
        routes && routes.find(route => route.slug === location.pathname);
        console.log('result1foundMatch', result)
      if (foundMatch) {
        setReportTypeData({
          key: foundMatch.slug,
          label: foundMatch.label,
          description: foundMatch?.description,
        });
      }
  };

  const CustomCell = ({ value, cell }) => {
    let transformedValue = value;
    if (['createdAt', 'updatedAt', 'auditDate'].includes(cell.column.id)) {
      transformedValue = value
        ? moment.tz(value, 'America/New_York').format('YYYY-MM-DDTHH:mm:ss') + ' EST'
        : '';
    }
    return (
      <DynamicHyperlink cell={cell} refetch={getReportData}>
        <DynamicComponent value={transformedValue} cell={cell} />
      </DynamicHyperlink>
    );
  };

  const memoizedReportData = useMemo(() => reportData, [reportData]);
  const tableColumns = useMemo(
    () => formatColumnData(reportData, columnDefinitions, CustomCell),
    [reportData, columnDefinitions]
  );

  const csvFileName = reportTypeData?.key &&
    `${reportTypeData?.key.split('/').join('-').slice(1)}-${moment().format('YYYYMMDD')}.csv`;

  return (
    <Layout hideMainOverflow={true} metaTitle={reportTypeData?.label || ''}>
      <section id="customer-reviews" className="px-3 d-flex mt-3 flex-column h-100">
        <ReactTable
          initialState={{
            filters: Object.keys(defaultFilters).map(key => ({
              id: key,
              value: defaultFilters[key],
            })),
          }}
          data={memoizedReportData}
          columns={tableColumns}
        >
          {({
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            rows,
            sortedRows,
            setFilter,
            columns,
          }) => {

            const rowProps = row => {
              //console.log('rowProps', row);
              if (row.original.rowClass_hidden) {
                return {
                  className: row.original.rowClass_hidden,
                };
              }
            };
            const generateSortingIndicator = column => {
              return column.isSorted ? (
                column.isSortedDesc ? (
                  <div>
                    <ChevronDown />
                  </div>
                ) : (
                  <div>
                    <ChevronUp />
                  </div>
                )
              ) : (
                <div className="d-flex flex-column">
                  <ChevronUp />
                  <ChevronDown style={{ marginTop: '-10px' }} />
                </div>
              );
            };

            const selectListFilterData = columns.find(
              column => column?.id === 'platform'
            );

            return (
            <>
              {reportTypeData && !loading && (
                  <div className="page-header d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex align-items-center">
                      <h1 className="mb-0">{reportTypeData?.label}</h1>
                      {reportTypeData?.description && (
                        <button
                          className="btn btn-link ml-1 p-0"
                          onClick={() => setReportDescriptionModalIsOpen(true)}
                        >
                          <Info size={18} />
                        </button>
                      )}
                    </div>

                    {reportData && reportTypeData && reportData.length > 0 && (
                      <div className="d-flex mb-2 align-items-center">
                        <span className="mr-2">
                          {selectListFilterData && (
                            <SelectColumnFilter
                              customDefaultLabel={selectListFilterData?.Header}
                              customOnChange={e => {
                                const { id } = selectListFilterData;
                                setFilter(id, e.target.value);
                                // for testing on graphql dev server
                                // setFilter('property', 'Aquarius (1122)');
                              }}
                              column={selectListFilterData}
                            />
                          )}
                        </span>
                        <CSVLink
                          filename={csvFileName}
                          data={sortedRows.map(sortedRow => sortedRow.values)}
                        >
                          <span style={{ whiteSpace: 'nowrap' }}>
                            Download Report
                          </span>
                        </CSVLink>
                      </div>
                    )}
                  </div>
                )}
                {!reportData && !loading && (
                  <div className="row mt-2">
                    <div className="col">
                      <span>Your data cannot be retrieved.</span>
                    </div>
                  </div>
                )}
                {reportData && reportData.length === 0 && !loading && (
                  <div className="row mt-2">
                    <div className="col">
                      <span>No data currently available.</span>
                    </div>
                  </div>
                )}
                <div id="table-wrapper">
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '80vh' }}
                    >
                      <LoadingSpinner />
                    </div>
                  ) : (
              <BTable
                className="mb-0 position-relative"
                data-sticky-header-table
                striped
                bordered
                hover
                size="sm"
                {...getTableProps()}
              >
                <thead className="header">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          key={column?.Header}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="d-flex justify-content-between">
                            <span
                              style={{
                                whiteSpace: column.Header.length < 15 ? 'nowrap' : 'inherit',
                              }}
                            >
                              {column.render('Header')}
                            </span>
                            {column?.id !== 'platform' && (
                              <Tooltip
                                html={
                                  <div className="d-flex">
                                    <div>{column.render('Filter')}</div>
                                    <div>
                                      <button
                                        className="btn btn-light py-0 ml-2"
                                        {...column.getSortByToggleProps()}
                                      >
                                        {generateSortingIndicator(column)}
                                      </button>
                                    </div>
                                  </div>
                                }
                                position="bottom"
                                interactive
                                trigger="click"
                                theme="light"
                                style={{ cursor: 'pointer' }}
                              >
                                <Filter
                                  color={column.filterValue || column.isSorted ? '#333' : '#ccc'}
                                  size={20}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr
                        key={row?.id}
                        {...row.getRowProps()}
                        className={row.original.rowClass_hidden}
                      >
                        {row.cells.map((cell, index) => {
                          const title = cell?.value?.length > 200 ? cell.value : null;
                          const cellStyles = cell?.row?.original?.cellStyles_hidden?.trim()
                            ? JSON.parse(cell?.row?.original?.cellStyles_hidden?.trim())
                            : {};
                          return (
                            <td
                              key={`${index}_${cell?.row?.id}_${cell?.value}`}
                              style={{
                                whiteSpace: cell?.value?.length > 50 ? 'inherit' : 'nowrap',
                                ...cellStyles,
                              }}
                              className={getCellClassNames(cell)}
                              {...cell.getCellProps()}
                              title={title}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </BTable>
                 )}
                </div>
              </>
            );
          }}

        </ReactTable>
      </section>

      <Modal
        title={reportTypeData?.label}
        isOpen={reportDescriptionModalIsOpen}
        onClose={() => setReportDescriptionModalIsOpen(false)}
        disableForceFocus={true}
      >
        <p>
          {reportTypeData?.description?.split('\n').map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
        </p>
      </Modal>
    </Layout>
  );
};

export default DynamicReport;
