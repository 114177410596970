import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'src/components/Modal';
import LoadingSpinner from 'src/components/LoadingSpinner';
import SearchInput from 'src/components/SearchInput';
import { Link, navigate } from '@reach/router';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Layout from 'src/layouts';
import { updateUrl } from 'src/util/urlHelper/index';
import debounce from 'src/util/debounce';
import * as queryString from 'query-string';

const GET_RULES = gql`
  {
    emailInsightRulesConnection(where: { accountId: 1 }) {
      edges {
        node {
          __typename
          searchQuery
        }
      }
    }
  }
`;

const GET_EMAILS = gql`
  query GetEmails($searchQuery: String) {
    emailInsightEmailsConnection(where: { searchQuery: $searchQuery }) {
      aggregate {
        count
      }
      edges {
        node {
          accountId
          subject
          date
          from
          to
        }
      }
    }
  }
`;

const Login = ({ location, accountId, emailAddress, feature }) => {
  // console.log('props', props);
  const queryParams = queryString.parse(location.search);
  const [searchTerm, setSearchTerm] = useState(queryParams.searchQuery);
  console.log('location', location);
  console.log('queryParams', queryParams.searchQuery);
  const { data: rules, loading: rulesLoading } = useQuery(GET_RULES, {
    //variables: { accountId: accountId },
    fetchPolicy: 'no-cache',
  }); //
  const { data, error, loading, refetch, ...rest } = useQuery(GET_EMAILS, {
    variables: { searchQuery: queryParams.searchQuery },
    fetchPolicy: 'no-cache',
    skip: !queryParams.searchQuery,
  }); //
  console.log('data', data);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    refetch({ searchQuery: searchParams.get('searchQuery') });
    setSearchTerm(searchParams.get('searchQuery'));
  }, [window.location.search]);
  // console.log('data', data);
  if (rulesLoading) return '...';
  return (
    <Layout>
      <div className="container mt-4 ml-0 mb-0 mr-0">
        <div className="row">
          <div className="col col-12 col-md-6">
            <h3>Email Insights</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Create New:</p>
            <SearchInput
              //  placeHolder={`Name, Email, Phone#`}
              //  customIconStyle={{ right: '5px', top: '7px' }}
              value={searchTerm}
              onChange={value => {
                const debounced = debounce(function() {
                  const queryParamsClone = { ...queryParams };
                  //  delete queryParamsClone.guestsPage;
                  //  delete queryParamsClone.leadsPage;
                  //  delete queryParamsClone.filter;
                  //  delete queryParamsClone.id;

                  updateUrl(
                    'email-insights/rules/1/create',
                    {
                      ...queryParamsClone,
                      searchQuery: value,
                    },
                    true
                  );
                }, 500);
                debounced();
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col  mt-3 ">
            <div className="text-right">
              Total: {data?.emailInsightEmailsConnection?.aggregate?.count || 0}
            </div>
            <table className="table table-bordered mb-0">
              <tr>
                <td>Subject</td>
                <td>From</td>
                <td>To</td>
                <td>Account</td>
                <td>Date</td>
              </tr>
              {data?.emailInsightEmailsConnection?.edges.map(({ node }) => {
                return (
                  <>
                    <tr>
                      <td>{node.subject}</td>
                      <td>{node.from}</td>
                      <td>{node.to}</td>
                      <td>
                        {node.accountId == '1'
                          ? 'alroberts.airbnb@gmail.com'
                          : 'beachhousesinparadise@gmail.com'}
                      </td>
                      <td className="text-nowrap">{node.date}</td>
                    </tr>
                  </>
                );
              })}
            </table>
            {searchTerm && (
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  alert(
                    'Open Modal allowing user to create / specify Tags that can be applied retroactively and going foward for new email received.'
                  );
                }}
              >
                Create Action
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col mt-4">
            <p>Existing Rules:</p>
            <table className="table table-bordered mb-0 ">
              <tr>
                <td>Rule</td>
                <td>Action</td>
              </tr>
              {rules?.emailInsightRulesConnection?.edges.map(({ node }) => {
                return (
                  <>
                    <tr>
                      <td>{node.searchQuery}</td>
                      <td>
                        <a
                          href={`/email-insights/rules/1/create?searchQuery=${encodeURIComponent(
                            node.searchQuery
                          )}`}
                        >
                          Test
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
