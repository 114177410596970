import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'src/components/Modal';
import { Link, navigate } from '@reach/router';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Layout from 'src/layouts';

const GET_RULES = gql`
  {
    emailInsightsRulesConnection(where: { accountId: 1 }) {
      edges {
        node {
          __typename
          searchQuery
        }
      }
    }
  }
`;

const Login = ({ accountId, emailAddress, feature }) => {
  // console.log('props', props);
  // const [copied, setCopied] = useState(false);
  // const { data, error, loading, refetch, ...rest } = useQuery(GET_RULES, {
  //   //variables: { accountId: accountId },
  //   fetchPolicy: 'no-cache',
  // }); //
  // console.log('data', data);
  // if (loading) return '...';
  return (
    <Layout>
      <div className="container mt-4 ml-0 mb-0 mr-0">
        <div className="row">
          <div className="col d-flex align-items-center">
            <div className="h1 m-0 mr-3">Email Insights</div>
            <div className="mt-1">
              <a href="#">Home</a> | <a href="#">Rules</a> |{' '}
              <a href="#">Tags & Sources</a>
            </div>
          </div>
          {/* {data.emailInsightsRulesConnection.edges.map(({ node }) => {
            return <>{node.searchQuery}---</>;
          })} */}
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-bordered mb-0 mt-3">
              <tr>
                <td>Account</td>
                <td>Actions</td>
              </tr>
              <tr>
                <td>alroberts.airbnb@gmail.com</td>
                <td>
                  <Link to={`/email-insights/rules/1/create`}>
                    Rules & Actions
                  </Link>
                </td>
              </tr>
              <tr>
                <td>beachhousesinparadise@gmail.com</td>
                <td>
                  <Link to={`/email-insights/rules/1/create`}>
                    Rules & Actions
                  </Link>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
