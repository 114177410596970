import React from 'react';
import { Link } from '@reach/router';
import { StarIcons, GuestReviewFeaturedCheckbox, AlertIcon, CheckMarkIcon } from './CustomCells';
import { GuestReservationModal, PaymentRequestEditModal, GuestReviewEmployeeModal, TemplateBuilderCheckInMessageModal } from '../modals';
import { generateTemplateString } from '../utils';

export const DynamicComponent = ({ value, cell }) => {
  const columnDefinition = cell.column.columnDefinition || {};
  const componentKey = columnDefinition?.component;

  if (componentKey) {
    const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
      if (cell.row.original[key]) {
        acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
      }
      return acc;
    }, {});

    switch (componentKey) {
      case 'GuestReviewFeaturedCheckbox':
        if (cell.row.original['reviewStatus'] !== 'APPROVED') return '';
        return (
          <GuestReviewFeaturedCheckbox
            siteStatus={value}
            reviewId={cell.row.original['reviewId_hidden']}
          />
        );
      case 'StarIcons':
        return <StarIcons numberOfStars={value} />;
      case 'Alert':
        return <AlertIcon value={value} />;
      case 'CheckMark':
        return <CheckMarkIcon value={value} />;
      default:
        return value;
    }
  }
  return <>{value}</>;
};

export const DynamicHyperlink = ({ cell, children, refetch }) => {
  const columnDefinition = cell.column.columnDefinition || {};
  const hyperlink = columnDefinition?.hyperlink;
  if (!hyperlink) {
    return children;
  }
  const dataForProps = Object.keys(cell.row.original).reduce((acc, key) => {
    if (cell.row.original[key]) {
      acc[key.replace(/_hidden$/, '')] = cell.row.original[key];
    }
    return acc;
  }, {});

  switch (hyperlink) {
    case 'GuestReservationModal':
      return (
        <GuestReservationModal
          onSuccess={async () => {
            console.log('success!');
            // Implement the refresh logic here
            refetch();
          }}
          {...dataForProps}
        >
          {children}
        </GuestReservationModal>
      );
    case 'PaymentRequestEditModal':
      return (
        <PaymentRequestEditModal
          onSuccess={async () => {
            console.log('success!');
            refetch();
            // Implement the refresh logic here
          }}
          {...dataForProps}
        >
          {children}
        </PaymentRequestEditModal>
      );
    case 'GuestReviewEmployeeModal':
      return (
        <GuestReviewEmployeeModal
          onSuccess={async () => {
            console.log('success!');
            refetch();
            // Implement the refresh logic here
          }}
          {...dataForProps}
        >
          {children}
        </GuestReviewEmployeeModal>
      );
    case 'TemplateBuilderCheckInMessageModal':
      return (
        <TemplateBuilderCheckInMessageModal
          {...dataForProps}
        >
          {children}
        </TemplateBuilderCheckInMessageModal>
      );
    default:
      return columnDefinition.hyperlinkTarget ? (
        <a
          target={columnDefinition.hyperlinkTarget}
          href={generateTemplateString(columnDefinition.hyperlink)(
            cell.row.original
          )}
        >
          {children}
        </a>
      ) : (
        <Link
          to={generateTemplateString(columnDefinition.hyperlink)(
            cell.row.original
          )}
        >
          {children}
        </Link>
      );
  }
};