import React, { useState, useEffect } from 'react';
import Query from 'src/components/GraphQL/components/Query';
import {
  PlusCircle,
  Search,
  ArrowLeft,
  Check,
  AlertTriangle,
} from 'react-feather';
import ThemedPager from 'src/components/ThemedPager/index.js';
import Layout from 'src/layouts';
import ThemedTable from 'src/components/ThemedTable/index.js';
import { navigate, Link } from 'gatsby';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
import styles from './styles.module.scss';
import Modal from 'src/components/Modal';
import SearchInput from 'src/components/SearchInput';
import withLocation from 'src/components/WithLocation';
import moment from 'moment';
import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';
import GuestReservationScrapeModal from 'src/components/GuestReservationScrapeModal';
import { enumToLabel } from 'src/util';
import TYFRFormLinkIcon, {
  TYFRLinkModal,
} from 'src/components/TYFRFormLinkIcon';

const RESERVATION = gql`
  query GuestsReservation($reference: String!) {
    guestReservation(where: { reference: $reference }) {
      id
      reference
      checkIn
      checkOut
      checkInHour
      checkOutHour
      reservationCreateDate
      status
      adults
      children
      confirmationStatus
      agreementSigned
      eventType
      visitors
      pets
      property {
        key
      }
      guest {
        id
        firstName
        lastName
      }
    }
  }
`;

const GUESTS_RESERVATIONS = gql`
  query GuestsReservations($checkOut_gte: Date, $first: Int!, $skip: Int!) {
    guestReservationConnection(
      where: { checkOut_gte: $checkOut_gte, status_not: "CANCELLED" }
      first: $first
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          id
          reference
          checkIn
          checkOut
          checkInHour
          checkOutHour
          reservationCreateDate
          status
          adults
          children
          confirmationStatus
          agreementSigned
          eventType
          visitors
          pets
          property {
            key
          }
          guest {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const checkOut_gte = new Date();

let timeout;
function debounce(func, wait) {
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const GuestRelations = ({
  location,
  reservationId,
  openTYFRModal,
  ...rest
}) => {
  console.log('rest', rest);

  const params = new URLSearchParams(location.search);
  const recordsPerPage = 10;
  const searchTerm = reservationId || params.get('search') || null;

  const [isTYFRModalOpen, setIsTYFRModalOpen] = useState(false);
  console.log('openTYFRModal', openTYFRModal);
  useEffect(() => {
    console.log('useEffect', openTYFRModal);
    if (openTYFRModal) {
      setIsTYFRModalOpen(true);
    }
  }, [openTYFRModal]);
  const [pageNumber, setPageNumber] = useState(params.get('page') || '1');
  const [guestData, setGuestData] = useState(null);
  const [
    guestReservationScrapeModalIsOpen,
    setGuestReservationScrapeModalIsOpen,
  ] = useState(false);

  const [selectedReservation, setSelectedReservation] = useState(false);
  const updateUrl = (value, pageNumber = 1) => {
    if (pageNumber === 1) {
      if (value?.length > 3 || searchTerm !== null) {
        navigate(`/reservations?search=${value}`);
      } else {
        navigate(`/reservations`);
      }
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber);
      navigate(
        `/reservations?page=${pageNumber}${value ? `&search=${value}` : ''}`
      );
    }
  };

  const LoadingSpinnerContainer = () => {
    return (
      <div className="container-fluid pt-3">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '80vh' }}
        >
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  return (
    <Layout metaTitle={'Reservations'}>
      <div className="container-fluid pt-3">
        <div className="row mb-3">
          <div className="col mt-1 d-flex align-items-center mb-2 mb-lg-0">
            <h1 className="mr-1 mb-0">Guest Reservations</h1>

            <div className="text-center">
              <a
                href="#"
                onClick={() => {
                  setGuestReservationScrapeModalIsOpen(true);
                }}
              >
                <span className="ml-2 d-inline-block">
                  <PlusCircle
                    strokeWidth="1"
                    className="align-top text-primary"
                    size="22"
                  />
                </span>
                <span className="d-inline-block pt-1 add-new">
                  Fetch from VRP
                </span>
              </a>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <span>
              <SearchInput
                placeHolder={`Reservation #`}
                value={searchTerm}
                onChange={value => {
                  const debounced = debounce(function() {
                    updateUrl(value);
                  }, 500);
                  debounced();
                }}
              />
            </span>
          </div>
        </div>
        <Query
          variables={{
            reference: searchTerm,
          }}
          query={RESERVATION}
          skip={searchTerm === null}
        >
          {({
            loading: loadingSingleReservation,
            data: reservationData,
            refetch: refetchSingleReservation,
          }) => {
            const guestReservation = reservationData?.guestReservation;
            return (
              <Query
                variables={{
                  checkOut_gte,
                  first: recordsPerPage ? recordsPerPage : 10,
                  skip: (pageNumber - 1) * 10,
                }}
                query={GUESTS_RESERVATIONS}
                skip={searchTerm}
                // fetchPolicy="no-cache"
              >
                {({
                  loading,
                  error,
                  data,
                  refetch: refetchAllReservations,
                }) => {
                  //   if (loading) return <LoadingSpinnerContainer />;
                  if (loadingSingleReservation)
                    return <LoadingSpinnerContainer />;
                  //   if (error)
                  //     return (
                  //       <div className="container-fluid pt-3">
                  //         {error.message}
                  //         <a
                  //           href="#"
                  //           onClick={() => {
                  //             updateUrl(searchTerm);
                  //           }}
                  //         >
                  //           Try again
                  //         </a>
                  //         .
                  //       </div>
                  //     );

                  const { guestReservationConnection } = data || {};
                  if (!guestReservationConnection && !searchTerm && !loading)
                    return (
                      <div className="container-fluid pt-3">
                        There are no reservations available. Please try again.
                      </div>
                    );

                  const transformReservationData = guestReservationConnection => {
                    return guestReservationConnection.edges.reduce(
                      (acc, edge) => {
                        acc.push(edge.node);
                        return acc;
                      },
                      []
                    );
                  };
                  const count =
                    guestReservationConnection?.aggregate?.count || 0;
                  const reservations =
                    guestReservationConnection &&
                    transformReservationData(guestReservationConnection);
                  const hoursToTime = value => {
                    const hour = `${value}`.split('.')[0];
                    const minutes = `${value}`.split('.')[1];
                    const minutesFormatted = minutes
                      ? 60 * `.${minutes}`
                      : '00'; // moment.duration(1.5, 'hours');
                    return moment(
                      '1900-01-01 ' + hour + ':' + minutesFormatted
                    ).format('hh:mm a');
                  };

                  const ReservationTableRow = ({ reservation }) => {
                    return (
                      <tr
                        className={`${styles.guestsRow} not-clickable`}
                        key={`reservation-${reservation?.id}`}
                      >
                        {/* <td className="text-center">
                          <button
                            className="btn btn-link p-0 no-wrap"
                            onClick={() => {
                              setSelectedReservation({
                                reference: reservation?.reference,
                                guestName: `${reservation?.guest?.firstName} 
                              ${reservation?.guest?.lastName}`,
                              });
                            }}
                          >
                            {reservation?.confirmationStatus &&
                              enumToLabel(reservation?.confirmationStatus)}
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation({
                                reference: reservation?.reference,
                                guestName: `${reservation?.guest?.firstName} 
                              ${reservation?.guest?.lastName}`,
                              });
                            }}
                          >
                            {reservation?.confirmationStatus &&
                              enumToLabel(reservation?.eventType)}
                          </button>
                        </td> 
                        <td className="text-center">
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation({
                                reference: reservation?.reference,
                                guestName: `${reservation?.guest?.firstName} 
                              ${reservation?.guest?.lastName}`,
                              });
                            }}
                          >
                            {reservation?.confirmationStatus &&
                              enumToLabel(reservation?.agreementSigned)}
                          </button>
                        </td>*/}
                        <td className="text-center px-0">
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation(reservation?.reference);
                            }}
                          >
                            {reservation?.reference}
                          </button>
                          <TYFRFormLinkIcon
                            reference={reservation?.reference}
                            isOpen={isTYFRModalOpen}
                          />
                          <TYFRLinkModal
                            isOpen={isTYFRModalOpen}
                            reference={reservation?.reference}
                            onClose={() => {
                              setIsTYFRModalOpen(false);
                            }}
                          />
                        </td>

                        <td className="text-center">
                          {moment(reservation?.reservationCreateDate)
                            .utc()
                            .format('MM/DD/YYYY')}{' '}
                          {/* at{' '}
                          {moment
                            .utc(reservation?.reservationCreateDate)
                            .format('hh:mm a')} */}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: reservation?.checkInHour != 17 ? 'red' : '',
                          }}
                        >
                          {moment(reservation?.checkIn)
                            .utc()
                            .format('MM/DD/YYYY')}{' '}
                          at {hoursToTime(reservation?.checkInHour)}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: reservation?.checkOutHour != 11 ? 'red' : '',
                          }}
                        >
                          {' '}
                          {moment(reservation?.checkOut)
                            .utc()
                            .format('MM/DD/YYYY')}{' '}
                          at {hoursToTime(reservation?.checkOutHour)}
                        </td>
                        <td className="text-center">
                          {reservation?.property?.key}
                        </td>
                        <td className="text-center no-wrap">
                          <a
                            target="_blank"
                            href={`guests/${reservation?.guest?.id}`}
                          >
                            {reservation?.guest?.firstName}{' '}
                            {reservation?.guest?.lastName}
                          </a>
                        </td>
                        <td className="text-center no-wrap">
                          {reservation?.confirmationStatus &&
                            enumToLabel(reservation?.status)}
                        </td>
                        <td className="text-center">
                          {' '}
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation(reservation?.reference);
                            }}
                          >
                            {reservation?.adults}
                          </button>
                        </td>
                        <td className="text-center">
                          {' '}
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation(reservation?.reference);
                            }}
                          >
                            {reservation?.children}
                          </button>
                        </td>
                        <td className="text-center">
                          {' '}
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation(reservation?.reference);
                            }}
                          >
                            {reservation?.pets}
                          </button>
                        </td>
                        <td className="text-center">
                          {' '}
                          <button
                            className="btn btn-link p-0"
                            onClick={() => {
                              setSelectedReservation(reservation?.reference);
                            }}
                          >
                            {reservation?.visitors}
                          </button>
                        </td>
                      </tr>
                    );
                  };
                  return (
                    <div>
                      {loading && <LoadingSpinnerContainer />}
                      <div className="row">
                        <div className="col">
                          {!loading && (
                            <ThemedTable>
                              <thead>
                                <tr>
                                  {/* <td
                                    className={`font-weight-bold text-center`}
                                  >
                                    Confirmed
                                  </td>
                                  <td
                                    className={`font-weight-bold text-center`}
                                  >
                                    Event
                                  </td>
                                  <td
                                    className={`font-weight-bold text-center no-wrap`}
                                  >
                                    Agreement Required
                                  </td> */}
                                  <td
                                    className={`font-weight-bold text-center ${styles.customTdWidth} no-wrap`}
                                  >
                                    Reservation Id
                                  </td>
                                  <td
                                    className={`font-weight-bold text-center ${styles.customTdWidth} no-wrap`}
                                  >
                                    Date Reserved
                                  </td>
                                  <td
                                    className={`font-weight-bold text-center ${styles.customTdWidth}`}
                                  >
                                    Check In
                                  </td>
                                  <td
                                    className={`font-weight-bold text-center ${styles.customTdWidth}`}
                                  >
                                    Check Out
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    House#
                                  </td>
                                  <td className="font-weight-bold text-center no-wrap">
                                    Guest Name
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    Status
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    Adults
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    Children
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    Pets
                                  </td>
                                  <td className="font-weight-bold text-center">
                                    Visitors
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {count === 0 && !guestReservation?.id && (
                                  <tr>
                                    <td
                                      colSpan="8"
                                      className="no-leads-heading mt-2"
                                    >
                                      No Reservations to display
                                    </td>
                                  </tr>
                                )}
                                {guestReservation && (
                                  <ReservationTableRow
                                    reservation={guestReservation}
                                  />
                                )}
                                {reservations &&
                                  reservations.map((reservation, index) => (
                                    <React.Fragment
                                      key={`all-${reservation?.id}`}
                                    >
                                      <ReservationTableRow
                                        reservation={reservation}
                                      />
                                    </React.Fragment>
                                  ))}
                              </tbody>
                            </ThemedTable>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {guestReservationConnection?.aggregate?.count >
                            recordsPerPage && (
                            <ThemedPager
                              totalRecords={
                                guestReservationConnection.aggregate.count
                              }
                              dataConnection={guestReservationConnection}
                              recordsPerPage={recordsPerPage}
                              pageNumber={Number(pageNumber)}
                              changePageNumber={pageNumber => {
                                // refetchAllReservations();
                                updateUrl(searchTerm, pageNumber);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <Modal
                        styleType="large"
                        disableForceFocus={true}
                        onClose={() => {
                          setSelectedReservation(false);
                        }}
                        isOpen={selectedReservation ? true : false}
                        title={`Guest Reservation ${
                          guestData
                            ? `(${guestData?.firstName} ${guestData?.lastName})`
                            : ``
                        }`}
                        //   styleType="large"
                      >
                        <GuestReservationForm
                          onDataLoad={guestReservationData => {
                            setGuestData(guestReservationData?.guest);
                          }}
                          onSuccess={async () => {
                            refetchAllReservations();
                            refetchSingleReservation();
                          }}
                          reference={selectedReservation}
                        />
                      </Modal>
                      <GuestReservationScrapeModal
                        isOpen={guestReservationScrapeModalIsOpen}
                        onClose={() => {
                          setGuestReservationScrapeModalIsOpen(false);
                        }}
                      />
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withLocation(GuestRelations);
