import React, { useState, useEffect } from 'react';
import Query from 'src/components/GraphQL/components/Query';
import { PlusCircle, Search, ArrowLeft } from 'react-feather';
import ThemedPager from 'src/components/ThemedPager/index.js';
import Layout from 'src/layouts';
// import { Location } from '@reach/router';
// import { URL } from 'url';
import { navigate, Link } from 'gatsby';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';
import styles from './styles.module.scss';
import Modal from 'src/components/Modal';
import GuestForm from './components/GuestForm';
import withLocation from 'src/components/WithLocation';
import GuestTable from './components/GuestTable';
import ThemedTable from 'src/components/ThemedTable/index.js';
import GuestsInnerTable from 'src/routes/guests/components/GuestsInnerTable';
import RelatedGuests from './components/RelatedGuests';
import SearchInput from 'src/components/SearchInput';

const ALL_GUESTS = gql`
  query AllGuests($first: Int, $skip: Int, $searchTerm: String) {
    guestsConnection(
      where: { searchTerm: $searchTerm }
      first: $first
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        node {
          id
          firstName
          lastName
          blockType
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
          }
          guestSource {
            id
            name
          }
        }
      }
    }
    guestSources {
      key
      name
      id
    }
  }
`;

let timeout;
function debounce(func, wait) {
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const GuestRelations = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const recordsPerPage = 10;
  const searchTerm = params.get('search') || null;
  // const [searchTerm, setSearchTerm] = useState(params.get('search') || null);
  const [pageNumber, setPageNumber] = useState(params.get('page') || '1');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const updateUrl = (value, pageNumber = 1) => {
    if (pageNumber === 1) {
      if (value?.length > 3 || searchTerm !== null) {
        navigate(`/guests?search=${value}`);
      }
      // setSearchTerm(value);
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber);
      navigate(`/guests?page=${pageNumber}${value ? `&search=${value}` : ''}`);
    }
  };
  const relatedGuestsURLParam =
    params.get('guestsRelatedTo') && Number(params.get('guestsRelatedTo'));
  return (
    <Layout metaTitle={'Guests'}>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col mt-1">
            <>
              {relatedGuestsURLParam ? (
                <div className="d-flex align-items-center">
                  <button
                    disabled={
                      window && window.history && window.history.length <= 1
                    }
                    onClick={() => window.history.back()}
                    className="mr-1 mr-0 p-0 btn"
                  >
                    <ArrowLeft className="align-top text-primary" size="30" />
                  </button>
                  <h1 className="d-inline-block mb-0">Related Guests</h1>
                </div>
              ) : (
                <h1 className="d-inline-block">Guest Relations</h1>
              )}
              {!relatedGuestsURLParam && (
                <span
                  onClick={() => setModalIsOpen(true)}
                  className={`${styles.addGuest} add-guest`}
                >
                  <span className="ml-2 d-inline-block">
                    <PlusCircle
                      className="align-top"
                      strokeWidth="1"
                      size="22"
                    />
                  </span>
                  <span className="d-inline-block pt-1">Add New</span>
                </span>
              )}
            </>
          </div>
          <div className="col col-12 col-lg-4 mb-3 text-right">
            <span>
              <SearchInput
                placeHolder={`Name, Email, Phone#`}
                value={searchTerm}
                onChange={value => {
                  const debounced = debounce(function() {
                    updateUrl(value);
                  }, 500);
                  debounced();
                }}
              />
              <Link to="/notes">Search Notes</Link>
            </span>
          </div>
        </div>
        <Query
          variables={{
            searchTerm: (searchTerm || {}).length > 3 ? searchTerm : null,
            first: recordsPerPage,
            skip: (pageNumber - 1) * recordsPerPage,
          }}
          query={ALL_GUESTS}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <div className="container-fluid pt-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <LoadingSpinner />
                  </div>
                </div>
              );
            if (error)
              return (
                <div className="container-fluid pt-3">
                  {error.message}
                  <a
                    href="#"
                    onClick={() => {
                      updateUrl(searchTerm);
                    }}
                  >
                    Try again
                  </a>
                  .
                </div>
              );
            const { guestsConnection, guestSources } = data || {};
            if (!guestsConnection)
              return (
                <div className="container-fluid pt-3">
                  There was a problem with your submission. Please try again.
                </div>
              );

            const transformGuestData = guestsConnection => {
              return guestsConnection.edges.reduce((acc, edge) => {
                acc.push(edge.node);
                return acc;
              }, []);
            };
            const count = guestsConnection?.aggregate?.count || 0;
            const guests = transformGuestData(guestsConnection);
            return (
              <div>
                {relatedGuestsURLParam ? (
                  <div className="row">
                    <div className="col">
                      <RelatedGuests id={relatedGuestsURLParam} />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col">
                        {/* <GuestTable
                          count={guestsConnection?.aggregate?.count || 0}
                          guests={transformGuestData(guestsConnection)}
                        /> */}
                        <ThemedTable>
                          <GuestsInnerTable guests={guests} count={count} />
                        </ThemedTable>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {guestsConnection.aggregate.count > recordsPerPage && (
                          <ThemedPager
                            totalRecords={guestsConnection.aggregate.count}
                            dataConnection={guestsConnection}
                            recordsPerPage={recordsPerPage}
                            pageNumber={Number(pageNumber)}
                            changePageNumber={pageNumber =>
                              updateUrl(searchTerm, pageNumber)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                <Modal
                  onClose={() => {
                    setModalIsOpen(false);
                    refetch();
                  }}
                  isOpen={modalIsOpen}
                  title={'Add New Guest'}
                  styleType="large"
                >
                  <GuestForm
                    onSuccess={() => refetch()}
                    sources={guestSources}
                  />
                </Modal>
              </div>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withLocation(GuestRelations);
