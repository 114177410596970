import React, { useState } from 'react';
import Modal from 'src/components/Modal';
import GuestReservationForm from 'src/routes/guests/components/GuestReservationForm';

const GuestReservationModal = ({
  reservationId,
  onSuccess,
  children,
  guestName,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => setModalIsOpen(true)}
        className="btn btn-link p-0 small"
      >
        {children}
      </button>
      <Modal
        title={`Guest Reservation (${guestName})`}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        styleType="large"
        disableForceFocus={true}
      >
        <GuestReservationForm
          onSuccess={() => onSuccess()}
          reference={reservationId}
        />
      </Modal>
    </>
  );
};

export default GuestReservationModal;