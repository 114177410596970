import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormResponse from './FormResponse';
import GoogleSignIn from '../../../images/btn_google_signin_dark_normal_web.png';
import Cookies from 'js-cookie';

import get from 'lodash.get';

// import GUESTS from '../../../graphql/queries/allGuests.gql';
import ThemedButton from './ThemedButton';
import axios from 'axios';
// import getConfig from 'next/config';
// import { Router } from '../../../routes';
// import { withRouter } from 'next/router';
// const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const getSuccessRedirectUrl = () => {
  console.log('window.previousPath', window.previousPath);
  console.log('referrer', document.referrer);
  const referrer = window.previousPath || '';

  const isBhipReferrer = referrer.match(/bhipmanagement/i);
  const isLoginPage = window.location.href.match(
    /^(.*bhipmanagement)(.*login)/s
  );
  // const { sendto } =  Router.router.query;
  // if (sendto) {

  // }

  if (isLoginPage && isBhipReferrer) {
    if (!referrer.match('/login')) {
      console.log('sendto1', referrer);
      return referrer;
    }
  } else if (!isLoginPage) {
    console.log('sendto2', window.location);
    return window.location;
  }
  console.log('sendto3', window.location.origin);
  return window.location.origin + '/calendar';
};

const loginWithGoogle = () => {
  // alert("IN")
  // Cookies.set('token', result.data.token);
  const successRedirectUrl = getSuccessRedirectUrl();
  const failureRedirectUrl = `${window.location.origin}/login`;
  window.location.href = `${process.env.BHIP_API_URL}/auth/google?successRedirect=${successRedirectUrl}&failureRedirect=${failureRedirectUrl}`;
};
const Login = () => {
  return (
    <div className="card p-3 mt-3">
      <Formik
        initialValues={{
          emailAddress: '',
          password: '',
          rememberMe: 'off',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
          //   const apiServerEndpoint = publicRuntimeConfig.BHIP_API_SERVER_PUBLIC;
          // setStatus({ error: true });
          // return;
          setSubmitting(true);
          try {
            const result = await axios.post(
              `${process.env.BHIP_API_URL}/auth/login`,
              {
                emailAddress: values.emailAddress,
                password: values.password,
              },
              { withCredentials: true }
            );

            if ((result.data || {}).token) {
              Cookies.set('token', result.data.token);
            }
            if ((result.data || {}).errors) {
              console.log('errors', (result.data || {}).errors);

              setStatus({ error: [(result.data || {}).errors[0].message] });
              setSubmitting(false);
              // setStatus({ isSubmitted: true });
              return false;
              // setSubmitting(false);
            } else {
              // const isBhipReferrer = document.referrer.match(/bhipmanagement/i);
              // const isReferrerLoginPage = document.referrer.match(/^(.*bhipmanagement)(.*login)/s)
              // const isLoginPage = window.location.href.match(/^(.*bhipmanagement)(.*login)/s)
              // const params = new URLSearchParams(window.location.search);
              // if (params.get('sendto')) {
              //     window.location.href = `${window.location.origin}${params.get('sendto')}`;
              // } else if (isLoginPage) {
              //     Router.pushRoute('/')
              // } else { // if the user is on a page with restricted access with the login overlay that is NOT the login page, allow the user to stay on the same page after login
              //     location.reload()
              // }

              window.location.href = getSuccessRedirectUrl();

              setErrors(null);
            }
          } catch (err) {
            // setErrors({ errors: err });
            setStatus({ error: err });
            setSubmitting(false);
            // setStatus({ isSubmitted: true });
            return false;
          }
          setSubmitting(false);
          setStatus({ isSubmitted: true });
          setStatus({ success: true });
        }}
      >
        {formikProps => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitCount,
            status,
          } = formikProps;
          console.log('status', status);
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="mb-1">Email</label>
                <input
                  className="form-control"
                  name="emailAddress"
                  type="email"
                  value={values.emailAddress}
                  onChange={handleChange}
                  // placeholder={values.lastName ? values.lastName : 'Enter a last name'}
                  //   tag={Field}
                  invalid={
                    errors.emailAddress && touched.emailAddress
                      ? 'true'
                      : 'false'
                  }
                />
                <div className="text-danger">
                  {touched.emailAddress &&
                    errors.emailAddress &&
                    errors.emailAddress}
                </div>
              </div>
              <div className="form-group">
                <label className="mb-1">Password</label>
                <input
                  className="form-control"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  value={values.password}
                  // placeholder={values.lastName ? values.lastName : 'Enter a last name'}
                  //   tag={Field}
                  invalid={
                    errors.password && touched.password ? 'true' : 'false'
                  }
                />
                <div className="text-danger">
                  {touched.password && errors.password && errors.password}
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    className="form-control"
                    type="checkbox"
                    name="rememberMe"
                    className="custom-control-input"
                    id="customSwitch1"
                    onChange={e => setFieldValue('rememberMe', e.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch1"
                  >
                    Remember Me
                  </label>
                </div>
              </div>

              <FormResponse
                errors={get(status, 'error')}
                alert={Object.keys(errors).length > 0 && submitCount > 0}
                isVisible={
                  get(status, 'error') ||
                  get(status, 'success') ||
                  (Object.keys(errors).length > 0 && submitCount > 0)
                }
              >
                {get(status, 'success')}
              </FormResponse>

              {/* <FormResponse
                errors={errors}
                formikProps={formikProps}
              ></FormResponse> */}
              {/* {query.error && (
                <div className="alert w-100" color="danger" isOpen={true}>
                  {query.error === 'USER_NOT_FOUND' ? (
                    <div>
                      Sorry, that user was not found. Please contact an
                      administrator to authorize your email address.
                    </div>
                  ) : (
                    <div>{query.error}</div>
                  )}
                </div>
              )} */}
              <ThemedButton
                type="submit"
                loading={isSubmitting}
                className="w-100 mb-2 btn btn-outline-primary"
              >
                Submit
              </ThemedButton>
              <div className="text-muted text-center">
                <p className="mb-2">or</p>
                <a href="#!" onClick={loginWithGoogle}>
                  <img src={GoogleSignIn} />
                </a>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
