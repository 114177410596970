import React, { useState } from 'react';

const DropdownSelector = ({
  selectedOption,
  defaultValue,
  options,
  onSelect,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    selectedOption || defaultValue
  );
  const buildSelectList = () => {
    const selectList = options.reduce((acc, option, index) => {
      if (
        selectedValue !== option &&
        (option !== defaultValue || selectedValue !== defaultValue)
      ) {
        acc.push(
          <button
            data-class={`dropdown-selector - ${option}`}
            key={`${option}_${index}`}
            className="dropdown-selector-dropdown-item dropdown-item"
            onClick={e => {
              setSelectedValue(option);
              if (onSelect) {
                onSelect(option);
              }
              e.stopPropagation();
            }}
          >
            {option}
          </button>
        );
      }
      return acc;
    }, []);
    return selectList;
  };
  return (
    <div className={`${className} dropdown`}>
      <button
        onClick={e => e.stopPropagation()}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-class={
          selectedValue
            ? `dropdown-selector - ${selectedValue}`
            : `dropdown-selector - ${selectedValue}`
        }
        className={`btn pl-2 pr-3 dropdown-toggle dropdown-selector-toggle-button dropdownMenuButton`}
      >
        {selectedValue || defaultValue}
      </button>
      <div className="dropdown-menu dropdown-selector-dropdown-menu p-0">
        {buildSelectList()}
      </div>
    </div>
  );
};

export default DropdownSelector;
