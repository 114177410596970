import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Query from 'src/components/GraphQL/components/Query';
import get from 'lodash/get';
import LoginForm from '../../components/Forms/components/LoginForm';
import Layout from '../../layouts';
import UserProvider, { UserConsumer } from '../../contexts/User';

/*
const isLoggedIn = () => {
  const min = Math.ceil(1);
  const max = Math.floor(2);
  const random = Math.floor(Math.random() * (max - min + 1)) + min;
  return true; // random === 1;
};

*/

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  // console.log('location.pathname', location.pathname);
  return (
    <UserProvider>
      <UserConsumer>
        {({ state }) => {
          const role = get(state, 'userData.role') || null;
          return (
            <>
              {role === 'ANONYMOUS' && location.pathname !== `/login` ? (
                <Layout>
                  <div className="container mt-4 mb-0 ml-2 mr-0">
                    <div className="row">
                      <div className="col col-12 col-md-6">
                        <h1>Please Log In</h1>
                        <span>
                          You are not authorized to view this page without
                          logging in.
                        </span>
                        <LoginForm />
                      </div>
                    </div>
                  </div>
                </Layout>
              ) : (
                <Component location={location} {...rest} />
              )}
            </>
          );
        }}
      </UserConsumer>
    </UserProvider>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
