import React from 'react';
import { navigate } from 'gatsby';

const GuestsInnerTable = ({ guests, count }) => {
  return (
    <>
      <thead>
        <tr>
          <td className="font-weight-bold text-center house-number">
            First Name
          </td>
          <td className="font-weight-bold text-center">Last Name</td>
          <td className="font-weight-bold text-center">Email</td>
          <td className="font-weight-bold text-center">Phone Number</td>
          <td className="font-weight-bold text-center">Block</td>
          <td className="font-weight-bold text-center">Source</td>
        </tr>
      </thead>
      <tbody>
        {count === 0 && (
          <tr>
            <td colSpan="5" className="no-leads-heading mt-2">
              No guests to display
            </td>
          </tr>
        )}
        {guests &&
          guests.map((guest, index) => (
            <tr
              onClick={() => navigate(`/guests/${guest.id}`)}
              key={`guest-${index + 1}`}
            >
              <td className="text-center">{guest.firstName}</td>
              <td className="text-center">{guest.lastName}</td>
              <td className="text-center">
                {(guest.primaryEmailAddress || {}).emailAddress}
              </td>
              <td className="text-center">
                {(guest.primaryPhoneNumber || {}).phoneNumber}
              </td>
              <td className="text-center">
                {guest.blockType != 'NONE' && guest.blockType}
              </td>
              <td className="text-center">{(guest.guestSource || {}).name}</td>
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default GuestsInnerTable;
