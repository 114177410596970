import React, { useState } from 'react';
import gql from 'graphql-tag';
import Query from '../../components/GraphQL/components/Query';
import LoadingSpinner from '../../components/LoadingSpinner';
import withLocation from '../../components/WithLocation';
import Layout from '../../layouts';
import get from 'lodash/get';
import ScrollTable from '../../components/ScrollTable';
import ThemedPager from '../../components/ThemedPager/index.js';
import { navigate } from 'gatsby';
import JSONTree from 'react-json-tree';
import Modal from '../../components/Modal';
import moment from 'moment';

const GET_EMPLOYEE_ACTIVITY = gql`
  query EmployeeActivity($employeeId: Int, $skip: Int, $first: Int) {
    employee(where: { id: $employeeId }) {
      firstName
    }
    employeeActivityLogsConnection(
      where: { employeeId: $employeeId }
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          operationName
          query
          variables
          createdAt
          userAgent
        }
      }
      aggregate {
        count
      }
    }
  }
`;

// const dataSource = [
//   {
//     type: 'Employees',
//     collapsed: false,
//     people: [
//       {name: 'Paul Gordon', age: 25, sex: 'male', role: 'coder', collapsed: false},
//       {name: 'Sarah Lee', age: 23, sex: 'female', role: 'jqueryer', collapsed: false},
//     ],
//   },
//   {
//     type: 'CEO',
//     collapsed: false,
//     people: [
//       {name: 'Drew Anderson', age: 35, sex: 'male', role: 'boss', collapsed: false},
//     ],
//   },
// ];

const EmployeeActivity = ({ location, ...props }) => {
  const { id } = props;
  const params = new URLSearchParams(location.search);
  const [pageNumber, setPageNumber] = useState(
    params.get('page') ? Number(params.get('page')) : 1
  );
  const recordsPerPage = 10;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContentTitle, setModalContentTitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  return (
    <Layout>
      <Query
        variables={{
          employeeId: Number(id),
          first: recordsPerPage,
          skip: (pageNumber - 1) * recordsPerPage,
        }}
        query={GET_EMPLOYEE_ACTIVITY}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className="container-fluid pt-3 text-center m-auto">
                <LoadingSpinner />
              </div>
            );
          if (error)
            return <div className="conatiner-fluid pt-3">{error.message}</div>;
          const activity =
            get(data, 'employeeActivityLogsConnection.edges') || null;
          const count =
            get(data, 'employeeActivityLogsConnection.aggregate.count') || 0;
          const getModalBodyContent = modalTitle => {
            switch (modalTitle) {
              case 'Variables': {
                return (
                  <JSONTree
                    // shouldExpandNode={() => false}
                    shouldExpandNode={() => {
                      return true;
                    }}
                    theme={{
                      tree: ({ style }) => {
                        return {
                          style: {
                            ...style,
                            // color: white,
                            backgroundColor: undefined,
                          },
                        };
                      },
                    }}
                    data={modalContent}
                  />
                );
              }
              case 'Query': {
                return (
                  <textarea
                    className="w-100"
                    id="query"
                    name="query"
                    rows="20"
                    cols="20"
                    readOnly
                    defaultValue={modalContent}
                  />
                );
              }
              default:
                return null;
            }
          };
          return (
            <>
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col">
                    <div className="d-flex align-items-center mb-3">
                      <h1 className="mr-2 mb-1">
                        Employee Activity ({data.employee.firstName})
                      </h1>
                    </div>
                    <div
                      className="card pl-3 pr-3 d-block"
                      // style={{ maxWidth: '1100px' }}
                    >
                      <ScrollTable>
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="align-middle font-weight-bold"
                              >
                                Operation Name
                              </th>
                              <td
                                scope="col"
                                className="align-middle text-center font-weight-bold"
                              >
                                Variables
                              </td>
                              <td
                                scope="col"
                                className="align-middle text-center font-weight-bold"
                              >
                                Query
                              </td>
                              <td
                                scope="col"
                                className="align-middle text-center font-weight-bold"
                              >
                                Created At
                              </td>
                              <td
                                scope="col"
                                className="align-middle text-center font-weight-bold"
                              >
                                User Agent
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {activity &&
                              activity.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <th scope="row" className="align-middle">
                                      {item.node.operationName}
                                    </th>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-link"
                                        onClick={() => {
                                          setModalContentTitle('Variables');
                                          setModalIsOpen(true);
                                          setModalContent(
                                            JSON.parse(item.node.variables)
                                          );
                                        }}
                                      >
                                        View Variables
                                      </button>
                                    </td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-link"
                                        onClick={() => {
                                          setModalContentTitle('Query');
                                          setModalIsOpen(true);
                                          setModalContent(item.node.query);
                                        }}
                                      >
                                        View Query
                                      </button>
                                    </td>
                                    <td className="text-center align-middle">
                                      {moment(item.node.createdAt).format(
                                        'MM/DD/YYYY hh:mm A'
                                      )}
                                    </td>
                                    <td className="text-left align-middle">
                                      {item.node.userAgent}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </ScrollTable>
                    </div>
                  </div>
                </div>
              </div>
              <ThemedPager
                className="mt-0"
                totalRecords={count}
                dataConnection={data.employeeActivityLogsConnection}
                recordsPerPage={10}
                pageNumber={pageNumber}
                changePageNumber={pageNumber => {
                  setPageNumber(pageNumber);
                  navigate(`/employees/${id}/activity?page=${pageNumber}`);
                }}
              />
              <Modal
                isOpen={modalIsOpen}
                title={modalContentTitle}
                onClose={() => setModalIsOpen(false)}
                disableForceFocus={true}
              >
                <>{getModalBodyContent(modalContentTitle)}</>
              </Modal>
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withLocation(EmployeeActivity);
