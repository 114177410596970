import React from 'react';
import Tooltip from '../../../Tooltip';
import styles from './styles.module.scss';

const DropdownFilterSelect = ({
  selectedFilter,
  options,
  defaultLabel,
  onFilterSelect,
  tooltipData,
  id,
  calendar,
  customToggleButtonStyles,
  extendedOptions,
  customDropdownClasses,
  dropdownMenuAlignmentClass,
  hasReponsiveLabel,
}) => {
  const filterLabel = selectedFilter.length > 0 ? selectedFilter : defaultLabel;
  const getDropdownList = options => {
    const dropdownList =
      options &&
      options.reduce((acc, option, index) => {
        if (typeof option === 'object') {
          if (
            selectedFilter &&
            selectedFilter.length > 0 &&
            selectedFilter !== option.key
          ) {
            acc.push(
              <button
                key={`${option.key}_${index}`}
                className={`${
                  customDropdownClasses
                    ? `${customDropdownClasses}`
                    : `rounded-0 text-left w-100 btn ${styles.filterDropdownItem} d-block`
                } `}
                data-class={option.key}
                onClick={() => onFilterSelect(option.key)}
              >
                {option.label}
              </button>
            );
          }
          return acc;
        }
        if (
          selectedFilter &&
          selectedFilter.length > 0 &&
          selectedFilter !== option
        ) {
          acc.push(
            <button
              key={`${option}_${index}`}
              className={`${
                customDropdownClasses
                  ? `${customDropdownClasses}`
                  : `rounded-0 text-left w-100 btn ${styles.filterDropdownItem} d-block`
              } `}
              data-class={option}
              onClick={() => onFilterSelect(option)}
            >
              {option}
            </button>
          );
        }
        return acc;
      }, []);

    return dropdownList;
  };
  const abbreviateLabel = resolution => {
    // return filterLabel.length < 14
    //   ? filterLabel
    //   : `${filterLabel.substring(0, 14)}...`;
    switch (resolution) {
      case 'large': {
        return filterLabel.length < 30
          ? filterLabel
          : `${filterLabel.substring(0, 30)}...`;
      }
      case 'medium': {
        return filterLabel.length < 10
          ? filterLabel
          : `${filterLabel.substring(0, 10)}...`;
      }
      case 'small': {
        return filterLabel.length < 5
          ? filterLabel
          : `${filterLabel.substring(0, 5)}...`;
      }
      case 'xsmall': {
        return `${filterLabel.substring(0, 4)}...`;
      }
    }
  };
  const getAbbreviatedFilterLabel = resolution => {
    // return filterLabel.length < 14
    //   ? filterLabel
    //   : `${filterLabel.substring(0, 14)}...`;
    switch (resolution) {
      case 'large':
        return abbreviateLabel('large');
      case 'medium':
        return abbreviateLabel('medium');
      case 'small':
        return abbreviateLabel('small');
      case 'xsmall':
        return abbreviateLabel('xsmall');
    }
  };
  const DropdownFilterToggle = ({
    customToggleButtonStyles,
    customDropdownClasses,
    dropdownMenuAlignmentClass,
  }) => {
    const ResponsiveLabel = () => {
      return (
        <>
          <div title={filterLabel} className={styles.labelLargeResolution}>
            {getAbbreviatedFilterLabel('large')}
          </div>
          <div title={filterLabel} className={styles.labelMediumResolution}>
            {getAbbreviatedFilterLabel('medium')}
          </div>
          <div title={filterLabel} className={styles.labelSmallResolution}>
            {getAbbreviatedFilterLabel('small')}
          </div>
          <div title={filterLabel} className={styles.labelXSmallResolution}>
            {getAbbreviatedFilterLabel('xsmall')}
          </div>
          <div className={styles.labelXLargeResolution}>{filterLabel}</div>
        </>
      );
    };
    return (
      <button
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        className={`${
          customToggleButtonStyles
            ? customToggleButtonStyles
            : `btn  px-2 ${styles.dropdownToggle} dropdown-toggle filter-button text-left dropdownMenuButton`
        }`}
      >
        {tooltipData ? (
          <Tooltip label={tooltipData} placement="bottom" id={id}>
            {hasReponsiveLabel ? <ResponsiveLabel /> : filterLabel}
          </Tooltip>
        ) : (
          <> {hasReponsiveLabel ? <ResponsiveLabel /> : filterLabel}</>
        )}
      </button>
    );
  };
  return (
    <div
      // id="dropdown-with-overflow"
      className={`dropdown filter-dropdown text-left `}
    >
      <DropdownFilterToggle
        customToggleButtonStyles={customToggleButtonStyles}
        customDropdownClasses={customDropdownClasses}
        dropdownMenuAlignmentClass={dropdownMenuAlignmentClass}
      />
      <div
        id="dropdown-with-overflow"
        className={`dropdown-menu filter-dropdown-menu p-0 ${dropdownMenuAlignmentClass &&
          dropdownMenuAlignmentClass}`}
      >
        {getDropdownList(options)}
        {extendedOptions &&
          extendedOptions.map((option, index) => {
            return (
              <button
                key={`${option.key}_${index}`}
                className={`${
                  customDropdownClasses
                    ? `${customDropdownClasses}`
                    : `rounded-0 text-left w-100 btn ${styles.filterDropdownItem} d-block`
                }`}
                data-class={option.key}
                onClick={() => onFilterSelect(option.key)}
              >
                {option.label}
              </button>
            );
          })}
      </div>
    </div>
  );
};

DropdownFilterSelect.defaultProps = {
  selectedFilter: [],
  tooltipData: false,
  customToggleButtonStyles: false,
  extendedOptions: false,
  customDropdownClasses: false,
  dropdownMenuAlignmentClass: false,
};

export default DropdownFilterSelect;
