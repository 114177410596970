import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import Query from 'src/components/GraphQL/components/Query';
import { Mutation, withApollo } from 'react-apollo';
import FormResponse from 'src/components/Forms/components/FormResponse';
import ThemedButton from 'src/components/Forms/components/ThemedButton';

import gql from 'graphql-tag';

const SYNC_FINALIZATION_STEPS = gql`
  mutation syncGuestReservationFinalizationSteps(
    $reference: String!
    $guestReservationFinalizationStepOptionIds: [Int]
  ) {
    syncGuestReservationFinalizationSteps(
      where: { reference: $reference }
      data: {
        guestReservationFinalizationStepOptionIds: $guestReservationFinalizationStepOptionIds
      }
    ) {
      id
      reference
      finalizationSteps {
        id
        label
        readOnly
      }
    }
  }
`;

const FinalizedOptionsForm = ({
  reference,
  finalizationSteps,
  finalizationOptions,
  onSuccess,
}) => {
  const getDefaultValues = options => {
    return options.reduce((acc, option) => {
      const found = finalizationSteps.find(step => step?.id === option?.id);

      if (found) {
        acc.push(`${option?.id}`);
      }
      return acc;
    }, []);
  };

  return (
    <Mutation mutation={SYNC_FINALIZATION_STEPS} fetchPolicy="no-cache">
      {(syncFinalizationSteps, { data }) => {
        return (
          <Formik
            // enableReinitialize={true}
            initialValues={{
              checked: getDefaultValues(finalizationOptions),
            }}
            // validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, setErrors }
            ) => {
              //   const apiServerEndpoint = publicRuntimeConfig.BHIP_API_SERVER_PUBLIC;

              setSubmitting(false);
              const readOnlyStepIds = finalizationSteps.reduce((acc, val) => {
                if (val.readOnly) {
                  acc.push(val.id);
                }
                return acc;
              }, []);

              const guestReservationFinalizationStepOptionIds = values?.checked?.reduce(
                (acc, value) => {
                  if (!readOnlyStepIds.includes(Number(value))) {
                    acc.push(Number(value));
                  }
                  return acc;
                },
                []
              );

              setSubmitting(true);
              try {
                const result = await syncFinalizationSteps({
                  variables: {
                    reference,
                    guestReservationFinalizationStepOptionIds,
                  },
                });

                if (result) {
                  setStatus({ success: true });
                  onSuccess(
                    result?.data?.syncGuestReservationFinalizationSteps
                  );
                }
                setSubmitting(false);
                //   setStatus({ isSubmitted: true });
              } catch (err) {
                console.log('err', err);
                // setErrors({ errors: err });
                setStatus({ error: err });
              }
            }}
          >
            {formikProps => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                submitCount,
                status,
              } = formikProps;

              const CheckBoxInput = ({ option, customClasses }) => {
                const foundStep = finalizationSteps.find(
                  step => step?.id === option?.id
                );

                const isReadOnly = foundStep?.readOnly || false;

                return (
                  <div
                    className="form-group mb-1"
                    key={`${option?.id}_${option?.stage}`}
                  >
                    <div
                      className={`custom-control custom-checkbox ${customClasses}`}
                    >
                      <Field
                        disabled={isReadOnly}
                        id={option?.id}
                        className="custom-control-input form-control _disabled"
                        type="checkbox"
                        name="checked"
                        value={`${option?.id}`}
                      />
                      <label
                        className="custom-control-label font-weight-bold"
                        htmlFor={option?.id}
                      >
                        {option?.label}
                      </label>
                    </div>
                  </div>
                );
              };

              const stage1Options = finalizationOptions.map(option => {
                if (option?.stage === 1) {
                  return (
                    <CheckBoxInput
                      key={`${option?.stage}_${option?.id}`}
                      option={option}
                    />
                  );
                }
              });
              const stage2Options = finalizationOptions.map(option => {
                if (option?.stage === 2) {
                  return (
                    <CheckBoxInput
                      key={`${option?.stage}_${option?.id}`}
                      option={option}
                    />
                  );
                }
              });
              const stage3Options = finalizationOptions.map(option => {
                if (option?.stage === 3) {
                  return (
                    <CheckBoxInput
                      key={`${option?.stage}_${option?.id}`}
                      option={option}
                      customClasses={'pl-auto pl-lg-0'}
                    />
                  );
                }
              });
              const stage4Options = finalizationOptions.map(option => {
                if (option?.stage === 4) {
                  return (
                    <CheckBoxInput
                      key={`${option?.stage}_${option?.id}`}
                      option={option}
                      customClasses={'pl-auto pl-lg-0'}
                    />
                  );
                }
              });

              return (
                <form onSubmit={handleSubmit}>
                  <section>
                    <p className="font-weight-bold">Check to confirm:</p>
                  </section>
                  <section className="d-block d-lg-flex justify-content-between mb-3">
                    <div id="left-column">
                      <div className="mb-auto mb-lg-4">{stage1Options}</div>
                      <div>{stage2Options}</div>
                    </div>
                    <div id="right-column">
                      <div className="mb-auto mb-lg-4">{stage3Options}</div>
                      <div>{stage4Options}</div>
                    </div>
                  </section>

                  <FormResponse
                    errors={status?.error}
                    isVisible={status?.error || status?.success}
                  >
                    {status?.success}
                  </FormResponse>
                  <ThemedButton
                    type="submit"
                    loading={isSubmitting}
                    className="w-100 btn btn-outline-primary"
                  >
                    Save
                  </ThemedButton>
                </form>
              );
            }}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default withApollo(FinalizedOptionsForm);
